import React, { useEffect, useState } from 'react';
import styles from './profile.module.scss';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { useFormHandler } from '../../utils/useFormHandler';
import { ProfileRequestData, updateStudentProfile } from '../../api/profile/profile';

interface AddressDetailsTabProps {
    onClose: () => void;
    profile?: any; // Profile type should be defined appropriately
}

const AddressDetailsTab: React.FC<AddressDetailsTabProps> = ({ onClose, profile }) => {
    const [form] = Form.useForm();
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode


    // Handler for form submission
    const onFinish = useFormHandler(
        async (values: ProfileRequestData) => {
            // Call API to update the student profile
            return await updateStudentProfile(values);
        },
        form,
        () => {
            // Success message and close the form
            message.success('Profile updated successfully!');
            setIsEditable(false)
            onClose();
        }
    );

    return (
        <div>

            <div className={styles.header}>
                <h2>Address Details</h2>
                <Button type="default" onClick={() => setIsEditable(prev => !prev)}>
                    {isEditable ? "Cancel" : "Edit"}
                </Button>
            </div>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                initialValues={profile.student}
                className={styles.form}
                disabled={!isEditable} // Disable the form if not editable
            >
                {/* Permanent Address */}
                <Form.Item
                    label="Permanent Address"
                    name="permanent_address"
                    rules={[{required: true, message: 'Please input your permanent address!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Permanent Address"/>
                    ) : (
                        <span>{profile.student.permanent_address || "N/A"}</span>
                    )}
                </Form.Item>

                {/* Permanent Address Pincode */}
                <Form.Item
                    label="Permanent Address Pincode"
                    name="permanent_address_pincode"
                    rules={[{required: true, message: 'Please input your permanent address pincode!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <InputNumber placeholder="Enter Permanent Address Pincode"/>
                    ) : (
                        <span>{profile.student.permanent_address_pincode || "N/A"}</span>
                    )}
                </Form.Item>

                {/* Correspondence Address */}
                <Form.Item
                    label="Correspondence Address"
                    name="correspondence_address"
                    rules={[{required: true, message: 'Please input your correspondence address!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Correspondence Address"/>
                    ) : (
                        <span>{profile.student.correspondence_address || "N/A"}</span>
                    )}
                </Form.Item>

                {/* Correspondence Address Pincode */}
                <Form.Item
                    label="Correspondence Address Pincode"
                    name="correspondence_address_pincode"
                    rules={[{required: true, message: 'Please input your correspondence address pincode!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <InputNumber placeholder="Enter Correspondence Address Pincode"/>
                    ) : (
                        <span>{profile.student.correspondence_address_pincode || "N/A"}</span>
                    )}
                </Form.Item>

                {/* Footer with buttons */}
                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? 'Update' : 'Submit'}
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default AddressDetailsTab;
