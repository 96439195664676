import {Avatar, Breadcrumb, Button, Drawer, Dropdown, Input, List, message, Space, Spin, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {FaEllipsisV, FaRegFolder} from "react-icons/fa";
import {LuFileSpreadsheet} from "react-icons/lu";
import {CalendarOutlined, UploadOutlined} from "@ant-design/icons";
import {
    createCourseFolder,
    deleteCourseFile,
    deleteCourseFolder,
    getCourseFolderStructure,
    renameCourseFile,
    renameCourseFolder,
    toggleLockCourseFile,
    updateCourseOrder,
    uploadCourseFile
} from "../../api/course/createFolderFiles";
import {
    createBatchFolder,
    deleteBatchFile,
    deleteBatchFolder,
    getBatchFolderStructure,
    getBatchRecordedLiveClasses,
    renameBatchFile,
    renameBatchFolder,
    toggleLockBatchFile,
    updateBatchOrder,
    uploadBatchFile
} from "../../api/batch/batch";
import {
    createResourceFolder,
    deleteResourceFile,
    deleteResourceFolder,
    getResourceFolderStructure,
    renameResourceFile,
    renameResourceFolder,
    toggleLockResourceFile,
    updateResourceOrder,
    uploadResourceFile
} from "../../api/freeResources/freeResource";
import {BreadCrumbResponse, FileResponse, FolderResponse, Recording} from "../../types/folderFiles";
import {BASE_URL} from "../../utils/apiClient";
import styles from "./contentmodal.module.scss"
import {getCourseRecordedLiveClasses} from "../../api/course/course";
import {IoArrowDownCircleOutline, IoArrowUpCircleOutline} from "react-icons/io5";
import {formattedDateTime} from "../../utils/utils";
import {Images} from "../../common/Images";

interface ContentModalProps {
    onClose: () => void;
    open: boolean;
    type: "course" | "batch" | "resource";
    course?: any;
    batch?: any;
    resource?: any;
}


function ClockCircleOutlined() {
    return null;
}

const ContentModal: React.FC<ContentModalProps> = ({onClose, open, type, course, batch, resource}) => {
    const [items, setItems] = useState<(FolderResponse | FileResponse)[]>([]);
    const [currentPath, setCurrentPath] = useState<BreadCrumbResponse[]>([]);
    const [newFolderAdded, setNewFolderAdded] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    const [parentFolderId, setParentFolderId] = useState<number | null>(null);
    const [selectedItem, setSelectedItem] = useState<FolderResponse | FileResponse | null>(null); // To track the item for renaming
    const [recordedClasses, setRecordedClasses] = useState<Recording[]>([]);
    const [showRecordedClasses, setShowRecordedClasses] = useState(false);
    const [uploadingSpinner, setUploadingSpinner] = useState(false)

    // Determine the entity based on type
    const entity = type === "batch" ? batch : type === "resource" ? resource : course;
    const contentType = type === "batch" ? "Batch" : type === "resource" ? "Resource" : "Course";

    // Define API functions based on type
    const api = {
        getFolderStructure: type === "batch" ? getBatchFolderStructure : type === "resource" ? getResourceFolderStructure : getCourseFolderStructure,
        createFolder: type === "batch" ? createBatchFolder : type === "resource" ? createResourceFolder : createCourseFolder,
        uploadFile: type === "batch" ? uploadBatchFile : type === "resource" ? uploadResourceFile : uploadCourseFile,
        deleteItem: type === "batch" ? deleteBatchFolder : type === "resource" ? deleteResourceFolder : deleteCourseFolder,
        deleteFile: type === "batch" ? deleteBatchFile : type === "resource" ? deleteResourceFile : deleteCourseFile,
        renameItem: type === "batch" ? renameBatchFolder : type === "resource" ? renameResourceFolder : renameCourseFolder,
        renameFile: type === "batch" ? renameBatchFile : type === "resource" ? renameResourceFile : renameCourseFile,
        toggleLock: type === "batch" ? toggleLockBatchFile : type === "resource" ? toggleLockResourceFile : toggleLockCourseFile,
        updateOrder: type === "batch" ? updateBatchOrder : type === "resource" ? updateResourceOrder : updateCourseOrder,
        getRecordedLiveClasses: type === "batch" ? getBatchRecordedLiveClasses : getCourseRecordedLiveClasses,  // Add this for course
    };

    // Fetch initial folder structure
    const fetchInitialFolderStructure = async (folderId: number | null) => {
        try {
            if (entity) {
                const initialStructure = await api.getFolderStructure(entity.id, {folder_id: folderId});
                setItems(initialStructure.folder_structure.items || []);
                setCurrentPath(initialStructure.breadcrumb);
                setParentFolderId(initialStructure.folder_structure.id);
            }
        } catch (error) {
            message.error(`Error fetching ${contentType.toLowerCase()} folder structure.`);
        }
    };

    useEffect(() => {
        if (entity && entity.id) {
            fetchInitialFolderStructure(null); // Fetch root structure initially
        }
    }, [entity?.id]);


    const handleMove = async (item: any, direction: string, index: number) => {
        await api.updateOrder(entity.id, item.id, item.type, direction);
        fetchInitialFolderStructure(parentFolderId)

    };

    const menuItems = (item: FolderResponse | FileResponse, index: number) => {
        const menuItemsArray = [];

        if (item.type !== "folder") {
            menuItemsArray.push({
                key: "4",
                label: "View",
                onClick: (e: any) => {
                    handleView(item);
                },
            });
        }

        menuItemsArray.push({
            key: "1",
            label: "Rename",
            onClick: (e: any) => {
                handleRename(item);
            },
        });

        menuItemsArray.push({
            key: "2",
            label: "Delete",
            onClick: (e: any) => {
                handleDelete(item);
            },
        });

        if (item.type !== "folder" && type !== 'resource') {
            menuItemsArray.push({
                key: "3",
                label: item.is_locked ? <span style={{color: 'red'}}>Unlock</span> :
                    <span style={{color: 'green'}}>Lock</span>,
                onClick: (e: any) => {
                    handleToggleLock(item);
                },
            });
        }
        // if (index > 0) {
        //     menuItemsArray.push({
        //         key: "5",
        //         onClick: () => handleMove(item, 'up', index),
        //     });
        // }

        // // Move Down
        // if (index < items.length - 1) {
        //     menuItemsArray.push({
        //         key: "6",
        //         onClick: () => handleMove(item, 'down', index),
        //     });
        // }
        return {items: menuItemsArray};
    };

    // Handle view (if applicable)
    const handleView = (item: FileResponse) => {
        message.info(`Viewing file: ${item.title}`);
        // Assuming item.url contains the URL of the file
        const fileUrl = `${BASE_URL}${item.url}`;
        // Open the file in a new tab
        window.open(fileUrl, '_blank');
    };

    // Handle delete
    const handleDelete = async (item: FolderResponse | FileResponse) => {
        try {
            if (item.type === 'folder') {
                await api.deleteItem(entity.id, item.id);
            } else {
                await api.deleteFile(entity.id, item.id)
            }
            message.success(`${contentType} item deleted successfully!`);
            fetchInitialFolderStructure(parentFolderId)
        } catch (error) {
            message.error(`Error deleting ${contentType.toLowerCase()} item.`);
        }
    };

    // Handle rename
    const handleRename = (item: FolderResponse | FileResponse) => {
        setSelectedItem(item);
        setNewFolderName(item.title);
        message.info(`Renaming ${contentType.toLowerCase()} item: ${item.title}`);
    };

    // Confirm rename
    const confirmRename = async () => {
        if (!selectedItem || !newFolderName.trim()) {
            message.error("Name cannot be empty!");
            return;
        }

        const newData: string = newFolderName.trim();
        try {
            if (selectedItem.type === 'folder') {
                await api.renameItem(entity.id, selectedItem.id, newData);
            } else {
                await api.renameFile(entity.id, selectedItem.id, newData);
            }

            message.success(`${contentType} item renamed successfully!`);
            fetchInitialFolderStructure(parentFolderId)
            setSelectedItem(null);
            setNewFolderName("");
        } catch (error) {
            message.error(`Error renaming ${contentType.toLowerCase()} item.`);
        }
    };

    const fetchLiveClasses = async () => {
        try {
            const classes = await api.getRecordedLiveClasses(entity.id);
            const recorded: Recording[] = classes.recorded
            setRecordedClasses(recorded);
        } catch (error) {
            console.error("Failed to fetch recorded live classes for batch:", error);
        }

    };


    // Handle toggle lock
    const handleToggleLock = async (item: FileResponse) => {
        try {
            await api.toggleLock(entity.id, item.id);
            message.success(`${contentType} file ${item.is_locked ? "unlocked" : "locked"} successfully!`);
            fetchInitialFolderStructure(parentFolderId)

        } catch (error) {
            message.error(`Error toggling lock for ${contentType.toLowerCase()} file.`);
        }
    };

    // Add new folder
    const addNewFolder = async () => {
        if (!newFolderName.trim()) {
            message.error("Folder name cannot be empty!");
            return;
        }

        const folderData = {
            title: newFolderName.trim(),
            parent: parentFolderId,
        };

        try {
            if (entity) {
                const response = await api.createFolder(entity.id, folderData);
                setItems(prev => [
                    {...response, type: 'folder'},
                    ...prev,
                ]);
                message.success(`${contentType} folder created successfully!`);
                setNewFolderAdded(false);
                setNewFolderName("");
                fetchInitialFolderStructure(parentFolderId);
            }
        } catch (error) {
            message.error(`Error creating ${contentType.toLowerCase()} folder.`);
        }
    };

    // Handle file upload
    const handleFileUpload = async ({file, onSuccess, onError}: any) => {
        try {
            if (entity) {
                const response = await api.uploadFile(entity.id, parentFolderId, file);
                setItems(prev => [
                    {...response, type: 'file'},
                    ...prev,
                ]);
                message.success(`${contentType} file uploaded successfully!`);
                onSuccess("ok");
                fetchInitialFolderStructure(parentFolderId);
            }
        } catch (error) {
            message.error(`Error uploading ${contentType.toLowerCase()} file.`);
            onError("Error");
        }
    };

    // Handle breadcrumb navigation
    const handleBreadcrumbClick = async (index: number) => {
        const newPath = currentPath.slice(0, index + 1);
        setCurrentPath(newPath);
        const newParentId = newPath[index]?.id;
        setParentFolderId(newParentId || null);
        await fetchInitialFolderStructure(newParentId);
    };

    const handleFolderClick = async (folder: FolderResponse) => {
        setCurrentPath([...currentPath, folder]);
        setParentFolderId(folder.id);
        await fetchInitialFolderStructure(folder.id);
    };

    const toggleRecordedClasses = () => {
        setShowRecordedClasses((prev) => !prev); // Toggle state
        if (!showRecordedClasses) {
            fetchLiveClasses(); // Fetch recorded classes when opening
        }
    };

    const handleFileOnChange = (info:any) => {
        console.log(info.file, "demo")
        if (info.file.status === 'uploading') {
            setUploadingSpinner(true)
          }
          if (info.file.status === 'done') {
            setUploadingSpinner(false)
          } else if (info.file.status === 'error') {
            setUploadingSpinner(false)
          }
    }
    return (
        <Drawer title={`${contentType} Content`} onClose={onClose} open={open} width={1000}>
            <div className={styles.content}>
                <Button type="primary" onClick={() => setNewFolderAdded(true)}>
                    <FaRegFolder/> New Folder
                </Button>
                <Upload
                    customRequest={handleFileUpload}
                    showUploadList={false}
                    onChange={handleFileOnChange}
                >
                    <Button icon={<UploadOutlined/>}style={{color:'#008ebd', borderColor:"#008ebd",backgroundColor:"#ffffff"}}>
                    Upload File
                    </Button>
                    <Spin spinning={uploadingSpinner}/>
                </Upload>

                {type !== "resource" && (
                    <Button type={showRecordedClasses?"primary": "default"} onClick={toggleRecordedClasses}>
                        <FaRegFolder/> {showRecordedClasses ? "Hide Recorded Classes" : "Recorded Classes"}
                    </Button>
                )}
            </div>
            <Breadcrumb>
                {currentPath.map((crumb) => (
                    <Breadcrumb.Item key={crumb.id} onClick={() => handleBreadcrumbClick(currentPath.indexOf(crumb))}>
                        <span style={{cursor: "pointer"}}>{crumb.title}</span>
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
            {newFolderAdded && (
                <div className={styles.addFolderInputDiv} style={{display: 'flex', marginBottom: '16px', gap: "20px"}}>
                    <Input
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        placeholder="New Folder Name"
                    />
                    <Button type="primary" onClick={addNewFolder}>Add Folder</Button>
                </div>
            )}

            {!showRecordedClasses &&
                <List
                    itemLayout="horizontal"
                    dataSource={items}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                index > 0 && (
                                    <IoArrowUpCircleOutline
                                        key="arrow-up"
                                        onClick={() => handleMove(item, 'up', index)}
                                        className={styles.arrowIcon}
                                    />
                                ),
                                index < items.length - 1 && (
                                    <IoArrowDownCircleOutline
                                        key="arrow-down"
                                        onClick={() => handleMove(item, 'down', index)}
                                        className={styles.arrowIcon}
                                    />
                                ),
                                <Dropdown key="more" menu={menuItems(item, index)} trigger={['click']}>
                                    <Button icon={<FaEllipsisV/>}/>
                                </Dropdown>,
                            ]}
                        >


                            <List.Item.Meta
                                avatar={item.type === "folder" ? <FaRegFolder/> : <LuFileSpreadsheet/>}
                                title={
                                    selectedItem && selectedItem.id === item.id && item.type === selectedItem.type ? (
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Input
                                                value={newFolderName}
                                                onChange={(e) => setNewFolderName(e.target.value)}
                                                style={{marginRight: "8px"}}
                                            />
                                            <Button type="primary" onClick={confirmRename} style={{marginRight: "4px"}}>
                                                Update
                                            </Button>
                                            <Button
                                                onClick={(e) => {
                                                    setSelectedItem(null);
                                                    setNewFolderName("");
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    ) : (
                                        <span
                                            onClick={() => {
                                                if (item.type === "folder") {
                                                    handleFolderClick(item);
                                                }
                                            }}
                                            style={{cursor: "pointer"}} // Optional: to indicate it's clickable
                                        >
                                {item.title}
                                </span>
                                    )
                                }
                            />
                        </List.Item>
                    )}
                />}
            {showRecordedClasses && recordedClasses && recordedClasses.length > 0 &&
                <List
                    itemLayout="horizontal"
                    dataSource={recordedClasses}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar size={64} src={Images.video_recording}/>}
                                title={
                                    <a
                                        href={item.recording_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{color: "inherit"}} // Optional: Inherit text color
                                    >
                                        {item.title}
                                    </a>
                                }
                                description={
                                    <>
                                        <Space>
                                            <ClockCircleOutlined/>
                                            <span>Duration: {item.duration}</span>
                                        </Space>
                                        <br/>
                                        <Space>
                                            <CalendarOutlined/>
                                            <span>On: {formattedDateTime(item.date)}</span>
                                        </Space>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            }

        </Drawer>
    );
};

export default ContentModal;
