import React, {useState} from 'react';
import {Button, Drawer, Form, Input, InputNumber, message} from 'antd';
import {useFormHandler} from "../../utils/useFormHandler";
import {StudentRequestData, updateStudent} from "../../api/student/studentApi";
import styles from "./addStudentDrawer.module.scss";
import UploadField from "../uploadField";

interface EducationalDetailsDrawerProps {
    open: boolean;
    onClose: () => void;
    student?: any; // Define the student type according to your needs
}

const EducationalDetailsDrawer: React.FC<EducationalDetailsDrawerProps> = ({open, onClose, student}) => {
    const [form] = Form.useForm();


    // Custom form handler
    const onFinish = useFormHandler(
        async (values: StudentRequestData) => {
            return await updateStudent(student.id, values);
        },
        form,
        () => {
            message.success('Educational details updated successfully!');
            onClose();
        }
    );

    return (
        <Drawer
            title="Educational Details"
            placement="right"
            width={'30%'}
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={student.student}
                style={{width: "70%"}}
            >
                <Form.Item
                    label="School Name"
                    name="school_name"
                    rules={[{required: true, message: "Please input your school name!"}]}
                >
                    <Input placeholder="Enter School Name"/>
                </Form.Item>
                <Form.Item
                    label="College Name"
                    name="college_name"
                    rules={[{required: true, message: "Please input your college name!"}]}
                >
                    <Input placeholder="Enter College Name"/>
                </Form.Item>
                <Form.Item
                    label="Marks in X (%)"
                    name="marks_x"
                    rules={[{required: true, message: "Please input marks in X!"}]}
                >
                    <InputNumber placeholder="Enter Marks in X" style={{width: "100%"}}/>
                </Form.Item>

                <UploadField form={form} name={'x_result'} title={'X Result'}/>
                <Form.Item
                    label="Marks in XII (%)"
                    name="marks_xii"
                    rules={[{required: true, message: "Please input marks in XII!"}]}
                >
                    <InputNumber placeholder="Enter Marks in XII" style={{width: "100%"}}/>
                </Form.Item>

                <UploadField form={form} name={'xii_result'} title={'XII Result'}/>
                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        {student ? "Update" : "Submit"}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default EducationalDetailsDrawer;
