import React, {createContext, ReactNode, useEffect, useState} from 'react';
import apiClient from '../utils/apiClient';
import {getAccessToken, removeTokens, setAccessToken, setRefreshToken} from "../utils/auth";

export interface ProfileResponseProps {
    id:number;
    role: string;
    full_name: string;
    email: string;
    phone_number: string;
}

export interface UserRoleProps {
    id:number;
    userRole: string;
    full_name: string;
    email: string;
    phone_number: string;
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: UserRoleProps | null;
    loading: boolean;
    login: (accessToken: string, refreshToken: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<UserRoleProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const accessToken = getAccessToken()
        if (accessToken) {
            fetchProfileData(accessToken); // Fetch user profile if accessToken exists
        } else {
            setLoading(false);
        }
    }, []);

    const fetchProfileData = async (token: string) => {
        try {
            const response = await apiClient.get<ProfileResponseProps>('/api/profile/');
            const {phone_number, full_name, email, role, id} = response.data;
            setUser({userRole: role, full_name, email, phone_number,id});
            setIsAuthenticated(true);
        } catch (error) {
            console.error('Failed to fetch profile data', error);
            logout();
        } finally {
            setLoading(false);
        }
    };

    const login = (accessToken: string, refreshToken: string) => {
        setRefreshToken(refreshToken)
        setAccessToken(accessToken)
        fetchProfileData(accessToken); // Fetch user profile on login
    };

    const logout = () => {
        removeTokens()
        setUser(null);
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout, user, loading}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
