import React from 'react';
import {Modal} from 'antd';
import styles from "./deleteWarningModal.module.scss"
import {MdDeleteOutline} from 'react-icons/md';

interface props {
    open: boolean
    onClose: () => void;
    onConfirm: any,
    message: string,
    okBtnLoading?: boolean,
}

const DeleteWarningModal: React.FC<props> = ({open, onClose, onConfirm, message, okBtnLoading}) => {

    return (
        <Modal
            title={<div className={styles.deleteHeader}><MdDeleteOutline/> Delete Confirmation</div>}
            centered
            className={styles.deleteModal}
            open={open}
            onOk={onConfirm}
            onCancel={onClose}
            okText="Yes, Delete"
            cancelText="Cancel"
            okButtonProps={{danger: true, loading: okBtnLoading}}
        >
            <p className={styles.warningMsg}>{message}</p>
        </Modal>
    );
};

export default DeleteWarningModal;
