import React from "react";
import {Button, Drawer, Form, Input, message} from "antd";
import styles from "./announcement.module.scss";
import {useFormHandler} from "../../utils/useFormHandler";
import {AnnouncementRequestData, createAnnouncement} from "../../api/announcement/announcementApi";

interface AnnouncementDrawerProps {
    open: boolean;
    onClose: () => void;
    course?: { id: number; name: string }; // Define appropriate type for course
    batch?: { id: number; name: string };  // Define appropriate type for batch
}

const AnnouncementCreateDrawer: React.FC<AnnouncementDrawerProps> = ({
                                                                         open,
                                                                         onClose,
                                                                         course,
                                                                         batch
                                                                     }) => {
    const [form] = Form.useForm();


    const onFinish = useFormHandler(
        async (values: AnnouncementRequestData) => {
            // Directly create an announcement since update is not needed
            return await createAnnouncement(values);
        },
        form,
        () => {
            message.success("Announcement created successfully!");
            onClose();
        }
    );

    const drawerTitle = course
        ? `Create Course Announcement - ${course.name}`
        : batch
            ? `Create Batch Announcement - ${batch.name}`
            : "Create Global Announcement";

    return (
        <Drawer
            title={drawerTitle}
            width={"40%"}
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >


                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            message: "Please enter the announcement title",
                        },
                    ]}
                >
                    <Input placeholder="Enter title"/>
                </Form.Item>

                <Form.Item
                    name="message"
                    label="Message"
                    rules={[
                        {
                            required: true,
                            message: "Please enter the announcement Message",
                        },
                    ]}
                >
                    <Input.TextArea rows={4} placeholder="Enter message"/>
                </Form.Item>

                {/* Hidden fields for course, batch, and is_global */}
                <Form.Item name="is_global" hidden initialValue={!course && !batch}/>
                {course && <Form.Item name="course" hidden initialValue={course.id}/>}
                {batch && <Form.Item name="batch" hidden initialValue={batch.id}/>}

                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default AnnouncementCreateDrawer;
