import React, {useEffect} from 'react';
import {Card, List, Typography} from 'antd';
import styles from "./announcementbatch.module.scss"
import PageHeader from "../../common/page-header/PageHeader";
import {useStudentAnnouncementList} from "../../api/announcement/announcementApi";
import {formattedDateTime} from '../../utils/utils';
import { BatchProps } from '../../types/batch';

const {Text} = Typography;

interface AnnouncementsProps {
    batch: BatchProps;
}

const AnnouncementsBatch: React.FC<AnnouncementsProps> = ({batch}) => {
    const {data, fetchWithParams} = useStudentAnnouncementList(false);

    useEffect(() => {
   
        if (batch?.id) {
            fetchWithParams({batch: batch.id});
        }
    }, [batch?.id]);

    

    return (
        <div className={styles.container}>
            <PageHeader heading="Announcements"/>
            <List
                itemLayout="horizontal"
                dataSource={data}
                className={styles.announcementList}
                renderItem={(item:any) => (
                    <List.Item className={styles.announcementListItem}>
                        <h5 className={styles.announcementTitle}>
                            {item.title}
                        </h5>
                        <p className={styles.announcementMsg}>{item.message}</p>
                        <div className={styles.announcementByDiv}>
                            <p>Announce By: <br/><strong>{item.created_by || 'Unknown'}</strong></p>
                            <p>Announced At: <br/><strong>{formattedDateTime(item.created)}</strong></p>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default AnnouncementsBatch;
