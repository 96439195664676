import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

// Define the request data type for creating a subject
interface SubjectRequestData {
    name: string;
    description: string;
    is_active: boolean;
}

// Define the response type for subject creation
interface SubjectResponse {
    message: string;
    subject_id: number;  // Example of response data (adjust based on your backend)
}

// Define the createSubject function
export const createSubject = async (values: SubjectRequestData): Promise<SubjectResponse> => {
    const response = await apiClient.post<SubjectResponse>('/api/batch/subjects/', values);
    return response.data;
};

// Define the request data type for updating a subject
interface UpdateSubjectRequestData {
    name?: string;
    description?: string;
    is_active?: boolean;
}

// Define the response type for subject update
interface UpdateSubjectResponse {
    message: string;
    subject_id: number;  // Example of response data (adjust based on your backend)
}

// Define the updateSubject function
export const updateSubject = async (subjectId: number, values: UpdateSubjectRequestData): Promise<UpdateSubjectResponse> => {
    const response = await apiClient.put<UpdateSubjectResponse>(`/api/batch/subjects/${subjectId}/`, values);
    return response.data;
};

export const useSubjectList = () => {
    return useFetchList("/api/batch/subjects/");
};
