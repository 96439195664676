// PermissionsContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { ModulePermissions } from "../types/permissions"; // Assuming ModulePermissions is imported from your types
import { getPermissions } from "../api/user/userApi";
import { getAccessToken } from "../utils/auth";

// Define the structure of permissions for each module
interface PermissionsContextType {
    permissions: ModulePermissions[];  // Update to use an array of ModulePermissions
    loading: boolean;
    fetchPermissions: () => Promise<void>; // Expose fetchPermissions
}

// Create context with proper typings
const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

export const usePermissions = (): PermissionsContextType => {
    const context = useContext(PermissionsContext);
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionsProvider');
    }
    return context;
};

interface PermissionsProviderProps {
    children: ReactNode;
}

export const PermissionsProvider: React.FC<PermissionsProviderProps> = ({ children }) => {
    const [permissions, setPermissions] = useState<ModulePermissions[]>([]);  // Use an array of ModulePermissions
    const [loading, setLoading] = useState<boolean>(true);

    const fetchPermissions = async () => {
        console.log('Fetching permissions...');
        try {
            const data = await getPermissions();

            // Assuming `data` is an array of type ModulePermissions
            setPermissions(data);

            console.log('Set permissions:', data);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('useEffect fired');
        const accessToken = getAccessToken()
        if (accessToken) {
            fetchPermissions();
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <PermissionsContext.Provider value={{ permissions, loading, fetchPermissions }}>
            {children}
        </PermissionsContext.Provider>
    );
};
