import { NotificationProps } from "../../types/notification";
import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

interface NotificationData {
    batch: number;
    course: number;
    student_ids: number;
    title: string;
    message: string;
    criteria: string;
    action:string;

}

interface NotificationResponse {
    message: string
}
export const createNotification = async (values: NotificationData): Promise<NotificationResponse> => {
    const response = await apiClient.post<NotificationResponse>(`/api/notification/send-push-notification/`, values);
    return response.data;
}

export const useNotificationList = () => {
    return useFetchList<NotificationProps>("/api/notification/notifications");
};