import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient"; // Assuming you have an apiClient setup

export interface EnquiryRequestData {
    student_name: string;
    phone_number: string;
    enquiry_date: string;
    assign_lead: number;
    source: string;
    enquiry_status: string;
    class_and_subject?: string;
    followup_type: string;
    followup_date: string;
    followup_time: string;
    notes?: string;
    id?: number; // Add id for update operations
}

export const createEnquiry = async (values: EnquiryRequestData) => {
    const response = await apiClient.post<EnquiryRequestData>('/api/lead/leads/', values,);
    return response.data;
};

export const updateEnquiry = async (id: number, values: EnquiryRequestData) => {
    const response = await apiClient.patch<EnquiryRequestData>(`/api/lead/leads/${id}/`, values,);
    return response.data;
};
export const useLeadList = () => {
    return useFetchList("/api/lead/leads/");
};

export const deleteEnquiry = async (enquiryId: number) => {
    const response = await apiClient.delete(`/api/lead/leads/${enquiryId}/`);
    return response.data;
};
