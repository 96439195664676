import React from 'react';
import styles from "./coursesList.module.scss";
import { formattedDateTime } from "../../utils/utils";
import { CourseProps } from "../../types/course";
import cn from "classnames"

export const getEffectivePriceValidity = (course: CourseProps) => {
    if (course.validity_type === 'multiple' && course.validity_periods.length > 0) {
        // Sort by promoted status and return the effective price from the first valid period
        const promotedPeriod = [...course.validity_periods].sort((a, b) => {
            if (a.is_promoted && !b.is_promoted) return -1;
            if (!a.is_promoted && b.is_promoted) return 1;
            return 0;
        })[0];

        return {
            duration_value: promotedPeriod.duration_value,
            duration_unit: promotedPeriod.duration_unit,
            price: promotedPeriod.price,
            effective_price: promotedPeriod.effective_price
        };
    }

    return null; // Return null if no valid periods or not 'multiple'
};
interface CoursePriceProps {
    course: CourseProps;
    className?:string;
}

const CoursePrice: React.FC<CoursePriceProps> = ({ course, className }) => {
    const validity = getEffectivePriceValidity(course);

    return (
        <div className={cn(styles.tagsDiv,className)}>
            <p className={styles.durationCourse}>
                {course.validity_type === 'multiple' && validity && (
                    <>
                        <div key={course.id}>
                            {/* Show validity duration */}
                           Duration:  <span>{validity.duration_value} {validity.duration_unit}</span>,
                            {/* Show course price */}
                        </div>
                        <div>
                           Multiple Validity Available
                        </div>
                    </>
                )}

                {course.validity_type === 'single' && course.duration_value && (
                    <>
                        Duration: <span>{course.duration_value} {course.duration_unit}</span>
                    </>
                )}

                {course.validity_type === 'lifetime' && (
                    <>
                        Duration: <span>Lifetime Access</span>
                    </>
                )}

                {course.validity_type === 'expiry_date' && course.expiry_date && (
                    <>
                        Expiry Date: <span>{formattedDateTime(course.expiry_date)}</span>
                    </>
                )}
            </p>

            <h6 className={styles.priceCourse}>
                {/* Show effective price for multiple validity courses */}
                {validity && validity.effective_price ? (
                    <span>₹{validity.effective_price}</span>
                ) : (
                    <span>₹{course.effective_price}</span>
                )}
                {/* Show the base price */}
                {validity && validity.price ? (
                    <div>₹{validity.price}</div>
                ): (
                    <div>₹{course.price}</div>
                )}
            </h6>
        </div>
    );
};

export default CoursePrice;
