import React, {useEffect, useState} from "react";
import {Card, Col, message, Row, Tag} from "antd";
import PageHeader from "../../common/page-header/PageHeader";
import StatsCard from "../../common/StatsCard/StatsCard";
import cn from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import {FiEyeOff, FiUpload, FiVideo} from 'react-icons/fi';
import styles from "./courseDetailPage.module.scss"
import PageLoader from "../../common/page-loader/PageLoader";
import moment from "moment";
import {CiEdit} from "react-icons/ci";
import {CgFileDocument} from "react-icons/cg";
import ContentModal from "../../components/ContentModel/ContentModel";
import {Action, ModuleName, Role} from "../../utils/Role";
import {TfiAnnouncement} from "react-icons/tfi";
import {AiFillStar} from "react-icons/ai";
import {FaChalkboardTeacher} from "react-icons/fa";
import {BsCurrencyRupee} from "react-icons/bs";
import {useFormHandler} from "../../utils/useFormHandler";
import {deleteCourse, detailCourse, updateCourse, UpdateCourseRequestData} from "../../api/course/course";
import CreateCourse from "../../components/create-course-drawer/CreateCourseDrawer";
import CoursePriceModal from "../../components/course-price-modal/CoursePriceModal";
import AnnouncementCreateDrawer from "../Announcement/AnnouncementCreateDrawer";
import AssignFacultyDrawer from "../../components/AssignFaculty/AssignFaculty";
import {formattedDateTime} from "../../utils/utils";
import {MdDeleteForever} from "react-icons/md";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import LiveClassList from "./LiveClassList";
import LiveClassDrawer from "./liveClassDrawer";

const CourseDetailPage = () => {

    const {courseId} = useParams<{ courseId: any }>();
    const [course, setCourse] = useState<any>(null)
    const [openCreateCourse, setOpenCreateCourse] = useState(false);
    const [openPriceModal, setOpenPriceModal] = useState(false);
    const [openContentModal, setOpenContentModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(true); // State for loading status
    const [openAnnouncementDrawer, setOpenAnnouncementDrawer] = useState(false);
    const [isFacultyDrawerOpen, setIsFacultyDrawerOpen] = useState(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
    const [liveClassDrawer, setLiveClassDrawer] = useState(false)
    const [deleteBtnLoading, setDeleteBtnLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null); // State for error handling

    const navigate = useNavigate()

    const fetchCourseDetails = async () => {
        if (!courseId) {
            setError('Course ID is missing'); // Handle missing courseId
            setLoading(false);
            return;
        }

        try {
            const courseData: any = await detailCourse(courseId); // Fetch course details
            setCourse(courseData); // Set the fetched data to state
        } catch (err) {
            setError('Failed to fetch course details'); // Set error message if any
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };
    useEffect(() => {
        fetchCourseDetails(); // Call the fetch function
    }, [courseId]); // Dependency array to re-fetch if courseId changes



    const onBackClick = () => {
        navigate('/courses')
    }

    const handleCourseOpenDrawer = () => {
        setOpenCreateCourse(true)
    }

    const handlePriceOpenDrawer = () => {
        setOpenPriceModal(true)
    }

    const handleContentOpenDrawer = () => {
        setOpenContentModal(true)
    }
    const handleAnnouncementOpenDrawer = () => {
        setOpenAnnouncementDrawer(true)
    }

    const handleOpenFacultyDrawer = () => {
        setIsFacultyDrawerOpen(true);
    };

    const handleLiveClasses = () => {
        setLiveClassDrawer(true);
    }

    const handleCloseLiveClassesDrawer = async () => {
        setLiveClassDrawer(false)
        await fetchCourseDetails()
    }

    const onFeatureFinish = useFormHandler(
        async () => {
            const updatedCourseData: UpdateCourseRequestData = {
                is_featured: !course.is_featured, // Update the is_published field
            };
            await updateCourse(course.id!, updatedCourseData);
        },
        null,
        () => {
            fetchCourseDetails()
            message.success('Course updated successfully!');
        }
    );
    const onPublishFinish = useFormHandler(
        async () => {
            const updatedCourseData: UpdateCourseRequestData = {
                is_published: !course.is_published, // Update the is_published field
            };
            await updateCourse(course.id!, updatedCourseData);
        },

        null,
        () => {
            fetchCourseDetails()
            message.success('Course updated successfully!');
        }
    );

    const onDeleteCourseFinish = useFormHandler(
        async () => {
            setDeleteBtnLoading(true)
            await deleteCourse(course.id)
        },
        null,
        () => {
            setDeleteBtnLoading(false)
            message.success('course deleted successfully!');
        }
    )

      // Render loading, error, or course details
    if (loading) return <PageLoader/>;
    if (error) return <div>{error}</div>;


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Course Detail" onBackClick={onBackClick}/>
            <Row gutter={24}>
                {/* Left side: Form */}
                <Col xs={24} md={16}>
                    <Col xs={24} md={24}>
                        <div className={styles.detailViewCard}>
                            <div className={styles.labelValData}>
                                <label>Course Name</label>
                                <span>{course?.name}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Regular Price</label>
                                <span>{course?.price && <div>₹{course?.price}</div>}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Discounted Price</label>
                                <span>{course?.effective_price ? <span>₹{course?.effective_price}</span> :
                                    <i>Not Added</i>}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Created At</label>
                                <span>{moment(course?.created).format("DD MMM YYYY, HH:mm")}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Created By</label>
                                <span>{course?.created_by}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Total Enrolled</label>
                                <span>{course?.total_enrolled_students}</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={24}>
                        <h4 className={styles.sectionHeading}>Category and SubCategory</h4>
                        <div className={styles.detailViewCard}>
                            {course.categories_subcategories.map((cat: any) =>
                                <div className={styles.categoryDiv}>
                                    <div className={styles.labelValData}>
                                        <label>Category</label>
                                        <span>{cat?.category.title}</span>
                                    </div>
                                    <div className={styles.labelValData}>
                                        <label>Sub Category</label>
                                        <span>{cat.subcategories.join(", ")}</span>
                                    </div>
                                </div>)}
                        </div>
                    </Col>

                    <Col xs={24} md={24}>
                        <h4 className={styles.sectionHeading}>Schedule Live Classes</h4>
                        <LiveClassList course={course} fetchCourseDetails={fetchCourseDetails}/>
                    </Col>
                    <Col xs={24} md={24}>
                        <h4 className={styles.sectionHeading}>Price</h4>
                        <div className={cn(styles.detailViewCard, styles.priceDiv)}>
                            <div className={styles.labelValData}>
                                <label>Duration Type </label>
                                <span>{course?.validity_type}</span>
                            </div>

                            {course.validity_type === 'multiple' &&
                                <div className={styles.durationTypeContainer}>
                                    {course?.validity_periods.map((period: any) => (
                                        <div key={period.id} className={styles.durationBox}>
                                            <h6>
                                                {period.duration_value} {period.duration_unit}
                                                {period.is_promoted && (
                                                    <Tag className={styles.promo}>Promoted</Tag>
                                                )}
                                            </h6>
                                            <h4>₹ {period.effective_price}</h4>
                                        </div>
                                    ))}
                                </div>
                            }

                            {course?.validity_type !== 'multiple' &&
                                <React.Fragment>
                                    <div className={styles.labelValData}>
                                        <label>Duration</label>
                                        {course?.validity_type === 'single' && course?.duration_value && (
                                            <span>{course.duration_value} {course?.duration_unit}</span>
                                        )}

                                        {course?.validity_type === 'lifetime' && (
                                            <span>Lifetime Access</span>
                                        )}

                                        {course?.validity_type === 'expiry_date' && course?.expiry_date && (
                                            <span>{formattedDateTime(course?.expiry_date)}</span>
                                        )}
                                    </div>
                                    <div className={styles.labelValData}>
                                        <label>Duration Unit </label>
                                        <span>{course?.duration_unit}</span>
                                    </div>
                                    <div className={styles.labelValData}>
                                        <label>Course Price </label>
                                        <span>₹{course?.price}</span>
                                    </div>
                                    <div className={styles.labelValData}>
                                        <label>Discounted Price </label>
                                        <span>₹{course?.discount}</span>
                                    </div>
                                </React.Fragment>}

                            {/* <div className={styles.labelValData}>
                                <label>Effective Price </label>
                                <span>{course?.effective_price}</span>
                            </div> */}
                        </div>
                    </Col>
                    <Col xs={24} md={24}>
                        <h4 className={styles.sectionHeading}>Description</h4>
                        <div className={cn(styles.detailViewCard, styles.descriptionDiv)}>
                            <p>{course?.description || "No Description"}</p>
                        </div>
                    </Col>

                </Col>
                {/* Right side: Go Live and Add Student */}
                <Col xs={24} md={8}>
                    <div className={styles.detailViewAction}>
                        <Card title="Actions">
                            <Row gutter={[0, 16]}>
                                <Role moduleName={ModuleName.COURSES} action={Action.EDIT}>
                                    <Col span={24}>
                                        <StatsCard onClick={handleCourseOpenDrawer}
                                                   icon={<CiEdit/>}
                                                   title="Edit"
                                                   description=""/>
                                    </Col>
                                </Role>
                                <Col span={24}>
                                    <StatsCard onClick={handlePriceOpenDrawer}
                                               icon={<BsCurrencyRupee/>}
                                               title="Price"
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard
                                        onClick={handleContentOpenDrawer}
                                        icon={<CgFileDocument/>}
                                        title="Content"
                                        description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard
                                        icon={<FaChalkboardTeacher/>}
                                        title="Assign Instructor"
                                        description=""
                                        onClick={handleOpenFacultyDrawer}/>
                                </Col>

                                <Col span={24}>
                                    <StatsCard
                                        icon={<FiVideo/>}
                                        title="Go Live"
                                        description=""
                                        onClick={handleLiveClasses}
                                    />
                                </Col>

                                <Col span={24}>
                                    <StatsCard onClick={handleAnnouncementOpenDrawer}
                                               icon={<TfiAnnouncement/>}
                                               title="Annoucements"
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={onPublishFinish}
                                               icon={course?.is_published ? <FiEyeOff/> : <FiUpload/>}
                                               title={course?.is_published ? "Unpublish" : "Publish"}
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={onFeatureFinish}
                                               icon={<AiFillStar/>}
                                               title={course?.is_featured ? "Unfeatured" : "Feature"}
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard
                                        onClick={() => setVisibleDeleteModal(true)}
                                        icon={<MdDeleteForever/>}
                                        title="Delete Course"
                                        description=""/>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>

            {openContentModal && course &&
                <ContentModal
                    open={openContentModal}
                    course={course}
                    type={"course"}
                    onClose={() => setOpenContentModal(false)}
                />
            }

            {/* Drawer Component */}
            {openCreateCourse &&
                <CreateCourse
                    open={openCreateCourse}
                    course={course}
                    fetchUpdatedData={fetchCourseDetails}
                    onClose={() => setOpenCreateCourse(false)}
                />
            }

            {openPriceModal &&
                <CoursePriceModal
                    open={openPriceModal}
                    course={course}
                    fetchUpdatedData={fetchCourseDetails}
                    onClose={() => setOpenPriceModal(false)}
                />
            }
            {openContentModal &&
                <ContentModal
                    open={openContentModal}
                    course={course}
                    type={'course'}
                    onClose={() => setOpenContentModal(false)}
                />
            }
            {openAnnouncementDrawer &&
                <AnnouncementCreateDrawer
                    open={openAnnouncementDrawer}
                    course={course}
                    onClose={() => setOpenAnnouncementDrawer(false)}
                />
            }

            {liveClassDrawer && course &&
                <LiveClassDrawer
                    open={liveClassDrawer}
                    course={course}
                    onClose={handleCloseLiveClassesDrawer}
                />
            }

            <DeleteWarningModal
                okBtnLoading={deleteBtnLoading}
                open={visibleDeleteModal}
                onConfirm={onDeleteCourseFinish}
                onClose={() => setVisibleDeleteModal(false)}
                message={"Are you sure, want to delete this Course?"}
            />
            {isFacultyDrawerOpen &&
                <AssignFacultyDrawer
                    open={isFacultyDrawerOpen}
                    onClose={() => setIsFacultyDrawerOpen(false)}
                    course={course}
                />
            }

        </div>
    );
};

export default CourseDetailPage;
