import styles from "./reports.module.scss";
import cn from "classnames";
import { Button, Input, Select, Tag } from "antd";
import { FiSearch } from "react-icons/fi";
import { useEffect, useState } from "react";
import { Table } from "antd";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
// import { STUDENT_REPORT_DATA } from "../../utils/constants";
import PageHeader from "../../common/page-header/PageHeader";
import AddReportDrawer from "../../components/report-drawer/AddReportDrawer";

const Reports = () => {
  const [data, setData] = useState<any[]>([]);
  const [openAddReportDrawer, setOpenAddReportDrawer] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);
  const [showCourseDetails, setShowCourseDetails] = useState(false);

    const getPerformColor=(score:any)=>{
       switch (true){
            case score>89:
                return "cyan";

            case score>69:  
                return "green"
            
            case score>59:
                return "gold"

        default: 
            return "red"    
       }
    }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Enroll Date',
      dataIndex: 'enrollDate',
      key: 'enrollDate',
    },
    {
      title: 'Test Score',
      dataIndex: 'testScore',
      key: 'testScore',
      render:(testScore:any)=>
      <Tag color={getPerformColor(testScore)}>
      {testScore}
    </Tag>
    },
    {
      title: 'Attendance',
      dataIndex: 'attendance',
      key: 'attendance',
      render:(attendance:any)=>
        <Tag color={getPerformColor(attendance)}>
        {`${attendance}%`}
      </Tag>
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
  ];

  useEffect(() => {
    setTableLoading(true);
    setTimeout(() => {
      // setData(STUDENT_REPORT_DATA);
      setTableLoading(false);
    }, 1000);
  }, []);

  return (
    <div className={cn("container-fluid")}>
      <PageHeader heading="Reports" />
      <section className={styles.actionBtnsSection}>
        <div className="row">
          <div className="col-sm-7 col-12">
            <div className={styles.filterDiv}>
              <Input placeholder="Search by name" prefix={<FiSearch />} />

              <Select className={styles.sortSelect} placeholder="Sort By">
                <Select.Option value="courseName">Student Name</Select.Option>
                <Select.Option value="newest">Active</Select.Option>
                <Select.Option value="lowToHigh">InActive</Select.Option>
              </Select>
            </div>
          </div>
          <div className="col-sm-5 col-12">
            <div className={styles.actionBtnDiv}>
              <Button
                onClick={() => setOpenAddReportDrawer(true)}
                type="primary"
              >
                <FaPlus /> Add Report
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.tableSection}>
        <div className={styles.tableCard}>
          <Table loading={tableLoading} columns={columns} dataSource={data} />
        </div>
      </section>
      <AddReportDrawer
      onClose={()=>setOpenAddReportDrawer(false)}
      open={openAddReportDrawer}
      />
    </div>
  );
};
export default Reports;
