import { BannerProps } from "../../types/banner";
import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";


interface BannerData {
   
    title: string;
    image:string;
    link:string;
    is_active:boolean;
    action:string;

}

interface BannerResponse {
    message: string
}
export const createBanner = async (values:BannerData): Promise<BannerResponse> => {
    const response = await apiClient.post<BannerResponse>(`/api/mobile/banners/`, values);
    return response.data;
}


interface UpdateBannerData {
    title: string;
    image: string;
    link:string;
    is_active?: boolean;
    action:string;
}


interface UpdateBannerResponse {
    message: string;
    banner_id: number;  
}

export const updateBanner = async (bannerId: number, values: UpdateBannerData): Promise<UpdateBannerResponse> => {
    const response = await apiClient.patch<UpdateBannerResponse>(`/api/mobile/banners/${bannerId}/`, values);
    return response.data;
};

export const useBannerList = () => {
    return useFetchList<BannerProps>("/api/mobile/banners");
};

export const deleteBanner = async (bannerId: number) => {
    const response = await apiClient.delete(`/api/mobile/banners/${bannerId}/`);
    return response.data;
};