import React from 'react';
import {List, Tag} from 'antd';
import styles from "./announcement.module.scss";
import PageHeader from "../common/page-header/PageHeader";
import {useStudentAnnouncementList} from "../api/announcement/announcementApi";
import {AnnouncementProps} from "../types/announcement";
import {formattedDateTime} from "../utils/utils";


const AnnouncementList: React.FC = () => {
    const {data} = useStudentAnnouncementList();


    return (
        <>
            <PageHeader heading="Announcements"/>
            <List
                bordered
                className={styles.announcementList}
                dataSource={data}
                renderItem={(announcement: AnnouncementProps) => (
                    <List.Item>
                        <List.Item.Meta
                            title={announcement.title}
                            description={
                                <>  
                                     <div className={styles.createdDetails}>
                                        {announcement.is_global ? (
                                            <Tag color="blue">Global</Tag>
                                        ) : (
                                            <>
                                                {announcement.batch &&
                                                    <Tag color="green">Batch - {announcement.batch.name}</Tag>}
                                                {announcement.course &&
                                                    <Tag color="purple">Course - {announcement.course.name}</Tag>}
                                            </>
                                        )}
                                        <p className={styles.createdDateText}>{formattedDateTime(announcement.created)}</p>
                                    </div>
                                    <p className={styles.description}>{announcement.message}</p>
                                   
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        </>
    );
};

export default AnnouncementList;
