import {Button, Checkbox, Drawer, Form, Input, InputNumber, message, Select} from "antd";
import React from "react";
import styles from "./coursePriceModal.module.scss";
import {useFormHandler} from "../../utils/useFormHandler";
import {updatePrice} from "../../api/course/updatePrice";
import cn from "classnames"
const {Option} = Select;

interface CourseProps {
    onClose: () => void;
    open: boolean;
    course: any;
    fetchUpdatedData?:()=>void
}

const CoursePriceModal: React.FC<CourseProps> = ({onClose, open, course,fetchUpdatedData}) => {
    const [form] = Form.useForm();

    const onFinish = useFormHandler(
        (values: any) => {
            const response= updatePrice(course.id, values);
            fetchUpdatedData && fetchUpdatedData()
            return response;
        },
        form,
        () => {
            message.success(course ? 'Category updated successfully!' : 'Category created successfully!');
            onClose();
        }
    );

    const handleValuesChange = (changedValues: any, allValues: any) => {
        const {price, discount} = allValues;
        const effectivePrice = price && discount ? price - (price * (discount / 100)) : price || 0;
        form.setFieldsValue({effective_price: effectivePrice});
    };

    return (
        <Drawer
            title="Course Pricing"
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.priceModal}
            footer={false}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={handleValuesChange}
                className={styles.modalFormStyle}
                initialValues={course}
            >
                <div className="row">
                    <div className="col-12">
                        <Form.Item
                            label="Course Duration Type"
                            name="validity_type"
                            rules={[{required: true, message: "Please select a duration type"}]}
                        >
                            <Select placeholder="Select Duration">
                                <Option value="single">Single Validity</Option>
                                <Option value="lifetime">Lifetime Validity</Option>
                                <Option value="expiry_date">Course Expiry Type</Option>
                                <Option value="multiple">Multiple Validity</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <Form.Item noStyle dependencies={['validity_type']}>
                        {({getFieldValue}) => {
                            const validityType = getFieldValue('validity_type');

                            if (validityType === 'single') {
                                return (
                                    <>
                                        <div className="col-sm-6 col-12">
                                            <Form.Item
                                                label="Duration Value"
                                                name="duration_value"
                                                rules={[{required: true, message: "Please input duration value"}]}
                                            >
                                                <InputNumber controls={false} min={0} placeholder="Enter duration value"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <Form.Item
                                                label="Duration Unit"
                                                name="duration_unit"
                                                rules={[{required: true, message: "Please select a duration unit"}]}
                                            >
                                                <Select placeholder="Select Unit">
                                                    <Option value="days">Days</Option>
                                                    <Option value="months">Months</Option>
                                                    <Option value="years">Years</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </>
                                );
                            }

                            if (validityType === 'expiry_date') {
                                return (
                                    <div className="col-12">
                                        <Form.Item
                                            label="Expiry Date"
                                            name="expiry_date"
                                            rules={[{required: true, message: "Please input the expiry date"}]}
                                        >
                                            <Input type="date"/>
                                        </Form.Item>
                                    </div>
                                );
                            }

                            if (validityType === 'multiple') {
                                return (
                                    <Form.List name="validity_periods">
                                        {(fields, {add, remove}) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <div key={field.key} className={cn(styles.validityPeriod,"row")}>
                                                        <div className="col-sm-6 col-12">
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'duration_value']}
                                                                label="Duration Value"
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Please input duration value"
                                                                }]}
                                                            >
                                                                <InputNumber controls={false} min={0}
                                                                             placeholder="Enter duration value"/>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-sm-6 col-12">
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'duration_unit']}
                                                                label="Duration Unit"
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Please select a duration unit"
                                                                }]}
                                                            >
                                                                <Select placeholder="Select Unit">
                                                                    <Option value="days">Days</Option>
                                                                    <Option value="months">Months</Option>
                                                                    <Option value="years">Years</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-sm-6 col-12">
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'price']}
                                                                label="Course Price"
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Please input the course price"
                                                                }]}
                                                            >
                                                                <InputNumber controls={false} min={0} placeholder="Enter course price"/>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-sm-6 col-12">
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'discount']}
                                                                label="Discounted Price"
                                                            >
                                                                <InputNumber controls={false} min={0}
                                                                             placeholder="Enter discounted price"/>
                                                            </Form.Item>
                                                        </div>
                                                        {/* <div className="col-sm-4 col-12">
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'effective_price']}
                                                                label="Effective Price"
                                                            >
                                                                <InputNumber disabled/>
                                                            </Form.Item>
                                                        </div> */}
                                                        <div className="col-4 col-12">
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'is_promoted']}
                                                                valuePropName="checked"
                                                            >
                                                                <Checkbox>
                                                                    Select to promote this plan
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                        <Button
                                                            onClick={() => remove(field.name)}
                                                            type="dashed"
                                                            danger
                                                            style={{marginBottom: 8}}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                ))}
                                                <Button onClick={() => add()} type="dashed" style={{width: '100%'}}>
                                                    Add Validity Period
                                                </Button>
                                            </>
                                        )}
                                    </Form.List>
                                );
                            }

                            return null;
                        }}
                    </Form.Item>
                    <Form.Item noStyle dependencies={['validity_type']}>
                        {({getFieldValue}) => {
                            const validityType = getFieldValue('validity_type');

                            if (validityType !== 'multiple') {
                                return (
                                    <>
                                        <div className="col-sm-6 col-12">
                                            <Form.Item
                                                name="price"
                                                label="Course Price"
                                                rules={[{required: true, message: "Please input the course price"}]}
                                            >
                                                <InputNumber controls={false} min={0} placeholder="Enter course price"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-sm-6 col-12">
                                            <Form.Item
                                                name="discount"
                                                label="Discounted Price"
                                                rules={[{
                                                    required: true,
                                                    message: "Please input the discount percentage"
                                                }]}
                                            >
                                                <InputNumber controls={false} min={0} placeholder="Enter discount percentage"/>
                                            </Form.Item>
                                        </div>
                                        {/* <div className="col-sm-4 col-12">
                                            <Form.Item name={'effective_price'} label="Effective Price">
                                                <InputNumber disabled/>
                                            </Form.Item>
                                        </div> */}
                                    </>
                                );
                            }

                            return null;
                        }}
                    </Form.Item>

                    <div className={styles.formFooter}>
                        <Button onClick={onClose}>Close</Button>
                        <Button htmlType="submit" type="primary" style={{marginLeft: '8px'}}>
                            Update
                        </Button>
                    </div>
                </div>
            </Form>
        </Drawer>
    );
};

export default CoursePriceModal;
