import styles from "./coursesList.module.scss";
import cn from "classnames";
import ImageCard from "../../common/imageCard/ImageCard";
import PageHeader from "../../common/page-header/PageHeader";
import {Button, Input, Select} from "antd";
import {FiSearch} from "react-icons/fi";
import {useState} from "react";
import CreateCourse from "../../components/create-course-drawer/CreateCourseDrawer";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import CoursePriceModal from "../../components/course-price-modal/CoursePriceModal";
import {useCourseList} from "../../api/course/course";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import {BASE_URL} from "../../utils/apiClient";
import {AiFillStar} from "react-icons/ai";
import ContentModal from "../../components/ContentModel/ContentModel";
import {Action, ModuleName, Role} from "../../utils/Role";
import AnnouncementCreateDrawer from "../Announcement/AnnouncementCreateDrawer";
import AssignFacultyDrawer from "../../components/AssignFaculty/AssignFaculty";
import CoursePrice from "./CoursePricing";
import useDebounceValue from "../../hooks/useDebouncedValue";
import {Images} from "../../common/Images";


const CoursesList = () => {
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [openCreateCourse, setOpenCreateCourse] = useState(false);
    const [openPriceModal, setOpenPriceModal] = useState(false);
    const [openContentModal, setOpenContentModal] = useState(false);
    const [openAnnouncementDrawer, setOpenAnnouncementDrawer] = useState(false);
    const [isFacultyDrawerOpen, setIsFacultyDrawerOpen] = useState(false);
    const navigate = useNavigate()
    const {
        data,
        loading,
        fetchWithParams,
        pagination,
        loadMoreLoading,
        loadMore
    } = useCourseList();
    const {
        params,
        updateParams,
        updateSearch,
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 200);

    const onConfigClick = () => {
        navigate('/course-categories')
    }


    const handleCourseCloseDrawer = () => {
        setOpenCreateCourse(false)
        setSelectedCourse(null)
        resetParams()

    }

    const handlePriceCloseDrawer = () => {
        setOpenPriceModal(false)
        setSelectedCourse(null)
        resetParams()

    }


    const handleContentCloseDrawer = () => {
        setOpenContentModal(false)
        setSelectedCourse(null)
        resetParams()
    }
    const handleAnnouncementCloseDrawer = () => {
        setOpenAnnouncementDrawer(false)
        setSelectedCourse(null)
    }

    const handleCloseFacultyDrawer = () => {
        setIsFacultyDrawerOpen(false);
        setSelectedCourse(null)
        resetParams()

    };

    const handleNewCourseClick = (course: any) => {
        navigate(`/course/${course.id}`);
    }
    const handleSorting = (value: string) => {
        updateParams({ordering: value})
    }


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="All Courses" onConfigClick={onConfigClick}/>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name"
                                prefix={<FiSearch/>}
                                onChange={handleDebounceInputChange}
                            />

                            <Select onChange={handleSorting} className={styles.sortSelect} placeholder="Sort By">
                                <Select.Option value="newest">Newest</Select.Option>
                                <Select.Option value="oldest">Oldest</Select.Option>
                                <Select.Option value="lowToHigh">
                                    Price Low To High
                                </Select.Option>
                                <Select.Option value="highToLow">
                                    Price High To Low
                                </Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            {/* <Button>Featured</Button> */}
                            <Role moduleName={ModuleName.COURSES} action={Action.CREATE}>
                                <Button onClick={() => setOpenCreateCourse(true)} type="primary">
                                    <FaPlus/> Create Course
                                </Button>
                            </Role>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.coursesSection}>
                <div className="row">
                    {data.map((course: any, index: number) => {
                        return <div className="col-sm-4 col-12" onClick={() => handleNewCourseClick(course)}
                                    key={course.id}>
                            <ImageCard
                                className={styles.courseCard}
                                contentClassName={styles.courseListContent}
                                title={course.name}
                                // description={course.description}
                                image={
                                    course.thumbnail?`${BASE_URL}${course.thumbnail}`: Images.thumbnail
                                }
                            >

                                <div className={cn(styles.badge, {
                                    [styles.published]: course.is_published,
                                    [styles.unpublished]: !course.is_published
                                })}>
                                    {course.is_published ? "Published" : "Unpublished"}
                                </div>
                                {course.is_featured && (
                                    <AiFillStar className={styles.goldenStar}/>
                                )}
                                {/* <div className={styles.coursesActionBar}>
                                    <div onClick={() => handlePriceOpenDrawer(course)}><BsCurrencyRupee/> Price</div>
                                    <div onClick={() => handleContentOpenDrawer(course)}><BiBookContent/> Content</div>
                                    <div onClick={() => handleOpenFacultyDrawer(course)}><FaChalkboardTeacher/>Assign
                                        Faculty
                                    </div>
                                    <div onClick={() => handleAnnouncementOpenDrawer(course)}>
                                        <TfiAnnouncement/> Announcements
                                    </div>
                                    <Role moduleName={ModuleName.COURSES} action={Action.EDIT}>
                                        <div onClick={() => handleCourseOpenDrawer(course)}><CiEdit/> Edit</div>
                                    </Role>
                                    <div onClick={() => onPublishFinish(course)}>
                                        <CiEdit/>{course.is_published ? "Unpublish" : "Publish"}</div>
                                    <div onClick={() => {
                                        setOpenCourseDetails(true);
                                        setSelectedCourse(course)
                                    }}><MdOutlineRemoveRedEye/> View
                                    </div>
                                    <div onClick={() => onFeatureFinish(course)}>
                                        <AiFillStar/> {course.is_featured ? "Unfeatured" : "Feature"}
                                    </div>
                                </div> */}
                                <CoursePrice course={course}/>

                                <p className={styles.createdByTag}>
                                    Created By: <span>{course.created_by}</span>
                                </p>
                            </ImageCard>
                        </div>
                    })}
                    {pagination.next && (
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <Button type="primary" onClick={loadMore} disabled={loadMoreLoading}>
                                {loadMoreLoading ? 'Loading...' : 'Load More'}
                            </Button>
                        </div>
                    )}

                </div>
            </section>
            {openCreateCourse &&
                <CreateCourse
                    open={openCreateCourse}
                    course={selectedCourse}
                    onClose={handleCourseCloseDrawer}
                />
            }
            {openPriceModal && selectedCourse &&
                <CoursePriceModal
                    open={openPriceModal}
                    course={selectedCourse}
                    onClose={handlePriceCloseDrawer}
                />
            }
            {openContentModal && selectedCourse &&
                <ContentModal
                    open={openContentModal}
                    course={selectedCourse}
                    type={'course'}
                    onClose={handleContentCloseDrawer}
                />
            }

            {openAnnouncementDrawer && selectedCourse &&
                <AnnouncementCreateDrawer
                    open={openAnnouncementDrawer}
                    course={selectedCourse}
                    onClose={handleAnnouncementCloseDrawer}
                />
            }
            {isFacultyDrawerOpen && selectedCourse &&
                <AssignFacultyDrawer
                    open={isFacultyDrawerOpen}
                    onClose={handleCloseFacultyDrawer}
                    course={selectedCourse}
                />
            }
        </div>
    );
};
export default CoursesList;
