import apiClient from "../../utils/apiClient";

interface ResendOtpRequestData {
  
    reference_key: string;
}

interface ResendOtpResponse {
    success: boolean;
    message: string;
   
   
}

export const resendOtp = async (values: ResendOtpRequestData): Promise<ResendOtpResponse> => {
    const response = await apiClient.post<ResendOtpResponse>('/api/resend-otp/', values);
    return response.data;

};