import React, {useState} from 'react';
import {Button, Drawer, Form, Input, InputNumber, message} from 'antd';
import {useFormHandler} from "../../utils/useFormHandler";
import {StudentRequestData, updateStudent} from "../../api/student/studentApi"; // Ensure this fits your API
import styles from "./addStudentDrawer.module.scss";
import UploadField from "../uploadField";

interface ParentInformationDrawerProps {
    open: boolean;
    onClose: () => void;
    student?: any; // Define the student type according to your needs
}

const ParentInformationDrawer: React.FC<ParentInformationDrawerProps> = ({open, onClose, student}) => {
    const [form] = Form.useForm();

    // Custom form handler for submitting form data and the uploaded file
    const onFinish = useFormHandler(
        async (values: StudentRequestData) => {
            // Call API to update the parent data
            return await updateStudent(student.id, values); // Modify API call as per your setup
        },
        form,
        () => {
            message.success('Parent information updated successfully!');
            onClose();
        }
    );

    return (
        <Drawer
            title="Parent Information"
            placement="right"
            width={'35%'}
            onClose={onClose}
            open={open}
            style={{paddingBottom: 20}}
            footer={
                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit" form="parentForm">
                        {student ? "Update" : "Submit"}
                    </Button>
                </div>
            }
        >
            <Form
                form={form}
                id="parentForm"
                onFinish={onFinish}
                layout="vertical"
                initialValues={student.student}  // Adjust according to your data structure
            >
                <Form.Item
                    label="Mother's Name"
                    name="mother_name"
                    rules={[{required: true, message: "Please input mother's name!"}]}
                >
                    <Input placeholder="Enter Mother's Name"/>
                </Form.Item>
                <Form.Item
                    label="Father's Name"
                    name="father_name"
                    rules={[{required: true, message: "Please input father's name!"}]}
                >
                    <Input placeholder="Enter Father's Name"/>
                </Form.Item>
                <Form.Item
                    label="Occupation"
                    name="occupation"
                    rules={[{required: true, message: "Please input parent's occupation!"}]}
                >
                    <Input placeholder="Enter Occupation"/>
                </Form.Item>
                <Form.Item
                    label="Parent Mobile Number"
                    name="parent_mobile_number"
                >
                    <InputNumber placeholder="Enter Parent Mobile Number" style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item
                    label="Parent Email"
                    name="parent_email"
                >
                    <Input placeholder="Enter Parent Email"/>
                </Form.Item>

                <UploadField form={form} name={'parent_profile_photo'} title={'Parent Profile Photo'}/>
            </Form>
        </Drawer>
    );
};

export default ParentInformationDrawer;
