import React, {useState} from 'react';
import {Button, Input, message, Select, Table, Tag} from 'antd';
import styles from "../Students/students-list/studentsList.module.scss";
import {FiSearch} from "react-icons/fi";
import {FaPlus} from "react-icons/fa6";
import cn from "classnames";
import PageHeader from "../../common/page-header/PageHeader";
import {IoEyeOutline} from 'react-icons/io5';
import {CiEdit} from 'react-icons/ci';
import AddEnquiryDrawer from '../../components/add-lead-enquriy-drawer/AddLeadEnquiryDrawer';
import LeadInfo from '../../components/Lead/LeadInfo';
import {MdDeleteOutline} from 'react-icons/md';
import DeleteWarningModal from '../../common/delete-warning-modal/DeleteWarningModal';
import {deleteEnquiry, EnquiryRequestData, useLeadList} from "../../api/lead/leadApi";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import {Action, ModuleName, Role, UserRole} from "../../utils/Role";
import { useFormHandler } from '../../utils/useFormHandler';

const LeadsTable: React.FC = () => {
    const {data, loading, fetchWithParams} = useLeadList();
    const {
        params,
        updateParams,
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);
    const [openAddEnquiryDrawer, setOpenAddEnquiryDrawer] = useState<boolean>(false);
    const [selectedLead, setSelectedLead] = useState<EnquiryRequestData | null>(null)
    const [showLeadInfo, setShowLeadInfo] = useState<boolean>(false);
    const [selectEnquiry, setSelectEnquiry] = useState<any>(null);

    const handleLeadOpenDrawer = (lead: EnquiryRequestData) => {
        setOpenAddEnquiryDrawer(true)
        setSelectedLead(lead)
    }
    const handleLeadCloseDrawer = () => {
        setOpenAddEnquiryDrawer(false)
        setSelectedLead(null)
        resetParams()

    }
    const handleOpenDeleteModal = (record: any) => {
        setSelectEnquiry(record)
        setOpenDeleteWarning(true)
    }

    const handleCloseDeleteModal = () => {
        setSelectEnquiry(null)
        setOpenDeleteWarning(false)
        resetParams()
    }

    const onDelete = useFormHandler(
        async () => {
            return await deleteEnquiry(selectEnquiry.id);
        },

        null,
        () => {
            message.success('Lead delete successfully!');
            handleCloseDeleteModal()
        },
        () => {
            message.success("Something went wrong")
        }
    )
    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'student_name',
            key: 'student_name',
        },
        {
            title: 'Assign Lead',
            dataIndex: 'assign_lead',
            key: 'assign_lead',
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Follow-Up Type',
            dataIndex: 'followup_type',
            key: 'followup_type',
        },
        {
            title: 'Enquiry Status',
            key: 'enquiry_status',
            dataIndex: 'enquiry_status',
            render: (enquiry_status: string) => (
                <Tag color={
                    enquiry_status === 'Medium Interest' ? 'volcano' :
                        enquiry_status === 'High Interest' ? 'green' :
                            enquiry_status === 'Low Interest' ? 'gray' :
                                'geekblue'
                }>
                    {enquiry_status}
                </Tag>
            ),
        },
        {
            title: "Follow-Up Date",
            dataIndex: "followup_date",
            key: "followup_date",
            render: (date: string) => new Date(date).toLocaleDateString(), // Format the date
        },
        {
            title: "Action",
            render: (_: any, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Button onClick={() => {
                        setSelectedLead(record); // Set the user data to view
                        setShowLeadInfo(true);
                    }}>
                        <IoEyeOutline/>
                    </Button>
                    <Role moduleName={ModuleName.LEAD_INQUIRIES} action={Action.EDIT}>
                    <Button
                        onClick={() => handleLeadOpenDrawer(record)}
                    >
                        <CiEdit/>
                    </Button>
                    </Role>
                    <Role moduleName={ModuleName.LEAD_INQUIRIES} action={Action.DELETE}>
                    <Button
                         onClick={() => handleOpenDeleteModal(record)}>

                        <MdDeleteOutline/>
                    </Button>
                    </Role>
                </div>
            ),
        },
    ];

    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="All Leads"/>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input placeholder="Search by name, email" prefix={<FiSearch/>}/>
                            <Select className={styles.sortSelect} placeholder="Sort By">
                                <Select.Option value="student_name">Student Name</Select.Option>
                                <Select.Option value="active">Active</Select.Option>
                                <Select.Option value="inactive">Inactive</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button
                                onClick={() => {
                                    setSelectedLead(null); // Reset editing lead
                                    setOpenAddEnquiryDrawer(true);
                                }}
                                type="primary"
                            >
                                <FaPlus/> New Enquiry
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table loading={loading} columns={columns} dataSource={data}/>
                </div>
            </section>

            {/* Drawer for enquiry */}
            {openAddEnquiryDrawer && (
                <AddEnquiryDrawer
                    open={openAddEnquiryDrawer}
                    onClose={handleLeadCloseDrawer}
                    lead={selectedLead} // Pass enquiry data for editing
                />
            )}

            {openDeleteWarning && (
                <DeleteWarningModal
                    open={openDeleteWarning}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => onDelete({})}
                    message={'Are you sure you want to delete this lead?'}
                />
            )}

            {showLeadInfo && selectedLead && (
                <LeadInfo // for viewing lead detail
                    open={showLeadInfo}
                    onClose={() => setShowLeadInfo(false)}
                    leadInfo={selectedLead}
                />
            )}
        </div>
    );
};

export default LeadsTable;
