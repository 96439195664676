import React, {useEffect, useState} from 'react';
import {Button, Checkbox, DatePicker, Drawer, Form, Input, message} from 'antd';
import {BatchProps, createLiveClassForBatch} from "../../../api/batch/batch";
import styles from "./liveClassDrawer.module.scss";
import {showErrors, useFormHandler} from "../../../utils/useFormHandler";
import dayjs, {Dayjs} from 'dayjs';
import {NonErrorFieldDisplay} from "../../../common/NonErrorField";
import {disabledDate} from "../../../utils/utils"; // Using Dayjs

interface LiveClassDrawerProps {
    batch: BatchProps | null; // Define the shape of the batch object as needed
    open: boolean;
    onClose: any;
}

const LiveClassDrawer: React.FC<LiveClassDrawerProps> = ({batch, open, onClose}) => {
    const [form] = Form.useForm();
    const [buttonText, setButtonText] = useState<string>("Go live Now"); // Default button text
    const [loading, setLoading] = useState(false)
    // Track form field changes to determine button text
    useEffect(() => {
        form.setFieldsValue({
            title: batch ? batch.name : '',
           
        });

        const start_time = form.getFieldValue('start_time');
        setButtonText(start_time ? "Schedule" : "Go live Now");
    }, [batch, form]);

    // Update button text when the start_time field changes
    const handleStartTimeChange = (date: Dayjs | null) => {
        setButtonText(date ? "Schedule" : "Go live Now");
    };



    const onFinish = useFormHandler(
        async (values) => {
            values['batch'] = batch!.id;

            // Convert start_time to ISO string if provided
            if (values.start_time) {
                values.start_time = values.start_time.toISOString();
            }

            return createLiveClassForBatch(values);
        },
        form,
        (response: any) => {
            message.success('Live class created successfully!');
            // window.open(response.host_link, '_blank');
            onClose()
        },
        (error_response) => {
            showErrors(error_response)
        },
        setLoading
    );

    return (
        <Drawer
            title="Create Live Class"
            placement="right"
            closable={false}
            open={open}
            width={400}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
               
            >
                <Form.Item name="non_field_errors" noStyle>
                    <NonErrorFieldDisplay/>
                </Form.Item>
                <Form.Item
                    label="Live Class Title"
                    name="title"
                    rules={[{required: true, message: 'Please input the class title!'}]}
                >
                    <Input placeholder="Enter Class Title"/>
                </Form.Item>

                <Form.Item
                    label="Start Time"
                    name="start_time"
                    tooltip="If not provided, the class will start immediately as a live class."
                >
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        placeholder="Select Start Time"
                        onChange={handleStartTimeChange} // Track changes for button text
                        disabledDate={disabledDate} // Disable past dates
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{required: false}]}
                >
                    <Input.TextArea
                        rows={4}
                        placeholder="Enter class description (optional)"
                    />
                </Form.Item>

                <Form.Item
                    name="enable_recording"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox>Enable Recording</Checkbox>
                </Form.Item>

                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Close</Button>
                    <Button  loading={loading} type="primary" htmlType="submit">
                        {buttonText}
                    </Button>
                </div>

            </Form>
        </Drawer>
    );
};

export default LiveClassDrawer;
