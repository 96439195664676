import styles from "./courseCategories.module.scss";
import {Button, Drawer, Form, Input, message, Select, Space} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {useFormHandler} from "../../utils/useFormHandler";
import {useCategoryList} from "../../api/category/createUpdateDeleteListCategory";
import {createSubCategory, updateSubCategory} from "../../api/subCategory/createUpdateDeleteListSubCategory";

interface SubCategoryProps {
    onClose: () => void;
    open: boolean;
    subCategory?: { id: number; name: string;category:any };
}


const AddSubCategoryDrawer: React.FC<SubCategoryProps> = ({onClose, open, subCategory}) => {
    const [form] = Form.useForm();
    const {
        data: categories,
        loading,
        fetchWithParams: categoryFetchWithParams,
    } = useCategoryList();
    // Function to handle form submission
    const onFinish = useFormHandler(
        (values: any) => {
            if (subCategory) {
                // If category exists, update the existing category using its ID
                return updateSubCategory(subCategory.id, values);
            } else {
                // Otherwise, create a new category
                return createSubCategory(values);
            }
        },
        form,
        () => {
            message.success(subCategory ? 'Sub Category updated successfully!' : 'Sub Category created successfully!');
            onClose();
        }
    );

    const customInitialValues = {
        ...subCategory,
        category: subCategory? subCategory.category.id: null,
    };

    return (
        <Drawer
            title={subCategory? "Update Subcategories":"Add Subcategories"}
            onClose={onClose}
            open={open}
            width={"40%"}
            closeIcon={true}
            className={styles.createStudentDrawer}
        >
            <div className={styles.drawerMain}>
                <Form
                    form={form}
                    layout={"vertical"}
                    style={{maxWidth: 600}}
                    initialValues={customInitialValues}
                    onFinish={onFinish}
                >
                    {/* Category Dropdown */}
                    <Form.Item
                        label="Category Name"
                        name="category"
                        rules={[{required: true, message: "Please select a category!"}]}
                    >
                        <Select
                            placeholder="Select Category"
                            loading={loading}
                            options={categories.map((category: any) => ({
                                value: category.id,
                                label: category.title,
                            }))}
                        />
                    </Form.Item>

                    {/* Subcategories List */}
                    <Form.List name="sub_categories" initialValue={[{}]}>
                        {(fields, {add, remove}) => (
                            <>
                                <h6>Sub Categories</h6>
                                {fields.map(({key, name, ...restField}) => (
                                    <Space
                                        key={key}
                                        style={{display: "flex", marginBottom: 8}}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, "title"]}
                                            rules={[{required: true, message: "Please input the subcategory!"}]}
                                        >
                                            <Input placeholder="Enter Sub Category"/>
                                        </Form.Item>
                                        <DeleteOutlined onClick={() => remove(name)}/>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined/>}
                                    >
                                        Add Sub Category
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    {/* Footer Buttons */}
                    <div className={styles.formFooter}>
                        <Button onClick={onClose}>Close</Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
};

export default AddSubCategoryDrawer;
