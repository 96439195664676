import styles from "./testSeries.module.scss";
import cn from "classnames";
import {Button, Input, message, Select, Table, Tabs, Tag} from "antd";
import {FiSearch} from "react-icons/fi";
import {useState} from "react";
import {FaPlus} from "react-icons/fa6";
import {CiEdit} from "react-icons/ci";
import {MdDeleteOutline} from "react-icons/md";
import {IoEyeOutline} from "react-icons/io5";
import PageHeader from "../../common/page-header/PageHeader";
import AddTestSeriesDrawer from "../../components/add-test-series-drawer/AddTestSeriesDrawer";
import {useNavigate} from "react-router-dom";
import {
    deleteTestSeries,
    updateProductOrderStatus,
    useProductOrderList,
    useTestSeriesList
} from "../../api/testSeries/testSeries";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../hooks/useDebouncedValue";
import TestSeriesView from "./TestSeriesView";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import {useFormHandler} from "../../utils/useFormHandler";
import {Action, ModuleName, Role} from "../../utils/Role";
import {formattedDateTime} from "../../utils/utils";

import EditOrderDrawer from "./EditOrderDrawer";

const TestSeries = () => {
    const {data, fetchWithParams} = useTestSeriesList();
    const {data: orders, fetchWithParams: fetchProductWithParams,} = useProductOrderList()
    const {
        updateSearch,
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const {
        resetParams: resetProductParams
    } = useFilterSortPaginate(fetchProductWithParams);


    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 200);
    const [openAddSeries, setOpenAddSeries] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectTestSeries, setSelectTestSeries] = useState<any>(null);
    const [openViewModal, setOpenViewModal] = useState<boolean>(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState('1');
    const [selectOrder, setSelectOrder]=useState<any>(null);
    const[editViewModal,setEditViewModal]=useState<boolean>(false);

    const onConfigClick = () => {
        navigate('/test-series/configration');
    };
    const handleOpenAddDrawer = (record: any) => {
        setSelectTestSeries(record)
        setOpenAddSeries(true)
    }
    const handleCloseAddDrawer = () => {
        setSelectTestSeries(null)
        setOpenAddSeries(false)
        resetParams()
    }
    const handleOpenViewDrawer = (record: any) => {
        setSelectTestSeries(record)
        setOpenViewModal(true)
    }
    const handleCloseViewDrawer = () => {
        setSelectTestSeries(null)
        setOpenViewModal(false)
    }
    const handleOpenDeleteModal = (record: any) => {
        setSelectTestSeries(record)
        setVisibleDeleteModal(true)
    }
    const handleCloseDeleteModal = () => {
        setSelectTestSeries(null)
        setVisibleDeleteModal(false)
        resetParams()
    }

    const handleOpenEditDrawer=(record:any)=>{
        setSelectOrder(record);
        setEditViewModal(true)
        

    }
    
    const handleOpenCloseDrawer=()=>{
      
        setSelectOrder(null);
        setEditViewModal(false);
        resetProductParams()
          
        
    }

    const onDelete = useFormHandler(
        async () => {
            return await deleteTestSeries(selectTestSeries.id); // Non-null assertion for course.id
        },

        null,
        () => {
            message.success('Test Series delete successfully!');
            handleCloseDeleteModal()
        },
        () => {
            message.success("Something went wrong")
        }
    )
    const productColumns = [
        {
            title: "Product Name",
            dataIndex: "name", // Updated to match API response
            key: "name",
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (data: any) => data.title // You might want to fetch the category name if it's an ID
        },
        {
            title: "Regular Price",
            dataIndex: "price",
            key: "price",
            render: (text: string) => `₹${text}` // Format as currency
        },
        {
            title: "Discounted Price",
            dataIndex: "discount",
            key: "discount",
            render: (text: string) => `₹${text}` // Format as currency
        },
        {
            title: "Product Type",
            dataIndex: "is_digital",
            key: "is_digital",
            render: (is_digital: string) => is_digital ? "Digital Product" : "Physical Product"
        },
        {
            title: "Published",
            dataIndex: "is_published",
            key: "is_published",
            render: (status: string) => (
                <Tag color={status ? "green" : "red"}>
                    {status ? "Active" : "Inactive"}
                </Tag>
            ),
        },

        {
            title: "Highlights",
            dataIndex: "highlights",
            key: "highlights",
            render: (highlights: any[]) => highlights.join(', '), // Join highlight points
        },
        {
            title: "Action",
            render: (text: string, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Button title="View" onClick={() => handleOpenViewDrawer(record)}>
                        <IoEyeOutline/>
                    </Button>
                    <Role moduleName={ModuleName.TEST_SERIES} action={Action.EDIT}>
                        <Button title="Edit" onClick={() => handleOpenAddDrawer(record)}>
                            <CiEdit/>
                        </Button>
                    </Role>
                    <Role moduleName={ModuleName.TEST_SERIES} action={Action.DELETE}>
                        <Button title="Delete" onClick={() => handleOpenDeleteModal(record)}>
                            <MdDeleteOutline/>
                        </Button>
                    </Role>
                </div>
            ),
        },
    ];

    const orderColumns = [
        {
            title: "Order ID",
            dataIndex: "id",
            key: "id",
        },

        {
            title: "Order Date",
            dataIndex: "created",
            key: "created",
            render: (date: any) => formattedDateTime(date)
        },

        {
            title: "Product Name",
            dataIndex: "test_series",
            key: "test_series",
            render: (test_series: any) => test_series.name


        },

        {
            title: "Student Name",
            dataIndex: "user",
            key: "user",
        },
        {
            title: "AWB No.",
            dataIndex: "awb_no",
            key: "awb_no",
          
        },

        {
            title: "Tracking No.",
            dataIndex: "tracking_no",
            key: "tracking_no",
          
        },

        {
            title: "Tracking URL.",
            dataIndex: "tracking_url",
            key: "tracking_url",
           
        },

        {
            title: "Order Status",
            dataIndex: "delivery_status",
            key: "delivery_status",
            render: (status: string) => {
                const statusMap: Record<string, { value: string; label: string; color: string }> = {
                    "shipped": {value: "shipped", label: "Shipped", color: "blue"},
                    "packed": {value: "packed", label: "Packed", color: "orange"},
                    "delivered": {value: "delivered", label: "Delivered", color: "green"},
                    "canceled": {value: "canceled", label: "Canceled", color: "red"},
                   
                };
                const {label, color} = statusMap[status] || {label: "Unknown", color: "gray"};
                return <Tag color={color}>{label}</Tag>;
            }

        },

        {
            title: "Status",
            key: "action",
            render: (_: any, record: any) => {
                const handleStatusChange = async (delivery_status: string) => {
                    console.log("Updated status for order:", record.order_id, "to", delivery_status);
                    // Call API or state update logic here
                    await updateProductOrderStatus(record.id, delivery_status);
                    resetProductParams()

                };

                


                return (
                    <Select
                        defaultValue={record.delivery_status}
                        onChange={handleStatusChange}
                        options={[
                            {value: "packed", label: "Packed"},
                            {value: "shipped", label: "Shipped"},
                            {value: "delivered", label: "Delivered"},
                            {value: "canceled", label: "Canceled"},
                        ]}
                        style={{width: 150}}
                    />
                );
            },
        },


        {
            title: "Action",
            render: (text: string, record: any) => (
                <div className={styles.tableActionBtns}>
                 
                    <Role moduleName={ModuleName.TEST_SERIES} action={Action.EDIT}>
                        <Button title="Edit"  onClick={() => handleOpenEditDrawer(record)}>
                            <CiEdit/>
                        </Button>
                    </Role>
                  
                </div>
            ),
        },
    ];


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Products" onConfigClick={onConfigClick}/>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name"
                                prefix={<FiSearch/>}
                                onChange={handleDebounceInputChange}
                            />
                            <Select
                                className={styles.sortSelect}
                                placeholder="Sort By"
                            >
                                <Select.Option value="categoryName">Category Name</Select.Option>
                                <Select.Option value="price">Price</Select.Option>
                                <Select.Option value="duration">Duration</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button
                                onClick={() => {
                                    setSelectTestSeries(null); // Reset for new entry
                                    setOpenAddSeries(true);
                                }}
                                type="primary"
                            >
                                <FaPlus/> Add Product
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <Tabs.TabPane tab="Products" key="1">

                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={productColumns}
                                rowKey="id" // Use 'id' from the API response
                                dataSource={data} // Ensure you access results
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Orders" key="2">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={orderColumns}
                                dataSource={orders}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
            </Tabs>
            {openAddSeries &&
                <AddTestSeriesDrawer
                    open={openAddSeries}
                    testSeries={selectTestSeries}
                    onClose={handleCloseAddDrawer}
                />
            }
            {openViewModal && (
                <TestSeriesView
                    open={openViewModal}
                    onClose={handleCloseViewDrawer}
                    testSeries={selectTestSeries}
                />
            )}

          
            {visibleDeleteModal &&
                <DeleteWarningModal
                    open={visibleDeleteModal}
                    onConfirm={() => onDelete({})}
                    onClose={handleCloseDeleteModal}
                    message={"Are you sure, want to delete this Test Series?"}
                />
            }

                {editViewModal &&(
                <EditOrderDrawer 
                     open={editViewModal}
                     onClose={handleOpenCloseDrawer}
                     orderId={selectOrder}
                    
                     />
                     
            )}

        </div>
    );
};

export default TestSeries;
