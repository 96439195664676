import React, {useEffect, useState} from "react";
import {Button, Input, message, Select, Table, Tag} from "antd";
import {FiSearch} from "react-icons/fi";
import {IoEyeOutline} from "react-icons/io5";
import {CiEdit} from "react-icons/ci";
import {MdDeleteOutline, MdOutlineCurrencyRupee} from "react-icons/md"; // Rupee icon remains the same
import {FaPlus} from "react-icons/fa6";
import cn from "classnames";

import AddUserDrawer from "../../../components/add-user-drawer/AddUserDrawer";
import DeleteWarningModal from "../../../common/delete-warning-modal/DeleteWarningModal";
import PageHeader from "../../../common/page-header/PageHeader";
import StatsCard from "../../../common/StatsCard/StatsCard";
import styles from "./usersList.module.scss";
import UserInfo from "../../../components/User/UserInfo";
import {deleteUser, useUserList} from "../../../api/user/userApi";
import useFilterSortPaginate from "../../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../../hooks/useDebouncedValue";
import {getUserMetrics, UserMetrics} from "../../../api/widgets/widgets";
import {FcReadingEbook} from "react-icons/fc";
import {GiBookshelf} from "react-icons/gi";
import AccessControlModal from "../../../components/User/AccessControlModal";
import {IoKeyOutline} from "react-icons/io5";
import {Action, ModuleName, Role, UserRole} from "../../../utils/Role";

interface UserFormValues {
    full_name: string;
    email: string;
    phone_number: string;
    role: string;
    id: number; // Optional id for update

}

const UsersList = () => {
    const {data: userData, loading, fetchWithParams} = useUserList(); // Fetch user data from a hook
    const {
        params,
        updateParams,
        updateSearch,
        updateFilters,
        updateOrdering,
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 500);
    const [openAddUserDrawer, setOpenAddUserDrawer] = useState<boolean>(false);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);
    const [selectedUserKey, setSelectedUserKey] = useState<string | null>(null);
    const [editingUser, setEditingUser] = useState<UserFormValues | null>(null); // State for editing user
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
    const [showUserInfo, setShowUserInfo] = useState<boolean>(false);

    // Filtered and search input state
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortOption, setSortOption] = useState<string>("userName");
    const [openAccessControl, setOpenAccessControl] = useState<boolean>(false);

    const [userMetrics, setUserMetrics] = useState<UserMetrics | null>(null);

    const fetchUserMetrics = async () => {
        try {
            const metrics = await getUserMetrics();
            setUserMetrics(metrics);
        } catch (err) {
            console.error('Error fetching user metrics:', err);
        } finally {
        }
    };

    useEffect(() => {
        fetchUserMetrics()
    }, [])

    const handleSortChange = (value: string) => {
        setSortOption(value);
        // Implement sorting logic here (ascending or descending based on value)
    };

    const handleOpenCreateDrawer = (user: any) => {
        setEditingUser(user);
        setOpenAddUserDrawer(true);
    }
    const handleCloseCreateDrawer = () => {
        setEditingUser(null);
        setOpenAddUserDrawer(false);
        // TODO we need to refresh if we change our self
        resetParams()
    }

    const handleOpenDeleteModal = (record: any) => {
        setSelectedUser(record);
        setOpenDeleteWarning(true);
    };

    const handleCloseDeleteModal = () => {
        setSelectedUser(null);
        setOpenDeleteWarning(false);
        resetParams()
    };
    const handleOnConfirmDeleteModal = async () => {
        try {
            await deleteUser(selectedUser.id)
            message.success("User deleted successfully")
        } catch (e) {
            message.error("Something went wrong")
        }
        handleCloseDeleteModal()
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "full_name",
            key: "full_name",
            render: (text: string) =>
                text.toLowerCase().includes(searchTerm.toLowerCase()) ? (
                    <span>{text}</span>
                ) : null,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone Number",
            dataIndex: "phone_number",
            key: "phone_number",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (role: string) => <Tag color={getRoleTagColor(role)}>{role}</Tag>,
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "is_active",
            render: (isActive: boolean) => (
                <Tag color={isActive ? "green" : "red"}>
                    {isActive ? "Active" : "Inactive"}
                </Tag>
            ),
        },
        {
            title: "Date Joined",
            dataIndex: "date_joined",
            key: "date_joined",
            render: (date: string) => new Date(date).toLocaleDateString(), // Format the date
        },
        {
            title: "Action",
            render: (_: any, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Button onClick={() => {
                        setSelectedUser(record); // Set the user data to view
                        setShowUserInfo(true);
                    }}>
                        <IoEyeOutline/>
                    </Button>
                    <Role moduleName={ModuleName.TEAM_MEMBERS} action={Action.EDIT}>
                        <Button
                            onClick={() => handleOpenCreateDrawer(record)}>
                            <CiEdit/>
                        </Button>
                    </Role>
                    <Role moduleName={ModuleName.TEAM_MEMBERS} action={Action.DELETE}>
                        <Button
                            onClick={() => handleOpenDeleteModal(record)}
                        >
                            <MdDeleteOutline/>
                        </Button>
                    </Role>
                </div>
            ),
        },
    ];

    const getRoleTagColor = (role: string) => {
        switch (role) {
            case "MANAGER":
                return "blue";
            case "ADMIN":
                return "purple";
            case "INSTRUCTOR":
                return "orange";
            default:
                return "gray";
        }
    };
    const handleUserTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        let paramsTransaction = {}
        // let symbol = '';
        // if (sorter.order === 'descend')
        //     symbol = '-';
        paramsTransaction = {
            ...params,
            page: pagination.current,
        }
        // if (sorter.columnKey) {
        //     params.ordering = `${symbol}${sorter.columnKey}`
        // }
        updateParams(paramsTransaction);
    };


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="All Users"/>
            <section>
                <div className="row">
                    {userMetrics ? (
                        <>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<FcReadingEbook color="#9E49E6" className="icon"/>} // Updated reading icon
                                    title={userMetrics.total_users} description={"Total Users"}/>
                            </div>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<GiBookshelf color="#49BD65" className="icon"/>} // Bookshelf icon
                                    title={userMetrics.active_users} description={"Active Users"}/>
                            </div>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<FcReadingEbook color="#0A8FDC" className="icon"/>} // Updated graduation icon
                                    title={userMetrics.new_enrollments_last_week} description={"New Enroll"}/>
                            </div>
                            <div className="col-12 col-sm-3">
                                <StatsCard
                                    icon={<MdOutlineCurrencyRupee color="#9E49E6" className="icon"/>} // Rupee icon
                                    title={userMetrics.inactive_users} description={"Inactive Users"}/>
                            </div>
                        </>
                    ) : ( // Handle case when userMetrics is null (e.g., if an error occurred)
                        <div className="col-12">Failed to load user metrics.</div>
                    )}
                </div>
            </section>

            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name"
                                prefix={<FiSearch/>}
                                onChange={handleDebounceInputChange}
                            />

                            <Select
                                className={styles.sortSelect}
                                value={sortOption}
                                onChange={handleSortChange}
                                placeholder="Sort By"
                            >
                                <Select.Option value="userName">User Name</Select.Option>
                                <Select.Option value="active">Active</Select.Option>
                                <Select.Option value="inactive">Inactive</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <Role moduleName={ModuleName.TEAM_MEMBERS} action={Action.CREATE}>
                            <div className={styles.actionBtnDiv}>
                                <Button type="primary"
                                        onClick={() => setOpenAccessControl(true)}
                                >
                                    <IoKeyOutline/>
                                    Access Control
                                </Button>
                                <Button
                                    onClick={() => setOpenAddUserDrawer(true)}
                                    type="primary"
                                >
                                    <FaPlus/> Add Team Member
                                </Button>
                            </div>
                        </Role>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table loading={loading} columns={columns} dataSource={userData} onChange={handleUserTableChange}/>
                </div>
            </section>

            {/* Drawer and Modal components */}
            {openAddUserDrawer &&
                <AddUserDrawer
                    open={openAddUserDrawer}
                    onClose={handleCloseCreateDrawer}
                    user={editingUser} // Pass user data for editing
                />
            }
            <DeleteWarningModal
                open={openDeleteWarning}
                onClose={handleCloseDeleteModal}
                onConfirm={handleOnConfirmDeleteModal}
                message={'Are you sure you want to delete this user record?'}
                // userKey={selectedUserKey}
            />
            <UserInfo  /// for view user detail
                open={showUserInfo}
                onClose={() => setShowUserInfo(false)}
                userInfo={selectedUser}
            />
            <AccessControlModal
                open={openAccessControl}
                onClose={() => setOpenAccessControl(false)}
            />
        </div>
    );
};

export default UsersList;
