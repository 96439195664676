import React, {useEffect, useState} from 'react';
import {Button, Divider, message, Spin} from 'antd';
import styles from './batchpricing.module.scss';
import {getBatchPricing, purchaseBatch, verifyPayment} from '../api/payment/payment'; // Ensure to create the API calls
import {useFormHandler} from "../utils/useFormHandler";
import {BatchProps} from "../types/batch";
import {useNavigate} from "react-router-dom";

interface BatchPricingProps {
    batch: BatchProps;
    installment_number: number;
}

const BatchPricing: React.FC<BatchPricingProps> = ({batch, installment_number}) => {
    const [pricingDetails, setPricingDetails] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchPricingDetails = useFormHandler(
        () => {
            setLoading(true);
            return getBatchPricing(batch.id, installment_number);
        },
        null,
        (response: any) => {
            setPricingDetails(response);
            setLoading(false);
        },
        (error) => {
            setLoading(false);
            message.error('Failed to fetch pricing details.');
        }
    );

    useEffect(() => {
        fetchPricingDetails({});
    }, [batch.id]);

    const handlePurchaseBatch = useFormHandler(
        () => {
            setLoading(true);
            return purchaseBatch(batch.id, installment_number);
        },
        null,
        async (response: any) => {
            const {order_id, amount: orderAmount, currency, name, description, image, prefill} = response;
            try {
                // Define Razorpay options
                const options: any = {
                    key: 'rzp_test_2cywPv1gKde6UC', // Razorpay Key ID
                    amount: orderAmount * 100, // Amount in paise
                    currency: currency,
                    name: name,
                    description: description,
                    image: image,
                    order_id: order_id,
                    handler: async (response: any) => {
                        // Handle payment success
                        const {razorpay_order_id, razorpay_payment_id, razorpay_signature} = response;

                        try {
                            const verifyResponse: any = await verifyPayment({
                                razorpay_order_id,
                                razorpay_payment_id,
                                razorpay_signature,
                            });
                                message.success('Payment successful!');
                                navigate(`/purchased-batch/${batch.id}`);
                        } catch (error) {
                            message.error('Payment verification failed.');
                            console.error('Verification Error:', error);
                        }
                    },
                    prefill: prefill,
                    notes: {
                        // Any additional notes
                    },
                    theme: {
                        color: '#3399cc',
                    },
                };

                const rzp1 = new (window as any).Razorpay(options);
                rzp1.open();
            } catch (error: any) {
                message.error(error.response?.data?.error || 'Payment initiation failed.');
                console.error('Payment Initiation Error:', error);
            } finally {
                setLoading(false);
            }
        }
    );

    if (!pricingDetails) {
        return <div>No pricing details available.</div>;
    }

    const {original_price, internet_charges, platform_fees, gst_amount, total_amount} = pricingDetails;

    return (
        <div>
            <h5 className={styles.payHeading}>Pricing Details</h5>
            <Divider/>
            <div className={styles.paymentItem}>
                <div>First Installment Price</div>
                <div>₹{original_price.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>Internet Charges</div>
                <div>+ ₹{internet_charges.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>Platform Fee</div>
                <div>+ ₹{platform_fees.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>GST</div>
                <div>+ ₹{gst_amount.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>Total Amount Payable</div>
                <div>₹{total_amount.toFixed(2)}</div>
            </div>
            <Divider/>
            <Button onClick={handlePurchaseBatch} type="primary" block loading={loading}>
                {`Enroll Now ₹${total_amount.toFixed(2)}`}
            </Button>
        </div>
    );
};

export default BatchPricing;
