// Example TypeScript Types

import useFetchList from "../../utils/listClient";
import apiClient from "../../utils/apiClient";
import {FileResponse, FolderResponse, GetBatchFolderStructureResponse} from "../../types/folderFiles";


interface CreateDocumentRequest {
    title: string;
    document_file: string;
    link: string;
}

interface UpdateDocumentRequestData {
    id: number;
    title: string;
    document_file: string;
    link: string;
    thumbnail: string;
}


export const createFreeResource = async (data: CreateDocumentRequest) => {
    const response = await apiClient.post<UpdateDocumentRequestData>('/api/free-resource/resources/', data);
    return response.data;
};

export const updateFreeResource = async (documentId: number, data: CreateDocumentRequest) => {
    const response = await apiClient.patch<UpdateDocumentRequestData>(`/api/free-resource/resources/${documentId}/`, data);
    return response.data;
};

export const detailFreeResource = async (documentId: number): Promise<UpdateDocumentRequestData> => {
    const response = await apiClient.get<UpdateDocumentRequestData>(`/api/free-resource/resources/${documentId}/`);
    return response.data;
};

export const deleteFreeResource = async (documentId: number) => {
    const response = await apiClient.delete(`/api/free-resource/resources/${documentId}/`);
    return response.data;
};

export const useFreeResourceList = () => {
    return useFetchList<UpdateDocumentRequestData>("/api/free-resource/resources/");
};

export const createResourceFolder = async (resourceId: number, folderData: {
    title: string;
    parent: number | null;
}): Promise<FolderResponse> => {
    const response = await apiClient.post<FolderResponse>(`/api/free-resource/content/${resourceId}/create-folder/`, folderData);
    return response.data;
};
export const uploadResourceFile = async (resourceId: number, parentFolderId: number | null, file: any): Promise<FileResponse> => {
    const formData = new FormData();
    formData.append("url", file);

    const response = await apiClient.post<FileResponse>(`/api/free-resource/content/${resourceId}/folders/${parentFolderId}/create-file/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
};

export const updateResourceOrder = async (resourceId:number, itemId:number, type:string, direction:string,) => {
    const response = await apiClient.patch(`/api/free-resource/content/${resourceId}/update-order/`, {
        type,
        direction,
        id: itemId,
    });
    return response.data; // Now only contains the status message
};
export const renameResourceFolder = async (resourceId: number, folderId: number, newTitle: string): Promise<FolderResponse> => {
    const response = await apiClient.patch<FolderResponse>(`/api/free-resource/content/${resourceId}/folders/${folderId}/rename-folder/`, {
        title: newTitle,
    });
    return response.data;
};
export const renameResourceFile = async (resourceId: number, fileId: number, newTitle: string): Promise<FileResponse> => {
    const response = await apiClient.patch<FileResponse>(`/api/free-resource/content/${resourceId}/files/${fileId}/rename-file/`, {
        title: newTitle,
    });
    return response.data;
};
export const deleteResourceFolder = async (resourceId: number, folderId: number): Promise<void> => {
    await apiClient.delete(`/api/free-resource/content/${resourceId}/folders/${folderId}/delete-folder/`);
};
export const deleteResourceFile = async (resourceId: number, fileId: number): Promise<void> => {
    await apiClient.delete(`/api/free-resource/content/${resourceId}/files/${fileId}/delete-file/`);
};
export const toggleLockResourceFile = async (resourceId: number, fileId: number): Promise<FileResponse> => {
    const response = await apiClient.patch<FileResponse>(`/api/free-resource/content/${resourceId}/files/${fileId}/toggle-lock-file/`);
    return response.data;
};


export const getResourceFolderStructure = async (resourceId: number, params: any): Promise<GetBatchFolderStructureResponse> => {
    const response = await apiClient.get<GetBatchFolderStructureResponse>(`/api/free-resource/resources/${resourceId}/folder-structure/`, {params: params});
    return response.data;
};
