// src/utils/apiClient.ts
import axios from 'axios';
import {getAccessToken, getRefreshToken, removeTokens, setAccessToken} from "./auth";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

interface ProfileResponseProps {
    access: string
}

// Create an Axios instance with a base URL
const apiClient = axios.create({
    baseURL: BASE_URL, // Your API base URL
    headers: {
        'Content-Type': 'application/json',
    },
    // withCredentials: true, // This allows the browser to send cookies along with requests
});

// Request interceptor: Add the access token to the headers if available
apiClient.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers!.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Refresh token function: Send a POST request to refresh the access token
const refreshAccessToken = async () => {
    try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }
        // Attempt to get a new access token using the refresh token
        const response = await axios.post<ProfileResponseProps>(`${BASE_URL}token-refresh/`, {
            refresh: refreshToken
        });
        const {access} = response.data;

        // Update the access token in local storage
        setAccessToken(access);

        return access; // Return the new access token
    } catch (error) {
        // Refresh token has expired or is invalid
        console.error('Error refreshing access token:', error);
        removeTokens(); // Clear tokens on failure
        throw error;
    }
};

// Response interceptor: Handle 401 Unauthorized error and retry the request after refreshing the token
apiClient.interceptors.response.use(
    (response) => response, // Return successful response
    async (error) => {
        const originalRequest = error.config;
        const {status} = error.response || {};

        if (status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Avoid infinite loops

            try {
                // Refresh the token
                const newAccessToken = await refreshAccessToken();

                // Update the Authorization header with the new access token
                apiClient.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry the original request with the new token
                return apiClient(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed, redirecting to login.');
                // Handle redirection to login or other actions here
                window.location.href = '/login'; // Redirect to login page
                return Promise.reject(refreshError);
            }
        }

        // Handle other errors like 403 Forbidden
        if (status === 403) {
            console.log('Forbidden error:', error.response?.data?.error || 'Access denied.');
        }

        return Promise.reject(error);
    }
);

export default apiClient;
