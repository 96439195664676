// CourseDetail.tsx
import React, {useEffect, useState} from 'react';
import {Card, Space, Tabs} from 'antd';
import {useParams} from "react-router-dom";
import {purchaseDetailStudentCourse} from "../../api/course/student/studentCourse";
import PageHeader from '../../common/page-header/PageHeader';
import styles from "./freeResourceDetail.module.scss"
import {MdOutlineVideoLibrary} from "react-icons/md";
import {TfiFiles} from "react-icons/tfi";
import {LuCalendarClock} from "react-icons/lu";
import DirectoryTreeStructure from "../DirectoryTreeStructure";
import {ResourceProps} from "../../types/resource";
import {detailStudentFreeResource} from "../../api/freeResources/student/studentFreeResource";
import { FaImages } from 'react-icons/fa6';



const FreeResourceDetail: React.FC = () => {
    const {resourceId} = useParams<any>();
    const [resource, setResource] = useState<ResourceProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchResourceDetails = async () => {
        if (!resourceId) {
            setError('Resource ID is missing');
            setLoading(false);
            return;
        }

        try {
            const resourceData: ResourceProps = await detailStudentFreeResource(parseInt(resourceId));
            setResource(resourceData);
        } catch (err) {
            setError('Failed to fetch resource details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchResourceDetails();
    }, [resourceId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    if (!resource) return <div>No resource found.</div>;

    return (
        <div className={styles.courseData}>

            <PageHeader heading={resource.title}/>
            <Card>
                <h4 className={styles.cardHeading}>Content Summary</h4>
                <Space size="large" className={styles.contentStats}>
                    <span><MdOutlineVideoLibrary/> <strong>Videos:</strong> {resource.content.videos} </span>
                    <span><FaImages/> <strong>Images:</strong> {resource.content.images} </span>
                    <span><TfiFiles/> <strong> Total Files:</strong> {resource.content.total_files} </span>
                </Space>

                <h4 className={styles.cardHeading}>Content</h4>
                <DirectoryTreeStructure directory={resource.content.directory}/>
            </Card>
        </div>
    );
};

export default FreeResourceDetail;
