import {Checkbox, DatePicker, Drawer, Form, Input, InputNumber, message, Radio, Select} from "antd";
import React, {useEffect, useState} from "react";
import styles from "../coupon/createCoupon.module.scss";
import {createCoupon, updateCoupon} from "../../../api/coupon/couponApi";
import {useFormHandler} from "../../../utils/useFormHandler";
import moment from "moment";
import {useCourseList} from "../../../api/course/course";
import {useStudentList} from "../../../api/student/studentApi";
import FooterButton from "../../../components/FooterButton";
import useFilterSortPaginate from "../../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../../hooks/useDebouncedValue";

interface CouponProps {
    onClose: () => void;
    open: boolean;
    coupon: any;
}

const {Option} = Select;

const AddCoupon: React.FC<CouponProps> = ({onClose, open, coupon}) => {
    const [form] = Form.useForm();
    // Student related
    const {data: studentList, fetchWithParams: studentFetchWithParams} = useStudentList();
    const {updateSearch: studentUpdateSearch} = useFilterSortPaginate(studentFetchWithParams);
    const {
        debouncedValue: debouncedStudentValue,
        handleDebounceSearchInput: handleStudentDebounceSearchInput
    } = useDebounceValue(studentUpdateSearch, 500);
    // Course related
    const {data: courseList, fetchWithParams: courseFetchWithParams} = useCourseList();
    const {updateSearch: courseUpdateSearch} = useFilterSortPaginate(courseFetchWithParams);
    const {
        debouncedValue: debouncedCourseValue,
        handleDebounceSearchInput: handleCourseDebounceSearchInput
    } = useDebounceValue(courseUpdateSearch, 500);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    const onFinish = useFormHandler(
        async (values) => {
            coupon ? await updateCoupon(coupon.id!, values) : await createCoupon(values);
        },
        form,
        () => {
            message.success('Coupon saved successfully!');
            onClose();
        },
        () => {

        },
        setBtnLoading
    );

    useEffect(() => {
        if (coupon) {
            console.log("Coupon data:", coupon);
            form.setFieldsValue({
                ...coupon,
                start_datetime: moment(coupon.start_datetime),
                end_datetime: coupon.end_datetime ? moment(coupon.end_datetime) : null,
                course_selection_type: coupon.courses && coupon.courses.length > 0 ? 'specific' : 'all',
            });
        }
    }, [coupon]);

    return (
        <Drawer
            title="Add Coupon"
            onClose={onClose}
            open={open}
            width={"50%"}
            closeIcon={true}
            className={styles.createCouponDrawer}
        >
            <div className={styles.drawerMain}>
                <Form form={form} layout={"vertical"} style={{maxWidth: 650}} onFinish={onFinish}>
                    {/* Discount Type Field */}
                    <Form.Item
                        label="Discount Type"
                        name="discount_type"
                        rules={[{required: true, message: "Please select discount type!"}]}
                    >
                        <Radio.Group>
                            <Radio value="fixed">Flat Discount</Radio>
                            <Radio value="percentage">Percentage Discount</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {/* Discount-Specific Fields */}
                    <div style={{display: "flex", gap: "16px"}}>
                        {/* Watch the discount_type field */}
                        <Form.Item noStyle dependencies={['discount_type']}>
                            {({getFieldValue}) => {
                                const discountType = getFieldValue('discount_type');

                                if (discountType === 'fixed') {
                                    return (
                                        <Form.Item
                                            label="Flat Discount Amount"
                                            name="discount_value"
                                            rules={[{required: true, message: "Please input discount amount!"}]}
                                            style={{flex: 1}}
                                        >
                                            <InputNumber prefix="₹" placeholder="Enter discount amount"
                                                         style={{width: '100%'}}/>
                                        </Form.Item>
                                    );
                                }

                                if (discountType === 'percentage') {
                                    return (
                                        <>
                                            <Form.Item
                                                label="Percentage Discount"
                                                name="discount_value"
                                                rules={[{required: true, message: "Please input discount percentage!"}]}
                                                style={{flex: 1}}
                                            >
                                                <InputNumber suffix="%" placeholder="Enter percentage amount"
                                                             style={{width: '100%'}}/>
                                            </Form.Item>
                                            <Form.Item
                                                label="Max Discount Amount"
                                                name="max_discount_amount"
                                                rules={[{required: true, message: "Please input max discount amount!"}]}
                                                style={{flex: 1}}
                                            >
                                                <InputNumber prefix="₹" placeholder="Enter max discount amount"
                                                             style={{width: '100%'}}/>
                                            </Form.Item>
                                        </>
                                    );
                                }

                                return null; // Render nothing if discount_type is neither 'fixed' nor 'percentage'
                            }}
                        </Form.Item>
                    </div>

                    {/* Common Fields */}
                    <div style={{display: "flex", gap: "16px"}}>
                        <Form.Item
                            label="Start Date"
                            name="start_datetime"
                            rules={[{required: true, message: "Please select start date!"}]}
                            style={{flex: 1}}
                        >
                            <DatePicker placeholder="select date" style={{width: '100%'}}/>
                        </Form.Item>

                        <Form.Item
                            label="End Date"
                            name="end_datetime"
                            style={{flex: 1}}
                        >
                            <DatePicker style={{width: '100%'}} placeholder="select date"/>
                        </Form.Item>
                    </div>

                    <Form.Item name={'lifetime'} valuePropName="checked">
                        <Checkbox>
                            Check, if you want to set coupon validity to lifetime
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Minimum Order Value"
                        name="min_order_value"
                        rules={[{required: true, message: "Please input minimum order value!"}]}
                    >
                        <InputNumber prefix="₹" placeholder="Enter minimum order value" style={{width: '100%'}}/>
                    </Form.Item>

                    <div style={{display: "flex", gap: "18px"}}>
                        <Form.Item
                            label="Offer Name"
                            name="name"
                            rules={[{required: true, message: "Please input offer name!"}]}
                            style={{flex: 1}}
                        >
                            <Input placeholder="Enter offer name"/>
                        </Form.Item>

                        <Form.Item
                            label="Coupon Code"
                            name="code"
                            rules={[{required: true, message: "Please input coupon code!"}]}
                            style={{flex: 1}}
                        >
                            <Input placeholder="Enter coupon code"/>
                        </Form.Item>
                    </div>

                    {/* Coupon Type Field */}
                    <Form.Item
                        label="Coupon Type"
                        name="coupon_type"
                        rules={[{required: true, message: "Please select coupon type!"}]}
                    >
                        <Radio.Group>
                            <Radio value="public">Public Coupon</Radio>
                            <Radio value="private">Private Coupon</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {/* Conditionally Render Select Dropdown for Students if Private Coupon */}
                    <Form.Item noStyle dependencies={['coupon_type']}>
                        {({getFieldValue}) => {
                            const couponType = getFieldValue('coupon_type');

                            if (couponType === 'private') {
                                return (
                                    <Form.Item
                                        label="Select Students"
                                        name="students"
                                        rules={[{required: true, message: "Please select at least one student!"}]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Select students"
                                            style={{width: '100%', height: 42}}
                                            onSearch={handleStudentDebounceSearchInput}
                                            filterOption={false}
                                        >
                                            {studentList?.map(student => (
                                                <Option key={student.id} value={student.id}>
                                                    {student.full_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                );
                            }

                            return null; // Render nothing if coupon_type is not 'private'
                        }}
                    </Form.Item>

                    {/* Course Selection */}
                    <Form.Item
                        label="Course Selection Type"
                        name="course_selection_type"
                        rules={[{required: true, message: "Please select course selection type!"}]}
                    >
                        <Radio.Group>
                            <Radio value="all">Assign to all courses</Radio>
                            <Radio value="specific">Assign to specific courses</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {/* Conditionally Render Select Dropdown for Specific Courses */}
                    <Form.Item noStyle dependencies={['course_selection_type']}>
                        {({getFieldValue}) => {
                            const courseSelectionType = getFieldValue('course_selection_type');

                            if (courseSelectionType === 'specific') {
                                return (
                                    <Form.Item
                                        label="Select Courses"
                                        name="courses"
                                        rules={[{required: true, message: "Please select at least one course!"}]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Select courses"
                                            onSearch={handleCourseDebounceSearchInput}
                                            style={{width: '100%'}}
                                        >
                                            {courseList.map(course => (
                                                <Option key={course.id} value={course.id}>
                                                    {course.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                );
                            }

                            return null; // Render nothing if course_selection_type is not 'specific'
                        }}
                    </Form.Item>

                    <Form.Item
                        label="Usage per Student"
                        name="usage_per_student"
                        rules={[{required: true, message: "Please input usage per student!"}]}
                    >
                        <InputNumber min={1} style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="is_visible"
                        initialValue={true}
                        valuePropName="checked"
                    >
                        <Checkbox> Active </Checkbox>
                    </Form.Item>

                    <FooterButton loading={btnLoading} onClose={onClose}
                                  submitButtonText={coupon ? "Update Coupon" : "Add Coupon"}/>
                </Form>
            </div>
        </Drawer>
    );
};

export default AddCoupon;
