import React, { useState } from 'react';
import styles from "./profile.module.scss";
import { Button, Form, Input, message, Select } from 'antd';
import { useFormHandler } from '../../utils/useFormHandler';
import { AdminProfileRequestData, updateAdminProfile } from '../../api/profile/adminProfile/admin';


interface AcademicInformationDetailsProps {
    onClose: () => void;
    profile?: any;
}

const AcademicInformation: React.FC<AcademicInformationDetailsProps> = ({ onClose, profile }) => {
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode
    const [form] = Form.useForm();

    const onFinish = useFormHandler(
        async (values: AdminProfileRequestData) => {
            profile = { 'profile': values }
            return await updateAdminProfile(profile);
        },
        form,
        () => {
            message.success('Profile updated successfully!');
            setIsEditable(false)
            onClose();
        }
    );
    console.log(profile, "hello api")


    return (
        <div>
            <div className={styles.header}>
                <h2>Academic Information</h2>
                <Button type="default" onClick={() => setIsEditable(prev => !prev)}>
                    {isEditable ? "Cancel" : "Edit"}
                </Button>
            </div>

            <Form
                layout="vertical"
                form={form}
                initialValues={profile.profile}
                onFinish={onFinish}
                className={styles.form}
                disabled={!isEditable} // Disable the form if not editable
            >
                <div className="row">
                    {/* Left Column */}
                    <div className="col-sm-6 extended-column">
                        <Form.Item
                            label="Highest Degree"
                            name="highest_degree"

                        >
                            {isEditable ? (
                                <Input placeholder='Enter Highest Degree' />
                            ) : (
                                <span>{profile.profile.highest_degree || "N/A"}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Institution Attended "
                            name="institution_attended"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Institution Name" />
                            ) : (
                                <span>{profile.profile.institution_attended || "N/A"}</span>
                            )}
                        </Form.Item>
                    </div>


                    <div className="col-sm-6 extended-column">
                        <Form.Item
                            label="Field of Study"
                            name="major_field_of_study"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Field of Study" />
                            ) : (
                                <span>{profile.profile.major_field_of_study || "N/A"}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Graduation Year"
                            name="graduation_year"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Graduation Year" />
                            ) : (
                                <span>{profile.profile.graduation_year || "N/A"}</span>
                            )}
                        </Form.Item>

                    </div>
                </div>
                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? "Update" : "Submit"}
                        </Button>
                    </div>
                )}

            </Form>
        </div>
    );
}

export default AcademicInformation;
