import React, { useState } from 'react';
import { Drawer, List, Avatar, Button, Input } from 'antd';

interface BatchDetailsDrawerProps {
  batchName: string;

  students: { name: string; phone: string }[];
  visible: boolean;
  onClose: () => void;
}

const BatchStudentEnroll: React.FC<BatchDetailsDrawerProps> = ({ batchName,  students, visible, onClose }) => {
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  // Filter students based on search query
  const filteredStudents = students.filter(student =>
    student.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Drawer
      title={`Batch Details - ${batchName}`}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
    >
      {/* Search bar */}
      <Input
        placeholder="Search by Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update search query
        style={{ marginBottom: '16px' }}
      />

      <List
        dataSource={filteredStudents}
        renderItem={student => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar>{student.name.charAt(0)}</Avatar>}
              title={student.name}
              description={student.phone}
            />
          </List.Item>
        )}
      />


    </Drawer>
  );
};

export default BatchStudentEnroll;
