// LiveClasses.tsx
import React from 'react';
import { Avatar, Button, Card, List, Popconfirm } from 'antd';
import { BatchProps } from "../../types/batch";
import styles from "./liveclasses.module.scss";
import PageHeader from "../../common/page-header/PageHeader";
import { VideoCameraOutlined } from "@ant-design/icons";
import { IoMdCalendar } from "react-icons/io";
import { BsClockHistory } from "react-icons/bs";
import { useStudentBatchLiveClasses } from "../../api/batch/student/studentBatch";
import { formattedDateTime, } from "../../utils/utils";
import { isDateExpired } from '../../utils/dateUtils';
import { useNavigate } from "react-router-dom";

interface LiveClassesProps {
    batch: BatchProps;

}


const LiveClasses: React.FC<LiveClassesProps> = ({ batch }) => {
    const { data } = useStudentBatchLiveClasses(batch.id)
    const navigate = useNavigate()

    const handleJoinClick = (item: any) => {
        if (item?.student_join_link) {
            navigate("/full-screen", { state: { url: item.student_join_link } });
        } else {
            alert("Host link is not available.");
        }
    };

    const handleRecordingLinkClick = (item: any) => {
        if (item?.recording_url) {
            window.open(item?.recording_url, '_blank', 'noopener,noreferrer');
        } else {
            alert("Host link is not available.");
        }
    };
    return (
        <Card>
            <div className={styles.leftSection}>
                <PageHeader heading={`Live Classes (${data.length})`} />
                <Card className={styles.card}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => {
                            const isExpired = isDateExpired(item.date);
                            return (
                                <List.Item className={styles.listItem}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                className={styles.avatarIcon}
                                                icon={<VideoCameraOutlined />}
                                            />
                                        }
                                        title={<h3 className={styles.title}>{item.title}</h3>}
                                        description={
                                            <div>
                                                <div className={styles.descDate}>
                                                    <IoMdCalendar /> <span>On: {formattedDateTime(item.date)}</span>
                                                </div>

                                                <div className={styles.duration}>
                                                    <BsClockHistory /> Duration: 60 Minutes
                                                </div>
                                            </div>
                                        }
                                    />
                                    <div className={styles.actions}>
                                        <Popconfirm
                                            title="Are you sure you want to join?"
                                            onConfirm={() => handleJoinClick(item)} // Executes when confirmed
                                            okText="Yes"
                                            cancelText="No"
                                            disabled={isExpired} // Prevents showing Popconfirm if disabled
                                        >
                                            <Button
                                                type="primary"
                                                className={styles.joinButton}
                                                disabled={isExpired}
                                            >
                                                <BsClockHistory /> Join Now
                                            </Button>
                                        </Popconfirm>
                                        <Button
                                            type="default"
                                            className={styles.viewButton}
                                            disabled={!item.recording_url}
                                            onClick={() => handleRecordingLinkClick(item)}

                                        >
                                            <BsClockHistory /> View Class Video
                                        </Button>
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </Card>
            </div>
        </Card>
    );
};

export default LiveClasses;
