import {Modal} from 'antd';
import React from 'react';
import styles from "./testSeriesPurchaseModal.module.scss"; // Updated styles import
import {MdCalendarMonth} from 'react-icons/md';
import {FaBook} from 'react-icons/fa6';
import moment from 'moment';
import TestSeriesPricing from "./TestSeriesPricing";

interface props {
    open: boolean;
    onClose: () => void;
    data?: any;
}

const TestSeriesPurchaseModal: React.FC<props> = ({onClose, open, data}) => {
    return (
        <Modal
            open={open}
            centered
            onCancel={onClose}
            footer={null}
            className={styles.testSeriesModal} // Updated className
        >
            <div className="row">
                <div className={"col-12"}>
                    <div className={styles.seriesContent}>
                        <div className={styles.heading}>{data.name.toUpperCase()}</div>
                        <div className={styles.smallContent}>
                            <p title="starts from"><MdCalendarMonth/> <label>Available
                                From:</label> {moment(data?.available_date).format("DD MMM YYYY")}</p>
                            <p title="subject"><FaBook/> <label>Category:</label> {data.category.title}</p>
                        </div>
                        <p className={styles.description}>{data.description}</p>
                    </div>
                    <TestSeriesPricing testSeries={data} />
                </div>
            </div>
        </Modal>
    );
}

export default TestSeriesPurchaseModal;
