import React, {useEffect} from 'react';
import {Avatar, Card, List} from 'antd';
import {BatchProps} from "../../types/batch";
import PageHeader from "../../common/page-header/PageHeader";
import styles from "./overview.module.scss";
import {CalendarOutlined, NumberOutlined} from "@ant-design/icons";
import {FaBook} from "react-icons/fa";
import {useStudentAnnouncementList} from "../../api/announcement/announcementApi";
import {formattedDateTime} from "../../utils/utils";

interface OverviewProps {
    batch: BatchProps;  // Ensure BatchProps matches the batch structure you're passing in
}


const Overview: React.FC<OverviewProps> = ({batch}) => {
    // const {data, fetchWithParams} = useStudentAnnouncementList(false);


    // useEffect(() => {
    //     // Check if batch ID is defined before fetching
    //     if (batch?.id) {
    //         // Fetch data only once when the component mounts or `batch.id` changes
    //         fetchWithParams({batch: batch.id});
    //     }
    // }, [batch?.id]);

    return (
        <Card>
            <PageHeader heading="Overview"/>
            <div className={styles.container}>
                {/* Left Section */}
                <div className={styles.leftSection}>
                    {/* Rewind Batch Card */}
                    <Card>
                        <h5>Batch Details</h5>
                        <div className={styles.batchInfo}>
                            <div className={styles.batchInfoItem}>
                                <CalendarOutlined className={styles.icon}/>
                                <div>
                                    <span>Batch Start Date:</span>
                                    <strong>{batch.start_date}</strong>
                                </div>
                            </div>
                            <div className={styles.batchInfoItem}>
                                <NumberOutlined className={styles.icon}/>
                                <div>
                                    <span>Batch Code:</span>
                                    <strong>{batch.batch_code}</strong>
                                </div>
                            </div>
                            <div className={styles.batchInfoItem}>
                                <FaBook/>
                                <div>
                                    <span>Subject:</span>
                                    <strong>{batch.subject}</strong>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                
            </div>
        </Card>
    );
};

export default Overview;
