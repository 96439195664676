// StudentBatchesList.tsx
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './studentBatchesList.module.scss';
import {
    studentJoinBatchRequest,
    useAvailableStudentBatchList,
    usePurchasedStudentBatchList
} from '../api/batch/student/studentBatch';
import {BatchProps} from "../types/batch"; // Adjust import path as needed
import PageLoader from '../common/page-loader/PageLoader';
import {Button, message, Segmented, Tag} from 'antd';
import {TYPE_AVAILABLE, TYPE_PURCHASED} from '../utils/constants';
import cn from "classnames"
import {MdCalendarMonth} from "react-icons/md";
import {FaBook, FaPlusCircle} from "react-icons/fa";
import PageHeader from '../common/page-header/PageHeader';
import EnrollBatchModal from '../components/enroll-batch-modal/EnrollBatchModal';
import moment from 'moment';
import {BASE_URL} from "../utils/apiClient";
import {useFormHandler} from "../utils/useFormHandler";

const StudentBatchesList: React.FC = () => {
    const navigate = useNavigate();
    const {data: availableBatches = [], loading, fetchWithParams} = useAvailableStudentBatchList();
    const {data: purchasedBatches = [],} = usePurchasedStudentBatchList();
    const [selectedBatchType, setSelectedBatchType] = useState(TYPE_AVAILABLE)
    const [openPurchaseModal, setOpenPurchaseModal] = useState({visible: false, selectedData: null})
    const [joinBtnLoading, setJoinBtnLoading] = useState(false)

    const onPurchasedBatchPageClick = (batch: BatchProps) => {
        navigate(`/purchased-batch/${batch.id}`);
    };

    const onJoinRequest = useFormHandler(
        async (batch: any) => {
            setJoinBtnLoading(true)
            await studentJoinBatchRequest({batch: batch.id})
            fetchWithParams()
            setJoinBtnLoading(false)
        },
        null,
        () => {
            message.success("Batch joining request send successfully")
        },
        (errorResponse) => {
            setJoinBtnLoading(false)
            if (errorResponse && errorResponse.error) {
                Object.values(errorResponse.error).forEach((value) => {
                    message.error(value); // Display each error message
                });
            } else {
                message.error("An unexpected error occurred.");
            }
        }
    );

    return (
        <div>
            {loading && <PageLoader/>}
            {/* Purchased Batches Section */}
            <PageHeader heading="Batches"/>
            <section>
                <Segmented
                    className={styles.segmentDiv}
                    defaultValue={selectedBatchType}
                    options={[
                        {label: "Available Batches", value: TYPE_AVAILABLE},
                        {label: "Purchased Batches", value: TYPE_PURCHASED}
                    ]}
                    onChange={(value) => setSelectedBatchType(value)}
                />
            </section>
            {/* Available Batches Section */}
            {selectedBatchType === TYPE_AVAILABLE &&
                <section className={styles.availableBatchesSection}>
                    <div className="row">
                        {availableBatches.length === 0 ? (
                            <div className={cn(styles.noDataDiv, "col-12")}><p>No New Batches Found</p></div>
                        ) : (availableBatches.map((batch: any) => (
                            <div className="col-sm-4 col-12" key={batch.id}>
                                <div className={styles.batchDiv} onClick={() => {
                                    setOpenPurchaseModal({visible: true, selectedData: batch});
                                }}>
                                    <div className={styles.batchCardImg}>
                                        <img src={`${BASE_URL}${batch.thumbnail}`}/>
                                    </div>
                                    <div className={styles.batchContent}>
                                        <div className={styles.smallContent}>
                                            <div className={styles.heading}>{batch.name}</div>
                                            {batch.is_joining_request_sent ?
                                                  <Tag color="gold">Request Submitted</Tag>
                                                :
                                                <Button className={styles.joinBatchBtn} loading={joinBtnLoading} onClick={(e) => {
                                                    e.stopPropagation()
                                                    onJoinRequest(batch)
                                                }}><FaPlusCircle/> Join Batch</Button>
                                            }
                                        </div>
                                        <div className={styles.smallContent}>
                                            <p title="starts from">
                                                <MdCalendarMonth/> {moment(batch?.start_date).format("DD MMM YYYY")}
                                            </p>
                                            <p title="subject"><FaBook/> {batch.subject}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>
                </section>}
            {selectedBatchType === TYPE_PURCHASED &&
                <section className={styles.purchasedBatchesSection}>
                    <div className="row">
                        {purchasedBatches.length === 0 ? (
                            <div className={cn(styles.noDataDiv, "col-12")}><p>No Purchased Batches</p></div>
                        ) : (purchasedBatches.map((batch) => (
                            <div className="col-sm-4 col-12" key={batch.id}
                                 onClick={() => onPurchasedBatchPageClick(batch)}>
                                <div className={styles.batchDiv}>
                                    <div className={styles.batchCardImg}>
                                        <img src={`${BASE_URL}${batch.thumbnail}`}/>
                                    </div>
                                    <div className={styles.batchContent}>
                                        <div className={styles.heading}>{batch.name}</div>
                                        <div className={styles.smallContent}>
                                            <p title="starts from">
                                                <MdCalendarMonth/> {moment(batch?.start_date).format("DD MMM YYYY")}</p>
                                            <p title="subject"><FaBook/> {batch.subject}</p>
                                        </div>
                                    </div>
                                    {/* <div className={styles.footer}>
                                    <div className={styles.subjectName}><FiBook/> {batch.created_by}</div>
                                </div> */}
                                </div>
                            </div>
                        )))}
                    </div>
                </section>}
            {openPurchaseModal.visible && <EnrollBatchModal
                installment_number={1}
                open={openPurchaseModal.visible}
                data={openPurchaseModal.selectedData}
                onClose={() => setOpenPurchaseModal({visible: false, selectedData: null})}
            />}
        </div>
    );
};

export default StudentBatchesList;
