import styles from "./batchesList.module.scss";
import cn from "classnames";
import {Alert, Button, Dropdown, Input, MenuProps, message, Select} from "antd";
import {FiBook, FiSearch} from "react-icons/fi";
import React, {useState} from "react";
import {
    CloudUploadOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    MoreOutlined,
    PlusCircleTwoTone,
    StopOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import PageHeader from "../../common/page-header/PageHeader";
import {FaPlus} from "react-icons/fa6";
// import { stat } from "fs/promises";
import {useNavigate} from "react-router-dom";
import {useFormHandler} from "../../utils/useFormHandler";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import RegisteredUser from "./Batch/RegisteredUser";
import {deleteBatch, toggleBatchPublish, useBatchList} from "../../api/batch/batch";
import AddStudentEnrollDrawer from "./Batch/AddStudentEnrollDrawer";
import AddBatch from "./Batch/CreateBatchDrawer";
import PageLoader from "../../common/page-loader/PageLoader";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import {BiBookContent} from "react-icons/bi";
import useDebounceValue from "../../hooks/useDebouncedValue";
import ContentModal from "../../components/ContentModel/ContentModel";
import {BASE_URL} from "../../utils/apiClient";
import {BatchProps} from "../../types/batch";
import EnrolledUserList from "./Batch/EnrolledUserList";
import BatchStudentRequestEnroll from "./Batch/BatchStudentRequestEnroll";
import { AiOutlineUserAdd } from "react-icons/ai";
import { PiStudentFill } from "react-icons/pi";


const BatchesList = () => {
    const navigate = useNavigate();
    const {data: batchData, loading, loadMoreLoading, pagination, error, fetchWithParams, loadMore} = useBatchList();
    const [openCreateBatch, setOpenCreateBatch] = useState(false);
    const [openAddStudentDrawer, setOpenAddStudentDrawer] = useState<boolean>(false);
    const [openExitingUserDrawer, setExitingUserDrawer] = useState<boolean>(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState<any>(null);
    const [enrollStudentDrawer, setEnrollStudentDrawer] = useState(false)
    const [enrollRequestStudentDrawer, setEnrollRequestStudentDrawer] = useState(false)

    const [selectedBatch, setSelectedBatch] = useState<BatchProps | null>(null)
    const {
        updateSearch,
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 200);

    const [deleteBtnLoading, setDeleteBtnLoading] = useState<boolean>(false);
    const [openContentModal, setOpenContentModal] = useState(false);
    // Filtered and search input state
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortOption, setSortOption] = useState<string>("Recently Created");
    const [statusType, setStatusType] = useState<string>("Active Branch");


    const handleSortChange = (value: string) => {
        setSortOption(value);
        // Implement sorting logic here (ascending or descending based on value)
    };

    const handleStatusChange = (value: string) => {
        setStatusType(value);
        // Implement sorting logic here (ascending or descending based on value)
    };

    const handleBatchOpenDrawer = (batch: any) => {
        setOpenCreateBatch(true)
        setSelectedBatch(batch)
    }

    const handleBatchCloseDrawer = () => {
        setOpenCreateBatch(false)
        setSelectedBatch(null)
        resetParams()
    }

    const handleBatchRegisteredUserOpenDrawer = (batch: any) => {
        setExitingUserDrawer(true)
        setSelectedBatch(batch)
        resetParams()
    }

    const handleBatchRegisteredUserCloseDrawer = () => {
        setExitingUserDrawer(false)
        setSelectedBatch(null)
        resetParams()
    }
    const handleBatchNewUserOpenDrawer = (batch: any) => {
        setOpenAddStudentDrawer(true)
        setSelectedBatch(batch)
        resetParams()
    }

    const handleBatchNewUserCloseDrawer = () => {
        setOpenAddStudentDrawer(false)
        setSelectedBatch(null)
        resetParams()
    }
    const handleContentOpenDrawer = (batch: any) => {
        setOpenContentModal(true)
        setSelectedBatch(batch)
    }

    const handleCloseStudentEnrollDrawer = () => {
        setEnrollStudentDrawer(false)
        setSelectedBatch(null)
    }

    const handleOpenStudentEnrollDrawer = (batch: any) => {
        setEnrollStudentDrawer(true)
        setSelectedBatch(batch)
    }
    const handleOpenStudentRequestEnrollDrawer = (batch: any) => {
        setEnrollRequestStudentDrawer(true)
        setSelectedBatch(batch)

    }
    const handleCloseStudentRequestEnrollDrawer = () => {
        setEnrollRequestStudentDrawer(false)
        setSelectedBatch(null)

        resetParams()
    }
    const onDeleteBatchFinish = useFormHandler(
        async (batchId: number) => {
            setDeleteBtnLoading(true);
            await deleteBatch(batchId)
        },
        null,
        () => {
            fetchWithParams()
            setDeleteBtnLoading(false)
            message.success('Batch deleted successfully!');
            // resetParams()
        }
    );

    const handleBatchTogglePublish = async (batch: BatchProps) => {
        try {
            // Call the updateBatch function to update the batch
            await toggleBatchPublish(batch.id);

            // Optionally, you can refresh the batch list or update the local state here
            // e.g., setBatches((prevBatches) => prevBatches.map(b => b.id === batch.id ? updatedValues : b));

            // Optionally show a success message
            message.success(`Batch ${!batch.is_published ? 'published' : 'unpublished'} successfully.`);
            resetParams();
        } catch (error) {
            console.error('Failed to update batch:', error);
            // Optionally show an error message
            message.error('Failed to update the batch status.');
        }
    };

    const menu = (batch: any) => [
        {
            key: '1',
            label: "View",
            icon: <EyeOutlined/>,
            onClick: () => onNavigateDetailPage(batch)
        },

        {

            key: '2',
            label: "Edit",
            icon: <EditOutlined/>,
            onClick: () => handleBatchOpenDrawer(batch)
        },

        {
            key: '3',
            label: batch.is_published ? 'Unpublish' : 'Publish',
            icon: batch.is_published ? <StopOutlined/> : <CloudUploadOutlined/>,
            onClick: () => handleBatchTogglePublish(batch),
        },
        {
            key: '4',
            label: "Content",
            onClick: () => handleContentOpenDrawer(batch),
            icon: <BiBookContent/>,
        },
        {
            key: '5',
            label: "Delete",
            onClick: () => setVisibleDeleteModal(batch.id),
            icon: <DeleteOutlined/>,
        },
        // {
        // key: '4',
        // label: "Duplicate",
        // icon:<CopyOutlined /> ,
        // },
    ]
    const studentsMenu = (batch: any): MenuProps['items'] => [
        {
            key: '1',
            onClick: (e) => handleBatchNewUserOpenDrawer(batch),
            label: "Add Student Manually",
            icon: <UserAddOutlined/>,
        },
        {
            key: '2',
            label: "Add Student from Registered Users",
            icon: <UsergroupAddOutlined/>,
            onClick: () => handleBatchRegisteredUserOpenDrawer(batch),
        },
    ];


    const onConfigClick = () => {
        navigate('/batch-subjects')
    }
    const onNavigateDetailPage = (batch: any) => {
        navigate(`/batches/${batch.id}`)
    }

    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading={`Batches (${batchData.length})`} onConfigClick={onConfigClick}/>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input placeholder="Search by name"
                                   prefix={<FiSearch/>}
                                   onChange={handleDebounceInputChange}
                            />
                            <Select
                                placeholder={"Sort by"}
                                className={styles.sortSelect}
                                value={sortOption}
                                onChange={handleSortChange}
                            >
                                <Select.Option value="Recently Created">Recently Created </Select.Option>
                                <Select.Option value="Batch Name">Batch Name</Select.Option>
                            </Select>
                            <Select
                                className={styles.sortSelect}
                                placeholder="Status Type"
                                value={statusType}
                                onChange={handleStatusChange}
                            >
                                <Select.Option value="Active Batches">Active Batches</Select.Option>
                                <Select.Option value="Archived Batches">Archived Batches</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button
                                onClick={() => {
                                    setOpenCreateBatch(true)

                                }}
                                type="primary"
                            >
                                <FaPlus/> Create Batch
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <div className={cn(styles.batchRow, "row")}>
                {loading && <PageLoader/>}
                {error && <Alert message="Error" description={error} type="error"/>}
                {!loading && !error && batchData.map((batch: any) => (
                    <div className="col-sm-4 col-12" key={batch.id}>
                        <div className={styles.batchDiv}>
                            <div onClick={() => onNavigateDetailPage(batch)} className={styles.batchImg}>
                                <img src={`${BASE_URL}${batch.thumbnail}`}/>
                            </div>

                            <div className={cn(styles.badge, {
                                [styles.published]: batch.is_published,
                                [styles.unpublished]: !batch.is_published
                            })}>
                                {batch.is_published ? "Published" : "Unpublished"}
                            </div>

                            <div className={styles.batchContent}>
                                <div onClick={() => onNavigateDetailPage(batch)}
                                     className={styles.heading}>{batch.name.toUpperCase()}</div>

                                <Dropdown arrow={true} menu={{items: menu(batch)}} trigger={['click']}>
                                    <Button type="dashed" shape="circle" icon={<MoreOutlined/>}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault()
                                            }}/>
                                </Dropdown>
                            </div>
                            <div className={styles.addStudent}>
                                <Dropdown arrow={true} menu={{items: studentsMenu(batch)}} trigger={['click']}>
                                    <Button onClick={(e) => e.stopPropagation()}>
                                        <PlusCircleTwoTone/> Add Student
                                    </Button>
                                </Dropdown>
                            </div>
                            <div className={styles.addedDetails}>
                                <span onClick={() => handleOpenStudentEnrollDrawer(batch)}><PiStudentFill /> Enrolled: <strong>{batch.total_enrolled_students}</strong> </span>
                                <span onClick={() => handleOpenStudentRequestEnrollDrawer(batch)}><AiOutlineUserAdd  /> Request: <strong>{batch.student_join_request.length}</strong></span>
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.subjectName}><FiBook/> {batch.subject.name}</div>
                            </div>

                        </div>

                    </div>
                ))}
                {pagination.next && (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button type="primary" onClick={loadMore} disabled={loadMoreLoading}>
                            {loadMoreLoading ? 'Loading...' : 'Load More'}
                        </Button>
                    </div>
                )}
            </div>

            {openCreateBatch && (
                <AddBatch
                    open={openCreateBatch}
                    onClose={handleBatchCloseDrawer}
                    batch={selectedBatch}
                />
            )}
            {openAddStudentDrawer && selectedBatch && (
                <AddStudentEnrollDrawer
                    open={openAddStudentDrawer}
                    batch={selectedBatch}
                    onClose={handleBatchNewUserCloseDrawer}
                />
            )}
            {openExitingUserDrawer && selectedBatch && (
                <RegisteredUser
                    open={openExitingUserDrawer}
                    batch={selectedBatch}
                    onClose={handleBatchRegisteredUserCloseDrawer}
                />
            )}
            {!!visibleDeleteModal &&
                <DeleteWarningModal
                    okBtnLoading={deleteBtnLoading}
                    open={!!visibleDeleteModal}
                    onConfirm={() => onDeleteBatchFinish(visibleDeleteModal)}
                    onClose={() => setVisibleDeleteModal(null)}
                    message={"Are you sure, want to delete this batch?"}
                />
            }
            {openContentModal && selectedBatch &&
                <ContentModal
                    open={openContentModal}
                    batch={selectedBatch}
                    type={"batch"}
                    onClose={() => setOpenContentModal(false)}
                />
            }

            {enrollStudentDrawer && selectedBatch &&
                <EnrolledUserList batch={selectedBatch} open={enrollStudentDrawer}
                                  onClose={handleCloseStudentEnrollDrawer}/>
            }
            {enrollRequestStudentDrawer && selectedBatch &&
                <BatchStudentRequestEnroll
                    batchName="Student Join Request"
                    students={selectedBatch.student_join_request}
                    visible={enrollRequestStudentDrawer}

                    onClose={handleCloseStudentRequestEnrollDrawer}
                />
            }
        </div>
    );

};

export default BatchesList;
