import React from "react";
import {Button, Drawer, Form, Input, InputNumber, message} from "antd";
import styles from "./addStudentEnrollDrawer.module.scss";
import {useFormHandler} from "../../../utils/useFormHandler";
import {addStudentToBatch} from "../../../api/enrollment/enrollmentApi";
import {BatchProps} from "../../../types/batch";

interface AddStudentEnrollDrawerProps {
    onClose: () => void;
    open: boolean;
    batch: BatchProps; // Batch object containing id and name
}

const AddStudentEnrollDrawer: React.FC<AddStudentEnrollDrawerProps> = ({open, onClose, batch}) => {
    const [form] = Form.useForm();

    // Form handler for batch enrollment
    const onFinish = useFormHandler(
        addStudentToBatch,
        form,
        () => {
            message.success(`Student enrolled in batch ${batch.name} successfully!`);
            onClose();
        }
    );

    return (
        <Drawer
            title={`Enroll Student in ${batch.name}`}
            placement="right"
            width={'30%'}
            onClose={onClose}
            className={styles.createStudentDrawer}
            open={open}
            footer={
                <div className={styles.formFooter}>
                    <Button type="primary" htmlType="submit" form="studentForm">
                        Enroll Student
                    </Button>
                </div>
            }
        >
            <Form
                id="studentForm"
                form={form}
                layout="vertical"
                style={{width: "70%"}}
                initialValues={{batch: batch.id}}
                onFinish={onFinish}
            >
                <Form.Item name="batch" hidden>
                    <Input value={batch.id}/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{required: true, message: "Please input your email!"}]}
                >
                    <Input placeholder="Enter Email"/>
                </Form.Item>

                <Form.Item
                    label="Phone Number"
                    name="phone_number"
                    rules={[{required: true, message: "Please input your phone number!"}]}
                >
                    <InputNumber placeholder="Enter Phone Number" style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item
                    label="Full Name"
                    name="full_name"
                    rules={[{required: true, message: "Please input your full name!"}]}
                >
                    <Input placeholder="Enter Full Name"/>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default AddStudentEnrollDrawer;
