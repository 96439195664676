import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";
import {ModulePermissions, Permissions} from "../../types/permissions";
import {UserProps} from "../../types/users";

// Define the request data type for creating a user
interface CreateUserRequestData {
    full_name: string;
    email: string;
    phone_number: string;
    role: string;
}

// Define the response type
interface CreateUserResponse {
    message: string;
    user_id: number;  // Example of response data (adjust based on your backend)
}

// Define the createUser function that will be used with useFormHandler
export const createUser = async (values: CreateUserRequestData): Promise<CreateUserResponse> => {
    const response = await apiClient.post<CreateUserResponse>('/api/users/', values);
    return response.data;

};


// Define the request data type for updating a user
interface UpdateUserRequestData {
    full_name?: string;
    email?: string;
    phone_number?: string;
    role?: string;
}

// Define the response type
interface UpdateUserResponse {
    message: string;
    user_id: number;  // Example of response data (adjust based on your backend)
}

// Define the updateUser function
export const updateUser = async (userId: number, values: UpdateUserRequestData): Promise<UpdateUserResponse> => {
    const response = await apiClient.patch<UpdateUserResponse>(`/api/users/${userId}/`, values);
    return response.data;

};
export const deleteUser = async (userId: number): Promise<UpdateUserResponse> => {
    const response = await apiClient.delete<UpdateUserResponse>(`/api/users/${userId}/`);
    return response.data;

};


export const getPermissions = async () => {
    const response = await apiClient.get<ModulePermissions[]>('/api/user-permissions/');
    return response.data;
}
export const getRolesPermissions = async () => {
    const response = await apiClient.get<Permissions>('/api/roles-permissions/');
    return response.data;
}
export const postRolesPermissions = async (data: any) => {
    const response = await apiClient.post<Permissions>('/api/roles-permissions/', data);
    return response.data;
}

export const useUserList = (default_fetch: boolean = true) => {
    return useFetchList<UserProps>("/api/users/", default_fetch);
};
