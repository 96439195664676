import React from 'react';
import { Card, Table, Button, Typography } from 'antd';
import styles from './installmentList.module.scss';
import { BatchProps } from "../../types/batch";

interface InstallmentListProps {
    batch: BatchProps;
}

const InstallmentList: React.FC<InstallmentListProps> = ({ batch }) => {
    const installments = batch.installment_details;

    // Define columns for the table
    const columns = [
        {
            title: 'Installment #',
            dataIndex: 'installment_number',
            key: 'installment_number',
            render: (text: number) => `Installment #${text}`,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => `₹ ${amount.toFixed(2)}`,
        },
        {
            title: 'Status',
            dataIndex: 'is_paid',
            key: 'is_paid',
            render: (isPaid: boolean, record:any) => (
                <strong>{isPaid || record.transaction_id ? 'Paid' : 'Unpaid'}</strong>
            ),
        },
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (date: string | null) => date ? date : 'Not Paid Yet',
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
            render: (id: string | null) => id ? id : 'N/A',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_:any, record: any) => (
                <Button
                    type="primary"
                    disabled={record.is_paid || record.transaction_id}
                    onClick={() => handlePayInstallment(record.installment_number)}>
                    Pay Installment
                </Button>
            ),
        },
    ];

    // Dummy function to handle payment action
    const handlePayInstallment = (installmentNumber: number) => {
        // Logic to handle installment payment goes here
        console.log(`Paying installment #${installmentNumber}`);
    };

    return (
        <Card title="Installment Details" className={styles.installmentListCard}>
            <Table
                columns={columns}
                dataSource={installments}
                rowKey="installment_number"
                pagination={false} // Disable pagination if all installments should be shown
            />
        </Card>
    );
};

export default InstallmentList;
