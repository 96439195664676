import React, { useState } from 'react';
import { Button, Switch, List } from 'antd';
import styles from './MenuManager.module.scss';

interface MenuItem {
  id: string;
  label: string;
  visible: boolean;
}

const initialMenuItems: MenuItem[] = [
  { id: '1', label: 'Online Courses', visible: true },
  { id: '2', label: 'Offline Courses', visible: true },
  { id: '3', label: 'Test Series', visible: true },
  { id: '4', label: 'About Us', visible: true },
  { id: '5', label: 'Free Resources', visible: true },
  { id: '6', label: 'Contact Us', visible: true },
];

const WebsiteMenus: React.FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>(initialMenuItems);

  const handleToggleVisibility = (id: string) => {
    setMenuItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, visible: !item.visible } : item
      )
    );
  };

  return (
    <div className={styles.menuManager}>
      <List
        dataSource={menuItems}
        renderItem={(item) => (
          <List.Item className={styles.menuItem}>
            <span>{item.label}</span>
            <Switch
              checked={item.visible}
              onChange={() => handleToggleVisibility(item.id)}
              checkedChildren="Show"
              unCheckedChildren="Hide"
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default WebsiteMenus;
