import React, { useState } from "react";
import { Button, Table } from "antd";
import cn from "classnames";
import styles from "./notification.module.scss"
import { FaPlus } from "react-icons/fa6";
import NotificationDrawer from "../../components/NotificationDrawer/NotificationDrawer";
import { formattedDateTime } from "../../utils/utils";

import { useNotificationList } from "../../api/notification/Notification";
import { NotificationProps } from "../../types/notification";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import { BASE_URL } from "../../utils/apiClient";

const Notification = () => {
    const { data, loading, error, fetchWithParams } = useNotificationList();
    const {
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const [addNotification, setAddNotificationModal] = useState<boolean>(false);
    const [notification, setNotification] = useState<NotificationProps | null>(null);
    const handleOpenAddNotificationDrawer = (notification: any) => {
        setNotification(notification);
        setAddNotificationModal(true);
    }

    const handleOpenCloseNotificationDrawer = () => {
        setNotification(null);
        setAddNotificationModal(false);
        resetParams()
    }

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Description",
            dataIndex: "message",
            key: "message",

        },
        {
            title: "Name",
            dataIndex: "criteria",
            key: "criteria",
            render: (text: string, record: any) =>
                record.criteria === "batch"
                    ? record.batch_title
                    : record.criteria === "student"
                        ? Array.isArray(record.student_names)
                            ? record.student_names.join(", ")
                            : record.student_names
                        : record.criteria === "course"
                            ? record.course_title
                            : record.criteria === "general"
                                ? record.general_title
                                : "",
        },
        {
            title: "Type",
            dataIndex: "criteria",
            key: "criteria",
            render: (text: string) => text.charAt(0).toUpperCase() + text.slice(1)
        },

        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (image: string) =>
                image ? (
                    <a
                        href={`${BASE_URL}${image}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Image
                    </a>
                ) : null,
        },
        // {
        //     title: "Status",
        //     dataIndex: "student_ids",
        //     key: "student_ids",

        // },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
        {
            title: "Date and Time",
            dataIndex: "sent_at",
            key: "sent_at",
            render: (date: any) => formattedDateTime(date)

        },
    ];

    return (
        <div className={cn("container-fluid")}>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-12 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button
                                type="primary" onClick={() => handleOpenAddNotificationDrawer(notification)}
                            >
                                <FaPlus /> Create Notification
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table columns={columns} dataSource={data} rowKey="id" loading={loading} />
                </div>
            </section>

            {addNotification && (
                <NotificationDrawer
                    open={addNotification}
                    onClose={handleOpenCloseNotificationDrawer}
                // notification={notification}

                />

            )}


        </div>
    );
};

export default Notification;
