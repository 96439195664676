// ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { usePermissions } from '../context/PermissionsContext';
import { useAuth } from '../context/AuthContext';
import { ModulePermissions } from "../types/permissions";
import { hasPermission } from "../utils/Role";

interface ProtectedRouteProps {
  moduleName: string;
  action: keyof ModulePermissions;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ moduleName, action, children }) => {
  const { permissions, loading } = usePermissions(); // Destructure loading
  const isAuthorized = hasPermission(action, moduleName, permissions);
  if (loading) {
    // Show a loading message or spinner while permissions are being loaded
    return <div>Loading...</div>;
  }

  if (!isAuthorized) {
    return <Navigate to="/dashboard" replace />; // Redirect if unauthorized
  }

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
