// Define the types for your requests and responses
import useFetchList from "../../utils/listClient";
import apiClient from "../../utils/apiClient";

interface CreateTestSeriesRequest {
    name: string;
    category: number; // Adjust this type based on your API requirements
    total_questions: number;
    duration: number;
    price: number;
    description?: string; // Optional
    highlights?: string[]; // Adjust if highlights should be in a different format
}

// Define the UpdateTestSeriesRequestData type if you have it
interface UpdateTestSeriesRequestData {
    id: number;
    name: string;
    category: number;
    total_questions: number;
    duration: number;
    price: number;
    description?: string;
    highlights?: string[];
}

interface UpdateOrder{
    id:number;
    awb_no:string;
    tracking_no:string;
    tracking_url:string;

}

// Function to create a new test series
export const createTestSeries = async (data: CreateTestSeriesRequest) => {
    const response = await apiClient.post<UpdateTestSeriesRequestData>('/api/test-series/test-series/', data);
    return response.data;
};
export const updateTestSeries = async (testSeriesId:string, data: CreateTestSeriesRequest) => {
    const response = await apiClient.patch<UpdateTestSeriesRequestData>(`/api/test-series/test-series/${testSeriesId}/`, data);
    return response.data;
};

// Function to fetch details of a specific test series
export const detailTestSeries = async (testSeriesId: string): Promise<UpdateTestSeriesRequestData> => {
    const response = await apiClient.get<UpdateTestSeriesRequestData>(`/api/test-series/test-series/${testSeriesId}/`);
    return response.data;
};

// Function to delete a specific test series
export const deleteTestSeries = async (testSeriesId: number) => {
    const response = await apiClient.delete(`/api/test-series/test-series/${testSeriesId}/`);
    return response.data;
};

// Custom hook to fetch the list of test series
export const useTestSeriesList = () => {
    return useFetchList<UpdateTestSeriesRequestData>("/api/test-series/test-series/");
};
// Custom hook to fetch the list of test series
export const useProductOrderList = () => {
    return useFetchList<UpdateTestSeriesRequestData>("/api/test-series/product-orders/");
};

export const updateProductOrderStatus = async (productId:string, delivery_status:string) => {
    const response = await apiClient.patch<UpdateTestSeriesRequestData>(`/api/test-series/product-orders/${productId}/update-status/`, {delivery_status});
    return response.data;
};


interface UpdateOrderRequestData {
    awb_no:string;
    tracking_no: string;
    tracking_url: string;
}

interface UpdateOrderResponseData{
    message:string;
}
export const updateOrders = async (orderId: string, values: UpdateOrderRequestData): Promise<UpdateOrderResponseData> => {
    const response = await apiClient.patch<UpdateOrderResponseData>(`/api/test-series/product-orders/${orderId}/`,values);
    return response.data;
};
