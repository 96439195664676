import React from 'react';
import { message } from 'antd';
import LiveClassCard from './LiveClassCard';
import styles from './liveClass.module.scss';
import { deleteLiveClassForBatch } from '../../api/batch/batch';
import { useFormHandler } from '../../utils/useFormHandler';
import { BatchProps } from '../../types/batch';
import {CourseProps} from "../../types/course";
import { deleteLiveClassForCourse } from '../../api/course/course';


interface LiveClassListProps {
  course: CourseProps ;
  fetchCourseDetails: any;
}

const LiveClassList: React.FC<LiveClassListProps> = ({ course, fetchCourseDetails }) => {

  const handleDelete = useFormHandler(
    async (classId:number) => await deleteLiveClassForCourse(classId),
    null,
    () => {
      message.success('Live class deleted successfully');
      fetchCourseDetails();
    },
    () => {
      message.error('Failed to delete the live class');
    }
  );

  // const liveClasses=course?.live_classes ??[];

  return (
    <div className={styles.feeDetailViewCard}>
      <div className={styles.headerRow}>
        <div>Title</div>
        <div>Start Time</div>
        <div>Join Button</div>
        <div>Action</div>
      </div>
      {course.live_classes.length > 0 ? (
        course.live_classes.map((liveClass) => (
          <LiveClassCard key={liveClass.id} liveClass={liveClass} onDelete={() => handleDelete(liveClass.id)} />
        ))
      ) : (
        <div>No live classes available</div>
      )}
    </div>
  );
};

export default LiveClassList;
