import apiClient from "../../utils/apiClient";
import {FeeStructureProps} from "../../types/feeStructure";
import useFetchList from "../../utils/listClient";

export const createFeeStructure = async (values:FeeStructureProps) => {
    const response = await apiClient.post("/api/batch/fee-structures/", values);
    return response.data;
};

export const updateFeeStructure = async (feesId:number, values:FeeStructureProps) => {
    const response = await apiClient.patch(`/api/batch/fee-structures/${feesId}/`, values);
    return response.data;
};
export const deleteFeeStructure = async (feesId:number) => {
    const response = await apiClient.delete(`/api/batch/fee-structures/${feesId}/`);
    return response.data;
};

export const useFeeStructureList = () => {
    return useFetchList("/api/batch/fee-structures/");
};
