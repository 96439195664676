import React from 'react';
import {Button, Drawer, Form, Input, InputNumber, message} from 'antd';
import {useFormHandler} from "../../utils/useFormHandler";
import {StudentRequestData, updateStudent} from "../../api/student/studentApi";
import styles from "./addStudentDrawer.module.scss";

interface AddressInformationDrawerProps {
    open: boolean;
    onClose: () => void;
    student?: any; // Define the student type according to your needs
}

const AddressInformationDrawer: React.FC<AddressInformationDrawerProps> = ({open, onClose, student}) => {
    const [form] = Form.useForm();

    const onFinish = useFormHandler(
        async (values: StudentRequestData) => {
            return await updateStudent(student.id, values);
        },
        form,
        () => {
            message.success('Address updated successfully!');
            onClose();
        }
    );

    return (
        <Drawer
            title="Address Information"
            placement="right"
            width={'30%'}
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={student.student}
                style={{width: "70%"}}
            >
                <Form.Item
                    label="Permanent Address"
                    name="permanent_address"
                    rules={[{required: true, message: "Please input your permanent address!"}]}
                >
                    <Input.TextArea placeholder="Enter Permanent Address"/>
                </Form.Item>
                <Form.Item
                    label="Permanent Address Pincode"
                    name="permanent_address_pincode"
                    rules={[{required: true, message: "Please input your permanent address pincode!"}]}
                >
                    <InputNumber placeholder="Enter Permanent Address Pincode" style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item
                    label="Correspondence Address"
                    name="correspondence_address"
                    rules={[{required: true, message: "Please input your correspondence address!"}]}
                >
                    <Input.TextArea placeholder="Enter Correspondence Address"/>
                </Form.Item>
                <Form.Item
                    label="Correspondence Address Pincode"
                    name="correspondence_address_pincode"
                    rules={[{required: true, message: "Please input your correspondence address pincode!"}]}
                >
                    <InputNumber placeholder="Enter Correspondence Address Pincode" style={{width: "100%"}}/>
                </Form.Item>
                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        {student ? "Update" : "Submit"}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default AddressInformationDrawer;
