import React from 'react';
import { message } from 'antd';
import LiveClassCard from './LiveClassCard';
import styles from './LiveClassList.module.scss';
import { deleteLiveClassForBatch } from '../../../api/batch/batch';
import { useFormHandler } from '../../../utils/useFormHandler';
import {BatchProps} from "../../../types/batch";


interface LiveClassListProps {
  batch: BatchProps;
  fetchBatchDetails: any;
}

const LiveClassList: React.FC<LiveClassListProps> = ({ batch, fetchBatchDetails }) => {

  const handleDelete = useFormHandler(
    async (classId:number) => await deleteLiveClassForBatch(classId),
    null,
    () => {
      message.success('Live class deleted successfully');
      fetchBatchDetails();
    },
    () => {
      message.error('Failed to delete the live class');
    }
  );

  return (
    <div className={styles.feeDetailViewCard}>
      <div className={styles.headerRow}>
        <div>Title</div>
        <div>Start Time</div>
        <div>Join Button</div>
        <div>Action</div>
      </div>
      {batch.live_classes.length > 0 ? (
        batch.live_classes.map((liveClass) => (
          <LiveClassCard key={liveClass.id} liveClass={liveClass} onDelete={() => handleDelete(liveClass.id)} />
        ))
      ) : (
        <div>No live classes available</div>
      )}
    </div>
  );
};

export default LiveClassList;
