import styles from "./courseCategories.module.scss";
import { Button, Drawer, Form, Input, Space, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useFormHandler } from "../../utils/useFormHandler";
import {createCategory, updateCategory} from "../../api/category/createUpdateDeleteListCategory";
import React from "react"; // Import your custom hook


interface CourseProps {
  onClose: () => void;
  open: boolean;
  category?: { id: number; name: string; }; // Adjust as necessary
}

const AddCategoryDrawer: React.FC<CourseProps> = ({ onClose, open, category }) => {
  const [form] = Form.useForm();

  // Function to handle form submission
  const onFinish = useFormHandler(
    (values: any) => {
      if (category) {
        // If category exists, update the existing category using its ID
        return updateCategory(category.id, values);
      } else {
        // Otherwise, create a new category
        return createCategory(values);
      }
    },
    form,
    () => {
      message.success(category ? 'Category updated successfully!' : 'Category created successfully!');
      onClose();
    }
  );


  return (
    <Drawer
      title={category ? "Edit Category" : "Add Category"}
      onClose={onClose}
      open={open}
      width={"40%"}
      className={styles.createStudentDrawer}
    >
      <div className={styles.drawerMain}>
        <Form
          form={form}
          initialValues={category}
          layout={"vertical"}
          style={{ maxWidth: 600 }}
          onFinish={onFinish} // Use the form handler
        >
          {/* Category Name */}
          <Form.Item
            label="Category Name"
            name="title"
            rules={[{ required: true, message: "Please input the category name!" }]}
          >
            <Input placeholder="Enter Category Name" />
          </Form.Item>


          {/* Subcategories List */}
          <Form.List name="sub_categories" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <h6>Sub Categories</h6>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      rules={[{ required: true, message: "Please input the subcategory!" }]}
                    >
                      <Input placeholder="Enter Sub Category" />
                    </Form.Item>
                    <DeleteOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Sub Category
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Footer Buttons */}
          <div className={styles.formFooter}>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddCategoryDrawer;
