// Overview.tsx
import React, {useEffect} from 'react';
import {Avatar, Card, Image, List, Typography} from 'antd';
import styles from './overview.module.scss';
import {CourseProps} from "../../types/course";
import {formattedDate, formattedDateTime} from "../../utils/utils";
import {BASE_URL} from '../../utils/apiClient';
import {useStudentAnnouncementList} from "../../api/announcement/announcementApi";
import PageHeader from "../../common/page-header/PageHeader";

const {Paragraph} = Typography;


interface OverviewProps {
    course: CourseProps; // Ensure this is the correct type
}

const Overview: React.FC<OverviewProps> = ({course}) => {
    const {data, fetchWithParams} = useStudentAnnouncementList(false);
    useEffect(() => {
        // Check if batch ID is defined before fetching
        if (course?.id) {
            // Fetch data only once when the component mounts or `batch.id` changes
            fetchWithParams({course: course.id});
        }
    }, [course?.id]);
    return (
        <Card>
            <PageHeader heading="Overview"/>
            <div className={styles.container}>
                <div className={styles.leftSection}>
                    <div className={styles.courseInfo}>
                        <Image
                            src={`${BASE_URL}${course.thumbnail}`}
                            alt="Course Image"
                            width={250}
                            className={styles.courseImage} // Add class for styling
                        />
                        <div className={styles.courseDetails}>
                            <h4 className={styles.cardHeading}>
                                {course.name}
                            </h4>
                            {course.expiry_date && <Paragraph className={styles.courseDate}>Ends
                                on {formattedDate(course.expiry_date)}</Paragraph>}
                        </div>
                    </div>
                    <Paragraph className={styles.description}>
                        {course.description}
                    </Paragraph>
                </div>
               

            </div>
        </Card>
    );
};

export default Overview;
