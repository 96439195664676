// Content.tsx
import React from 'react';
import {Card, Space} from 'antd';
import styles from './content.module.scss'; // Ensure correct path
import {MdOutlineVideoLibrary} from 'react-icons/md';
import {TfiFiles} from 'react-icons/tfi';
import {LuCalendarClock} from 'react-icons/lu';
import DirectoryTreeStructure from '../DirectoryTreeStructure';
import {BatchProps} from "../../types/batch";
import { FaImages } from 'react-icons/fa6';


interface ContentProps {
    batch: BatchProps;
}

const Content: React.FC<ContentProps> = ({batch}) => {
    return (
        <Card>
            <h4 className={styles.cardHeading}>Content Summary</h4>
            <Space size="large" className={styles.contentStats}>
                <span><MdOutlineVideoLibrary/> <strong>Videos:</strong> {batch.content.videos} </span>
                <span><FaImages/> <strong>Images:</strong> {batch.content.images} </span>
                <span><TfiFiles/> <strong> Total Files:</strong> {batch.content.total_files || 0} </span>
            </Space>

            <h4 className={styles.cardHeading}>Content</h4>
            <DirectoryTreeStructure directory={batch.content.directory}/>
        </Card>
    );
};

export default Content;
