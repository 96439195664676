import apiClient from "../../utils/apiClient";
import {FileResponse, FolderResponse, GetCourseFolderStructureResponse} from "../../types/folderFiles";

export const createCourseFolder = async (courseId: number, folderData: {
    title: string;
    parent: number | null;
}): Promise<FolderResponse> => {
    const response = await apiClient.post<FolderResponse>(`/api/course/content/${courseId}/create-folder/`, folderData);
    return response.data;
};

export const uploadCourseFile = async (courseId: number, parentFolderId: number | null, file: any): Promise<FileResponse> => {
    const formData = new FormData();
    formData.append("url", file);

    const response = await apiClient.post<FileResponse>(`/api/course/content/${courseId}/folders/${parentFolderId}/create-file/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
};
export const renameCourseFolder = async (courseId: number, folderId: number, newTitle: string): Promise<FolderResponse> => {
    const response = await apiClient.patch<FolderResponse>(`/api/course/content/${courseId}/folders/${folderId}/rename-folder/`, {
        title: newTitle,
    });
    return response.data;
};
export const renameCourseFile = async (courseId: number, fileId: number, newTitle: string): Promise<FileResponse> => {
    const response = await apiClient.patch<FileResponse>(`/api/course/content/${courseId}/files/${fileId}/rename-file/`, {
        title: newTitle,
    });
    return response.data;
};
export const deleteCourseFolder = async (courseId: number, folderId: number): Promise<void> => {
    await apiClient.delete(`/api/course/content/${courseId}/folders/${folderId}/delete-folder/`);
};
export const deleteCourseFile = async (courseId: number, fileId: number): Promise<void> => {
    await apiClient.delete(`/api/course/content/${courseId}/files/${fileId}/delete-file/`);
};
export const toggleLockCourseFile = async (courseId: number, fileId: number): Promise<FileResponse> => {
    const response = await apiClient.patch<FileResponse>(`/api/course/content/${courseId}/files/${fileId}/toggle-lock-file/`);
    return response.data;
};

export const updateCourseOrder = async (courseId:number, itemId:number,type:string, direction:string) => {
    const response = await apiClient.patch(`/api/course/content/${courseId}/update-order/`, {
        type,
        direction,
        id: itemId,
    });
    return response.data; // Now only contains the status message
};
export const getCourseFolderStructure = async (courseId: number, params: any): Promise<GetCourseFolderStructureResponse> => {
    const response = await apiClient.get<GetCourseFolderStructureResponse>(`/api/course/courses/${courseId}/folder-structure/`, {params: params});
    return response.data;
};
export const manageFaculty = async (courseId:number, facultyId: number, action: string) => {
    const response = await apiClient.post(`/api/course/courses/${courseId}/manage-faculty/`, {
        faculty_id: facultyId,
        action: action
    });
    return response.data;
};
