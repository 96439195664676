import apiClient from "../../../utils/apiClient";

export interface AdminProfileRequestData{
    id:number;
    full_name: string;
    phone_number: string;
    email: string;
    profile: {
        joining_date?:string;
        mother_name?:string;
        father_name?:string;
        parent_mobile_number?:string;
        dob?: string;
        gender?: string;
        nationality?: string;
        blood_group?: string;
        permanent_address_pincode?: string;
        correspondence_address?: string;
        correspondence_address_pincode?: string;
        permanent_address?: string;
        job_title?:string;
        institution_name?:string;
        department?:string;
        areas_of_expertise?: string;
        professional_bio?: string;
        highest_degree?: string;
        institution_attended?:string;
        major_field_of_study?:string;
        graduation_year?:string;
        years_of_experience?:string;
        profile_photo?:string
    }
   

}

interface AdminProfileResponse {
    message: string;
    profile: number;
}

export const getProfile = async () => {
    const response = await apiClient.get('/api/profile/');
    return response.data;
};


export const updateAdminProfile = async (values: AdminProfileRequestData): Promise<AdminProfileResponse> => {
    const response = await apiClient.post<AdminProfileResponse>(`/api/profile/`, values);
    return response.data;
};