import React, { useEffect, useState } from 'react';
import styles from "./profile.module.scss";
import { Button, Form, Input, message } from 'antd';
import { useFormHandler } from '../../utils/useFormHandler';
import { ProfileRequestData, updateStudentProfile } from '../../api/profile/profile';
import UploadField from "../../components/uploadField";

interface ParentsDetailsProps {
    onClose: () => void;
    profile?: any;
}

const ParentsDetailsTab: React.FC<ParentsDetailsProps> = ({ profile, onClose }) => {
    const [form] = Form.useForm();
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode

    // Handler for form submission
    const onFinish = useFormHandler(
        async (values: ProfileRequestData) => {
            return await updateStudentProfile(values);
        },
        form,
        () => {
            message.success('Profile updated successfully!');
            setIsEditable(false)
            onClose();
        }
    );
    console.log(profile)

    return (
        <div>
            <div className={styles.header}>
                <h2>Parent Details</h2>
                <Button type="default" onClick={() => setIsEditable(prev => !prev)}>
                    {isEditable ? "Cancel" : "Edit"}
                </Button>
            </div>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={profile.student}
                className={styles.form}
                disabled={!isEditable} // Disable the form if not editable
            >
                <Form.Item
                    label="Mother's Name"
                    name="mother_name"
                    rules={[{required: true, message: "Please input mother's name!"}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Mother's Name"/>
                    ) : (
                        <span>{profile.student.mother_name || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Father's Name"
                    name="father_name"
                    rules={[{required: true, message: "Please input father's name!"}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Father's Name"/>
                    ) : (
                        <span>{profile.student.father_name || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Occupation"
                    name="occupation"
                    rules={[{required: true, message: "Please input parent's occupation!"}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Occupation"/>
                    ) : (
                        <span>{profile.student.occupation || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Parent Contact Number"
                    name="parent_mobile_number"
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Contact"/>
                    ) : (
                        <span>{profile.student.parent_mobile_number || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Parent Email"
                    name="parent_email"
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Email"/>
                    ) : (
                        <span>{profile.student.parent_email || "N/A"}</span>
                    )}
                </Form.Item>

                <UploadField required={false} form={form} name={'parent_profile_photo'} title={"Parent Profile Picture"}/>

                {/* Footer with buttons */}
                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? "Update" : "Submit"}
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
}

export default ParentsDetailsTab;
