import React, {useEffect} from "react";
import {Drawer, Button, Form, Input, Select, message} from "antd";
import styles from "./addUserDrawer.module.scss";
import {useFormHandler} from "../../utils/useFormHandler";
import {verifyOtp} from "../../api/auth/otp";
import {createUser, updateUser} from "../../api/user/userApi";

interface UserDrawerProps {
    onClose: () => void;
    open: boolean;
    user?: UserFormValues | null;
}

interface UserFormValues {
    id: number; // Optional id for update
    full_name: string;
    email: string;
    phone_number: string;
    role: string;
}

const AddUserDrawer: React.FC<UserDrawerProps> = ({onClose, open, user}) => {
    const [form] = Form.useForm<UserFormValues>();

    // Pre-fill form with user data if editing
    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        } else {
            form.resetFields();
        }
    }, [user, form]);

    // Function to handle success response from API
    const handleSuccess = (response: any) => {
        message.success(user ? "User updated successfully!" : "User created successfully!");
        onClose(); // Close the drawer
    };


    // Using the custom form handler to handle API call and form submission
    const onFinish = useFormHandler(
        user ? async (values: UserFormValues) => {
            const userId = user.id
            return updateUser(userId, values);
        } : createUser, // Choose the correct API function
        form,
        handleSuccess // Success callback
    );

    return (
        <Drawer
            title={user ? "Edit Team Member" : "Add Team Member"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <div className={styles.drawerMain}>
                <Form
                    layout={"vertical"}
                    form={form}
                    onFinish={onFinish} // Set the form submit handler
                    style={{maxWidth: 600}}
                >
                    {/* Full Name Field */}
                    <Form.Item
                        label="Full Name"
                        name="full_name"
                        rules={[{required: true, message: "Please input full name!"}]}
                    >
                        <Input placeholder="Enter Full Name"/>
                    </Form.Item>

                    {/* Email Field */}
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {required: true, message: "Please input email!"},
                            {type: "email", message: "Please enter a valid email!"},
                        ]}
                    >
                        <Input placeholder="Enter Email Address"/>
                    </Form.Item>

                    {/* Phone Number Field */}
                    <Form.Item
                        label="Phone Number"
                        name="phone_number"
                        rules={[{required: true, message: "Please input phone number!"}]}
                    >
                        <Input placeholder="Enter Phone Number"/>
                    </Form.Item>

                    {/* Role Dropdown Field */}
                    <Form.Item
                        label="Role"
                        name="role"
                        rules={[{required: true, message: "Please select a role!"}]}
                    >
                        <Select placeholder="Select Role">
                            <Select.Option value="MANAGER">Manager</Select.Option>
                            <Select.Option value="ADMIN">Admin</Select.Option>
                            <Select.Option value="INSTRUCTOR">Instructor</Select.Option>
                        </Select>
                    </Form.Item>

                    <div className={styles.formFooter}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            {user ? "Update" : "Submit"}
                        </Button>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
}


export default AddUserDrawer;
