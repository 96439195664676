import styles from "./studentsList.module.scss";
import cn from "classnames";
import {BiBasket} from "react-icons/bi";
import {FcGraduationCap, FcReading} from "react-icons/fc";
import {GiBookshelf} from "react-icons/gi";
import {Button, Dropdown, Input, MenuProps, message, Select, Table, Tag} from "antd";
import {FiSearch} from "react-icons/fi";
import PageHeader from "../../../common/page-header/PageHeader";
import StatsCard from "../../../common/StatsCard/StatsCard";
import {useEffect, useState} from "react";
import {FaPlus} from "react-icons/fa6";
import AddStudentDrawer from "../../../components/add-student-drawer/AddStudentDrawer";
import {CiEdit} from "react-icons/ci";
import {MdDeleteOutline, MdLock, MdLockOpen} from "react-icons/md";
import {IoEyeOutline} from "react-icons/io5";
import ParentInformationDrawer from "../../../components/add-student-drawer/ParentInformationDrawer";
import PersonalDetailsDrawer from "../../../components/add-student-drawer/PersonalDetailsDrawer";
import AddressInformationDrawer from "../../../components/add-student-drawer/AddressInformationDrawer";
import EducationalDetailsDrawer from "../../../components/add-student-drawer/EducationalDetailsDrawer";
import {formatIndianDate} from "../../../utils/utils";
import useFilterSortPaginate from "../../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../../hooks/useDebouncedValue";
import StudentInfo from "../../../components/Student/StudentInfo";
import DeleteWarningModal from "../../../common/delete-warning-modal/DeleteWarningModal";
import {deleteStudent, toggleStudentActivation, useStudentList} from "../../../api/student/studentApi";
import {TfiMore} from "react-icons/tfi";
import {getStudentMetrics, StudentMetrics} from "../../../api/widgets/widgets";
import {Action, ModuleName, Role, UserRole} from "../../../utils/Role";
import { CgOverflow } from "react-icons/cg";
import { Content } from "antd/es/layout/layout";


const StudentsList = () => {
    // Use the custom hook to manage student data
    const {data: studentData, loading, error, fetchWithParams} = useStudentList();

    const [openAddStudentDrawer, setOpenAddStudentDrawer] = useState<boolean>(false);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);

    // State variables for drawer visibility
    const [openParentDrawer, setOpenParentDrawer] = useState<boolean>(false);
    const [openPersonalDrawer, setOpenPersonalDrawer] = useState<boolean>(false);
    const [openAddressDrawer, setOpenAddressDrawer] = useState<boolean>(false);
    const [openEducationalDrawer, setOpenEducationalDrawer] = useState<boolean>(false);

    const [selectedStudent, setSelectedStudent] = useState<any>(null);
    const [showStudentInfo, setShowStudentInfo] = useState<boolean>(false);
    const [studentMetrics, setStudentMetrics] = useState<StudentMetrics | null>(null);

    const {
        params,
        updateSearch,
        resetParams,
        updateParams
    } = useFilterSortPaginate(fetchWithParams);

    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 500);

    const fetchStudentMetrics = async () => {
        try {
            const metrics = await getStudentMetrics();
            setStudentMetrics(metrics);
        } catch (err) {
            console.error('Error fetching student metrics:', err);
        } finally {
        }
    };

    useEffect(() => {
        fetchStudentMetrics()
    }, [])
    // Handle opening and closing child component drawers/modals
    const handleOpenAddStudentDrawer = (record: any) => {
        setOpenAddStudentDrawer(true);
        setSelectedStudent(record);
    };

    const handleCloseAddStudentDrawer = () => {
        setOpenAddStudentDrawer(false);
        setSelectedStudent(null);
        resetParams();
    };

    const handleOpenViewStudentModal = (record: any) => {
        setSelectedStudent(record);
        setShowStudentInfo(true);
    };

    const handleCloseViewStudentModal = () => {
        //---
        setSelectedStudent(null);
        setShowStudentInfo(false);
    };

    const handleOpenPersonalDrawer = (record: any) => {
        setSelectedStudent(record);
        setOpenPersonalDrawer(true);
    };

    const handleClosePersonalDrawer = () => {
        setSelectedStudent(null);
        setOpenPersonalDrawer(false);
    };

    const handleOpenParentDrawer = (record: any) => {
        setSelectedStudent(record);
        setOpenParentDrawer(true);
    };

    const handleCloseParentDrawer = () => {
        setSelectedStudent(null);
        setOpenParentDrawer(false);
    };

    const handleOpenAddressDrawer = (record: any) => {
        setSelectedStudent(record);
        setOpenAddressDrawer(true);
    };

    const handleCloseAddressDrawer = () => {
        setSelectedStudent(null);
        setOpenAddressDrawer(false);
    };

    const handleOpenEducationalDrawer = (record: any) => {
        setSelectedStudent(record);
        setOpenEducationalDrawer(true);
    };

    const handleCloseEducationalDrawer = () => {
        setSelectedStudent(null);
        setOpenEducationalDrawer(false);
    };

     const handleOpenDeleteModal = (record: any) => {
        setSelectedStudent(record);
        setOpenDeleteWarning(true);
    };

    const handleCloseDeleteModal = () => {
        setSelectedStudent(null);
        setOpenDeleteWarning(false);
        resetParams()
    };
    const handleOnConfirmDeleteModal = async () => {
        try {
            await deleteStudent(selectedStudent.id)
            message.success("Student deleted successfully")
        }
        catch (e){
            message.error("Something went wrong")
        }
        handleCloseDeleteModal()
    }
    const handleStudentTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        let paramsTransaction = {}
        // let symbol = '';
        // if (sorter.order === 'descend')
        //     symbol = '-';
        paramsTransaction = {
            ...params,
            page: pagination.current,
        }
        // if (sorter.columnKey) {
        //     params.ordering = `${symbol}${sorter.columnKey}`
        // }
        updateParams(paramsTransaction);
    };
    const handleMarkActive = async (record: any) => {
        try {
            await toggleStudentActivation(record.id); // Call the API to toggle activation
            message.success('Student account status updated successfully!'); // Success message
            resetParams();
        } catch (error) {
            console.error('Error updating student status:', error); // Log the error for debugging
            message.error('Failed to update student account status. Please try again.'); // Error message
        }
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "full_name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone Number",
            dataIndex: "phone_number",
            key: "phone_number",
        },
        {
            title: "Date Joined",
            dataIndex: "date_joined",
            key: "enrollDate",
            render: (text: string) => formatIndianDate(text),
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "status",
            render: (status: string) => (
                <Tag color={status ? "green" : "red"}>
                    {status ? "Active" : "Inactive"}
                </Tag>
            ),
        },
        {
            title: "Action",
            render: (text: string, record: any) => {
                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: "Personal Info",
                        onClick: () => handleOpenPersonalDrawer(record),
                    },
                    {
                        key: '2',
                        label: "Address",
                        onClick: () => handleOpenAddressDrawer(record),
                    },
                    {
                        key: '3',
                        label: "Education",
                        onClick: () => handleOpenEducationalDrawer(record),
                    },
                    {
                        key: '4',
                        label: "Parents Info",
                        onClick: () => handleOpenParentDrawer(record),
                    },
                ];
                return (
                    <div className={styles.tableActionBtns}>
                        <Button title="View" onClick={() => handleOpenViewStudentModal(record)}>
                            <IoEyeOutline/>
                        </Button>
                        <Role moduleName={ModuleName.STUDENTS} action={Action.EDIT}>
                        <Button title="Edit" onClick={() => handleOpenAddStudentDrawer(record)}>
                            <CiEdit/>
                        </Button>
                        </Role>
                        <Role moduleName={ModuleName.STUDENTS} action={Action.DELETE}>
                        <Button title="Delete" onClick={() => handleOpenDeleteModal(record)}>
                            <MdDeleteOutline/>
                        </Button>
                        </Role>
                        <Button title={record.is_active ? "Mark as Inactive" : 'Mark as Active'} onClick={() => {
                            handleMarkActive(record)
                        }}>
                            {record.is_active ? <MdLock/> : <MdLockOpen/>}
                        </Button>
                        <Dropdown trigger={['click']} menu={{items}} placement="bottomRight" arrow>
                            <Button title="Additional Info" className={styles.additionInfoDropBtn}>
                                <TfiMore/>
                            </Button>
                        </Dropdown>
                    </div>
                );
            }
        },
    ];
    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="All Students" onConfigClick={() => {
            }}/>
            <section className={styles.statsCardSection}>
                <div className="row" style={{display:"Content"}}>
                    {studentMetrics ? (
                        <>
                            <div className="col-6 col-sm-3">
                                <StatsCard
                                    icon={<FcReading color="#9E49E6" className="icon"/>}
                                    iconBg="rgb(231, 244, 251)"
                                    title={studentMetrics.total_students.toString()} // Assuming these fields exist
                                    description={"Total Students"}
                                />
                            </div>
                            <div className="col-6 col-sm-3">
                                <StatsCard
                                    icon={<GiBookshelf color="#49BD65" className="icon"/>}
                                    iconBg="rgb(237, 248, 240)"
                                    title={studentMetrics.active_students.toString()}
                                    description={"Active Students"}
                                />
                            </div>
                            <div className="col-6 col-sm-3">
                                <StatsCard
                                    icon={<FcGraduationCap color="#0A8FDC" className="icon"/>}
                                    iconBg="rgb(231, 244, 251)"
                                    title={studentMetrics.new_enrollments_last_week.toString()}
                                    description={"New Enrollments"}
                                />
                            </div>
                            <div className="col-6 col-sm-3">
                                <StatsCard
                                    icon={<BiBasket color="#9E49E6" className="icon"/>}
                                    iconBg="rgb(245, 237, 252)"
                                    title={studentMetrics.inactive_students.toString()}
                                    description={"Inactive Students"}
                                />
                            </div>
                        </>
                    ) : (
                        <div>Loading metrics...</div> // Handle loading state
                    )}
                </div>
            </section>

            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name"
                                prefix={<FiSearch/>}
                                onChange={handleDebounceInputChange}
                            />
                            <Select className={styles.sortSelect} placeholder="Sort By">
                                <Select.Option value="courseName">Student Name</Select.Option>
                                <Select.Option value="newest">Active</Select.Option>
                                <Select.Option value="lowToHigh">Inactive</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button
                                onClick={() => handleOpenAddStudentDrawer(null)}
                                type="primary"
                            >
                                <FaPlus/> Add Student
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={studentData}
                        rowKey="key"
                        onChange={handleStudentTableChange}
                    />
                </div>
            </section>

            {/* Drawer Components */}
            {openAddStudentDrawer && (
                <AddStudentDrawer
                    open={openAddStudentDrawer}
                    onClose={handleCloseAddStudentDrawer}
                    student={selectedStudent}
                />
            )}

            {/* Uncomment these lines if you have implemented DeleteWarningModal and CourseDetails */}
            {openDeleteWarning && (
                <DeleteWarningModal
                    open={openDeleteWarning}
                    onClose={handleCloseDeleteModal}
                    onConfirm={handleOnConfirmDeleteModal}
                    message={'Are you sure you want to delete this student?'}
                />
            )}
            {showStudentInfo && selectedStudent && (
                <StudentInfo
                    open={showStudentInfo}
                    onClose={handleCloseViewStudentModal}
                    studentInfo={selectedStudent}

                />
            )}
            {openParentDrawer && selectedStudent && (
                <ParentInformationDrawer
                    open={openParentDrawer}
                    onClose={handleCloseParentDrawer}
                    student={selectedStudent}
                />
            )}
            {openPersonalDrawer && selectedStudent && (
                <PersonalDetailsDrawer
                    open={openPersonalDrawer}
                    onClose={handleClosePersonalDrawer}
                    student={selectedStudent}
                />
            )}
            {openAddressDrawer && selectedStudent && (
                <AddressInformationDrawer
                    open={openAddressDrawer}
                    onClose={handleCloseAddressDrawer}
                    student={selectedStudent}
                />
            )}
            {openEducationalDrawer && selectedStudent && (
                <EducationalDetailsDrawer
                    open={openEducationalDrawer}
                    onClose={handleCloseEducationalDrawer}
                    student={selectedStudent}
                />
            )}
        </div>
    );
};

export default StudentsList;
