import React, {useState} from "react";
import {Layout, Space, Typography,} from "antd";
import styles from "./login.module.scss";
import {Images} from "../../common/Images";
import {FcGoogle} from "react-icons/fc";
import {BsFacebook} from "react-icons/bs";
import LoginForm from "./LoginForm";
import OtpPage from "../OTPVerification/Otp";

const {Content} = Layout;
const {Title, Text} = Typography;


const LoginPage: React.FC = () => {

    const [reference_key, setReferenceKey] = useState(null)

    return (
        <Layout className={styles["login-container"]}>
            <Content>
                <div className={styles["login-page"]}>
                    <div className="row">
                        <div className="col-sm-6 col-12 px-0">
                            <div className={styles["login-left"]}>
                                <img
                                    src={Images.baluja}
                                    alt="Welcome illustration"
                                    className={styles["illustration"]}
                                />
                                <Title level={3} className={styles["welcome-title"]}>
                                    Welcome to Baluja Classes.
                                </Title>
                                <Text className={styles["login-text"]}>
                                    Learn new skills and improve your knowledge with our comprehensive courses.
                                </Text>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 px-0">
                            <div className={styles["login-right"]}>

                                {reference_key ?
                                    <OtpPage reference_key={reference_key}/> :
                                    <LoginForm setReferenceKey={setReferenceKey}/>
                                }
                                <div className={styles["social-signin-container"]}>

                                    <p className={styles["new-user"]}>
                                        New User? <a href="/signup">Create an Account</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default LoginPage;
