import {useState} from 'react';

interface Params {
    search?: string;
    ordering?: string;
    page?: number;
}

const useFilterSortPaginate = (
    fetchData: (params: Params, isReset?: boolean) => void,
) => {
    const initialParams = {
        search: '',
        ordering: '',
        page: 1,
    };
    const [params, setParams] = useState<Params>(initialParams);

    // Update params function for search, ordering, pagination, and filters
    const updateParams = (newParams: Partial<Params>) => {
        setParams((prevParams) => {
            const updatedParams = {...prevParams, ...newParams};
            fetchData(updatedParams, true); // Manually trigger data fetching when params change
            return updatedParams;
        });
    };

    const updateSearch = (searchValue: string) => {
        setParams((prevParams) => {
            const updatedParams = {...prevParams, search: searchValue, page: 1}; // Reset to first page
            fetchData(updatedParams, true); // Manually trigger data fetching when filters change
            return updatedParams;
        });
    };

    // Update specific filters
    const updateFilters = (filterKey: string, filterValue: any) => {
        setParams((prevParams) => {
            const updatedFilters = {
                ...prevParams,
                [filterKey]: filterValue,
                page: 1, // Reset to first page
            };
            fetchData(updatedFilters, true); // Manually trigger data fetching when filters change
            return updatedFilters;
        });
    };

    // Update ordering for field sorting, where "-" indicates reverse order
    const updateOrdering = (field: string, reverse = false) => {
        const ordering = reverse ? `-${field}` : field;
        setParams((prevParams) => {
            const updatedParams = {...prevParams, ordering, page: 1}; // Reset to first page
            fetchData(updatedParams, true); // Manually trigger data fetching when ordering changes
            return updatedParams;
        });
    };

    // Update the current page
    const updatePage = (newPage: number) => {
        setParams((prevParams) => {
            const updatedParams = {...prevParams, page: newPage};
            fetchData(updatedParams, true); // Manually trigger data fetching when page changes
            return updatedParams;
        });
    };

    const resetParams = () => {
        setParams(() => {
            fetchData(initialParams, true); // Reset params and fetch data
            return initialParams;
        });
    };


    return {
        params,
        updateParams,
        updateSearch,
        updateFilters,
        resetParams,
        updateOrdering,
        updatePage, // Expose page update function
    };
};

export default useFilterSortPaginate;
