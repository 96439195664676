import React, {useEffect, useState} from 'react';
import styles from "./profile.module.scss";
import {Avatar, Button, Form, Input, message, Select} from 'antd';
import {useAuth} from '../../context/AuthContext';
import cn from "classnames";
import {ProfileRequestData, updateStudentProfile} from '../../api/profile/profile';
import {useFormHandler} from '../../utils/useFormHandler';
import UploadField from "../../components/uploadField";

interface BasicPersonalDetailsProps {
    onClose: () => void;
    profile?: any;
}

const {Option} = Select;

const BasicPersonalDetailsTab: React.FC<BasicPersonalDetailsProps> = ({onClose, profile}) => {
    const [form] = Form.useForm();
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode


    // Handler for form submission
    const onFinish = useFormHandler(
        async (values: ProfileRequestData) => {
            return await updateStudentProfile(values);
        },
        form,
        () => {
            message.success('Profile updated successfully!');
            setIsEditable(false); // Disable edit mode after submission
            onClose();
        }
    );


    return (
        <div>
            <div className={styles.header}>
                <h2>Basic Profile Details</h2>
                <Button type="default" onClick={() => setIsEditable(prev => !prev)}>
                    {isEditable ? "Cancel" : "Edit"}
                </Button>
            </div>

            <Form
                layout="vertical"
                onFinish={onFinish}
                className={styles.form}
                form={form}
                initialValues={profile.student}
                disabled={!isEditable} // Disable the form if not editable
            >
                <Form.Item
                    label="Date of Birth"
                    name="date_of_birth"
                    rules={[{required: true, message: "Please input your date of birth!"}]}
                >
                    {isEditable ? (
                        <Input type="date"/>
                    ) : (
                        <span>{profile.student.date_of_birth || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[{required: true, message: "Please select your gender!"}]}
                >
                    {isEditable ? (
                        <Select placeholder="Select Gender">
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                        </Select>
                    ) : (
                        <span>{profile.student.gender|| "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{required: true, message: "Please input your nationality!"}]}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Nationality"/>
                    ) : (
                        <span>{profile.student.nationality || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Blood Group"
                    name="blood_group"
                    rules={[{required: true, message: "Please input your blood group!"}]}
                >
                    {isEditable ? (
                        <Input placeholder="Enter Blood Group"/>
                    ) : (
                        <span>{profile.student.blood_group || "N/A"}</span>
                    )}
                </Form.Item>

                <UploadField disabled={!isEditable} form={form} name={'profile_photo'} title={"Profile Photo"}/>

                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? "Update" : "Submit"}
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
}

export default BasicPersonalDetailsTab;
