import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, message, Select } from "antd";
import styles from "../../components/add-test-series-drawer/addTestSeriesDrawer.module.scss";
import FooterButton from "../../components/FooterButton";
import { useBatchList } from "../../api/batch/batch";
import { useStudentList } from "../../api/student/studentApi";
import { useCourseList } from "../../api/course/course";
import ImageUpload from "../uploadField";

import { createNotification } from "../../api/notification/Notification";

interface AddReviewDrawerProps {
    onClose: () => void;
    open: boolean;
}
const NotificationDrawer: React.FC<AddReviewDrawerProps> = ({ onClose, open }) => {
    const { data: studentData, loading: studentLoading } = useStudentList();
    const { data: batchData, loading: batchLoading } = useBatchList();
    const { data: courseList, loading: courseLoading } = useCourseList();
    const [filter, setFilter] = useState<string | null>(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [form] = Form.useForm();


    const onFinish = async (values: any) => {
        setBtnLoading(true);

        try {
            const response = await createNotification(values);
            message.success(response.message);
            form.resetFields();
            onClose();
        } catch (error) {
            message.error("Failed to send notification. Please try again.");
        } finally {
            setBtnLoading(false);
        }
    };

    const handleFilterChange = (value: string) => {
        setFilter(value);
    };

    return (
        <Drawer
            title={"Notification"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout={"vertical"}
                className={styles.form}>
                <Form.Item
                    name="criteria"
                    label="Filter By"
                    rules={[{ required: true, message: "Please select a Filter" }]}
                    style={{ flex: 1 }}
                >
                    <Select
                        placeholder="Filter By"
                        onChange={handleFilterChange} // Handle filter change
                    >
                        <Select.Option value="batch">Batch</Select.Option>
                        <Select.Option value="student">Student</Select.Option>
                        <Select.Option value="course">Course</Select.Option>
                        <Select.Option value="general">General</Select.Option>

                    </Select>
                </Form.Item>
                {filter === "batch" && (
                    <Form.Item
                        name="batch"
                        label="Batch"
                        rules={[{ required: true, message: "Please select a Batch" }]}
                    >
                        <Select
                            placeholder="Select Batch"
                            loading={batchLoading}>
                            {batchData.map(batch => (
                                <Select.Option key={batch.id} value={batch.id}>
                                    {batch.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                {filter === "student" && (
                    <Form.Item
                        name="student_ids"
                        label="Student"
                        rules={[{ required: true, message: "Please select a Student" }]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select Student"
                            loading={studentLoading}>
                            {studentData.map(student => (
                                <Select.Option key={student.id} value={student.id}>
                                    {student.full_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                {filter === "course" && (
                    <Form.Item
                        label="Select Course"
                        name="course"
                        rules={[{ required: true, message: "Please select a course!" }]}
                    >
                        <Select
                            placeholder="Select course"
                            loading={courseLoading}
                            style={{ width: '100%' }}
                        >
                            {courseList.map(course => (
                                <Select.Option key={course.id} value={course.id}>
                                    {course.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Please input Title" }]}
                >
                    <Input placeholder="Enter Title" />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="message"
                    rules={[{ required: true, message: "Please Enter Description" }]}
                >
                    <Input.TextArea placeholder="Enter Description" />
                </Form.Item>
                <Form.Item
                    label="Action"
                    name="action"
                >
                    <Input placeholder="Enter Action" />
                </Form.Item>
                <ImageUpload form={form} name="image" required={false} title={'Thumbnail'} />
                <FooterButton loading={btnLoading} onClose={onClose} submitButtonText={"Submit"} />
            </Form>
        </Drawer>
    );
};

export default NotificationDrawer;
