import dayjs, {Dayjs} from "dayjs";

export function truncateText(text: string, maxLength: number) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
    }
    return text;
}

export const formatIndianDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };
    return new Intl.DateTimeFormat("en-IN", options).format(new Date(date));
};
export const formattedDate = (dateString: string) => {
    const date = new Date(dateString);

    // Format date as needed
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}

export const formattedTime = (dateString: string) => {
    const date = new Date(dateString);

    return date.toLocaleTimeString("en-US", {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // For 12-hour format (AM/PM)
    })
}

export const formattedDateTime = (dateString: string) => {

    return `${formattedDate(dateString)} ${formattedTime(dateString)}`;
}


export const disabledDate = (current: Dayjs) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
};



