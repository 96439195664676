import React, {useEffect, useState} from 'react';
import {Card, Divider, Layout, Space} from 'antd';
import {availableDetailStudentCourse} from "../api/course/student/studentCourse";
import {useParams} from "react-router-dom";
import {LuFiles} from "react-icons/lu";
import styles from './newcourse.module.scss';
import PageHeader from "../common/page-header/PageHeader";
import DirectoryTreeStructure from "./DirectoryTreeStructure";
import CoursePricing from "./CoursePricing";
import {formattedDate} from "../utils/utils";
import {BASE_URL} from '../utils/apiClient';
import {FaImage, FaVideo} from "react-icons/fa";
import {CourseProps} from "../types/course";

const {Content} = Layout;


const NewCourse = () => {
    const {courseId} = useParams<any>(); // Specify the type of useParams
    const [course, setCourse] = useState<CourseProps | null>(null); // State to store course data
    const [loading, setLoading] = useState<boolean>(true); // State for loading status
    const [error, setError] = useState<string | null>(null); // State for error handling


    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (!courseId) {
                setError('Course ID is missing'); // Handle missing courseId
                setLoading(false);
                return;
            }

            try {
                const courseData: any = await availableDetailStudentCourse(courseId); // Fetch course details
                setCourse(courseData); // Set the fetched data to state
            } catch (err) {
                setError('Failed to fetch course details'); // Set error message if any
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchCourseDetails(); // Call the fetch function
    }, [courseId]); // Dependency array to re-fetch if courseId changes

    // Render loading, error, or course details
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!course) return <div>No course found.</div>;


    return (
        <Layout>
            {/* <Header style={{background: '#fff', padding: 0}}>
                <h2 style={{padding: '20px'}}>New Course</h2>
            </Header> */}
            <PageHeader heading="New Courses"/>
            <Content style={{padding: '20px'}}>
                <div className={styles.courseContainer}>
                    {/* Left Section */}
                    <div className={styles.courseLeftSection}>
                        <Card>
                            <div className={styles.courseHeader}>
                                {/* Course Image */}
                                <img
                                    src={`${BASE_URL}${course.thumbnail}`}
                                    alt="Course Image"
                                    width={150}
                                    className={styles.courseImage}
                                />
                                <div className={styles.courseInfo}>
                                    <h4 style={{margin: 0}}>{course.name}</h4>
                                    {course?.expiry_date && <p>Ends on {formattedDate(course.expiry_date)}</p>}
                                </div>
                            </div>
                            <p className={styles.courseDescription}>
                                {course.description}
                            </p>
                        </Card>

                        {/* Content Summary */}
                        <Card className={styles.contentSummaryCard}>
                            <h5>Content Summary</h5>

                            <Space size="large" className={styles.contentStats}>
                                <span><FaVideo/> <strong>Videos:</strong> {course.content.videos} </span>
                                <span><FaImage/> <strong>Images:</strong> {course.content.images} </span>
                                <span><LuFiles/> <strong>Total Files:</strong> {course.content.total_files} </span>
                            </Space>
                            <h4 className={styles.cardHeading}>Content</h4>
                            <DirectoryTreeStructure directory={course.content.directory}/>

                        </Card>
                    </div>

                    {/* Right Section */}
                    <div className={styles.courseRightSection}>
                        <Card style={{textAlign: 'center'}}>
                            <p>Have doubts regarding this course?</p>
                            <a href="#" style={{display: 'block', marginTop: '10px'}}>
                                Talk to Tutor
                            </a>
                        </Card>

                        {/* Payment Details Section */}
                        <Card style={{marginTop: '20px'}}>
                            <div className={styles.paymentDetails}>
                                <Divider/>
                                <CoursePricing course={course} onPurchaseSuccess={() => ("demo")}/>
                            </div>
                        </Card>
                    </div>
                </div>
            </Content>

        </Layout>

    );
};


export default NewCourse;
