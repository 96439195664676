import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/globalStyle.scss';
import {AuthProvider} from "./context/AuthContext";
import {PermissionsProvider} from "./context/PermissionsContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <PermissionsProvider>
                <App/>
            </PermissionsProvider>
        </AuthProvider>

    </React.StrictMode>
);

reportWebVitals();
