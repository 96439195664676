import React, {useState} from 'react';
import {Avatar, Button, Drawer, Input, List, message} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {useFormHandler} from "../../../utils/useFormHandler";
import {approveStudentEnroll, rejectStudentEnroll} from "../../../api/batch/batch";

interface Enroll {
    id: number;
    full_name: string;
    phone_number: string;
}

interface BatchDetailsDrawerProps {
    batchName: string;
    students: Enroll[];
    visible: boolean;
    onClose: () => void;
}

const BatchStudentRequestEnroll: React.FC<BatchDetailsDrawerProps> = ({batchName, students, visible, onClose}) => {
    const [searchQuery, setSearchQuery] = useState(''); // State for search query



    const handleAccept = (student: Enroll) => {
        console.log(`Accepted: ${student.full_name}`);
        // Add your logic for accepting the student
    };


    const onApproveFinish = useFormHandler(
        async (enrollmentID:any) => {
            await approveStudentEnroll(enrollmentID);
        }
        , // For adding a new batch, use the createBatch API
        null,
        () => {
            message.success('User Enrollment Approved successfully!');
            onClose();
        }
    );
    const onRejectFinish = useFormHandler(
        async (enrollmentID:any) => {
            await rejectStudentEnroll(enrollmentID);
        },
        null,
        () => {
            message.success('User Enrollment Rejected successfully!');
            onClose();
        }
    );

    return (
        <Drawer
            title={`Batch Details - ${batchName}`}
            placement="right"
            onClose={onClose}
            visible={visible}
            width={400}
        >
            {/* Search bar */}
            <Input
                placeholder="Search by Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                style={{marginBottom: '16px'}}
            />

            <List
                dataSource={students}
                renderItem={enroll => (
                    <List.Item
                        actions={[
                            <Button
                                type="text"
                                icon={<CheckOutlined style={{color: 'green'}}/>}
                                onClick={() => onApproveFinish(enroll.id)}
                            />,
                            <Button
                                type="text"
                                icon={<CloseOutlined style={{color: 'red'}}/>}
                                onClick={() => onRejectFinish(enroll.id)}
                            />
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar>{enroll.full_name.charAt(0)}</Avatar>}
                            title={enroll.full_name}
                            description={enroll.phone_number}
                        />
                    </List.Item>
                )}
            />
        </Drawer>
    );
};

export default BatchStudentRequestEnroll;
