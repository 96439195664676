// src/common/PublicRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PublicRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();

  // Redirect authenticated users to the dashboard
  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
