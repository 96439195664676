import React, {useState} from 'react';
import {Avatar, Button, Checkbox, Drawer, Form, Input, List, message, Spin, Typography} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import styles from './registeruser.module.scss';
import {useStudentList} from "../../../api/student/studentApi"; // Import the hook for fetching student list
import {useFormHandler} from "../../../utils/useFormHandler";
import {createEnrollment} from "../../../api/enrollment/enrollmentApi";
import useFilterSortPaginate from "../../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../../hooks/useDebouncedValue"; // Import createEnrollment function

const {Text} = Typography;

interface RegisteredUserProps {
    onClose: () => void;
    open: boolean;
    batch: any;
}

interface FormValues {
    batch: number;
    students: number[];
}

const RegisteredUser: React.FC<RegisteredUserProps> = ({open, onClose, batch}) => {
    const [selectedStudents, setSelectedStudents] = useState<number[]>(batch.enrolled_students.map((i: any) => i.id));
    const {
        data: studentData,
        loading,
        error,
        pagination,
        loadMoreLoading,
        loadMore,
        fetchWithParams
    } = useStudentList(); // Fetch students
    const [form] = Form.useForm();
    const {
        updateSearch,
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 200);

    // useFormHandler to manage form submission and handle selected students
    const onFinish = useFormHandler(
        async (values: FormValues) => {
            const formData = {
                ...values,
                students: selectedStudents,
            };
            await createEnrollment(formData);
        },
        form,
        () => {
            message.success('Students added successfully!');
            onClose(); // Close the drawer on success
        }
    );

    const handleSelectStudent = (id: number) => {
        setSelectedStudents((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((studentId) => studentId !== id)
                : [...prevSelected, id]
        );
    };

    const handleAddStudents = () => {
        form.submit(); // Trigger form submission via useFormHandler
    };

    return (
        <div className={styles.registeredUserContainer}>
            <Drawer
                title="Registered Students"
                placement="right"
                open={open}
                onClose={onClose}
                width={400} // Adjust width as needed
                footer={
                    <div className={styles.formFooter}>
                        <Button
                            type="primary"
                            onClick={handleAddStudents}
                            loading={loading}
                            disabled={selectedStudents.length === 0}
                        >
                            Add
                        </Button>
                    </div>
                }
            >
                <Input
                    placeholder="Search by Name / Mobile Number"
                    prefix={<SearchOutlined/>}
                    className={styles.searchInput}
                    onChange={handleDebounceInputChange}
                />

                {loading ? (
                    <Spin size="large" style={{display: 'block', textAlign: 'center', marginTop: '20px'}}/>
                ) : error ? (
                    <div>{error}</div>
                ) : (
                    <Form form={form} onFinish={onFinish} initialValues={{batch: batch.id}}>
                        <Form.Item name="batch" hidden>
                            <Input value={batch.id}/>
                        </Form.Item>

                        <List
                            itemLayout="horizontal"
                            dataSource={studentData}
                            renderItem={(student: any) => (
                                <List.Item className={styles.regUserList}>
                                    <Checkbox
                                        checked={selectedStudents.includes(student.id)}
                                        onChange={() => handleSelectStudent(student.id)}
                                    />
                                    <Avatar style={{backgroundColor: '#f56a00'}}>{student.full_name.charAt(0)}</Avatar>
                                    <div>
                                        <Text strong>{student.full_name}</Text>
                                        <br/>
                                        <Text type="secondary">{student.phone_number}</Text>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Form>
                )}
                {pagination.next && (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button type="primary" onClick={loadMore} disabled={loadMoreLoading}>
                            {loadMoreLoading ? 'Loading...' : 'Load More'}
                        </Button>
                    </div>
                )}
            </Drawer>
        </div>
    );
};

export default RegisteredUser;
