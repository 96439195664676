import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, message, Select, Space } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "../add-course-steps/addCourseSteps.module.scss";
import drawerStyles from "./createCourseDrawer.module.scss";
import { useFormHandler } from "../../utils/useFormHandler";
import { CourseRequestData, createCourse, updateCourse } from "../../api/course/course";
import { useCategoryList } from "../../api/category/createUpdateDeleteListCategory";
import { useSubCategoryList } from "../../api/subCategory/createUpdateDeleteListSubCategory";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import ImageUpload from "../uploadField";
import PageHeader from "../../common/page-header/PageHeader";

interface CourseProps {
    onClose: () => void;
    open: boolean;
    course: CourseRequestData | null; // Optional prop for editing
    fetchUpdatedData?: () => void;
}

const CreateCourseDrawer: React.FC<CourseProps> = ({ onClose, open, course, fetchUpdatedData }) => {
    const [form] = Form.useForm();
    const {
        data: categories,
        loading,
        fetchWithParams: categoryFetchWithParams,
        pagination,
    } = useCategoryList();
    const {
        params: categoryParams,
        updateParams: categoryUpdateParams
    } = useFilterSortPaginate(categoryFetchWithParams);

    const {
        data: subCategories,
        pagination: subPagination,
        loading: subLoading,
        fetchWithParams: subCategoryFetchWithParams
    } = useSubCategoryList();
    const {
        params: subCategoryParams,
        updateParams: subCategoryUpdateParams,
        updateFilters: subCategoryUpdateFilters
    } = useFilterSortPaginate(subCategoryFetchWithParams);

    // Handle file change


    // Form submission handler
    const onFinish = useFormHandler(
        
        async (values: CourseRequestData) => {
            
          
            // Check if it's an update or create action
            if (course) {
                const response = await updateCourse(course.id!, values); // Non-null assertion for course.id
                fetchUpdatedData && fetchUpdatedData()
                return response

            } else {
                const response = await createCourse(values);
                fetchUpdatedData && fetchUpdatedData()
                return response
            }
        },

        form,
        () => {
            message.success('Course saved successfully!');
            onClose();
        }
    );
    const handleCategoryChange = async (categoryId: number) => {
        subCategoryUpdateFilters('category', categoryId)
        // try {
        //     const response = await apiClient.get(`/api/course/categories/${categoryId}/subcategories/`); // Adjust endpoint for subcategories
        //     setSubCategories(prev => ({
        //         ...prev,
        //         [categoryId]: response.data.results, // Assuming response structure includes a results array
        //     }));
        // } catch (error) {
        //     console.error("Failed to fetch subcategories:", error);
        // }
    };
    useEffect(() => {
        if (course) {
            const categoriesData = course?.categories_subcategories.map((catData: any) => {
                return {
                    ...catData,
                    category: { label: catData.category.title, value: catData.category.id },
                }
            })
            form.setFieldsValue({
                ...course,
                categories: categoriesData
            })
        }
    }, [course])
    return (
        <Drawer
            title={course ? "Edit Course" : "Create Course"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={drawerStyles.createDrawer}
            footer={
                <div className={styles.formFooter}>
                    <Button type="primary" htmlType="submit" form="courseForm">
                        {course ? "Update" : "Create"}
                    </Button>
                </div>
            }
        >
            <Form
                id="courseForm"
                form={form}
                layout="vertical"
                initialValues={course || undefined}
                onFinish={onFinish}
                className={styles.basicInfoForm}
            >
                <Form.Item
                    label="Course Name"
                    name="name"
                    rules={[{ required: true, message: "Please input the course name!" }]}
                >
                    <Input placeholder="Enter course name" minLength={4} />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: "Please input the course description!" }]}
                >
                    <Input.TextArea rows={4} placeholder="Enter course description here" />
                </Form.Item>

                <ImageUpload form={form} name={'thumbnail'} required={false} />

                <div className={styles.categoryList}>
                    <Form.List name="categories" initialValue={[{}]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: "flex", alignItems: "center" }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, "category"]}
                                            label="Category"
                                            rules={[{ required: true, message: "Please select category!" }]}
                                        >
                                            <Select
                                                placeholder="Select category"
                                                loading={loading}
                                                onChange={(value) => handleCategoryChange(value)}
                                            >
                                                {categories.map((category: any) => (
                                                    <Select.Option key={category.id} value={category.id}>
                                                        {category.title} {/* Adjust the display based on your data structure */}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            name={[name, "subcategories"]}
                                            label="Sub Category"
                                            rules={[{ required: true, message: "Please select sub category!" }]}
                                        >
                                            <Select placeholder="Select sub category" loading={subLoading}
                                                mode="multiple">
                                                {subCategories.map((subCategory: any) => (
                                                    <Select.Option key={subCategory.id} value={subCategory.title}>
                                                        {subCategory.title} {/* Adjust the display based on your data structure */}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <DeleteOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Category
                                    </Button>
                                </Form.Item>


                            </>
                        )}
                    </Form.List>
                </div>
                <hr />
                <PageHeader heading="Includes" />

                <div style={{ display: "flex", gap: "16px" }}>
                    <Form.Item
                        label="Duration"
                        name="param_duration"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter Total Duration" />
                    </Form.Item>


                    <Form.Item
                        label="Parameter 1"
                        name="param_1"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter Parameter 1" />
                    </Form.Item>
                </div>

                <div style={{ display: "flex", gap: "16px" }}>
                    <Form.Item
                        label="Enrolled Students"
                        name="param_enrolled"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter No of Enrolled Students" />
                    </Form.Item>


                    <Form.Item
                        label="Parameter 2"
                        name="param_2"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter Parameter 2" />
                    </Form.Item>


                </div>

                <div style={{ display: "flex", gap: "16px" }}>
                    <Form.Item
                        label="Chapters"
                        name="param_chapters"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter No Of Chapters " />
                    </Form.Item>



                    <Form.Item
                        label="Parameter 3"
                        name="param_3"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter Parameter 3" />
                    </Form.Item>

                </div>

                <div style={{ display: "flex", gap: "16px" }}>
                    <Form.Item
                        label="Levels"
                        name="param_level"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter No Of Levels"  />
                    </Form.Item>

                    <Form.Item
                        label="Parameter 4"
                        name="param_4"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter Parameter 4" />
                    </Form.Item>

                </div>
                <div style={{ display: "flex", gap: "16px" }}>

                    <Form.Item
                        label="Videos"
                        name="param_videos"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter No Of Videos"  />
                    </Form.Item>

                    <Form.Item
                        label="Parameter 5"
                        name="param_5"
                        style={{ flex: 1 }}
                    >
                        <Input placeholder="Enter Parameter 5" />
                    </Form.Item>
                </div>

            </Form>
        </Drawer>
    );
};

export default CreateCourseDrawer;
