import { Modal } from 'antd'
import React from 'react'
import styles from "./enrollBatchModal.module.scss"
import { MdCalendarMonth } from 'react-icons/md';
import { FaBook } from 'react-icons/fa6';
import moment from 'moment';
import { MdCurrencyRupee } from "react-icons/md";
import BatchPricing from "../../studentComponent/BatchPricing";

interface props {
    open: boolean;
    onClose: () => void;
    data?: any
    installment_number?: number
}
const EnrollBatchModal:React.FC<props>=({onClose,open,data, installment_number})=> {
  return (
    <Modal
        // title="Enroll Batch Details"
        open={open}
        centered
        onCancel={onClose}
        footer={null}
        className={styles.enrollModal}
        >
        <div className="row">
            <div className={"col-12"}>
                <div className={styles.batchContent}>
                    <div className={styles.heading}>{data.name.toUpperCase()}</div>
                    <div className={styles.smallContent}>
                        <p title="starts from"><MdCalendarMonth /> <label>Start From:</label> {moment(data?.start_date).format("DD MMM YYYY") }</p>
                        <p title="subject"><FaBook />  <label>Subject:</label> {data.subject}</p>
                    </div>
                    {/*<p className={styles.description}>Join our comprehensive JEE Mains coaching batch, designed to help students excel in one of the most competitive engineering entrance exams.*/}
                    {/*     With a structured curriculum, expert faculty, and personalized doubt-solving sessions, we ensure in-depth understanding of core subjects.*/}
                    {/*     Our regular assessments and mock tests track progress and build exam confidence. Tailored study material and interactive learning methods are*/}
                    {/*     key highlights of the course. Enroll now to secure your path to success in JEE Mains!*/}
                    {/*</p>*/}
                    <h5 className={styles.payHeading}>Payment terms</h5>
                    <div className={styles.paymentTerms}>
                        <div className={styles.paymentLabels}>
                            <label>Fee Installment Amount</label>
                            <span><MdCurrencyRupee /> {data.fee_structure?.fee_amount}</span>
                        </div>
                        <div className={styles.paymentLabels}>
                            <label>Total Installments</label>
                            <span>{data.fee_structure?.installments}</span>
                        </div>
                        <div className={styles.paymentLabels}>
                            <label>Total Amount</label>
                            <span><MdCurrencyRupee /> {data.fee_structure?.total_amount}</span>
                        </div>
                    </div>
                </div>
                <BatchPricing batch={data} installment_number={installment_number!} />
            </div>
         </div>
    </Modal>
  )
}
export default EnrollBatchModal;
