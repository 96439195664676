import React, { useEffect } from "react";
import {Drawer, Button, Form, Input, Select, DatePicker, message} from "antd";
import styles from './lead.module.scss';
import {useFormHandler} from "../../utils/useFormHandler";
import {createEnquiry, EnquiryRequestData, updateEnquiry} from "../../api/lead/leadApi";
import {useStudentList} from "../../api/student/studentApi";
import {useUserList} from "../../api/user/userApi";
import CustomDatePicker from "../CustomDatePicker";
import CustomTimePicker from "../CustomTimePicker";
import dayjs from "dayjs";

const {TextArea} = Input;

interface AddEnquiryDrawerProps {
    open: boolean;
    onClose: () => void;
    lead?: EnquiryRequestData|null; // assuming lead is of type EnquiryRequestData
    onEdit?: () => void;
}

const AddEnquiryDrawer: React.FC<AddEnquiryDrawerProps> = ({open, onClose, lead}) => {
    const [form] = Form.useForm();
    const {data: users, loading, error, fetchWithParams} = useUserList();
    const onFinish = useFormHandler(
        lead ? async (values: EnquiryRequestData) => {
                return await updateEnquiry(lead.id!, values);
            }
            : createEnquiry,
        form,
        () => {
            message.success('Enquiry saved successfully!');
            onClose();
        }
    );
    
    useEffect(()=>{
        if(lead){
            form.setFieldsValue({
                ...lead,
                followup_date_field: dayjs(lead?.followup_date, "YYYY-MM-DD"),
                enquiry_date_field: dayjs(lead?.enquiry_date, "YYYY-MM-DD"),
                followup_time_field:dayjs(lead?.followup_time,"HH:mm:ss")
            })
        }
    },[])

    return (
        <Drawer
            title={lead ? "Edit Enquiry" : "Add Enquiry"}
            width={600}
            onClose={onClose}
            open={open}
            className={styles.createLeadDrawer}
            footer={
                <div className={styles.formFooter}>
                    <Button type="primary" htmlType="submit" form="leadForm">
                        {lead ? "Update" : "Submit"}
                    </Button>
                </div>
            }
        >
            <Form
                id="leadForm"
                layout="vertical"
                onFinish={onFinish}
                // initialValues={lead!}
                form={form}
            >
                <div style={{display: "flex", gap: "16px"}}>
                    <Form.Item name="student_name" label="Student name"
                               rules={[{required: true, message: "Please provide a student name"}]} style={{flex: 1}}>
                        <Input placeholder="Enter student name"/>
                    </Form.Item>
                    <Form.Item name="phone_number" label="Phone number"
                               rules={[{required: true, message: "Please enter a phone number"}]} style={{flex: 1}}>
                        <Input placeholder="Enter phone number"/>
                    </Form.Item>
                </div>
                <div style={{display: "flex", gap: "16px"}}>

                    <CustomDatePicker form={form} field_name={'enquiry_date_field'} label={"Enquiry Date"}
                                      name={'enquiry_date'}
                                      rules={[{required: true, message: "Please select an enquiry date"}]}
                                      style={{flex: 1}}/>
                    <Form.Item name="assign_lead" label="Assign Lead"
                               rules={[{required: true, message: "Please select a lead"}]} style={{flex: 1}}>
                        <Select
                            placeholder="Select lead"
                            loading={loading}
                            options={users.map((user: any) => ({
                                value: user.id,
                                label: user.full_name,
                            }))}
                        />

                    </Form.Item>
                </div>
                <div style={{display: "flex", gap: "16px"}}>
                    <Form.Item name="source" label="Source" rules={[{required: true, message: "Please select Source"}]}
                               style={{flex: 1}}>
                        <Select placeholder="Select Source">
                            <Select.Option value="Justdial">Justdial</Select.Option>
                            <Select.Option value="Sulekha">Sulekha</Select.Option>
                            <Select.Option value="Hoardings">Hoardings</Select.Option>
                            <Select.Option value="Online Marketing">Online Marketing</Select.Option>
                            <Select.Option value="Reference">Reference</Select.Option>
                            <Select.Option value="Others">Others</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="enquiry_status" label="Enquiry Status"
                               rules={[{required: true, message: "Please select enquiry status"}]} style={{flex: 1}}>
                        <Select placeholder="Select Enquiry Status">
                            <Select.Option value="High Interest">High Interest</Select.Option>
                            <Select.Option value="Medium Interest">Medium Interest</Select.Option>
                            <Select.Option value="Low Interest">Low Interest</Select.Option>
                            <Select.Option value="Converted">Converted</Select.Option>
                            <Select.Option value="Lost">Lost</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div style={{display: "flex", gap: "16px"}}>
                    <Form.Item name="class_and_subject" label="Class and Subject (optional)" style={{flex: 1}}>
                        <Input placeholder="e.g. Physics"/>
                    </Form.Item>
                    <Form.Item name="followup_type" label="Followup type"
                               rules={[{required: true, message: "Please select a followup type"}]} style={{flex: 1}}>
                        <Select placeholder="Select followup type">
                            {/* Add options here */}
                            <Select.Option value="Call">Call</Select.Option>
                            <Select.Option value="Demo">Demo</Select.Option>
                            <Select.Option value="Counselling">Counselling</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div style={{display: "flex", gap: "16px"}}>
                    <CustomDatePicker form={form} field_name={'followup_date_field'} label={"Followup Date"}
                                      name={'followup_date'}
                                      rules={[{required: true, message: "Please select an followup date"}]}
                                      style={{flex: 1}}/>
                    <CustomTimePicker form={form}
                                      field_name={'followup_time_field'} label={"Followup Time"} name={'followup_time'}
                                      rules={[{required: true, message: "Please select a followup time"}]}
                                      style={{flex: 1}}/>
                </div>
                <Form.Item name="notes" label="Notes (optional)">
                    <TextArea placeholder="Enter Notes"/>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default AddEnquiryDrawer;
