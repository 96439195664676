import useFetchList from "../../../utils/listClient";
import apiClient from "../../../utils/apiClient";
import {CourseLiveClassesProps, CourseProps} from "../../../types/course";



export const usePurchasedStudentCourseList = () => {
    return useFetchList<CourseProps>("/api/course/student/purchased-courses/");
};

export const useAvailableStudentCourseList = () => {
    return useFetchList<CourseProps>("/api/course/student/available-courses/");
};

export const purchaseDetailStudentCourse = async (courseId: string): Promise<CourseProps> => {
    const response = await apiClient.get<CourseProps>(`/api/course/student/purchased-courses/${courseId}/`);
    return response.data;
}

export const availableDetailStudentCourse = async (courseId: string) => {
    const response = await apiClient.get(`/api/course/student/available-courses/${courseId}/`);
    return response.data;
}



export const useStudentCourseLiveClasses = (courseId:number) => {
    return useFetchList<CourseLiveClassesProps>(`/api/course/student/${courseId}/course-live-classes/`);
};