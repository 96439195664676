import {useCallback, useEffect, useRef, useState} from "react";
import apiClient from "./apiClient";
import {useFormHandler} from "./useFormHandler";
import paginationObj from "./pagination";

interface ApiResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

const initialPaginationObj = {
    ...paginationObj,
    next: null as string | null,
    previous: null as string | null,
}
const useFetchList = <T>(url: string, default_fetch=true) => {
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const appendDataRef = useRef(false);
    const isFetched = useRef(false); // Ref to track if fetch has already occurred
    const [pagination, setPagination] = useState(initialPaginationObj)

    // Function to fetch data with parameters
    const fetchData = async (params: Record<string, any> = {}) => {
        try {
            const response = await apiClient.get<ApiResponse<T>>(url, {params});
            return response.data; // Return the data to be handled by useFormHandler
        } catch (err) {
            throw new Error('Failed to fetch data.');
        }
    };

    // Define the success callback
    const onSuccess = (response: ApiResponse<T>) => {
        setData((prevData) => {
            return appendDataRef.current ? [...prevData, ...response.results] : response.results;
        });
        setPagination((prevState) => ({
            ...prevState,
            total: response.count,
            next: response.next,   // Update 'next' value
            previous: response.previous,  // Update 'previous' value
        }));
        setError(null); // Reset any existing errors
    };

    // Create the handler with parameters
    const handleApiCall = useFormHandler(fetchData, null, onSuccess);

    // Function to handle API calls with parameters
    const fetchWithParams = useCallback((params?: Record<string, any>, isReset = false) => {

        if (appendDataRef.current) {
            setLoadMoreLoading(true)
        } else {
            if (isReset) {
                appendDataRef.current = false;
                setPagination(initialPaginationObj)
            }
            setLoading(true);
        }
        handleApiCall(params)
            .catch((err: any) => {
                setError(err.message || "Failed to fetch data. Please try again.");
            })
            .finally(() => {
                setLoading(false)
                setLoadMoreLoading(false)
            });
    }, [handleApiCall]);

    // Internal function to load more data (next page)
    const loadMore = () => {
        if (!pagination.next) return; // No next page
        appendDataRef.current = true; // Set append mode to true
        fetchWithParams({page: pagination.page + 1});
        setPagination((prevState) => ({...prevState, page: prevState.page + 1}));
    };


    useEffect(() => {
        if (!isFetched.current && default_fetch) {
            fetchWithParams({}); // Initial fetch with no parameters
            isFetched.current = true; // Mark as fetched
        }
    }, [fetchWithParams, default_fetch]); // Correctly add fetchWithParams as a dependency

    return {data, loading, loadMoreLoading, error, pagination, fetchWithParams, loadMore};
};

export default useFetchList;
