import apiClient from "../../utils/apiClient";
import {SignupResponse} from '../types';

// Define the request data type for the signup API
interface SignupRequestData {
    fullName: string;
    email: string;
    mobile: string;
}

// Define the signup function that will be used with useFormHandler
export const handleSignup = async (values: SignupRequestData): Promise<SignupResponse> => {
    const response = await apiClient.post<SignupResponse>('/api/signup/', values);
    return response.data;

};
