import {Card as AntCard} from "antd"
import React from "react";
import cn from "classnames"
import style from "./statsCard.module.scss"
import styleMobile from "./statsCardmobile.module.scss"

interface cardProps{
    title:any,
    className?:string,
    onClick?:(...agrs:any)=>void;
    icon?:any;
    description:string;
    iconBg?:string;
}

const StatsCard:React.FC<cardProps>=({icon,description,title,iconBg,className,onClick,...props})=>{
    return(
        <AntCard 
            onClick={onClick} 
            className={cn(style.statsCard,className)} 
            bordered={false}
            {...props}
            >
            <div className={cn(style.cardBody, styleMobile.cardBody)}>
                <div style={{background:iconBg||"rgb(231, 244, 251)"}} className={cn(style.iconDiv)}>
                    {icon}
                </div>
                <div className={cn(style.contentDiv)}>
                    <h6>{title}</h6>
                    <p>{description}</p>
                </div>
            </div>
        </AntCard>
    )   
}
export default StatsCard