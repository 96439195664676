import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

// Define interfaces
export interface InitiatePaymentResponse {
    order_id: string;
    amount: number;
    currency: string;
}

export interface VerifyPaymentData {
    razorpay_order_id: string;
    razorpay_payment_id: string;
    razorpay_signature: string;
}

export interface ApplyCouponProps {
    course_id: number;
    coupon_code: string;
    validity_period?:number
}

// Function to initiate payment
export const purchaseCourse = async (
    course_id: number,
    coupon_code?: string,
    validity_period?: number,
): Promise<any> => {
    const response = await apiClient.post('/api/payment/purchase-course/', {course_id, coupon_code, validity_period});
    return response.data;
};
export const purchaseBatch = async (
    batch_id: number,
    installment_number: number
): Promise<any> => {
    const response = await apiClient.post('/api/payment/purchase-batch/', {batch_id, installment_number});
    return response.data;
};

export const purchaseTestSeries = async (
    test_series_id: number,
): Promise<any> => {
    const response = await apiClient.post('/api/payment/purchase-test-series/', {test_series_id});
    return response.data;
};

// Function to verify payment
export const verifyPayment = async (data: VerifyPaymentData) => {
    const response = await apiClient.post('/api/payment/verify-payment/', data);
    return response.data;
};

export const applyCoupon = async (data: ApplyCouponProps) => {
    const response = await apiClient.post('/api/payment/apply-coupon/', data);
    return response.data;
};

export const getCoursePricing = async (courseId: number, validity_period?: number) => {
    const params: Record<string, any> = {};
    if (validity_period) {
        params.validity_period = validity_period;
    }
    const response = await apiClient.get(`/api/payment/course-pricing/${courseId}/`, {params})
    return response.data;
};
export const getBatchPricing = async (batchId: number, installment_number: number) => {
    const response = await apiClient.get(`/api/payment/batch-pricing/${batchId}/installment/${installment_number}/`);
    return response.data;
};
export const getTestSeriesPricing = async (testSeriesId: number) => {
    const response = await apiClient.get(`/api/payment/test-series-pricing/${testSeriesId}/`);
    return response.data;
};
export const downloadInvoice = async (transactionId: number) => {
    const response = await apiClient.get(`/api/payment/transactions/${transactionId}/download-pdf/`, {
        responseType: 'blob', // Ensure response is treated as a blob
    });

    return response.data;
};


export const useTransactionList = () => {
    return useFetchList("/api/payment/transactions/");
};
export const useStudentTransactionList = () => {
    return useFetchList("/api/payment/student-transactions/");
};
