import React, {useEffect, useState} from 'react';
import {Button, Divider, message, Spin} from 'antd';
import styles from './testSeriesPricing.module.scss'; // Updated styles import
import {useFormHandler} from "../utils/useFormHandler";
import {TestSeriesProps} from "../types/testSeries";
import {getTestSeriesPricing, purchaseTestSeries, verifyPayment} from "../api/payment/payment";
import {useNavigate} from "react-router-dom"; // Assuming a similar type for test series

interface TestSeriesPricingProps {
    testSeries: TestSeriesProps; // Updated props type
}

const TestSeriesPricing: React.FC<TestSeriesPricingProps> = ({testSeries}) => {
    const navigate = useNavigate();

    const [pricingDetails, setPricingDetails] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const fetchPricingDetails = useFormHandler(
        () => {
            setLoading(true);
            return getTestSeriesPricing(testSeries.id); // Updated API call
        },
        null,
        (response: any) => {
            setPricingDetails(response);
            setLoading(false);
        },
        (error) => {
            setLoading(false);
            message.error('Failed to fetch pricing details.');
        }
    );

    useEffect(() => {
        fetchPricingDetails({});
    }, [testSeries.id]);

    const handlePurchaseTestSeries = useFormHandler(
        () => {
            return purchaseTestSeries(testSeries.id); // Updated purchase API call
        },
        null,
        async (response: any) => {
            const {order_id, amount: orderAmount, currency, name, description, image, prefill} = response;
            try {
                // Define Razorpay options
                const options: any = {
                    key: 'rzp_test_2cywPv1gKde6UC', // Razorpay Key ID
                    amount: orderAmount * 100, // Amount in paise
                    currency: currency,
                    name: name,
                    description: description,
                    image: image,
                    order_id: order_id,
                    handler: async (response: any) => {
                        // Handle payment success
                        const {razorpay_order_id, razorpay_payment_id, razorpay_signature} = response;

                        try {
                            const verifyResponse: any = await verifyPayment({
                                razorpay_order_id,
                                razorpay_payment_id,
                                razorpay_signature,
                            });
                            message.success('Payment successful!');
                            navigate(`/purchased-product/${testSeries.id}`);
                        } catch (error) {
                            message.error('Payment verification failed.');
                            console.error('Verification Error:', error);
                        }
                    },
                    prefill: prefill,
                    notes: {
                        // Any additional notes
                    },
                    theme: {
                        color: '#3399cc',
                    },
                };

                const rzp1 = new (window as any).Razorpay(options);
                rzp1.open();
            } catch (error: any) {
                message.error(error.response?.data?.error || 'Payment initiation failed.');
                console.error('Payment Initiation Error:', error);
            } finally {
                // Optional cleanup if needed
            }
        },
        (errorResponse) => {
            message.error("Something went wrong")
        }
    );

    if (!pricingDetails) {
        return <div>No pricing details available.</div>;
    }

    const {original_price, internet_charges, platform_fees, gst_amount, total_amount, gst_percentage} = pricingDetails;

    return (
        <div>
            <h5 className={styles.payHeading}>Pricing Details</h5>
            <Divider/>
            <div className={styles.paymentItem}>
                <div>Test Series Price</div>
                <div>₹{original_price.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>Internet Charges</div>
                <div>+ ₹{internet_charges.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>Platform Fee</div>
                <div>+ ₹{platform_fees.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>GST ({gst_percentage}%)</div>
                <div>+ ₹{gst_amount.toFixed(2)}</div>
            </div>
            <div className={styles.paymentItem}>
                <div>Total Amount Payable</div>
                <div>₹{total_amount.toFixed(2)}</div>
            </div>
            <Divider/>
            <Button onClick={handlePurchaseTestSeries} type="primary" block disabled={loading}>
                {loading ? <Spin/> : `Purchase Now ₹${total_amount.toFixed(2)}`}
            </Button>
        </div>
    );
};

export default TestSeriesPricing;
