// src/utils/dateUtils.ts
import dayjs from "dayjs";

export const isDateExpired = (date: string): boolean => {
    const classTime = dayjs(date);  //convert  date in day js object
    const now=dayjs(); // current date and time
    const InMinutes = now.diff(classTime,'minute')

    return InMinutes > 120;
};
