import apiClient from "../../utils/apiClient";

interface BatchReviewsData {
    batch: number;
    title: string;
    description?: string;
    rating: string;
}

interface BatchReviewResponse {
    message: string
}


interface CourseReviewsData {
    course: number;
    title: string;
    description?: string;
    rating: string;
}

interface CourseReviewResponse {
    message: string
}

export const createBatchReviews = async (values: BatchReviewsData): Promise<BatchReviewResponse> => {
    const response = await apiClient.post<BatchReviewResponse>('/api/batch/reviews/', values);
    return response.data;
};

export const createCourseReviews = async (values: CourseReviewsData): Promise<CourseReviewResponse> => {
    const response = await apiClient.post<CourseReviewResponse>('/api/course/reviews/', values);
    return response.data;
};