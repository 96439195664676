import {Button, Input, message} from 'antd'
import React, {useState} from 'react'
import styles from "./freeResources.module.scss"
import {deleteFreeResource, useFreeResourceList} from "../../api/freeResources/freeResource";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import {useFormHandler} from "../../utils/useFormHandler";
import {FiEye, FiSearch} from "react-icons/fi";
import {FaPlus} from "react-icons/fa6";
import PageLoader from "../../common/page-loader/PageLoader";
import {BASE_URL} from "../../utils/apiClient";
import {CiEdit} from "react-icons/ci";
import {MdDeleteOutline} from "react-icons/md";
import CreateImportResource from "../../components/FreeResource/CreateImportResource";
import ResourceContentModal from "../../components/FreeResource/ResourceContentModal";
import { VscFileSubmodule } from "react-icons/vsc";
import ContentModal from "../../components/ContentModel/ContentModel";

export default function FreeResources() {
    const [openImportDrawer, setOpenImportDrawer] = useState(false);
    const [openContentDrawer, setOpenContentDrawer] = useState(false);
    const [selectedResource, setSelectedResource] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const {
        data: resources,
        fetchWithParams,
        pagination,
        loadMore,
        loadMoreLoading,
        loading
    } = useFreeResourceList()
    const {updateSearch, resetParams} = useFilterSortPaginate(fetchWithParams);
    const [deleteLoading, setDeleteLoading] = useState(false)


    const onDelete = useFormHandler(
        async (documentId: number) => {
            return await deleteFreeResource(documentId)
        }, // For adding a new batch, use the createBatch API
        null,
        () => {
            message.success('Document deleted successfully');
            resetParams();
        },
        () => {

        },
        setDeleteLoading
    );

    const handleCloseDrawer = () => {
        setOpenImportDrawer(false)
        resetParams()
    }
    const handleCloseContentDrawer = () => {
        setOpenContentDrawer(false)
        resetParams()
    }

    return (
        <>
            {/* Action Buttons Section */}
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name"
                                prefix={<FiSearch/>}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button onClick={() => setOpenImportDrawer(true)} type="primary">
                                <FaPlus/> Create Resource
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            {/* document List Section */}
            {loading ? (
                <PageLoader/>
            ) : (
                <section className={styles.documentSection}>
                    <div className={"row"}>
                        {resources.map((resource) => (
                            <div key={resource.id} className="col-sm-4 col-12">
                                <div className={styles.documentCard}>
                                    <div className={styles.documentThumbnailDiv}>
                                        <img
                                            src={`${BASE_URL}${resource.thumbnail}`}
                                            alt={resource.title}
                                        />

                                    </div>
                                    <h5>{resource.title}</h5>
                                    <div className={styles.actions}>
                                        <Button
                                            onClick={() => {
                                                setOpenImportDrawer(true);
                                                setSelectedResource(resource)
                                            }}
                                        >
                                            <CiEdit/> Edit
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setOpenContentDrawer(true);
                                                setSelectedResource(resource)
                                            }}
                                        >
                                            <VscFileSubmodule/> Content
                                        </Button>
                                        <Button

                                            loading={deleteLoading}
                                            onClick={() => onDelete(resource.id)}
                                            danger
                                        >
                                            <MdDeleteOutline/> Delete
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    {pagination.next && (
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <Button type="primary" onClick={loadMore} disabled={loadMoreLoading}>
                                {loadMoreLoading ? 'Loading...' : 'Load More'}
                            </Button>
                        </div>
                    )}
                </section>
            )}

            {/* Drawer for Importing documents */}
            {openImportDrawer && (
                <CreateImportResource
                    resource={selectedResource}
                    open={openImportDrawer}
                    onClose={handleCloseDrawer}
                />
            )}
            {openContentDrawer && selectedResource &&
                <ContentModal
                    onClose={handleCloseContentDrawer}
                    type={'resource'}
                    open={openContentDrawer}
                    resource={selectedResource}
                />
            }
        </>
    );
}
