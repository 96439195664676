import React, { useState, useEffect } from "react";
import { Drawer, Button, List, Avatar, Select, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./assignFacultyDrawer.module.scss";
import { useUserList } from "../../api/user/userApi";
import { UserRole } from "../../utils/Role";
import {manageFaculty} from "../../api/course/createFolderFiles";
import {UserProps} from "../../types/users";


interface FacultyProps {
  faculty: UserProps;
  course: number;
  id:number
}

interface AssignFacultyDrawerProps {
  open: boolean;
  onClose: () => void;
  course: {
    id: number;
    faculties: FacultyProps[]; // Assumed structure for assigned faculties
  };
}

const AssignFacultyDrawer: React.FC<AssignFacultyDrawerProps> = ({ open, onClose, course }) => {
  const { data: availableFaculties, fetchWithParams } = useUserList(false);
  const [selectedFaculty, setSelectedFaculty] = useState<number | null>(null);
  const [addFacultyDrawerOpen, setAddFacultyDrawerOpen] = useState(false);

  useEffect(() => {
    if (open) {
      fetchWithParams({ role: UserRole.INSTRUCTOR });
    }
  }, [open]);

  // Filter out faculties already assigned to the course
  const unassignedFaculties: UserProps[] = availableFaculties?.filter(
  (faculty) =>
    !course.faculties.some(
      (row: FacultyProps) => row.faculty.id === faculty.id
    )
);

  const assignFaculty = async () => {
    if (!selectedFaculty) {
      message.warning("Please select a faculty.");
      return;
    }
    try {
      await manageFaculty(course!.id, selectedFaculty, "add");
      message.success("Faculty assigned successfully.");
      setAddFacultyDrawerOpen(false);
      onClose()
    } catch (error) {
      message.error("Failed to assign faculty.");
    }
  };

  const removeFaculty = async (facultyId: number) => {
    try {
      await manageFaculty(course!.id, facultyId, "remove");
      message.success("Faculty removed successfully.");
      onClose()
    } catch (error) {
      message.error("Failed to remove faculty.");
    }
  };

  return (
    <>
      {/* Outer Drawer: Show Assigned Faculties */}
      <Drawer
        title="Assigned Faculties"
        open={open}
        onClose={onClose}
        width={400}
        footer={
          <Button type="primary" onClick={() => setAddFacultyDrawerOpen(true)} icon={<PlusOutlined />}>
            Add Faculty
          </Button>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={course.faculties}
          renderItem={(row) => (
            <List.Item
              actions={[
                <Button type="link" onClick={() => removeFaculty(row.faculty.id)}>
                  Remove
                </Button>
              ]}
            >
              <List.Item.Meta
                  avatar={<Avatar >{row.faculty.full_name.charAt(0)}</Avatar>}
                title={row.faculty.full_name}
              />
            </List.Item>
          )}
        />
      </Drawer>

      {/* Inner Drawer: Add New Faculty */}
      <Drawer
        title="Add Faculty"
        open={addFacultyDrawerOpen}
        onClose={() => setAddFacultyDrawerOpen(false)}
        width={300}
      >
        <Select
          placeholder="Select a faculty"
          style={{ width: "100%", marginBottom: 20 }}
          onChange={(value) => setSelectedFaculty(value)}
        >
          {unassignedFaculties?.map((faculty) => (
            <Select.Option key={faculty.id} value={faculty.id}>
              {faculty.full_name}
            </Select.Option>
          ))}
        </Select>
        <Button type="primary" onClick={assignFaculty} block>
          Assign Faculty
        </Button>
      </Drawer>
    </>
  );
};

export default AssignFacultyDrawer;
