import styles from "./foorterButton.module.scss";
import {Button} from "antd";
import React from "react";

interface FooterButtonProps {
    loading: boolean;
    onClose: any;
    submitButtonText: string;
}

const FooterButton: React.FC<FooterButtonProps> = ({loading, onClose, submitButtonText}) => {
    return <div className={styles.formFooter}>
        <Button onClick={onClose}>Close</Button>
        <Button loading={loading} type="primary" htmlType="submit">
            {submitButtonText}
        </Button>
    </div>
}
export default FooterButton
