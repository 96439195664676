import {FormInstance} from 'antd/es/form';
import {AxiosError, AxiosErrorResponse, CustomAxiosError} from "../api/types";
import {message} from 'antd';
import {ErrorResponse} from "../types/error";

interface CustomFormError {
    name: string | (string | number)[];
    errors: string[];
}

// Utility function to handle form errors from Axios response
export const handleFormErrors = <T>(error: AxiosError, form: FormInstance<T>) => {
    const {response} = error;
    if (response && response.data) {
        const serverErrors = response.data as AxiosErrorResponse;
        const errorObject = serverErrors.error;
        if (errorObject && typeof errorObject === 'object') {
            // Convert server-side errors to Ant Design's form error format
            const formErrors: CustomFormError[] = Object.keys(errorObject).map((field) => ({
                name: field,
                errors: [errorObject[field] || ''],
            }));
            // Apply errors to form
            form.setFields(formErrors as any); // Cast to bypass TS check for setField
        } else {
            throw new CustomAxiosError('Unexpected server error format.');
        }
    } else {
        throw new CustomAxiosError('Unexpected error occurred.');
    }
};

export const useFormHandler = <T, R>(
    apiCall: (values: T) => Promise<R>,
    form: FormInstance<T> | null,
    onSuccess: (response: R) => void,
    onError?: (response?: ErrorResponse) => void,
    setLoading?: (loading: boolean) => void, // New parameter for managing loading state
) => {
    return async (values: T) => {
        try {
            // Set loading to true before the API call
            if (setLoading) setLoading(true);

            if (form) {
                // Clear previous errors
                form.setFields([{name: 'non_field_errors', errors: []}] as any);
            }

            const response = await apiCall(values);
            // Call the onSuccess callback if the API call was successful
            onSuccess(response);
        } catch (error: unknown) {
            const axiosError = error as AxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (form) {
                    // Delegate form error handling to the utility
                    handleFormErrors(axiosError, form);
                }
                const errorResponse = axiosError.response.data as unknown;
                onError?.(errorResponse as ErrorResponse);
            } else if (axiosError.code === 'ECONNABORTED' || !axiosError.response) {
                // Show a message for network errors
                message.error('Network error: Unable to reach the server. Please check your connection.');
            } else {
                // Show a generic error message for unknown errors
                message.error('An unexpected error occurred. Please try again later.');
            }
        } finally {
            // Set loading to false after the API call (whether successful or not)
            if (setLoading) setLoading(false);
        }
    };
};

export const showErrors = (errorResponse: unknown) => {
    const serverErrors = errorResponse as ErrorResponse;
    const errorObject = serverErrors.error;
    if (errorObject) {
        if (errorObject && typeof errorObject === 'object') {
            // Convert server-side errors to Ant Design's form error format
            Object.keys(errorObject).forEach((field) => {
                message.error(errorObject[field])
            });
        } else {
            message.error("Unexpected error occurred")
        }
    } else {
        message.error("Unexpected error occurred")
    }
}
