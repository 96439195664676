import React, {useState} from "react";
import {Button, Form, Input, message} from "antd";
import {Link} from "react-router-dom";
import styles from "./login.module.scss";
import {useFormHandler} from "../../utils/useFormHandler";
import {loginUser} from "../../api/auth/loginApi";
import {NonErrorFieldDisplay} from "../../common/NonErrorField"; // Ensure the path is correct
import {Images} from "../../common/Images";

interface LoginFormValues {
    phone_number: string;
    non_field_errors: string;
}

interface LoginFormProps {
    setReferenceKey: any;
}

const LoginForm: React.FC<LoginFormProps> = ({setReferenceKey}) => {
    const [form] = Form.useForm<LoginFormValues>();
    const [submitLoading, setSubmitLoading] = useState(false)

    const handleSuccess = (response: any) => {
        setSubmitLoading(false)
        setReferenceKey(response.reference_key)
        message.success("OTP sent to your phone number!");
    };
    const handleError = (response: any) => {
        setSubmitLoading(false)
    };

    const onFinish = useFormHandler(loginUser, form, handleSuccess, handleError);

    return (
        <div className={styles.loginForm}>
            <div className={styles["img-logo"]}>
                <img src={Images.logo} alt="title" className={styles["baluja"]}/>
                <Link to={"https://lms-website-pink.vercel.app/"}>Back to website</Link>
            </div>
            <h1 className={styles["signin-title"]}>Sign into Your Account</h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                    onFinish(values);
                    setSubmitLoading(true)
                }}
                className={styles["login-form"]}
            >
                <Form.Item name="non_field_errors" noStyle>
                    <NonErrorFieldDisplay/>
                </Form.Item>
                <Form.Item
                    label="Mobile"
                    name="phone_number"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your mobile number",
                        },
                    ]}
                    className={styles["form-item"]}
                >
                    <Input placeholder="Enter your mobile" size="large"/>
                </Form.Item>

                <Form.Item className={styles["submit-button"]}>
                    <Button loading={submitLoading} type="primary" htmlType="submit" size="large" block>
                        Sign In
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginForm;
