import {Alert, Form} from "antd";
import React from "react";

export const NonErrorFieldDisplay = () => {
    // Use Form.Item.useStatus or getFieldError to get the status and errors
    const {errors} = Form.Item.useStatus();

    // Alternatively, you can use form.getFieldError('non_field_errors') if you're not in Form.Item context
    const error_message = errors.length > 0 ? errors.join(' ') : ""
    return (
        <>
            {errors.length > 0 && (
                <Alert
                    message={error_message}  // Display all errors in a single string
                    type="error"
                    showIcon
                    style={{marginBottom: '16px'}}
                />
            )}
        </>
    );
};
