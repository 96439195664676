import apiClient from "../../utils/apiClient";
export interface ProfileRequestData {
    id:number
    full_name: string;
    phone_number: string;
    email: string;
    date_of_birth?: string;
    gender?: string;
    father_name?: string;
    occupation?: string;
    nationality?: string;
    blood_group?: string;
    permanent_address_pincode?: string;
    correspondence_address?: string;
    permanent_address?: string;
    college_name?: string;
    correspondence_address_pincode?: string;
    marks_college?: string;
    mother_name?: string;
    additional_notes?: string;
    parent_mobile_number?: string;
    parent_email?: string;
    school_name?: string;
    marks_x?: number;
    marks_xii?: number;
    student_image?: File;         // Add image field for student
    parent_image?: File;          // Add image field for parent
    x_result?: File;              // Add file field for X result
    xii_result?: File;            // Add file field for XII result
}

// Define the response type
interface ProfileResponse {
    message: string;
    profile: number;
}

export const getMyProfile = async () => {
    const response = await apiClient.get('/api/my-profile/');
    return response.data;
};




// API call to update a student profile

  export const updateStudentProfile = async (  values: ProfileRequestData): Promise<ProfileResponse> => {
    const response = await apiClient.post<ProfileResponse>(`/api/my-profile/`, values);
    return response.data;
};



  




