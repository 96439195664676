import {Button, Drawer, Form, Input, message} from 'antd';
import React, {useState} from 'react';
import styles from "./createResource.module.scss";
import ImageUpload from "../uploadField";
import {useFormHandler} from "../../utils/useFormHandler";
import {createFreeResource, updateFreeResource} from "../../api/freeResources/freeResource";

interface props {
    onClose: () => void;
    open: boolean;
    resource?: any
}

const CreateImportResource: React.FC<props> = ({onClose, open, resource}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onFinish = useFormHandler(
        async (values: any) => {
            // return await createFreeResourceDocument(values)
            if (resource) {
                await updateFreeResource(resource.id, values)
            } else {
                await createFreeResource(values)
            }
        },
        form,
        (response) => {
            message.success("Resource created successfully")
            onClose()
        },
        () => {
        },
        setLoading
    )

    return (
        <Drawer
            title={"Create Resource"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <div className={styles.drawerMain}>
                <Form
                    layout={"vertical"}
                    form={form}
                    onFinish={onFinish}
                    initialValues={resource}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{required: true, message: "Please input title!"}]}
                    >
                        <Input placeholder="Enter Title"/>
                    </Form.Item>

                    <ImageUpload
                        form={form}
                        name="thumbnail"
                        title="Thumbnail"
                    />


                    <div className={styles.formFooter}>
                        <Button onClick={onClose} disabled={loading}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
}

export default CreateImportResource;
