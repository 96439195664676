import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './studentcoursesList.module.scss';
import ImageCard from "../common/imageCard/ImageCard";
import { useAvailableStudentCourseList, usePurchasedStudentCourseList } from "../api/course/student/studentCourse";
import { BASE_URL } from '../utils/apiClient';
import PageLoader from '../common/page-loader/PageLoader';
import {CourseProps} from "../types/course";

const getEffectivePriceValidity = (course: CourseProps) => {
    if (course.validity_type === 'multiple' && course.validity_periods?.length > 0) {
        // Sort by promoted status and return the effective price from the first valid period
        const promotedPeriod = [...course.validity_periods].sort((a, b) => {
            if (a.is_promoted && !b.is_promoted) return -1;
            if (!a.is_promoted && b.is_promoted) return 1;
            return 0;
        })[0];

        return {
            duration_value: promotedPeriod.duration_value,
            duration_unit: promotedPeriod.duration_unit,
            price: promotedPeriod.price,
            effective_price: promotedPeriod.effective_price
        };
    }

    return null; // Return null if no valid periods or not 'multiple'
};

const StudentCoursesList: React.FC = () => {
    const navigate = useNavigate();
    const { data: availableCourses = [], loading } = useAvailableStudentCourseList();
    const { data: purchasedCourses = [] } = usePurchasedStudentCourseList();

    const handlePurchasedClick = (course: CourseProps) => {
        navigate(`/purchased-course/${course.id}`);
    };

    const handleNewCourseClick = (course: CourseProps) => {
        navigate(`/new-course/${course.id}`);
    };

    return (
        <div>
            {loading && <PageLoader />}

            {/* Purchase Courses Section */}
            <section className={styles.purchaseCoursesSection}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Purchased Courses</h4>
                    </div>
                    {purchasedCourses.length > 0 ? purchasedCourses.map((course) => (
                        <div className="col-sm-3 col-12" key={course.id} onClick={() => handlePurchasedClick(course)}>
                            <ImageCard
                                className={styles.courseCard}
                                title={course.name}
                                image={`${BASE_URL}/${course.thumbnail}`}
                            />
                        </div>
                    )) :
                        <p>Not purchased yet!</p>
                    }
                </div>
            </section>

            {/* New Courses Section */}
            <section className={styles.coursesSection}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>New Courses</h4>
                    </div>
                    {availableCourses.length > 0 ? availableCourses.map((course) => {
                        const validity = getEffectivePriceValidity(course);

                        return (
                            <div className="col-sm-3 col-12" key={course.id} onClick={() => handleNewCourseClick(course)}>
                                <ImageCard
                                    className={styles.courseCard}
                                    title={course.name}
                                    image={`${BASE_URL}/${course.thumbnail}`}
                                >
                                    <div className={styles.tagsDiv}>
                                        <h6 className={styles.priceCourse}>
                                            {/* Show effective price for multiple validity courses */}
                                            Price:
                                            {validity ? (
                                                <>
                                                    ₹{validity.effective_price}
                                                    <div>{validity.price && `₹${validity.price}`}</div>
                                                </>
                                            ) : (
                                                // If not multiple, fallback to standard price
                                                <>
                                                    {course.effective_price ? (
                                                        `₹${course.effective_price}`
                                                    ) : (
                                                        <i>Not Added</i>
                                                    )}
                                                    {course.price && <div>₹{course.price}</div>}
                                                </>
                                            )}
                                        </h6>
                                    </div>
                                    <p className={styles.createdByTag}>
                                        Created By: <span>{course.created_by}</span>
                                    </p>
                                </ImageCard>
                            </div>
                        );
                    }) :
                        <p>Data not added</p>
                    }
                </div>
            </section>
        </div>
    );
};

export default StudentCoursesList;
