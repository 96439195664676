import React, { useEffect, useState } from "react";
import { Button, Divider, message, Tag } from "antd";
import ApplyCoupon from "./ApplyCoupon"; // Ensure ApplyCoupon is properly implemented
import { TagOutlined } from "@ant-design/icons";
import styles from "./coursepricing.module.scss";
import { CourseProps } from "../types/course";
import {
  applyCoupon,
  getCoursePricing,
  purchaseCourse,
  verifyPayment,
} from "../api/payment/payment";
import { useFormHandler } from "../utils/useFormHandler";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../types/error";

interface CoursePricingProps {
  course: CourseProps;
  onPurchaseSuccess: (transactionId: string, amountPaid: number) => void; // Callback on successful purchase
}

const CoursePricing: React.FC<CoursePricingProps> = ({
  course,
  onPurchaseSuccess,
}) => {
  const [pricingDetails, setPricingDetails] = useState<any>({});
  const [selectedValidityPeriod, setSelectedValidityPeriod] =
    useState<any>(null); // New state to hold selected validity period
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchPricingDetails = useFormHandler(
    (validityPeriodId?: number) => {
      setLoading(true);
      return getCoursePricing(course.id, validityPeriodId); // Pass the selected validity period ID to the API
    },
    null,
    (response: any) => {
      setPricingDetails(response);
      setLoading(false);
    },
    (response) => {
      setLoading(false);
    }
  );

  useEffect(() => {
    if (course.validity_type === "multiple") {
      if (course.validity_periods.length === 1) {
        setSelectedValidityPeriod(course.validity_periods[0]);
      }
    } else {
      // If validity type is not multiple, fetch pricing directly
      fetchPricingDetails(undefined);
    }
  }, [course.id]); // Only depend on course.id here

  // You can still conditionally trigger pricing fetch on `selectedValidityPeriod` change
  useEffect(() => {
    if (selectedValidityPeriod) {
      fetchPricingDetails(selectedValidityPeriod.id);
    }
  }, [selectedValidityPeriod]);
  const handleDeleteClick = () => {
    fetchPricingDetails(selectedValidityPeriod.id);
  };

  const handlePurchaseCourse = useFormHandler(
    async () => {
      const { coupon } = pricingDetails;
      setLoading(true);
      const response = await purchaseCourse(
        course.id,
        coupon?.code,
        selectedValidityPeriod?.id
      ); // Include validity period ID
      setLoading(false);
      return response;
    },
    null,
    (response: any) => {
      const {
        order_id,
        amount: orderAmount,
        currency,
        name,
        description,
        image,
        prefill,
      } = response;
      try {
        // Define Razorpay options
        const options: any = {
          key: "rzp_test_2cywPv1gKde6UC", // Razorpay Key ID
          amount: orderAmount * 100, // Amount in paise
          currency: currency,
          name: name,
          description: description,
          image: image,
          order_id: order_id,
          handler: async (response: any) => {
            // Handle payment success
            const {
              razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
            } = response;

            try {
              const verifyResponse: any = await verifyPayment({
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
              });
              message.success("Payment successful!");
              navigate(`/purchased-course/${course.id}`);
            } catch (error) {
              message.error("Payment verification failed.");
              console.error("Verification Error:", error);
            }
          },
          prefill: prefill,
          notes: {
            // Any additional notes
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.open();
      } catch (error: any) {
        message.error(
          error.response?.data?.error || "Payment initiation failed."
        );
        console.error("Payment Initiation Error:", error);
      } finally {
      }
    }
  );

  const handleApplyCoupon = useFormHandler(
    (couponCode: string) => {
      setLoading(true);
      return applyCoupon({
        course_id: course.id,
        coupon_code: couponCode,
        validity_period: selectedValidityPeriod.id,
      });
    },
    null,
    (response: any) => {
      message.success(`Coupon applied successfully!`);
      setPricingDetails(response);
      setLoading(false);
    },
    (response?: ErrorResponse) => {
      setLoading(false);

      // Check if errorResponse has the expected structure
      if (response && response.error) {
        Object.values(response.error).forEach((value) => {
          if (typeof value === "string") {
            message.error(value); // Display each error message
          }
        });
      } else {
        message.error("An unexpected error occurred.");
      }
    }
  );

  // Destructure pricing details
  const {
    original_price,
    gst_percentage,
    gst_amount,
    coupon,
    discount_amount,
    internet_charges,
    platform_fees,
    total_amount,
  } = pricingDetails;

  const { validity_periods } = course;

  return (
    <div>
      <h3>PRICE DETAILS</h3>

      {/* Show validity periods if available */}
      {validity_periods && validity_periods.length > 1 && (
        <div className={styles.paymentItem}>
          <div>Select Validity Period</div>
          <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {validity_periods.map((period: any) => (
              <Tag
                key={period.id}
                color={
                  selectedValidityPeriod?.id === period.id ? "blue" : "default"
                } // Highlight selected chip
                onClick={() => setSelectedValidityPeriod(period)} // Set the selected period
                style={{ cursor: "pointer" }}
              >
                {`${period.duration_value} ${period.duration_unit}`}
              </Tag>
            ))}
          </div>
        </div>
      )}
      {pricingDetails.original_price && (
        <>
          <div className={styles.paymentItem}>
            <p>
              <TagOutlined /> Coupon Code
            </p>
            <ApplyCoupon
              onDeleteCoupon={handleDeleteClick}
              onApplyCoupon={handleApplyCoupon}
              disabled={!!coupon?.id}
            />
          </div>
            <h5 className={styles.couponHeading}>Available Coupons</h5>
            <div className={styles.coupon}>
              <span>WELCOME10</span>
              <span>poiuUIYTY</span>
              <span>FGHVJH</span>
              <span>KJHG</span>
              <span>KJHG123</span>
            </div>
         

          <Divider />
          <div className={styles.paymentItem}>
            <div>Original Price</div>
            <div>₹{original_price.toFixed(2)}</div>
          </div>

          {/* Applied coupon discount */}
          {coupon?.id && (
            <div className={styles.paymentItem}>
              <div>
                Coupon Discount ({coupon?.code.toUpperCase() || "Coupon"})
              </div>
              <div>- ₹{discount_amount.toFixed(2)}</div>
            </div>
          )}

          <div className={styles.paymentItem}>
            <div>Internet Charges</div>
            <div>+ ₹{internet_charges.toFixed(2)}</div>
          </div>
          <div className={styles.paymentItem}>
            <div>Platform Fee</div>
            <div>+ ₹{platform_fees.toFixed(2)}</div>
          </div>

          {/* GST will be second last */}
          <div className={styles.paymentItem}>
            <div>GST ({gst_percentage}%)</div>
            <div>+ ₹{gst_amount.toFixed(2)}</div>
          </div>

          <div className={styles.paymentItem}>
            <div>Total Amount Payable</div>
            <div>₹{total_amount.toFixed(2)}</div>
          </div>
          <Divider />
          <Button
            onClick={handlePurchaseCourse}
            loading={loading}
            type="primary"
            block
          >
            {`Buy Now ₹${total_amount.toFixed(2)}`}
          </Button>
          <p>
            * Amount payable is inclusive of taxes.{" "}
            <a href="#">Terms & Conditions</a> apply.
          </p>
        </>
      )}
    </div>
  );
};

export default CoursePricing;
