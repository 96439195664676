import React, { useState } from 'react';
import styles from './profile.module.scss';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { useFormHandler } from '../../utils/useFormHandler';
import { ProfileRequestData, updateStudentProfile } from '../../api/profile/profile';
import UploadField from "../../components/uploadField";

interface EducationDetailsProps {
    onClose: () => void;
    profile?: any;
}

const EducationDetails: React.FC<EducationDetailsProps> = ({ profile, onClose }) => {
    const [form] = Form.useForm();
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode

    // Handler for form submission
    const onFinish = useFormHandler(
        async (values: ProfileRequestData) => {
            return await updateStudentProfile(values);
        },
        form,
        () => {
            message.success('Profile updated successfully!');
            setIsEditable(false)
            onClose();
        }
    );

    return (
        <div>

            <div className={styles.header}>
                <h2>Education Details</h2>
                <Button type="default" onClick={() => setIsEditable(prev => !prev)}>
                    {isEditable ? "Cancel" : "Edit"}
                </Button>
            </div>
            <Form
                layout="vertical"
                onFinish={onFinish}
                initialValues={profile.student}
                form={form}
                className={styles.form}
                disabled={!isEditable} // Disable the form if not editable
            >
                <Form.Item
                    label="School Name"
                    name="school_name"
                    rules={[{required: true, message: 'Please input your school name!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter School Name"/>
                    ) : (
                        <span>{profile.student.school_name || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="College Name"
                    name="college_name"
                    rules={[{required: true, message: 'Please input your college name!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <Input placeholder="Enter College Name"/>
                    ) : (
                        <span>{profile.student.college_name || "N/A"}</span>
                    )}
                </Form.Item>

                <Form.Item
                    label="Marks in X (%)"
                    name="marks_x"
                    rules={[{required: true, message: 'Please input marks in X!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <InputNumber min={0} placeholder="Enter X Percentage"/>
                    ) : (
                        <span>{profile.student.marks_x || "N/A"}</span>
                    )}
                </Form.Item>

                <UploadField required={false} form={form} name={'x_result'} title={"X Result"} file_extensions={[]}/>

                <Form.Item
                    label="Marks in XII (%)"
                    name="marks_xii"
                    rules={[{required: true, message: 'Please input marks in XII!'}]}
                    className={styles.input}
                >
                    {isEditable ? (
                        <InputNumber min={0} placeholder="Enter XII Percentage"/>
                    ) : (
                        <span>{profile.student.marks_xii || "N/A"}</span>
                    )}
                </Form.Item>

                <UploadField required={false} form={form} name={'xii_result'} title={"XII Result"} file_extensions={[]}/>

                {/* Footer with buttons */}
                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? 'Update' : 'Submit'}
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default EducationDetails;
