import logo from "./../assets/images/Baluja_Logo.png"
import userDummy from "./../assets/images/userDummy.jpg"
import baluja from "./../assets/images/baluja.png"
import videoThumbnail from "./../assets/images/videoThumbnail.jpg"
import availableBatchImg from "./../assets/images/availableBatchImg.png"
import purchaseBatchImg from "./../assets/images/purchaseBathImg.jpg"
import thumbnail from "./../assets/images/thumbnail.png"
import video_recording from "./../assets/images/video-recorder.png"

export const Images = {
    logo, userDummy, baluja, videoThumbnail, availableBatchImg, purchaseBatchImg, thumbnail, video_recording
}
