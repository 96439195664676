import React from "react";
import {Button, Drawer, Form, Input, InputNumber, message} from "antd";
import styles from "./addStudentDrawer.module.scss";
import {createStudent, StudentRequestData, updateStudent} from "../../api/student/studentApi";
import {useFormHandler} from "../../utils/useFormHandler";

interface AddStudentDrawerProps {
    onClose: () => void;
    open: boolean;
    student?: StudentRequestData | undefined; // Optional prop for editing
    onEdit?: () => void; // Optional callback for editing
}

const AddStudentDrawer: React.FC<AddStudentDrawerProps> = ({open, onClose, student}) => {
    const [form] = Form.useForm();
    const onFinish = useFormHandler(
        student ? async (values: StudentRequestData) => {
            return await updateStudent(student.id, values);
        } : createStudent,
        form,
        () => {
            message.success('Student saved successfully!');
            onClose();
        }
    );

    return (
        <Drawer
            title={student ? "Edit Student" : "Add Student"}
            placement="right"
            width={'35%'}
            
            onClose={onClose}
            className={styles.createStudentDrawer}
            open={open}
            footer={
                <div className={styles.formFooter}>
                    <Button type="primary" htmlType="submit" form="studentForm">
                        {student ? "Update" : "Submit"}
                    </Button>
                </div>
            }
        >
            <Form
                id="studentForm"
                form={form}
                layout="vertical"
                initialValues={student}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Full Name"
                    name="full_name"
                    rules={[{required: true, message: "Please input your full name!"}]}
                >
                    <Input placeholder="Enter Full Name"/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{required: true, message: "Please input your email!"}]}
                >
                    <Input placeholder="Enter Email"/>
                </Form.Item>
                <Form.Item
                    label="Phone Number"
                    name="phone_number"
                    rules={[{required: true, message: "Please input your phone number!"}]}
                >
                    <InputNumber placeholder="Enter Phone Number" style={{width: "100%"}}/>
                </Form.Item>

                {/* Add any other fields you need for the student creation */}
            </Form>
        </Drawer>
    );
};

export default AddStudentDrawer;
