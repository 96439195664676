import React, {useState} from 'react';
import {Button, Drawer, Form, Input, message, Select} from 'antd';
import {useFormHandler} from "../../utils/useFormHandler";
import {StudentRequestData, updateStudent} from "../../api/student/studentApi";
import styles from "./addStudentDrawer.module.scss";
import UploadField from "../uploadField";

const {Option} = Select;

interface PersonalDetailsDrawerProps {
    open: boolean;
    onClose: () => void;
    student?: any; // Define the student type according to your needs
}

const PersonalDetailsDrawer: React.FC<PersonalDetailsDrawerProps> = ({open, onClose, student}) => {
    const [form] = Form.useForm();
    const onFinish = useFormHandler(
        async (values: StudentRequestData) => {
            return await updateStudent(student.id, values);
        },
        form,
        () => {
            message.success('Student updated successfully!');
            onClose();
        }
    );
    return (
        <Drawer
            title="Personal Details"
            placement="right"
            width={'40%'}
            id={'PersonalDetail'}
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={student.student}
                style={{width: "70%"}}
            >
                <Form.Item
                    label="Date of Birth"
                    name="date_of_birth"
                    rules={[{required: true, message: "Please input your date of birth!"}]}
                >
                    <Input type="date"/>
                </Form.Item>
                <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[{required: true, message: "Please select your gender!"}]}
                >
                    <Select placeholder="Select Gender">
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{required: true, message: "Please input your nationality!"}]}
                >
                    <Input placeholder="Enter Nationality"/>
                </Form.Item>
                <Form.Item
                    label="Blood Group"
                    name="blood_group"
                    rules={[{required: true, message: "Please input your blood group!"}]}
                >
                    <Input placeholder="Enter Blood Group"/>
                </Form.Item>
                <UploadField form={form} name={'profile_photo'} title={'Profile Photo'}/>

                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        {student ? "Update" : "Submit"}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default PersonalDetailsDrawer;
