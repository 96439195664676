import { Modal, Tag } from 'antd';
import React from 'react';
import styles from './studentInfo.module.scss';
import cn from 'classnames';

interface StudentInfoProps {
  open: boolean;
  onClose: () => void;
  studentInfo?: {
    full_name: string;
    email: string;
    phone_number: string;
    date_joined: string;
    is_active: string;
  } | null;
}

const StudentInfo: React.FC<StudentInfoProps> = ({ open, onClose, studentInfo }) => {
  return (
    <Modal
      title="Student Details"
      centered
      open={open}
      onOk={onClose}
      onCancel={onClose}
      footer={false}
      className={styles.studentInfo}
    >
      {studentInfo ? (
        <div className="row">
          <div className={cn(styles.details, 'col-sm-6 col-12')}>
               <label>Name: </label>
               <span>{studentInfo.full_name}</span>
          </div>
          <div className={cn(styles.details, 'col-sm-6 col-12')}>
               <label>Email: </label>
               <span>{studentInfo.email}</span>
          </div>
          <div className={cn(styles.details, 'col-sm-6 col-12')}>
               <label>Phone Number: </label>
               <span>{studentInfo.phone_number}</span>
          </div>
          <div className={cn(styles.details, 'col-sm-6 col-12')}>
               <label>Date Joined: </label>
               <span>{new Date(studentInfo.date_joined).toLocaleDateString()}</span>
          </div>
          <div className={cn(styles.details, 'col-sm-6 col-12')}>
               <label>Status: </label>
               <Tag color={studentInfo.is_active ? 'green' : 'red'}>
                    {studentInfo.is_active ? 'Active' : 'Inactive'}
               </Tag>
          </div>
        </div>
      ) : (
        <p>No student information available.</p>
      )}
    </Modal>
  );
};

export default StudentInfo;
