import {Modal, Tag} from 'antd';
import React from 'react';
import styles from './userInfo.module.scss';
import cn from 'classnames';

interface UserInfoProps {
    open: boolean;
    onClose: () => void;
    userInfo?: {
        full_name: string;
        email: string;
        phone_number: string;
        role: string;
        is_active: string;
        date_joined: string;
    } | null;
}

const UserInfo: React.FC<UserInfoProps> = ({open, onClose, userInfo}) => {
    if (!userInfo) {
        return null; // No content to display if userInfo is null
    }

    return (
        <Modal
            title={"User Details"}
            centered
            open={open}
            onOk={onClose}
            onCancel={onClose}
            footer={false}
            className={styles.userInfo}
        >
            <div className="row">
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Name: </label>
                    <span>{userInfo.full_name}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Email: </label>
                    <span>{userInfo.email}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Phone Number: </label>
                    <span>{userInfo.phone_number}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Role: </label>
                    <span>{userInfo.role}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Status: </label>
                    <Tag color={userInfo.is_active ? "green" : "red"}>
                        {userInfo.is_active ? "Active" : "Inactive"}
                    </Tag>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Date Joined: </label>
                    <span>{new Date(userInfo.date_joined).toLocaleDateString()}</span>
                </div>
            </div>
        </Modal>
    );
};

export default UserInfo;
