import React, {useEffect, useState} from "react";
import {Drawer, Form, Input, InputNumber, message, Select} from "antd";

import styles from "../../components/add-test-series-drawer/addTestSeriesDrawer.module.scss"

import FooterButton from "../../components/FooterButton"; // Custom form handler hook
import { updateOrders,} from "../../api/testSeries/testSeries";


interface EditOrderDrawerProps {
    onClose: () => void;
    open: boolean;
    orderId:any;

}

interface ProductOrderFormValues {
    
    awb_no: string;
    tracking_no: string;
    tracking_url: string;
}



const EditOrderDrawer: React.FC<EditOrderDrawerProps> = ({onClose, open,orderId}) => {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values: ProductOrderFormValues) => {
        setLoading(true);
        try {
            
            await updateOrders(orderId.id,values);
            message.success("Order status updated successfully!");
            onClose(); 
        } catch (error) {
            message.error("Failed to update order status. Please try again.");
        } finally {
            setLoading(false);
        }
    };
  
 
    return (
        <Drawer
            title={  "Edit Order"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <Form
                
                layout={"vertical"}
                form={form}
                onFinish={onFinish}
                initialValues={orderId}
                className={styles.form}
            >
                <Form.Item
                    label="AWB No"
                    name="awb_no"
                    rules={[{required: true, message: "Please input awb_no "}]}
                >
                    <Input placeholder="Enter AWB No"/>
                </Form.Item>
                <Form.Item
                    label="Tracking No"
                    name="tracking_no"
                    rules={[{required: true, message: "Please Enter Tracking No"}]}
                >
                   <Input placeholder="Enter Tracking No"/>
                </Form.Item>


                <Form.Item
                    label="Tracking URL"
                    name="tracking_url"
                   
                    rules={[{ message: "Please enter Tracking URL!"},
                    { type: "url", message: "Please enter a valid URL!" },
                ]}
                >
                    <Input placeholder="Enter Enter Tracking URL"/>
                </Form.Item>
                

                <FooterButton loading={loading} onClose={onClose} submitButtonText={'Submit'}/>
            </Form>
        </Drawer>
    );
}

export default EditOrderDrawer;
