import { Button, Drawer, Form, Input, InputNumber, Select } from "antd";
import React from "react";
import styles from "./addReportDrawer.module.scss";


interface courseProps {
  onClose: () => void;
  open: boolean;
}

const AddReportDrawer: React.FC<courseProps> = ({ onClose, open }) => {
  return (
    <Drawer
      title="Add Student"
      onClose={onClose}
      open={open}
      width={"40%"}
      closeIcon={true}
      className={styles.createStudentDrawer}
    >
      <div className={styles.drawerMain}>
        <Form layout={"vertical"} style={{ maxWidth: 600 }}>
          <Form.Item
            label="Select Student"
            name="student"
            rules={[{ required: true, message: "Please select student!" }]}
          >
             <Select
              placeholder="Select Student"
              options={[
                // ...DUMMY_STUDENT_DATA.map(student=>{ return {value: student.name, label: student.name }}),
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Select Course"
            name="course"
            rules={[{ required: true, message: "Please input course" }]}
          >
            <Select
              placeholder="Select Course"
              options={[
                { value: "jeemains", label: "JEE Mains" },
                { value: "jeeadvance", label: "JEE Advance" },
                { value: "maths", label: "Mathematics" },
                { value: "physics", label: "Physics" },
                { value: "chemistry", label: "Chemistry" },
              ]}
              />
          </Form.Item>
          <Form.Item
            label="Test Score"
            name="score"
          >
            <InputNumber placeholder="Enter Test Score" />
          </Form.Item>
          <Form.Item
            label="Attendance"
            name="attendance"
          >
            <InputNumber placeholder="Enter Attendance" />
          </Form.Item>
          <Form.Item
            label="Remark"
            name="remark"
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <div className={styles.formFooter}>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={onClose} type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};
export default AddReportDrawer;
