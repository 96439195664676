import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient"; // Assuming you have an apiClient setup

// Define the request data type for student creation or update
export interface StudentRequestData {
    id: number
    full_name: string;
    phone_number: string;
    email: string;
    date_of_birth?: string;
    gender?: string;
    father_name?: string;
    occupation?: string;
    nationality?: string;
    blood_group?: string;
    permanent_address_pincode?: string;
    correspondence_address?: string;
    permanent_address?: string;
    college_name?: string;
    correspondence_address_pincode?: string;
    marks_college?: string;
    mother_name?: string;
    additional_notes?: string;
    parent_mobile_number?: string;
    parent_email?: string;
    school_name?: string;
    marks_x?: number;
    marks_xii?: number;
    student_image?: File;         // Add image field for student
    parent_image?: File;          // Add image field for parent
    x_result?: File;              // Add file field for X result
    xii_result?: File;            // Add file field for XII result
}

// Define the response type
interface StudentResponse {
    message: string;
    student_id: number;
}

// API call to create a student
export const createStudent = async (values: StudentRequestData): Promise<StudentResponse> => {


    const response = await apiClient.post<StudentResponse>('/api/students/', values);
    return response.data;
};

// API call to update a student
export const updateStudent = async (studentId: number, values: StudentRequestData): Promise<StudentResponse> => {

    const response = await apiClient.put<StudentResponse>(`/api/students/${studentId}/`, values);
    return response.data;
};
export const deleteStudent = async (studentId: number): Promise<StudentResponse> => {

    const response = await apiClient.delete<StudentResponse>(`/api/students/${studentId}/`);
    return response.data;
};
export const singleStudentData = async (studentId: number): Promise<any> => {

    const response = await apiClient.get<StudentResponse>(`/api/students/${studentId}/`);
    return response.data;
};
export const toggleStudentActivation = async (studentId: number): Promise<any> => {
    const response = await apiClient.post<StudentResponse>(`/api/students/${studentId}/toggle_active/`);
    return response.data;
};

export const useStudentList = () => {
    return useFetchList<StudentRequestData>("/api/students/");
};
