import { Modal, Tag } from 'antd';
import React from 'react';
import styles from './testSeriesView.module.scss'; // Create this SCSS file for styles
import cn from 'classnames';
import {BASE_URL} from "../../utils/apiClient";

interface TestSeriesViewModalProps {
    open: boolean;
    onClose: () => void;
    testSeries?: {
        name: string;
        category: { title: string }; // Assuming category is an ID; you might want to fetch the actual name
        is_digital:boolean,
        price: string;
        description: string;
        effective_price: string;
        discount: string;
        url:string;
        highlights:[];
    } | null;
}

const TestSeriesView: React.FC<TestSeriesViewModalProps> = ({ open, onClose, testSeries }) => {
    if (!testSeries) {
        return null; // No content to display if testSeries is null
    }

    return (
        <Modal
            title={"Test Series Details"}
            centered
            open={open}
            onOk={onClose}
            onCancel={onClose}
            footer={false}
            className={styles.testSeriesViewModal}
        >
            <div className="row">
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Name: </label>
                    <span>{testSeries.name}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Category: </label>
                    <span>{testSeries.category.title}</span> {/* Ideally, fetch and display the category name */}
                </div>

                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Is Digital Product: </label>
                    <span>{testSeries.is_digital? "Yes": "No"}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Price: </label>
                    <span>₹{testSeries.price}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Discount Percentage: </label>
                    <span>₹{testSeries.discount}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Effective Price: </label>
                    <span>₹{testSeries.effective_price}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Description: </label>
                    <span>{testSeries.description}</span>
                </div>

                <div className={cn(styles.details, "col-sm-12")}>
                    <label>Highlights: </label>
                    {testSeries.highlights.length > 0 ? (
                        <ul className={styles.heighlightsList}>
                        {testSeries.highlights.map((highlight) => (
                            <li key={highlight}>{highlight}</li>
                        ))}
                        </ul>
                    ) : (
                        <span>No highlights available.</span>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default TestSeriesView;
