import React from 'react';
import {Button, Input, Select, Table, Tag} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import cn from 'classnames';
import PageHeader from "../../common/page-header/PageHeader";
import styles from "../Students/students-list/studentsList.module.scss";
import {downloadInvoice, useTransactionList} from "../../api/payment/payment";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../hooks/useDebouncedValue";
import {formatIndianDate} from "../../utils/utils";
import {FaFileInvoiceDollar} from "react-icons/fa6";

const {Option} = Select;

// Define the Transaction interface to match the shape of your data
interface Transaction {
    key: string;
    transactionId: string;
    customerName: string;
    amount: number;
    status: string;
    date: string;
    paymentMethod: string;
}

const TransactionsTable: React.FC = () => {
    const {data, loading, pagination, fetchWithParams} = useTransactionList()
    const {params, updateParams, updateSearch, updateFilters, updateOrdering} = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 2000);

    const handleTransactionInvoice = async (transactionId: number) => {
        try {
            const data: any = await downloadInvoice(transactionId)
            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));

            // Create a link element and trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `transaction_${transactionId}.pdf`); // Set file name
            document.body.appendChild(link);
            link.click();

            // Clean up by removing the link
            link.parentNode?.removeChild(link);
        } catch (e) {

        }
    }

    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'student_name',
            key: 'student_name',
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => `₹${amount}`
        },
        {
            title: 'Content Type',
            dataIndex: 'content_type',
            key: 'content_type',
            render: (text: string) => {
                let color = '';
                let label = '';

                switch (text) {
                    case 'test_series':
                        color = 'blue';
                        label = 'Test Series';
                        break;
                    case 'course':
                        color = 'green';
                        label = 'Course';
                        break;
                    case 'batch':
                        color = 'volcano';
                        label = 'Batch';
                        break;
                    default:
                        color = 'default';
                        label = text;
                }
                return <Tag color={color}>{label}</Tag>;
            }
        },
        {
            title: 'Name',
            dataIndex: 'content_name',
            key: 'content_name',
        },
        {
            title: 'RazorPay Payment ID',
            dataIndex: 'payment_id',
            key: 'payment_id',
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text: any) => {
                const color = text === 'completed' ? 'green' : text === 'failed' ? 'red' : 'geekblue';
                return <Tag color={color}>{text.toUpperCase()}</Tag>;
            }
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created',
            render: (text: string) => formatIndianDate(text),
        },
        {
            title: "Action",
            render: (text: string, record: any) => {
                return (
                    record.payment_status === 'completed' ?
                        <div className={styles.tableActionBtns}>
                            <Button title="Download Invoice" onClick={() => handleTransactionInvoice(record.id)}>
                                <FaFileInvoiceDollar/>
                            </Button>

                        </div>
                        :
                        null
                );
            },
        }

    ];

    const handleTransactionTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        let paramsTransaction = {}
        // let symbol = '';
        // if (sorter.order === 'descend')
        //     symbol = '-';
        paramsTransaction = {
            ...params,
            page: pagination.current,
        }
        // if (sorter.columnKey) {
        //     params.ordering = `${symbol}${sorter.columnKey}`
        // }
        updateParams(paramsTransaction);
    };


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="All Transactions"/>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name, email"
                                prefix={<SearchOutlined/>}
                                onChange={handleDebounceInputChange}
                            />
                            <Select
                                className={styles.sortSelect}
                                placeholder="Filter By Status"
                                allowClear
                            >
                                <Option value="Success">Success</Option>
                                <Option value="Failed">Failed</Option>
                                <Option value="Pending">Pending</Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table loading={loading} pagination={pagination} dataSource={data} columns={columns}
                           onChange={handleTransactionTableChange}/>
                </div>
            </section>
        </div>
    );
}


export default TransactionsTable;
