import apiClient from "../../utils/apiClient";

// Define the request data for creating an enrollment
export interface EnrollmentRequestData {
    batch: number;
    students: number[]; // Array of student IDs
    is_approved?: boolean;
}

// Define the response data for creating an enrollment
export interface EnrollmentResponse {
    enrollments: number[]; // Array of enrollment IDs
}

// Define the response data for listing enrollments
export interface EnrollmentListResponse {
    id: number;
    batch: number;
    student: number;
    is_approved: boolean;
    approved_by: string | null;
    created_at: string;
}

// Define the request data for adding a student
export interface AddStudentRequestData {
    batch: number;
    student: number;
}

// Define the response data for adding a student
export interface AddStudentResponse {
    user_id: number;
    message: string;
}

// Define the response data for approval
export interface ApproveResponse {
    message: string;
}

// Define the function for creating enrollments
export const createEnrollment = async (
    values: EnrollmentRequestData
): Promise<EnrollmentResponse> => {
    const response = await apiClient.post<EnrollmentResponse>('/api/batch/enrollments/', values);
    return response.data;
};

// Define the function for listing enrollments
export const listEnrollments = async (): Promise<EnrollmentListResponse[]> => {
    const response = await apiClient.get<EnrollmentListResponse[]>('/api/batch/enrollments/');
    return response.data;
};

// Define the function for deleting an enrollment
export const deleteEnrollment = async (enrollmentId: number): Promise<void> => {
    await apiClient.delete(`/api/batch/enrollments/${enrollmentId}/`);
};

// Define the function for adding a student to a batch
export const addStudentToBatch = async (
    values: AddStudentRequestData
): Promise<AddStudentResponse> => {
    const response = await apiClient.post<AddStudentResponse>('/api/batch/enrollments/add-student/', values);
    return response.data;
};

// Define the function for approving an enrollment
export const approveEnrollment = async (enrollmentId: number): Promise<ApproveResponse> => {
    const response = await apiClient.post<ApproveResponse>(`/api/batch/enrollments/${enrollmentId}/approve/`);
    return response.data;
};
