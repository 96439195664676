import React, {useEffect, useState} from 'react'
import PageHeader from '../../common/page-header/PageHeader'
import {Tabs, TabsProps,Grid} from 'antd'
import styles from "./profile.module.scss"
import PersonalDetailsTab from './PersonalDetailsTab';
import {useAuth} from '../../context/AuthContext';

import {getMyProfile} from "../../api/profile/profile";
import AddressDetailsTab from "../../studentComponent/Profile/AddressDetailsTab";
import EducationDetails from "../../studentComponent/Profile/EducationDetails";
import ParentsDetailsTab from "../../studentComponent/Profile/ParentsDetailsTab";
import BasicPersonalDetails from './BasicPersonalDetail';
import BasicPersonalDetailsTab from '../../studentComponent/Profile/BasicPersonalDeatil';
import AcademicInformation from './AcademicInformation';
import ProfessionalInformation from './ProfessionalInformation';
import { getProfile } from '../../api/profile/adminProfile/admin';



const { useBreakpoint } = Grid;
export default function Profile() {
    const [profile, setProfile] = useState<any>(null);
    const screens = useBreakpoint();

    const {user} = useAuth();


    const studentTabs = [

        {
            key: '5',
            label: 'Basic Detail',
            children: <BasicPersonalDetailsTab profile={profile} onClose={() => {
            }}/>,
        },
        {
            key: '2',
            label: 'Address',
            children: <AddressDetailsTab profile={profile} onClose={() => {
            }}/>,
        },
        {
            key: '3',
            label: 'Education',
            children: <EducationDetails profile={profile} onClose={() => {
            }}/>,
        },
        {
            key: '4',
            label: 'Parent Info',
            children: <ParentsDetailsTab profile={profile} onClose={() => {
            }}/>,
        },


    ]

    const adminTabs: TabsProps['items'] = [
        {
            key: '6',
            label: 'Basic Personal',
            children: <BasicPersonalDetails profile={profile} onClose={()=>{}} />,
        },
        {
            key: '7',
            label: 'Professional Information',
            children: <ProfessionalInformation profile={profile} onClose={()=>{}} />,
        },
        {
            key: '8',
            label: 'Academic Information',
            children: <AcademicInformation profile={profile}  onClose={()=>{}} />,
        },
    ];
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Personal Details',
            children: <PersonalDetailsTab profile={profile}/>,
        },
        ...(user?.userRole === "STUDENT" ? studentTabs : []),
        ...(user?.userRole !== "STUDENT" ? adminTabs : []),
        
    ];

  
    useEffect(() => {
        const getProfileData = async () => {
        if (user?.userRole == "STUDENT") {
            const data: any = await getMyProfile()
            setProfile(data)
        }
        else{
            const data: any = await getProfile()
            setProfile(data)
        }
    }
      getProfileData();
    }, [])


    return (
        <>
            <PageHeader heading={"Profile"}/>
            <div className={styles.profileTabs}>
                <Tabs
                    items={items}
                    tabPosition={screens.md ? "left" : "top"}
                    // style={{ width: '50%' }}
                    defaultActiveKey="1"
                />
            </div>
        </>
    )
}

// const ChangePasswordTab: React.FC = () => (
//     <Form style={{width:"60%"}} layout="vertical">
//         <Form.Item label="Current Password" name="current_pass">
//             <Input placeholder="Enter Current Password" />
//         </Form.Item>
//         <Form.Item label="New Password" name="new_pass">
//             <Input placeholder="Enter New Password" />
//         </Form.Item>
//         <Form.Item label="Confirm Password" name="confirm_pass">
//             <Input placeholder="Enter Confirm Password" />
//         </Form.Item>
//         <Form.Item>
//             <Button type="primary">Save</Button>
//         </Form.Item>
//     </Form>
// );
