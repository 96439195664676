import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";
import {CourseProps} from "../../types/course";
import { FileResponse, RecordFileResponse } from "../../types/folderFiles";

// Define the request data type for course creation or update
export interface CourseRequestData {
    id?: number;
    name: string;
    description: string;
    thumbnail?: string; // Add image field for the course
    is_published?: boolean;
    is_featured?: boolean;
    categories_subcategories?:any;
    categories?: { category: string; sub_category: string }[];
}
// Define the request data type for course creation or update
export interface UpdateCourseRequestData {
    is_published?: boolean;
    is_featured?:boolean;
}

// API call to create a course
// Update types to accept FormData
export const createCourse = async (values: CourseRequestData) => {
    const response = await apiClient.post("/api/course/courses/", values);
    return response.data;
};

export const updateCourse = async (courseId: number, values: UpdateCourseRequestData) => {
    const response = await apiClient.patch(`/api/course/courses/${courseId}/`, values);
    return response.data;
};

export const detailCourse = async (courseId: string): Promise<UpdateCourseRequestData> => {
    const response = await apiClient.get<UpdateCourseRequestData>(`/api/course/courses/${courseId}/`);
    return response.data;
};
export const getSingleCourseDetails = async (courseId: number) => {
    const response = await apiClient.get(`/api/course/courses/${courseId}/`);
    return response.data;
};

export const useCourseList = () => {
    return useFetchList<CourseProps>("/api/course/courses/");
};

export const deleteCourse = async (batchId: number) => {
    const response = await apiClient.delete(`/api/course/courses/${batchId}/`);
    return response.data;
};

export const createLiveClassForCourse = async (values: any) => {
    const response = await apiClient.post(`/api/course/create-live-class/`, values);
    return response.data;
}

export const deleteLiveClassForCourse = async (classId: number) => {
    const response = await apiClient.delete(`/api/course/live-classes/${classId}/`);
    return response.data;
}

export const getCourseRecordedLiveClasses = async (courseId: number) => {
    const response = await apiClient.get<RecordFileResponse>(`/api/course/courses/${courseId}/recorded-classes/`);
    return response.data;
};
