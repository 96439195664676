import React, { useState } from "react";
import { Button, message, Table, Tag } from "antd";
import cn from "classnames";
import styles from "./banner.module.scss";
import { FaPlus } from "react-icons/fa6";
import BannerDrawer from "../../components/BannerDrawer/BannerDrawer";
import { deleteBanner, useBannerList } from "../../api/banner/banner";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import { BannerProps } from "../../types/banner";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import { useFormHandler } from "../../utils/useFormHandler";
import { BASE_URL } from "../../utils/apiClient";


const Banner = () => {
    const { data, loading, error, fetchWithParams } = useBannerList();
    const {
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const [addBannerDrawer, setBannerDrawer] = useState<boolean>(false);
    const [banner, setBanner] = useState<BannerProps | null>(null);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);
    const [selectBanner, setSelectBanner] = useState<any>(null);
    const handleOpenBannerDrawer = (banner: any) => {
        setBanner(banner);
        setBannerDrawer(true);
    }

    const handleOpenCloseBannerDrawer = () => {
        setBanner(null);
        setBannerDrawer(false);
        resetParams()

    }

    const handleOpenDeleteModal = (record: any) => {
        setSelectBanner(record);
        setOpenDeleteWarning(true)
    }

    const handleCloseDeleteModal = () => {
        setSelectBanner(null);
        setOpenDeleteWarning(false)
        resetParams()
    }

    const onDelete = useFormHandler(
        async () => {
            return await deleteBanner(selectBanner.id);
        },

        null,
        () => {
            message.success('Banner delete successfully!');
            handleCloseDeleteModal()
        },
        () => {
            message.success("Something went wrong")
        }
    )

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (image: string) =>
                image ? (
                    <a
                        href={`${BASE_URL}${image}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Image
                    </a>
                ) : null,
        },
        {
            title: "Type",
            dataIndex: "link",
            key: "link",

        },
        {
            title: "Active",
            dataIndex: "is_active",
            key: "is_active",
            render: (is_active: boolean) => (
                <Tag color={is_active ? "green" : "red"}>
                    {is_active ? "Active" : "Inactive"}
                </Tag>
            ),
        },

        {
            title: "Expiry Date",
            dataIndex: "expiry_date",
            key: "expiry_date",
            // render:(date:any) =>ayjs
        },

        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },

        {
            title: "Controls",
            render: (_: any, record: any) => (
                <div className={styles.tableActionBtns}>


                    <Button
                        onClick={() => handleOpenBannerDrawer(record)}
                    >
                        <CiEdit />
                    </Button>


                    <Button
                        onClick={() => handleOpenDeleteModal(record)}>

                        <MdDeleteOutline />
                    </Button>

                </div>
            ),
        },

    ];

    return (
        <div className={cn("container-fluid")}>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-12 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button
                                type="primary" onClick={() => handleOpenBannerDrawer(banner)}
                            >
                                <FaPlus /> Create Banner
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table columns={columns} dataSource={data} rowKey="id" loading={loading} />
                </div>
            </section>

            {addBannerDrawer && (
                <BannerDrawer
                    open={addBannerDrawer}
                    onClose={handleOpenCloseBannerDrawer}
                    banner={banner}

                />

            )}

            {openDeleteWarning && (
                <DeleteWarningModal
                    open={openDeleteWarning}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => onDelete({})}
                    message={'Are you sure you want to delete this Banner?'}
                />
            )}


        </div>
    );
};

export default Banner;
