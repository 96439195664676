import React, {useState} from 'react';
import {Button, Dropdown, Input, Menu, Select, Table, TableColumnsType} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import styles from "../Students/students-list/studentsList.module.scss";
import cn from "classnames";
import {FiSearch} from "react-icons/fi";
import PageHeader from "../../common/page-header/PageHeader";
import {FaPlus} from "react-icons/fa6";
import {Action, ModuleName, Role, UserRole} from "../../utils/Role";

interface DataType {
    key: React.Key;
    name: string;
    date: string;
}

const columns: TableColumnsType<DataType> = [

    {
        title: 'Test / Folder',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => <a>{text}</a>,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Options',
        key: 'options',
        render: (_, record) => (
            <Dropdown
                overlay={
                    <Menu>
                        <Role moduleName={ModuleName.TEST_PORTAL} action={Action.EDIT}>
                        <Menu.Item key="modify">
                            Modify
                        </Menu.Item>
                        </Role>
                        <Role moduleName={ModuleName.TEST_PORTAL} action={Action.DELETE}>
                        <Menu.Item key="delete">
                            Delete
                        </Menu.Item>
                        </Role>
                    </Menu>
                }
                trigger={['click']}
            >
                <Button icon={<MoreOutlined/>}/>
            </Dropdown>
        ),
    },
];

const data: DataType[] = [
    {key: '1', name: 'Wisdom Major Test-02', date: '05-Sep-2024'},
    {key: '2', name: 'Sapience Major Test-02', date: '05-Sep-2024'},
    {key: '3', name: 'Wisdom Minor -Test-08 [Straight Line]', date: '14-Aug-2024'},
    {key: '4', name: 'Sapience Minor -Test-08 [AOD & MOD]', date: '14-Aug-2024'},
    {key: '5', name: 'Sapience Minor Test-07 [Continuity Differentiability]', date: '27-Jul-2024'},
    // Add more test data as needed
];

const TestPortal: React.FC = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
        getCheckboxProps: (record: DataType) => ({
            disabled: false, // Add condition to disable checkboxes if needed
        }),
    };

    const handleMenuClick = (key: React.Key, action: 'modify' | 'delete') => {
        console.log(`Action "${action}" clicked for row with key ${key}`);
        // Handle modify or delete action
    };

    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Quizzes"/>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input placeholder="Search by name" prefix={<FiSearch/>}/>

                            <Select className={styles.sortSelect} placeholder="Sort By">
                                <Select.Option value="courseName">Student Name</Select.Option>
                                <Select.Option value="newest">Active</Select.Option>
                                <Select.Option value="lowToHigh">InActive</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            {/* <Button>Featured</Button> */}
                            <Button type="primary">
                                <FaPlus/> Add Test
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="key"
                    />
                </div>
            </section>
        </div>
    );
};

export default TestPortal;
