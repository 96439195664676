
import React from 'react';
import {Button, Input, Select, Table, Tag} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import cn from 'classnames';
import {downloadInvoice, useStudentTransactionList} from "../api/payment/payment";
import useFilterSortPaginate from "../hooks/useFilterSortPaginate";
import useDebounceValue from "../hooks/useDebouncedValue";
import {formatIndianDate} from "../utils/utils";
import PageHeader from "../common/page-header/PageHeader";
import styles from "./studentTransaction.module.scss";
import { LuDownload } from "react-icons/lu";

const {Option} = Select;


const StudentTransactionsTable: React.FC = () => {
    const {data, loading, pagination, fetchWithParams} = useStudentTransactionList()
    const {params, updateParams, updateSearch, updateFilters, updateOrdering} = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceInputChange} = useDebounceValue(updateSearch, 2000);


    const handleInvoiceTransaction = async(transactionId:number) => {

        try{
            const invoice: any=await downloadInvoice(transactionId)

            const url = window.URL.createObjectURL(new Blob([invoice], {type: 'application/pdf'}));

            const link = document.createElement('a');
            link.href = url;
            link.download = `Invoice_${transactionId}.pdf`; // Suggested file name
            document.body.appendChild(link);
            link.click();

              // Clean up by removing the link
            link.parentNode?.removeChild(link);
        } catch (e) {

        }

    }


    const columns = [

        {
            title: 'Name',
            dataIndex: 'content_name',
            key: 'content_name',
        },
        {
            title: 'Content Type',
            dataIndex: 'content_type',
            key: 'content_type',
            render: (text: string) => {
                let color = '';
                let label = '';

                switch (text) {
                    case 'test_series':
                        color = 'blue';
                        label = 'Test Series';
                        break;
                    case 'course':
                        color = 'green';
                        label = 'Course';
                        break;
                    case 'batch':
                        color = 'volcano';
                        label = 'Batch';
                        break;
                    default:
                        color = 'default';
                        label = text;
                }
                return <Tag color={color}>{label}</Tag>;
            }
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => `₹${amount}`
        },

        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text: any) => {
                const color = text === 'completed' ? 'green' : text === 'failed' ? 'red' : 'geekblue';
                return <Tag color={color}>{text.toUpperCase()}</Tag>;
            }
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created',
            render: (text: string) => formatIndianDate(text),
        },
        {
            title: 'Download Invoice',
            className:styles.donwloadColumn,
            render: (record: any) => 
            <Button 
            className={styles.downloadInvoiceBtn}
            onClick={() => handleInvoiceTransaction(record.id)}>
            <LuDownload />
            </Button>,
        },
    ];

    const handleTransactionTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        let paramsTransaction = {}
        // let symbol = '';
        // if (sorter.order === 'descend')
        //     symbol = '-';
        paramsTransaction = {
            ...params,
            page: pagination.current,
        }
        // if (sorter.columnKey) {
        //     params.ordering = `${symbol}${sorter.columnKey}`
        // }
        updateParams(paramsTransaction);
    };


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="All Transactions"/>
            {/* <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            <Input
                                placeholder="Search by name, email"
                                prefix={<SearchOutlined/>}
                                onChange={handleDebounceInputChange}
                            />
                        </div>
                    </div>
                </div>
            </section> */}
            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table loading={loading} pagination={pagination} dataSource={data} columns={columns}
                           onChange={handleTransactionTableChange}/>
                </div>
            </section>
        </div>
    );
}


export default StudentTransactionsTable;
