import {DataNode} from "antd/es/tree";
import styles from "./directoytreestructure.module.scss";
import {FileOutlined, FolderOutlined, LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {Button, Modal, Tree, Typography} from "antd";
import React, {useMemo, useRef, useState} from "react";
import {BASE_URL} from "../utils/apiClient";

const {Text} = Typography;

export interface FileItem {
    id: number;
    title: string;
    url: string; // URL to the file
    is_locked: boolean;
}

export interface FolderItem {
    id: number;
    title: string;
    children?: FolderItem[];
    url: string;
    type: 'folder' | 'file';
    is_locked?: boolean;
}

export interface Course {
    content: FolderItem[];
    duration: string; // e.g., "1 Year"
}

export interface SelectedFile {
    type: 'image' | 'video';
    src: string;
}

export interface DirectoryTreeStructureProps {
    directory: FolderItem[];
}

export interface CourseContentProps {
    course: Course;
}

const DirectoryTreeStructure: React.FC<DirectoryTreeStructureProps> = ({directory}) => {
    const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);


    // Function to handle file clicks
    const handleFileClick = (file: FileItem|FolderItem) => {

        window.open(BASE_URL + file.url, '_blank');

    };

    // Function to close the modal
    const handleModalClose = () => {
        if (selectedFile?.type === 'video' && videoRef.current) {
            videoRef.current.pause();
        }
        setSelectedFile(null);
        setIsModalVisible(false);
    };
    const convertToTreeData = (folders: FolderItem[]): DataNode[] => {
        return folders.map((item) => ({
            key: `${item.type}-${item.id}`,
            title: (
                <div className={styles.treeTitle}>
                    <div className={styles.iconWrapper}>
                        {item.type === 'folder' ? (
                            <FolderOutlined className={styles.folderIcon}/>
                        ) : item.type === 'file' && item.is_locked ? (
                            <>
                                <LockOutlined style={{color: 'red'}}/>
                                <FileOutlined className={styles.fileIcon}/>
                            </>
                        ) : (
                            <>
                                <UnlockOutlined style={{color: 'green'}}/>
                                <FileOutlined className={styles.fileIcon}/>
                            </>
                        )}
                    </div>
                    {item.type === 'file' && item.is_locked ? (
                        <Text className={styles.lockedText}>{item.title}</Text>
                    ) : item.type === 'file' ? (
                        <Button
                            type="link"
                            onClick={() => handleFileClick(item)}
                            className={styles.fileButton}
                        >
                            <Text className={styles.unlockedText}>{item.title}</Text>
                        </Button>
                    ) : (
                        <Text strong>{item.title}</Text>
                    )}
                </div>
            ),
            disableCheckbox: item.type === 'file', // Disable checkbox for files
            selectable: false, // Disable default selection behavior for all nodes
            children: item.type === 'folder' && item.children
                ? convertToTreeData(item.children) // Recursive call for nested children
                : [],
        }));
    };
    // Generate Tree Data
    const treeData: DataNode[] = useMemo(() => convertToTreeData(directory), [directory]);
    return (
        <>
            <Tree
                treeData={treeData}
                defaultExpandAll
                showIcon
                selectable={false} // Disable selection of folders
                style={{userSelect: 'none'}} // Prevent text selection
            />
            <Modal open={isModalVisible} className={styles.contentViewModal} footer={null} onCancel={handleModalClose}
                   centered>
                {selectedFile?.type === 'image' ? (
                    <img src={`${BASE_URL}${selectedFile.src}`} alt="File"/>
                ) : selectedFile?.type === 'video' ? (
                    <video
                        ref={videoRef}
                        src={`${BASE_URL}${selectedFile.src}`}
                        controls
                        onContextMenu={(e) => e.preventDefault()} // Disable right-click
                        style={{width: '100%'}}/>
                ) : null}
            </Modal>
        </>)
}

export default DirectoryTreeStructure;
