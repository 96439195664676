import React, {useEffect, useState} from 'react';
import {Button, Checkbox, message, Modal, Table, Tabs, Tooltip} from 'antd';
import {ModulePermissions} from "../../types/permissions"; // Assuming this is your type for permissions
import {getRolesPermissions, postRolesPermissions} from "../../api/user/userApi";
import {UserRole} from "../../utils/Role";
import {useFormHandler} from "../../utils/useFormHandler"; // Adjust the import based on your file structure

const {TabPane} = Tabs;

interface AccessControlModalProps {
    open: boolean;
    onClose: () => void;
}

const AccessControlModal: React.FC<AccessControlModalProps> = ({open, onClose}) => {
    const [managerData, setManagerData] = useState<ModulePermissions[]>([]);
    const [instructorData, setInstructorData] = useState<ModulePermissions[]>([]);
    const [activeRole, setActiveRole] = useState<UserRole.MANAGER | UserRole.INSTRUCTOR>(UserRole.MANAGER);

    // Fetch permissions for Manager and Instructor
    useEffect(() => {
        const fetchPermissions = async () => {
            if (open) {
                try {
                    const data = await getRolesPermissions();
                    const {MANAGER, INSTRUCTOR} = data;

                    // Ensure MANAGER and INSTRUCTOR are arrays
                    setManagerData(Array.isArray(MANAGER) ? MANAGER : []);
                    setInstructorData(Array.isArray(INSTRUCTOR) ? INSTRUCTOR : []);
                } catch (error) {
                    console.error('Error fetching permissions:', error);
                }
            }
        };

        fetchPermissions();
    }, [open]);

    const handlePermissionChange = (key: string, action: keyof ModulePermissions, role: UserRole.MANAGER | UserRole.INSTRUCTOR) => {
        const data = role === UserRole.MANAGER ? managerData : instructorData;
        const setData = role === UserRole.MANAGER ? setManagerData : setInstructorData;

        const updatedData = data.map(item => {
            if (item.module === key) {
                const newItem = {...item, [action]: !item[action]};

                // If `create` is checked, also check `view`, `edit`, and `delete`
                if (action === 'create' && newItem.create) {
                    newItem.view = newItem.edit = newItem.delete = true;
                }

                // If `view` is unchecked, uncheck all other permissions
                if (action === 'view' && !newItem.view) {
                    newItem.create = newItem.edit = newItem.delete = false;
                }

                return newItem;
            }
            return item;
        });

        setData(updatedData);
    };

    const columns = [
        {
            title: 'Module',
            dataIndex: 'module',
            key: 'module',
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            render: (_: any, record: ModulePermissions) => (
                <Tooltip title={`Allow ${record.module} viewing`}>
                    <Checkbox
                        checked={record.view}
                        onChange={() => handlePermissionChange(record.module, 'view', activeRole)}
                    />
                </Tooltip>
            ),
        },
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
            render: (_: any, record: ModulePermissions) => (
                <Tooltip title={`Allow ${record.module} creation`}>
                    <Checkbox
                        checked={record.create}
                        onChange={() => handlePermissionChange(record.module, 'create', activeRole)}
                    />
                </Tooltip>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (_: any, record: ModulePermissions) => (
                <Tooltip title={`Allow ${record.module} editing`}>
                    <Checkbox
                        checked={record.edit}
                        onChange={() => handlePermissionChange(record.module, 'edit', activeRole)}
                    />
                </Tooltip>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (_: any, record: ModulePermissions) => (
                <Tooltip title={`Allow ${record.module} deletion`}>
                    <Checkbox
                        checked={record.delete}
                        onChange={() => handlePermissionChange(record.module, 'delete', activeRole)}
                    />
                </Tooltip>
            ),
        },
    ];


    const onFinish = useFormHandler(
        async () => {
            await postRolesPermissions({MANAGER: managerData, INSTRUCTOR: instructorData});
        }, // Choose the correct API function
        null,
        () => {
            message.success("Access Control updated successfully")
            onClose();
            window.location.reload();
        },
        () => {
            message.error("Something went wrong")
        }
    );


    return (
        <Modal
            title="Access Control Management"
            open={open}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={onFinish}>
                    Save Changes
                </Button>,
            ]}
            width={"60%"}
        >
            <Tabs
                defaultActiveKey="MANAGER"
                onChange={(key) => setActiveRole(key as UserRole.MANAGER | UserRole.INSTRUCTOR)}
            >
                <TabPane tab="Manager" key="MANAGER">
                    <Table
                        dataSource={managerData}
                        columns={columns}
                        pagination={false}
                        rowKey="module"
                    />
                </TabPane>
                <TabPane tab="Instructor" key="INSTRUCTOR">
                    <Table
                        dataSource={instructorData}
                        columns={columns}
                        pagination={false}
                        rowKey="module"
                    />
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default AccessControlModal;
