import { Modal } from "antd";
import React from "react";
import styles from "./studentProductDetailsModal.module.scss"
import { BASE_URL } from "../../utils/apiClient";

interface props {
    open: boolean;
    onClose: () => void;
    data?: any;
}

const StudentProductDetailModal: React.FC<props> = ({onClose, open, data}) => {
      
        const downloadFile = async () => {
           const fileUrl=data.url
            try {
                // Fetch the file
                const response = await fetch(fileUrl, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/octet-stream', // Ensure it's treated as a file download
                  },
                });
          
                // Check if the response is ok
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
          
                // Get the file as a Blob (binary data)
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
          
                // Create a temporary link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'algebra_I8oQWqH.jpg'); // Set custom file name
          
                // Append link to the DOM, click it, and remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
          
                // Revoke the object URL to free memory
                window.URL.revokeObjectURL(url);
              } catch (error) {
                console.error('Download failed:', error);
              }
        };
    return (
        <Modal
            open={open}
            centered
            onCancel={onClose}
            footer={null}
            className={styles.testSeriesModal} 
        >
            <div className="row">
                <div className={"col-4"}>
                    <div className={styles.imgDiv}>
                        <img src={`${BASE_URL}${data.thumbnail}`} />
                    </div>
                </div>
                <div className={"col-8"}>
                    <div className={styles.seriesContent}>
                        <div className={styles.heading}>
                            {data.name}
                        </div>
                        <div className={styles.smallContent}>
                            {/* <p title="starts from"><MdCalendarMonth /> <label>Available From:</label> {moment(data?.available_date).format("DD MMM YYYY")}</p> */}
                            <p title="subject"><label>Type:</label> {data.category.title}</p>
                            {data.is_digital && 
                            <div>
                                <a className={styles.downloadBtn} onClick={downloadFile}>Download</a>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className={"col-12"}>
                <div className={styles.seriesContent}>
                        {/* <div className={styles.heading}>
                            {data.name}
                        </div>
                        <div className={styles.smallContent}>
                            <p title="subject"><label>Type:</label> {data.category.title}</p>
                        </div> */}
                        <div className={styles.descriptionDiv}>
                            <h5 className={styles.payHeading}>Description</h5>
                            <p>{data.description}</p>
                        </div>
                        {data?.highlights?.length>0 && <div>
                            <h5 className={styles.payHeading}>Highlights</h5>
                            <ul className={styles.heighlightsList}>
                                {data?.highlights.map((item:any)=><li>{item}</li>)}
                            </ul>
                        </div>}
                        
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default StudentProductDetailModal
