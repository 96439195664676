import apiClient from "../../utils/apiClient";

export const uploadFile = async (formData: FormData) => {
  const response = await apiClient.post("/api/utils/upload-file/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
