import React, { useEffect, useState } from "react";
import { CgWebsite } from "react-icons/cg";
import { Button, Form, Input, InputNumber, message, Switch, Tabs, Radio, Col, Row ,Grid} from "antd";
import styles from "./settings.module.scss";
import PageHeader from "../../common/page-header/PageHeader";
import WebsiteMenus from "../WebsiteMenus";
import { getSettings, updateSettings } from "../../api/settings/settingApi";
import { showErrors } from "../../utils/useFormHandler";
import { SettingsDataProps } from "../../types/settings";
import { SettingsKeys } from "./keys";
import ImageUpload from "../../components/uploadField";
import { CiBank, CiCreditCard1 } from "react-icons/ci";
import { BsInfoCircle, BsPercent } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { FaMobileScreen } from "react-icons/fa6";
import { MdOutlineDisplaySettings, MdOutlineSecurity } from "react-icons/md";
import { AiOutlineGlobal, AiOutlineMessage } from "react-icons/ai";

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Settings: React.FC = () => {
    const [settingsData, setSettingsData] = useState<SettingsDataProps | null>(
        null
    );
    const [loading, setLoading] = useState(true);
    const screens = useBreakpoint();

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await getSettings();
                setSettingsData(data);
            } catch (error) {
                message.error("Failed to load settings.");
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleUpdate = async (updatedData: SettingsDataProps) => {
        try {
            const updatedSettings = await updateSettings(updatedData);
            message.success("Settings updated successfully");
        } catch (error) {
            showErrors(error);
        }
    };

    if (loading) return <div>Loading...</div>;
    return (
        <>
            <PageHeader heading={"Settings"} />
            <div className={styles.settingTabs}>
                <Tabs tabPosition={screens.md ? "left" : "top"} defaultActiveKey="0">
                    <TabPane
                        tab={
                            <>
                                <BsPercent /> Taxation
                            </>
                        }
                        key="0"
                    >
                        <TaxationConfigForm  data={settingsData!} onUpdate={handleUpdate}/>
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1 /> Razorpay Gateway
                            </>
                        }
                        key="razor"
                    >
                        <PaymentGatewayConfigForm data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1 /> MeritHub API
                            </>
                        }
                        key="merithub"
                    >
                        <MeritHubConfigForm data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <HiOutlineMail /> Email Config
                            </>
                        }
                        key="6"
                    >
                        <EmailConfigForm data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>
                    {/* <TabPane
                        tab={
                            <>
                                <MdOutlineDisplaySettings /> Website Config
                            </>
                        }
                        key="1"
                    >
                        <WebsiteConfigForm />
                    </TabPane> */}
                    {/* <TabPane
                        tab={
                            <>
                                <FaMobileScreen /> Mobile App Config
                            </>
                        }
                        key="2"
                    >
                        <MobileAppConfigForm />
                    </TabPane> */}
                    <TabPane
                        tab={
                            <>
                                <AiOutlineGlobal /> Global Settings
                            </>
                        }
                        key="3"
                    >
                        <GlobalSettingsForm />
                    </TabPane>


                    <TabPane
                        tab={
                            <>
                                <AiOutlineMessage /> Message Config
                            </>
                        }
                        key="7"
                    >
                        <MessageConfigForm />
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CgWebsite /> Website Menu
                            </>
                        }
                        key="8"
                    >
                        <WebsiteMenus />
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiBank /> Bank Details
                            </>
                        }
                        key="9"
                    >
                        <BankDetails data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>

                    <TabPane
                        tab={
                            <>
                                <BsInfoCircle /> Company Information
                            </>
                        }
                        key="10"
                    >
                        <CompanyInformation data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1 /> Invoice Settings
                            </>
                        }
                        key="11"
                    >
                        <InvoiceConfigForm data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>
                    <TabPane
                        tab={
                            <>
                                <CiCreditCard1 /> Mobile Security Settings
                            </>
                        }
                        key="mobile-security"
                    >
                        <SecuritySettingsForm data={settingsData!} onUpdate={handleUpdate} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};

const WebsiteConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item label="Website Name" name="websiteName">
                    <Input placeholder="Enter website name" />
                </Form.Item>
                <Form.Item label="Website URL" name="websiteUrl">
                    <Input placeholder="Enter website URL" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Website Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const TaxationConfigForm: React.FC <{
   data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.GST_PERCENTAGE]: data[SettingsKeys.GST_PERCENTAGE],
            [SettingsKeys.INTERNET_CHARGES]: data[SettingsKeys.INTERNET_CHARGES],
            [SettingsKeys.PLATFORM_FEE]: data[SettingsKeys.PLATFORM_FEE],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };
    return(
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical" form={form}  onFinish={onFinish}>
                <Form.Item 
                   label="Tax Percentage"
                   name={SettingsKeys.GST_PERCENTAGE}
                   tooltip="Default GST charges should be a 18%"
                >
                    <InputNumber min={0} placeholder="Enter Tax Percentage" />
                    
                </Form.Item>
                <Form.Item 
                   label="Internet Charges" 
                   name={SettingsKeys.INTERNET_CHARGES}
                   tooltip="Internet default charges flat ₹10"
                >
                    <InputNumber min={0} placeholder="Enter Internet Charges" />
                </Form.Item>
                <Form.Item 
                    label="Platform Fee" 
                    name={SettingsKeys.PLATFORM_FEE}
                    tooltip="PlatForm defaut fee flat ₹10"
                >
                    <InputNumber min={0} placeholder="Enter Platform Fee" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
 );
};
const MobileAppConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">

            <Form layout="vertical">
                <Form.Item label="App Name" name="appName">
                    <Input placeholder="Enter mobile app name" />
                </Form.Item>
                <Form.Item label="App Version" name="appVersion">
                    <Input placeholder="Enter app version" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Mobile App Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);

const GlobalSettingsForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item
                    label="Enable Maintenance Mode"
                    name="maintenanceMode"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item label="Site Locale" name="locale">
                    <Input placeholder="Enter locale (e.g. en-US)" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Global Settings</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);


const PaymentGatewayConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.RAZORPAY_API_KEY]: data[SettingsKeys.RAZORPAY_API_KEY],
            [SettingsKeys.RAZORPAY_API_SECRET]: data[SettingsKeys.RAZORPAY_API_SECRET],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                label="Razorpay API Key"
                name={SettingsKeys.RAZORPAY_API_KEY}
                rules={[{ required: true, message: 'API Key is required' }]}
            >
                <Input placeholder="Enter Razorpay API Key" />
            </Form.Item>
            <Form.Item
                label="Razorpay API Secret"
                name={SettingsKeys.RAZORPAY_API_SECRET}
                rules={[{ required: true, message: 'API Secret is required' }]}
            >
                <Input.Password placeholder="Enter Razorpay API Secret" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save Razorpay Config
                </Button>
            </Form.Item>
        </Form>
    );
};


const EmailConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.MAIL_NAME]: data[SettingsKeys.MAIL_NAME],
            [SettingsKeys.MAIL_ID]: data[SettingsKeys.MAIL_ID],
            [SettingsKeys.MAIL_PASSWORD]: data[SettingsKeys.MAIL_PASSWORD],
            [SettingsKeys.MAIL_PORT]: data[SettingsKeys.MAIL_PORT],
            [SettingsKeys.MAIL_SERVER]: data[SettingsKeys.MAIL_SERVER],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };
    return (
        <div className="row">
            <div className="col-sm-6">

                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item label="Main Name"
                        name={SettingsKeys.MAIL_NAME}>
                        <Input placeholder="Enter Mail Name" />
                    </Form.Item>
                    <Form.Item label="Mail Server"
                        name={SettingsKeys.MAIL_SERVER}>
                        <Input placeholder="Enter Mail server" />
                    </Form.Item>
                    <Form.Item label="Mail Port"
                        name={SettingsKeys.MAIL_PORT}
                    >
                        <Input type="number" placeholder="Enter Mail port" />
                    </Form.Item>

                    <Form.Item label="Mail ID"
                        name={SettingsKeys.MAIL_ID}
                    >
                        <Input placeholder="Enter Mail ID" />
                    </Form.Item>
                    <Form.Item label="Mail Password"
                        name={SettingsKeys.MAIL_PASSWORD}
                    >
                        <Input.Password placeholder="Enter Mail Password" />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">Save Email Config</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>

    );
};  
const MessageConfigForm: React.FC = () => (
    <div className="row">
        <div className="col-sm-6">
            <Form layout="vertical">
                <Form.Item label="SMS Provider" name="smsProvider">
                    <Input placeholder="Enter SMS provider" />
                </Form.Item>
                <Form.Item label="API Token" name="apiToken">
                    <Input placeholder="Enter API token" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save Message Config</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
);
const BankDetails: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.ACCOUNT_NAME]: data[SettingsKeys.ACCOUNT_NAME],
            [SettingsKeys.ACCOUNT_NUMBER]: data[SettingsKeys.ACCOUNT_NUMBER],
            [SettingsKeys.IFSC]: data[SettingsKeys.IFSC],
            [SettingsKeys.SWIFT_CODE]: data[SettingsKeys.SWIFT_CODE],
            [SettingsKeys.BANK]: data[SettingsKeys.BANK],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <div className="row">
            <div className="col-sm-6">
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item label="Account Name" name={SettingsKeys.ACCOUNT_NAME}>
                        <Input placeholder="Enter Account Name" />
                    </Form.Item>
                    <Form.Item label="Account Number" name={SettingsKeys.ACCOUNT_NUMBER}>
                        <Input placeholder="Enter Account Number" />
                    </Form.Item>
                    <Form.Item label="IFSC Code" name={SettingsKeys.IFSC}>
                        <Input placeholder="Enter IFSC Code" />
                    </Form.Item>
                    <Form.Item label="Swift Code" name={SettingsKeys.SWIFT_CODE}>
                        <Input placeholder="Enter Swift Code" />
                    </Form.Item>
                    <Form.Item label="Bank Name" name={SettingsKeys.BANK}>
                        <Input placeholder="Enter Bank Name" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Bank Details
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

const CompanyInformation: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.BILLED_BY]: data[SettingsKeys.BILLED_BY],
            [SettingsKeys.GSTIN]: data[SettingsKeys.GSTIN],
            [SettingsKeys.COMPANY_ADDRESS]: data[SettingsKeys.COMPANY_ADDRESS],
            [SettingsKeys.PAN]: data[SettingsKeys.PAN],
            [SettingsKeys.EMAIL]: data[SettingsKeys.EMAIL],
            [SettingsKeys.PHONE]: data[SettingsKeys.PHONE],
            [SettingsKeys.ADDRESS]: data[SettingsKeys.ADDRESS],
            [SettingsKeys.PINCODE]: data[SettingsKeys.PINCODE],
            [SettingsKeys.CITY]: data[SettingsKeys.CITY],
            [SettingsKeys.STATE]: data[SettingsKeys.STATE],
            [SettingsKeys.COMPANY_LOGO]: data[SettingsKeys.COMPANY_LOGO],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className={styles.formContainer}
        >
            <div className={styles.formLeft}>
                <Form.Item label="Bussiness Name" name={SettingsKeys.BILLED_BY}>
                    <Input placeholder="Enter Bussiness Name" />
                </Form.Item>
                <Form.Item label="GSTIN" name={SettingsKeys.GSTIN}>
                    <Input placeholder="Enter GSTIN Number" />
                </Form.Item>
                {/* <Form.Item label="COMPANY ADDRESS" name={SettingsKeys.COMPANY_ADDRESS}>
                    <Input placeholder="Enter COMPANY Address"/>
                </Form.Item> */}
                <Form.Item label="PAN" name={SettingsKeys.PAN}>
                    <Input placeholder="Enter PAN" />
                </Form.Item>

                <Form.Item label="Email" name={SettingsKeys.EMAIL}>
                    <Input placeholder="Enter Email" />
                </Form.Item>
                <Form.Item label="Phone" name={SettingsKeys.PHONE}>
                    <Input placeholder="Enter Phone" />
                </Form.Item>
                <div className={styles.submitButton}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Company Information
                        </Button>
                    </Form.Item>
                </div>
            </div>

            <div className={styles.formRight}>
                <Form.Item label="Address" name={SettingsKeys.ADDRESS}>
                    <Input placeholder="Enter Adresss" />
                </Form.Item>

                <Form.Item label="Pincode" name={SettingsKeys.PINCODE}>
                    <Input placeholder="Enter PinCode" />
                </Form.Item>

                <Form.Item label="City" name={SettingsKeys.CITY}>
                    <Input placeholder="Enter City" />
                </Form.Item>

                <Form.Item label="State" name={SettingsKeys.STATE}>
                    <Input placeholder="Enter State" />
                </Form.Item>
                <ImageUpload showInstruction={true} required={false} form={form} name={SettingsKeys.COMPANY_LOGO}
                    title={"Company Logo"} />

            </div>
        </Form>
    );
};
const MeritHubConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.MERITHUB_CLIENT_ID]: data[SettingsKeys.MERITHUB_CLIENT_ID],
            [SettingsKeys.MERITHUB_CLIENT_SECRET]: data[SettingsKeys.MERITHUB_CLIENT_SECRET],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                label="MeritHub Client ID"
                name={SettingsKeys.MERITHUB_CLIENT_ID}
                rules={[{ required: true, message: 'Client ID is required' }]}
            >
                <Input placeholder="Enter MeritHub Client ID" />
            </Form.Item>
            <Form.Item
                label="MeritHub Client Secret"
                name={SettingsKeys.MERITHUB_CLIENT_SECRET}
                rules={[{ required: true, message: 'Client Secret is required' }]}
            >
                <Input.Password placeholder="Enter MeritHub Client Secret" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save MeritHub Config
                </Button>
            </Form.Item>
        </Form>
    );
};

const InvoiceConfigForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.INVOICE_PRE_FIX]: data[SettingsKeys.INVOICE_PRE_FIX],
            [SettingsKeys.INVOICE_NUMBER_COUNTER]: data[SettingsKeys.INVOICE_NUMBER_COUNTER],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <div className="row">
            <div className="col-sm-6">
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item label="Invoice Prefix" name={SettingsKeys.INVOICE_PRE_FIX}>
                        <Input placeholder="Enter Invoice Prefix" />
                    </Form.Item>
                    <Form.Item
                        label="Invoice Number Counter"
                        name={SettingsKeys.INVOICE_NUMBER_COUNTER}
                    >
                        <InputNumber min={0} placeholder="Enter Invoice Number Counter" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Invoice Settings
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

const SecuritySettingsForm: React.FC<{
    data: SettingsDataProps;
    onUpdate: (data: SettingsDataProps) => void;
}> = ({ data, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            [SettingsKeys.ANTI_PIRACY_WATERMARK_VIDEO]: data[SettingsKeys.ANTI_PIRACY_WATERMARK_VIDEO],
            [SettingsKeys.ANTI_PIRACY_WATERMARK_LIVE_CLASS]: data[SettingsKeys.ANTI_PIRACY_WATERMARK_LIVE_CLASS],
            [SettingsKeys.ANTI_PIRACY_WATERMARK_IMAGE]: data[SettingsKeys.ANTI_PIRACY_WATERMARK_IMAGE],
            [SettingsKeys.ANTI_PIRACY_WATERMARK_IMAGE_UPLOAD]: data[SettingsKeys.ANTI_PIRACY_WATERMARK_IMAGE_UPLOAD],
            [SettingsKeys.SAFETY_NET_CHECK]: data[SettingsKeys.SAFETY_NET_CHECK],
            [SettingsKeys.DEVICE_RESTRICTION]: data[SettingsKeys.DEVICE_RESTRICTION],
            [SettingsKeys.ALLOW_ON_DESKTOP]: data[SettingsKeys.ALLOW_ON_DESKTOP],
            [SettingsKeys.SHOW_RECORDED_CLASS_ON_WEB]: data[SettingsKeys.SHOW_RECORDED_CLASS_ON_WEB],
        });
    }, [data, form]);

    const onFinish = (values: SettingsDataProps) => {
        onUpdate(values);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <div className="row">
                {/* Left Column */}
                <div className="col-sm-6 extended-column">

                    <Form.Item
                        label="Show Student Watermark on Store Videos"
                        name={SettingsKeys.ANTI_PIRACY_WATERMARK_VIDEO}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        label="Show Student Watermark on Live Class Videos"
                        name={SettingsKeys.ANTI_PIRACY_WATERMARK_LIVE_CLASS}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </div>
                <div className="col-sm-6 extended-column">

                    {/* Anti-Piracy Watermark */}

                    <Form.Item
                        label="Enable Image Watermark"
                        name={SettingsKeys.ANTI_PIRACY_WATERMARK_IMAGE}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <ImageUpload showInstruction={true} required={false} form={form} name={SettingsKeys.ANTI_PIRACY_WATERMARK_IMAGE_UPLOAD}
                        title={"WaterMark Image"} />
                </div>
            </div>

            <hr />
            <div className="row">
                <div className="col-sm-6 extended-column">
                    {/* Safety Net Check */}
                    <Form.Item
                        label="Enable Safety Net Check (Block rooted or unauthorized devices)"
                        name={SettingsKeys.SAFETY_NET_CHECK}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </div>
            </div>
            <hr />

            <div className="row">
                {/* Device Restriction */}
                <Form.Item
                    label="Device Restriction"
                    name={SettingsKeys.DEVICE_RESTRICTION}
                >
                    <Radio.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="col-sm-6 extended-column">

                            <Radio value="app_or_web_1_device_only">
                                App or Web: Students can log in on 1 device only. They will not be able to log in from any other device.
                            </Radio>
                            <Radio value="app_or_web_1_device_at_a_time">
                                App or Web: Students can log in on 1 device at a time. If they log in from another device, they will automatically be logged out from their previously logged-in device.
                            </Radio>

                            <Radio value="no_device_restrictions">
                                No device restrictions.
                            </Radio>


                        </div>
                        <div className="col-sm-6 extended-column">

                            <Radio value="app_and_web_1_device_each">
                                App and Web: Students can log in through 1 app and 1 web device at the same time. They will not be able to log in from any other device.
                            </Radio>
                            <Radio value="app_and_web_1_device_each_auto_logout">
                                App and Web: Students can log in through 1 app and 1 web device at the same time. Once they log in from another device, they will automatically be logged out from their previously logged-in devices.
                            </Radio>
                        </div>
                    </Radio.Group>
                </Form.Item>

            </div>
            <hr />
            <div className="row">
                <div className="col-sm-6 extended-column">
                    {/* Live Class Settings */}
                    <Form.Item
                        label="Allow Live Class on Desktop"
                        name={SettingsKeys.ALLOW_ON_DESKTOP}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    
                </div>

                {/* Right Column */}
                <div className="col-sm-6 extended-column">

                    <Form.Item
                        label="Show Recorded Classes on Web"
                        name={SettingsKeys.SHOW_RECORDED_CLASS_ON_WEB}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    </div>

                    {/* Submit Button */}
                    <Form.Item>
                        <Button  type="primary" htmlType="submit">
                            Save Security Settings
                        </Button>
                    </Form.Item>
                
            </div>
        </Form >

    );
};

export default Settings;
