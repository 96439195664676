// Roles.tsx
import React from 'react';
import { usePermissions } from "../context/PermissionsContext";
import { useAuth } from "../context/AuthContext";
import { ModulePermissions } from "../types/permissions";

// Define props for the Role component
interface RoleProps {
    moduleName: string;
    action: keyof ModulePermissions;  // Use keyof to restrict action to 'can_view', 'can_create', etc.
    children: React.ReactNode;  // children are of type ReactNode
}

export enum UserRole {
    ADMIN = 'ADMIN',
    STUDENT = 'STUDENT',
    MANAGER = 'MANAGER',
    INSTRUCTOR = 'INSTRUCTOR',
}
export enum Action {
    VIEW = 'view',
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete'
}
export enum ModuleName {
    COURSES = 'Courses',
    STUDENTS = 'Students',
    FEE_RECORD = 'Fee Record',
    FEE_STRUCTURE = 'Fee Structure',
    BATCHES = 'Batches',
    COUPONS = 'Coupons',
    TEST_PORTAL = 'Quiz',
    LEAD_INQUIRIES = 'Lead & Inquiries',
    TEST_SERIES = 'Products',
    TRANSACTIONS = 'Transactions',
    FREE_RESOURCE = 'Free Resource',
    TEAM_MEMBERS = 'Team Members',
    REPORT = 'Report',
    SETTINGS = 'Settings'
}

export const Role: React.FC<RoleProps> = (props): React.ReactElement | null => {
    const { permissions } = usePermissions(); // Get permissions from context
    const { user } = useAuth(); // Get user info from context
    const role = user?.userRole;

    if (!role) return null; // Return null if there's no user role
    // Check if the module's permissions match the required action for the current user role
    const hasPermission = (action: keyof ModulePermissions): boolean => {
        // Find the module permissions based on the moduleName
        const modulePermissions = permissions.find(
            (perm) => perm.module === props.moduleName
        );

        if (modulePermissions) {
            const permissionValue = modulePermissions[action];
            console.log(`Checking permission for ${props.moduleName} with action ${action}:`, permissionValue);

            // Ensure permissionValue is a boolean (use Boolean to force it)
            return typeof permissionValue === 'boolean' ? permissionValue : false;
        }

        return false;
    };

    // Check if the user's role has permission for the specific action
    const canPerformAction = hasPermission(props.action);

    if (canPerformAction) {
        return <>{props.children}</>; // Don't render children if reversed and user has permission
    }
    return null; // Render children if reversed and user doesn't have permission
};

export const hasPermission = (
  action: keyof ModulePermissions,
  moduleName: string,
  permissions: ModulePermissions[]
): boolean => {
  // Find the module permissions based on the moduleName
  const modulePermissions = permissions.find(
    (perm) => perm.module === moduleName
  );

  if (modulePermissions) {
    const permissionValue = modulePermissions[action];
    console.log(`Checking permission for ${moduleName} with action ${action}:`, permissionValue);

    // Ensure permissionValue is a boolean (use Boolean to force it)
    return typeof permissionValue === 'boolean' ? permissionValue : false;
  }

  return false; // If no permissions for the module, return false by default
};
