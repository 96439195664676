import React, {useEffect, useState} from "react";
import {Button, Checkbox, Drawer, Form, Input, InputNumber, Select} from "antd";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import styles from "./addTestSeriesDrawer.module.scss";
import ImageUpload from "../uploadField";
import {useFormHandler} from "../../utils/useFormHandler";
import {createTestSeries, updateTestSeries} from "../../api/testSeries/testSeries";
import {TestSeriesCategory, useTestSeriesCategoryList} from "../../api/testSeries/testSeriesCategories";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import useDebounceValue from "../../hooks/useDebouncedValue";
import FooterButton from "../FooterButton"; // Custom form handler hook

interface AddTestSeriesDrawerProps {
    onClose: () => void;
    open: boolean;
    testSeries: any; // Optional prop for editing
}

const {Option} = Select;


const AddTestSeriesDrawer: React.FC<AddTestSeriesDrawerProps> = ({onClose, open, testSeries}) => {
    const [form] = Form.useForm();
    const {data: categories, loading: categoryLoading, fetchWithParams} = useTestSeriesCategoryList();
    const {updateSearch} = useFilterSortPaginate(fetchWithParams);
    const {debouncedValue, handleDebounceSearchInput} = useDebounceValue(updateSearch, 2000);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (testSeries) {
            form.setFieldsValue({
                name: testSeries.name,
                category: testSeries.category?.id,
                price: testSeries.price,
                discount: testSeries.discount,
                description: testSeries.description,
                highlights: testSeries.highlights,
                thumbnail: testSeries.thumbnail,
                is_digital: testSeries.is_digital,
                url: testSeries.url,
                is_published: testSeries.is_published,
                gst: testSeries.gst,
            });

        } else {
            form.resetFields(); // Reset form if it's a create action
        }
    }, [testSeries, form]);

    // Form submission handler
    const onFinish = useFormHandler(
        async (values: any) => {
            testSeries ? updateTestSeries(testSeries.id, values) : createTestSeries(values);
        },
        form,
        () => {
            onClose();
        },
        () => {

        },
        setLoading
    );
    const gstRates = [
        {value: "0", label: "0% (Exempted)"},
        {value: "5", label: "5%"},
        {value: "12", label: "12%"},
        {value: "18", label: "18%"},
        {value: "28", label: "28%"},
    ];

    return (
        <Drawer
            title={testSeries ? "Edit Product" : "Create Product"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <Form
                layout={"vertical"}
                form={form}
                onFinish={onFinish}
                className={styles.form}
            >
                <Form.Item
                    label="Product Name"
                    name="name"
                    rules={[{required: true, message: "Please input Product Name!"}]}
                >
                    <Input placeholder="Enter Product Name"/>
                </Form.Item>
                <Form.Item
                    label="Select Category"
                    name="category"
                    rules={[{required: true, message: "Please select category!"}]}
                >
                    <Select
                        placeholder="Select category"
                        options={(categories as TestSeriesCategory[]).map((category: TestSeriesCategory) => ({
                            label: category.title,
                            value: category.id,
                        }))}
                        filterOption={false}
                        showSearch
                        onSearch={handleDebounceSearchInput}
                        className={"multiSelect"}
                        style={{width: '100%'}}
                    />
                </Form.Item>


                <Form.Item
                    label="Regular Price"
                    name="price"
                    initialValue={0}
                    rules={[{required: true, message: "Please enter Regular Price!"}]}
                >
                    <InputNumber min={0} placeholder="Enter Regular Price"/>
                </Form.Item>
                <Form.Item
                    label="Discounted Price"
                    name="discount"
                    initialValue={0}
                    rules={[{required: true, message: "Please enter Discount Price!"}]}
                >
                    <InputNumber min={0} placeholder="Enter Discount Price"/>
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Enter Description"/>
                </Form.Item>
                <Form.Item name={'is_digital'} valuePropName="checked">
                    <Checkbox>
                        Is Digital product
                    </Checkbox>
                </Form.Item>
                <ImageUpload form={form} name="thumbnail" title={'Thumbnail'}/>
                <Form.Item name={'is_published'} valuePropName="checked">
                    <Checkbox>
                        Published
                    </Checkbox>
                </Form.Item>

                <Form.Item noStyle dependencies={['is_digital']}>
                    {({getFieldValue}) => {
                        const isDigital = getFieldValue('is_digital');
                        return !isDigital ? (
                            <Form.Item name="gst" label="GST Rate"
                                       rules={[{required: true, message: "Please select gst"}]}>
                                <Select placeholder="Select GST Rate">
                                    {gstRates.map((rate: any) => (
                                        <Select.Option key={rate.value} value={rate.value}>
                                            {rate.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) :  <ImageUpload showInstruction={false} form={form} name="url" title={'Document'}/>;
                    }}
                </Form.Item>
                {/* Highlights Section */}
                <Form.List initialValue={testSeries?.highlights || []} name="highlights">
                    {(fields, {add, remove}) => (
                        <>
                            <label className={styles.highlightLabel}>
                                Highlights <span>(Optional)</span>
                            </label>
                            {fields.map(({key, name, ...restField}) => (
                                <div key={key} className={styles.formListItem}>
                                    <Form.Item
                                        {...restField}
                                        name={name}  // No nested field
                                        rules={[{required: true, message: "Please enter a highlight point"}]}
                                    >
                                        <Input placeholder="Enter Point"/>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)}/>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}  // Just add an empty string, no need for {}
                                    block
                                    icon={<PlusOutlined/>}
                                >
                                    Add More Point
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <FooterButton loading={loading} onClose={onClose} submitButtonText={'Submit'}/>
            </Form>
        </Drawer>
    );
}

export default AddTestSeriesDrawer;
