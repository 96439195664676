import React, {useEffect, useState} from "react";
import {Button, Drawer, Form, Input, InputNumber, message, Select} from "antd";
import {useStudentList} from "../../api/student/studentApi";
import styles from "./feeRecods.module.scss"
import {addFeesRecordBatch, useBatchList} from "../../api/batch/batch";
import FooterButton from "../FooterButton";
import {showErrors, useFormHandler} from "../../utils/useFormHandler";

interface AddFeeRecProps {
    onClose: () => void;
    open: boolean;
}


const AddFeeRecordModal: React.FC<AddFeeRecProps> = ({onClose, open}) => {
    const [form] = Form.useForm();
    const {data: studentData, loading, error, fetchWithParams} = useStudentList();
    const {data: batchData, loading: batchLoading, error: batchErr, fetchWithParams: batchParam} = useBatchList();
    const [studentOptionList, setStudentOptionList] = useState<any[]>([])
    const [batchOptionList, setBatchOptionList] = useState<any[]>([])
    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    useEffect(() => {
        const OptionList = studentData.map((student: any) => {
            return {value: student.id, label: student.full_name}
        })
        setStudentOptionList(OptionList)
        const batchOption = batchData.map((batch: any) => {
            return {value: batch.id, label: batch.name}
        })
        setBatchOptionList(batchOption)
    }, [studentData])

    useEffect(() => {
        const batchOption = batchData.map((batch: any) => {
            return {value: batch.id, label: batch.name}
        })
        setBatchOptionList(batchOption)
    }, [batchData])

    const onFinish = useFormHandler(
        async (values) => {
            debugger
            await addFeesRecordBatch(values)
        },
        form,
        () => {
            message.success('Fees Record saved successfully!');
            onClose();
        },
        (errorRespponse) => {
            showErrors(errorRespponse)
        },
        setBtnLoading
    );

    return (
        <Drawer
            title={"Add Fee Record"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <div className={styles.drawerMain}>
                <Form
                    layout={"vertical"}
                    form={form}
                    style={{maxWidth: "100%"}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Student"
                        name="student"
                        rules={[{required: true, message: "Please select student!"}]}
                    >
                        <Select placeholder="Select Student"
                                options={studentOptionList}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Batch"
                        name="batch"
                        rules={[{required: true, message: "Please select batch!"}]}
                    >
                        <Select placeholder="Select Batch"
                                options={batchOptionList}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Amount or installment Amount (any one)"
                        name="amount"
                        rules={[{required: true, message: "Please input fee amount!"}]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="Enter Fee Amount"
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Installment"
                        name="installment_number"
                        rules={[{required: true, message: "Please input Installment number!"}]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="Enter Installment number"
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Reference Number"
                        name="reference_number"
                        rules={[{required: true, message: "Please input Reference Number!"}]}
                    >
                        <Input
                            min={0}
                            placeholder="Enter Reference Number"
                            style={{width: "100%"}}
                        />
                    </Form.Item>

                    <div className={styles.formFooter}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                    <FooterButton loading={btnLoading} onClose={onClose} submitButtonText={"Submit"}/>
                </Form>
            </div>
        </Drawer>
    );
};

export default AddFeeRecordModal;
