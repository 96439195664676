// StudentBatchesList.tsx
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './studentFreeResourcesList.module.scss';
import {Button} from 'antd';
import PageHeader from '../common/page-header/PageHeader';
import {BASE_URL} from "../utils/apiClient";
import {useStudentFreeResourceList} from "../api/freeResources/student/studentFreeResource";
import {ResourceProps} from "../types/resource";

const StudentFreeResourcesList: React.FC = () => {
    const {data} = useStudentFreeResourceList()
    const navigate = useNavigate();


    const handleClick = (resource: ResourceProps) => {
        navigate(`/student-free-resources/${resource.id}`);
    };


    return (
        <div>
            <PageHeader heading="Free Resources"/>
            <section className={styles.purchasedBatchesSection}>
                <div className="row">
                    {data.map((resource) => (
                        <div key={resource.id} className="col-sm-4 col-12">
                            <div className={styles.documentCard}>
                                <div className={styles.documentThumbnailDiv}>
                                    <img
                                        src={`${BASE_URL}${resource.thumbnail}`}
                                        alt={document.title}
                                    />
                                </div>
                                <h5>{resource.title}</h5>
                                <div className={styles.actions}>
                                    <Button onClick={() => handleClick(resource)}>
                                        Content
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default StudentFreeResourcesList;
