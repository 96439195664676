// src/api/loginAPI.ts
import apiClient from "../../utils/apiClient";

// Define the request data type for the login API
export interface LoginRequestData {
    phone_number: string;
}

// Define the response type for login
interface LoginResponse {
    message: string;
    reference_key: string;
}

// Define the login function that will be used with useFormHandler
export const loginUser = async (values: LoginRequestData): Promise<LoginResponse> => {
    const response = await apiClient.post<LoginResponse>('/api/login/', values);
    return response.data;

};
