import {useCallback, useState} from 'react';
import {debounce} from 'lodash';

// Define the hook
const useDebounceValue = (onSearch: (value: string) => void, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState<string>('');

    // Create a debounced function to handle search
    const debouncedSearch = useCallback(
        debounce((searchTerm: string) => {
            onSearch(searchTerm);
        }, delay),
        []
    );

    // Handle the input change
    const handleDebounceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setDebouncedValue(newValue);
        debouncedSearch(newValue);
    };

    const handleDebounceSearchInput = (value: string) => {
        setDebouncedValue(value);
        debouncedSearch(value);
    }
    return {
        debouncedValue,
        handleDebounceInputChange,
        handleDebounceSearchInput
    };
};

export default useDebounceValue;
