// src/types/api.ts
export interface LoginResponse {
    token: string;
    // Add other relevant fields if necessary
}

// Define the response type for the signup API
export interface SignupResponse {
    message: string;
    reference_key: string;
}


export interface AxiosErrorResponse {
    // Optional message property
    message?: string;

    // Index signature for dynamic properties
    [key: string]: string | undefined;
}


export interface AxiosError {
    response?: {
        data?: AxiosErrorResponse;
        status?: number;
    };
    code: string;
    isAxiosError?: boolean;
}

// Define a custom error class to handle Axios errors
export class CustomAxiosError extends Error {
    constructor(public message: string, public statusCode?: number) {
        super(message);
        this.name = 'CustomAxiosError';
    }
}
