import useFetchList from "../../../utils/listClient";
import apiClient from "../../../utils/apiClient";
import {ResourceProps} from "../../../types/resource";




export const useStudentFreeResourceList = () => {
    return useFetchList<ResourceProps>("/api/free-resource/student-resources/");
};


export const detailStudentFreeResource = async (resourceId: number): Promise<ResourceProps> => {
    const response = await apiClient.get<ResourceProps>(`/api/free-resource/student-resources/${resourceId}/`);
    return response.data;
};
