
// Define the types for TypeScript (if needed)
import apiClient from "../../utils/apiClient";

export interface UserMetrics {
    total_users: number;
    active_users: number;
    new_enrollments_last_week: number;
    inactive_users: number;
}

export interface StudentMetrics {
    total_students: number;
    active_students: number;
    new_enrollments_last_week: number;
    inactive_students: number;
}

export interface GlobalMetrics {
    total_students: number;
    total_courses: number;
    total_batches: number;
    trending_courses: any[]; // Define a more specific type if needed
    trending_batches: any[]; // Define a more specific type if needed
    total_instructor: number; // Define a more specific type if needed
}
export interface FeesMetrics {
    total_paid_fees: number;
    total_outstanding_fees: number;
    total_records: number;
}
export interface StudentDashboardMetrics {
    available_batches: number;
    available_courses: number;
    enrolled_courses: number;
    enrolled_batches: number;
}

// Fetch user metrics
export const getUserMetrics = async (): Promise<UserMetrics> => {
    const response = await apiClient.get<UserMetrics>('/api/widget/metrics/user/');
    return response.data;
};

// Fetch student metrics
export const getStudentMetrics = async (): Promise<StudentMetrics> => {
    const response = await apiClient.get<StudentMetrics>('/api/widget/metrics/student/');
    return response.data;
};

// Fetch global metrics
export const getGlobalMetrics = async (): Promise<GlobalMetrics> => {
    const response = await apiClient.get<GlobalMetrics>('/api/widget/metrics/global/');
    return response.data;
};
export const getFeesMetrics = async (): Promise<FeesMetrics> => {
    const response = await apiClient.get<FeesMetrics>('/api/widget/metrics/fees/');
    return response.data;
};
export const getStudentDashboardMetrics = async (): Promise<StudentDashboardMetrics> => {
    const response = await apiClient.get<StudentDashboardMetrics>('/api/widget/student/metrics/dashboard');
    return response.data;
};
