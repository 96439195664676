import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {Button, Popconfirm} from 'antd';
import styles from './LiveClassCard.module.scss';
import {Action, ModuleName, Role} from "../../../utils/Role";
import {useNavigate} from "react-router-dom";

dayjs.extend(duration);

interface LiveClassProps {
    liveClass: {
        id: number;
        title: string;
        date: string;
        host_link: string;
    };
    onDelete: any;
}

const LiveClassCard: React.FC<LiveClassProps> = ({liveClass, onDelete}) => {
    const navigate = useNavigate()

    const [remainingTime, setRemainingTime] = useState<string>('');

    useEffect(() => {
        const calculateRemainingTime = () => {
            const currentTime = dayjs();
            const liveDate = dayjs(liveClass.date);
            const diff = liveDate.diff(currentTime);

            if (diff > 0) {
                const countdown = dayjs.duration(diff);
                setRemainingTime(
                    `${countdown.days()}d ${countdown.hours()}h ${countdown.minutes()}m ${countdown.seconds()}s`
                );
            } else {
                setRemainingTime('Live now!');
            }
        };

        calculateRemainingTime();
        const intervalId = setInterval(calculateRemainingTime, 1000);

        return () => clearInterval(intervalId);
    }, [liveClass.date]);

    const handleJoinClick = () => {
        if (liveClass?.host_link) {
            navigate("/full-screen", {state: {url: liveClass.host_link}});
        } else {
            alert("Host link is not available.");
        }
    };

    return (
        <div className={styles.liveClassRow}>
            <div>{liveClass.title}</div>
            <div>{remainingTime}</div>
            <div>
                {liveClass?.host_link ? (
                  <Popconfirm
                  title="Are you sure you want to join?"
                  onConfirm={handleJoinClick}
                  okText="Yes"
                  cancelText="No"
              >
                  <Button>Join</Button>
              </Popconfirm>
                ) : (
                    <span>Not available</span>
                )}
            </div>
            <div>
                <Role moduleName={ModuleName.FEE_STRUCTURE} action={Action.DELETE}>
                    <Popconfirm
                        title="Are you sure to delete this live class?"
                        onConfirm={onDelete}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button className={styles.deleteBtn} type="link" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Role>
            </div>
        </div>
    );
};

export default LiveClassCard;
