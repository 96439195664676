import React, {useEffect, useState} from 'react';
import styles from './studentProductList.module.scss';
import PageLoader from '../common/page-loader/PageLoader';
import {Segmented} from 'antd';
import {TYPE_AVAILABLE, TYPE_PURCHASED} from '../utils/constants';
import cn from "classnames";
import {FaBook} from "react-icons/fa";
import {TestSeriesProps} from "../types/testSeries";
import {
    useAvailableStudentTestSeriesList,
    usePurchasedStudentTestSeriesList
} from "../api/testSeries/student/studentTestSeries";
import {BASE_URL} from "../utils/apiClient";
import TestSeriesPurchaseModal from "../studentComponent/TestSeriesPurchaseModal";
import StudentProductDetailModal from '../studentComponent/Product/StudentProductDetailModal';

interface StudentProductListProps {
    is_digital: boolean
}

const StudentProductList: React.FC<StudentProductListProps> = ({is_digital}) => {

    const {
        data: availableProducts = [],
        loading,
        fetchWithParams: availableFetchWithParams
    } = useAvailableStudentTestSeriesList(false);
    const {
        data: purchasedProducts = [],
        fetchWithParams: purchasedFetchWithParams
    } = usePurchasedStudentTestSeriesList(false);
    const [selectedProductType, setSelectedProductType] = useState(TYPE_AVAILABLE);
    const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
    const [openProductPurchasedModal, setOpenProductPurchasedModal] = useState(false);
    const [selectedTestSeries, setSelectedTestSeries] = useState<TestSeriesProps | null>(null);
    const [selectedPurchasedProduct, setSelectedPurchasedProduct] = useState<any>(null);

    useEffect(() => {
        availableFetchWithParams({is_digital});
        purchasedFetchWithParams({is_digital})
    }, [is_digital]);


    const onPurchasedProductPageClick = (product: TestSeriesProps) => {
        setSelectedPurchasedProduct(product)
        setOpenProductPurchasedModal(true)
    };

    const handleOpenTestSeriesModal = (record: any) => {
        setOpenPurchaseModal(true)
        setSelectedTestSeries(record)
    }
    const handleCloseTestSeriesModal = () => {
        setOpenPurchaseModal(false)
        setSelectedTestSeries(null)
    }
    return (
        <div>
            {loading && <PageLoader/>}
            {/* Available/Purchased Products Section */}
            {/* <PageHeader heading="Test Series"/> */}
            <section>
                <Segmented
                    className={styles.segmentDiv}
                    defaultValue={selectedProductType}
                    options={[
                        {label: "Available Product", value: TYPE_AVAILABLE},
                        {label: "Purchased Product", value: TYPE_PURCHASED}
                    ]}
                    onChange={(value) => setSelectedProductType(value)}
                />
            </section>

            {/* Available Products Section */}
            {selectedProductType === TYPE_AVAILABLE &&
                <section className={styles.availableProductsSection}>
                    <div className="row">
                        {availableProducts.length === 0 ? (
                            <div className={cn(styles.noDataDiv, "col-12")}>
                                <p>No New Test Series Found</p>
                            </div>
                        ) : (
                            availableProducts.map((product: any) => (
                                <div className="col-sm-3 col-12" key={product.id}>
                                    <div className={styles.productDiv}
                                         onClick={() => handleOpenTestSeriesModal(product)}>
                                        <div className={styles.productCardImg}>
                                            <img src={`${BASE_URL}${product.thumbnail}`} alt={product.name}/>
                                        </div>
                                        <div className={styles.productContent}>
                                            <div className={styles.heading}>{product.name}</div>
                                            <div className={styles.smallContent}>
                                                <p><FaBook/> {product.category.title}</p>
                                                {/* <p>Price: ₹{product.effective_price}</p> */}
                                                <h6 className={styles.priceCourse}>
                                                    Price: <span>{product.effective_price ? `₹${product.effective_price}` : 'Free'}</span>
                                                    <br/>
                                                    <div>{product.discount && product.effective_price ? `₹${product.price}` : ''}</div>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </section>
            }

            {/* Purchased Products Section */}
            {selectedProductType === TYPE_PURCHASED &&
                <section className={styles.purchasedProductsSection}>
                    <div className="row">
                        {purchasedProducts.length === 0 ? (
                            <div className={cn(styles.noDataDiv, "col-12")}>
                                <p>No Purchased Test Series</p>
                            </div>
                        ) : (
                            purchasedProducts.map((product) => (
                                <div className="col-sm-3 col-12" key={product.id}
                                     onClick={() => onPurchasedProductPageClick(product)}>
                                    <div className={styles.productDiv}>
                                        <div className={styles.productCardImg}>
                                            <img src={`${BASE_URL}${product.thumbnail}`} alt={product.name}/>
                                        </div>
                                        <div className={styles.productContent}>
                                            <div className={styles.heading}>{product.name}</div>
                                            <div className={styles.smallContent}>
                                                <p><FaBook/> {product.category.title}</p>
                                                <h6 className={styles.priceCourse}>
                                                    Price: <span>{product.effective_price ? `₹${product.effective_price}` : 'Free'}</span>
                                                    <div>{product.discount && product.effective_price ? `₹${product.price}` : ''}</div>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </section>
            }

            {openPurchaseModal && (
                <TestSeriesPurchaseModal
                    open={openPurchaseModal}
                    data={selectedTestSeries}
                    onClose={handleCloseTestSeriesModal}
                />
            )}
            {openProductPurchasedModal && (
                <StudentProductDetailModal
                    open={openProductPurchasedModal}
                    data={selectedPurchasedProduct}
                    onClose={() => {
                        setOpenProductPurchasedModal(false)
                    }}
                />
            )}
        </div>
    );
};

export default StudentProductList;
