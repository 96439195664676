import React from "react";
import { Button, Drawer, Form, Input, InputNumber, message, Select } from "antd";
import styles from "./addFeeStructDrawer.module.scss";
import { useFormHandler } from "../../utils/useFormHandler";
import { createFeeStructure, updateFeeStructure } from "../../api/fees/feesApi";
import { FeeStructureProps } from "../../types/feeStructure";

const { Option } = Select;

interface AddFeeStructProps {
    onClose: () => void;
    open: boolean;
    fees: FeeStructureProps | null;
}

const AddFeeStructDrawer: React.FC<AddFeeStructProps> = ({ onClose, open, fees }) => {
    const [form] = Form.useForm();

    const handleSuccess = (response: any) => {
        message.success(fees ? "Fees Structure updated successfully!" : "Fees Structure created successfully!");
        onClose(); // Close the drawer
    };

    // Using the custom form handler to handle API call and form submission
    const onFinish = useFormHandler(
        fees ? async (values) => {
            return updateFeeStructure(fees.id, values);
        } : createFeeStructure, // Choose the correct API function
        form,
        handleSuccess // Success callback
    );

    const handleFeeAmountChange = (value: number | null) => {
        const installments = form.getFieldValue('installments') || 1;
        if (value !== null) {
            form.setFieldsValue({
                total_amount: (value * installments).toFixed(2),
            });
        } else {
            form.setFieldsValue({
                total_amount: undefined,
            });
        }
    };

    const handleInstallmentsChange = (value: number | null) => {
        const feeAmount = form.getFieldValue('fee_amount') || 0;
        if (value !== null) {
            form.setFieldsValue({
                total_amount: (feeAmount * value).toFixed(2),
            });
        } else {
            form.setFieldsValue({
                total_amount: undefined,
            });
        }
    };

    return (
        <Drawer
            title={"Create Fee Structure"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <div className={styles.drawerMain}>
                <Form
                    layout={"vertical"}
                    form={form}
                    initialValues={fees!}
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item
                        label="Fee Structure Name"
                        name="structure_name"
                        rules={[{ required: true, message: "Please input fee structure name!" }]}
                    >
                        <Input placeholder="Enter Structure Name" />
                    </Form.Item>

                    <Form.Item
                        label="Installment Fee Amount"
                        name="fee_amount"
                        rules={[{ required: true, message: "Please input  installement fee amount!" }]}
                    >
                        <InputNumber
                            min={0}
                            placeholder="Enter Installment Fee Amount"
                            onChange={handleFeeAmountChange}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Number of Installments"
                        name="installments"
                        rules={[{ required: true, message: "Please input number of installments!" }]}
                    >
                        <InputNumber
                            min={1}
                            placeholder="Enter Number of Installments"
                            onChange={handleInstallmentsChange}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Installment Frequency"
                        name="frequency"
                        rules={[{ required: true, message: "Please select installment frequency!" }]}
                    >
                        <Select placeholder="Select Frequency" style={{ width: "100%" }}>
                            <Option value="weekly">Weekly</Option>
                            <Option value="monthly">Monthly</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Number of Times"
                        name="number_of_values"
                        rules={[{ required: true, message: "Please input number of times!" }]}
                    >
                        <InputNumber
                            min={1}
                            placeholder="Enter Number of Times"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Total Amount"
                        name="total_amount"
                    >
                        <InputNumber
                            disabled
                            placeholder="Total Amount"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <div className={styles.formFooter}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
};

export default AddFeeStructDrawer;
