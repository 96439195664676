// Announcements.tsx
import React, {useEffect, useState} from 'react';
import {Avatar, Card, Input, List, Typography} from 'antd';
import styles from "./announcement.module.scss";
import PageHeader from "../../common/page-header/PageHeader";
import {SearchOutlined} from "@ant-design/icons";
import {CourseProps} from "../../types/course";
import { useStudentAnnouncementList } from '../../api/announcement/announcementApi';
import { formattedDateTime } from '../../utils/utils';

const {Text} = Typography;


interface AnnouncementsProps {
    course: CourseProps;
}

const AnnouncementsCourse: React.FC<AnnouncementsProps> = ({course}) => {
    const { data, fetchWithParams } = useStudentAnnouncementList(false); 
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (course?.id) {
            fetchWithParams({ course: course.id });
        }
    }, [course?.id]);

    const filteredAnnouncements = data.filter((announcement) =>
        announcement.message.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.container}>
            <PageHeader heading="Announcements"/>
            

            <List
                itemLayout="horizontal"
                dataSource={filteredAnnouncements}
               className={styles.announcementList}
                renderItem={(item) => (
                    <List.Item className={styles.announcementListItem}>
                    <h5 className={styles.announcementTitle}>
                        {item.title}
                    </h5>
                    <p className={styles.announcementMsg}>{item.message}</p>
                    <div className={styles.announcementByDiv}>
                        <p>Announce By: <br/><strong>{item.created_by || 'Unknown'}</strong></p>
                        <p>Announced At: <br /><strong>{formattedDateTime(item.created)}</strong></p>
                    </div>
                    {/* <List.Item.Meta
                        className={styles.announcementByIcon}
                        avatar={<Avatar style={{backgroundColor: '#f56a00'}}>{item.created_by ? item.created_by.charAt(0) : 'A'}</Avatar>}
                        title={<Text strong>{item.created_by || 'Unknown'}</Text>}
                        description={`${formattedDateTime(item.created)}`}
                    /> */}
                      
                    </List.Item>
                )}
            />
        </div>
    );
};

export default AnnouncementsCourse;
