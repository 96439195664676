import React, {useState} from 'react';
import {Button, Drawer, Menu} from 'antd';
import {MdOutlineMenuBook} from 'react-icons/md';
import {PiStudentFill} from 'react-icons/pi';
import {
    RiCoupon3Line,
    RiDiscountPercentLine,
    RiFileListLine,
    RiQuestionnaireLine,
    RiSettings3Line,
    RiStoreLine
} from 'react-icons/ri';
import {SiSpeedtest} from 'react-icons/si';
import {GiTeacher} from 'react-icons/gi';
import {LuArrowRightLeft, LuLayoutDashboard} from 'react-icons/lu';
import {BiTestTube} from 'react-icons/bi';
import {FaUsers} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {TbReportMoney} from "react-icons/tb";
import {useAuth} from "../../context/AuthContext";
import {FaBars, FaFileInvoiceDollar} from 'react-icons/fa6';
import {hasPermission, UserRole} from "../../utils/Role";
import {usePermissions} from "../../context/PermissionsContext";
import { TfiAnnouncement } from "react-icons/tfi";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { GiBookshelf } from "react-icons/gi";
import { IoMdNotificationsOutline } from 'react-icons/io';
import { GrFlag } from "react-icons/gr";
import styles from './sidebar.module.scss';

const useMediaQuery = (query: string): boolean => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    React.useEffect(() => {
        const media = window.matchMedia(query);
        const listener = () => setMatches(media.matches);
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [query]);

    return matches;
};


const {SubMenu} = Menu;

type MenuItem = {
    key: string;
    label: string;
    path?: string; // path can be undefined
    icon?: JSX.Element;
    children?: JSX.Element
    subMenuItems?: MenuItem[];
};

const Sidebar: React.FC = () => {
    const {logout, user} = useAuth();
    const [current, setCurrent] = useState<string>('');
    const {permissions} = usePermissions();
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    // Explicitly type adminMenuItems as MenuItem[]
    const adminMenuItems: MenuItem[] = [
        {key: 'dashboard', label: 'Dashboard', path: '/', icon: <LuLayoutDashboard/>},
        {key: 'courses', label: 'Courses', path: '/courses', icon: <MdOutlineMenuBook/>},
        {key: 'students', label: 'Students', path: '/students', icon: <PiStudentFill/>},

        {key: 'fee-record', label: 'Fee Record', path: '/fee-record', icon: <TbReportMoney/>},
        {key: 'fee-structure', label: 'Fee Structure', path: '/fee-structure', icon: <FaFileInvoiceDollar/>},


        {key: 'batches', label: 'Batches', path: '/batches', icon: <GiTeacher/>},
        {key: 'coupons', label: 'Coupons', path: '/coupons', icon: <RiCoupon3Line/>},
        {key: 'quiz', label: 'Quiz', path: '/quiz', icon: <SiSpeedtest/>},
        // { key: 'quiz', label: 'Quiz', path: '/quiz', icon: <IoExtensionPuzzleOutline /> },
        {key: 'leads', label: 'Lead & Inquiries', path: '/leads', icon: <RiQuestionnaireLine/>},
        {key: 'products', label: 'Products', path: '/products', icon: <BiTestTube/>},
        {key: 'transactions', label: 'Transactions', path: '/transactions', icon: <LuArrowRightLeft/>},
        // { key: 'free-material', label: 'Free Material', path: '/free-material', icon: <RiDiscountPercentLine /> },
        {key: 'free-resource', label: 'Free Resource', path: '/free-resource', icon: <RiDiscountPercentLine/>},
        {key: 'users', label: 'Team Members', path: '/users', icon: <FaUsers/>},
        {key: 'reports', label: 'Report', path: '/reports', icon: <RiFileListLine/>},
        {key: 'chat', label: 'Chat', path: '/chat', icon: <IoChatbubbleEllipsesOutline/>},
        {key: 'notifications', label: 'Notifications', path: '/notifications', icon:  <IoMdNotificationsOutline/>},
        {key: 'banner', label: 'Banner', path: '/banner', icon:  <GrFlag/>},
        {key: 'settings', label: 'Settings', path: '/settings', icon: <RiSettings3Line/>},
       
    ];

    const filteredMenuItems = adminMenuItems.filter((menuItem) =>
        menuItem.label === 'Dashboard' || hasPermission('view', menuItem.label, permissions)
    );

    // studentMenuItems is already typed as MenuItem[]
    const studentMenuItems: MenuItem[] = [
        {key: 'dashboard', label: 'Dashboard', path: '/dashboard', icon: <LuLayoutDashboard/>},
        {
            key: 'batch',
            label: 'Batch',
            path: '/student-batches',
            icon: <GiTeacher/>,

        },
        {
            key: 'course',
            label: 'Course',
            path: '/student-courses',
            icon: <MdOutlineMenuBook/>,

        },
        {
            key: 'digital_product',
            label: 'Digital Product',
            path: '/digital-products',
            icon: <TbDeviceDesktopAnalytics/>,

        },
        {
            key: 'physical_product',
            label: 'Physical Product',
            path: '/physical-products',
            icon: <GiBookshelf/>,

        },
        {
            key: 'free-resources',
            label: 'Free Resources',
            path: '/student-free-resources',
            icon: <RiDiscountPercentLine/>,

        }, {
            key: 'transactions',
            label: 'Transactions',
            path: '/student-transactions',
            icon: <LuArrowRightLeft/>,

        },
        {key: 'fee-record', label: 'Fee Record', path: '/student-fee-record', icon: <TbReportMoney/>},
        {key: 'announcements', label: 'Announcements', path: '/announcements', icon: <TfiAnnouncement/>},
    ];

    const menuItems: MenuItem[] = user!.userRole !== UserRole.STUDENT ? filteredMenuItems : studentMenuItems;

    const handleMenuClick = (e: any) => {
        setCurrent(e.key);
        setDrawerVisible(false);
    };

    return (
        <div>
            {isSmallScreen ? (
                <>
                    <Button
                        onClick={() => setDrawerVisible(!drawerVisible)}
                        className={styles.toggleButton}
                    >
                        <FaBars />
                    </Button>
                    <Drawer
                        placement="left"
                        onClose={() => setDrawerVisible(false)}
                        open={drawerVisible}
                        rootClassName={styles.mobileSidebar}
                    >
                        <Menu
                            onClick={handleMenuClick}
                            selectedKeys={[current]}
                            mode="inline"
                            className={styles.sidebarMenu}
                        >
                            {menuItems.map((item) =>
                                item.subMenuItems ? (
                                    <SubMenu key={item.key} icon={item.icon} title={item.label}>
                                        {item.subMenuItems.map((subItem) => (
                                            <Menu.Item key={subItem.key}>
                                                <Link to={subItem.path || ''}>
                                                    {subItem.icon} {subItem.label}
                                                </Link>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={item.key} icon={item.icon}>
                                        <Link to={item.path || ''}>{item.label}</Link>
                                    </Menu.Item>
                                )
                            )}
                        </Menu>
                    </Drawer>
                </>
            ) : (
        <div className={styles.sidebarContainer}>
            <Menu
                onClick={handleMenuClick}
                selectedKeys={[current]}
                mode="inline"
            >
                {menuItems.map((item) =>
                    item.subMenuItems ? (
                        <SubMenu
                            key={item.key}
                            icon={item.icon}
                            title={item.label}
                        >
                            {item.subMenuItems.map((subItem: MenuItem) => (
                                <Menu.Item key={subItem.key}>
                                    <Link to={subItem.path || ''}>
                                        {subItem.icon} {subItem.label}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Menu.Item key={item.key} icon={item.icon}>
                            <Link to={item.path || ''}>{item.label}</Link>
                        </Menu.Item>
                    )
                )}
            </Menu>
        </div>
       
    )}
        </div>
    );
};

export default Sidebar;
