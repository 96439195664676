import React, {useState} from "react";
import {Button, Drawer, Form, Input, message, Select} from "antd";
import {useFormHandler} from "../../../utils/useFormHandler";
import {createTestSeriesCategory, updateTestSeriesCategory} from "../../../api/testSeries/testSeriesCategories";
import styles from "../../CourseCategories/courseCategories.module.scss";

const {Option} = Select;

interface AddCategoryDrawerProps {
    open: boolean;
    category: any; // Use a more specific type if possible
    onClose: () => void;
}

const AddTestSeriesCategoryDrawer: React.FC<AddCategoryDrawerProps> = ({open, category, onClose}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = useFormHandler(
        (values: any) => {
            if (category) {
                // If category exists, update the existing category using its ID
                return updateTestSeriesCategory(category.id, values);
            } else {
                // Otherwise, create a new category
                return createTestSeriesCategory(values);
            }
        },
        form,
        () => {
            message.success(category ? 'Category updated successfully!' : 'Category created successfully!');
            onClose();
        },
        () => {

        },
        setLoading
    );


    return (
        <Drawer
            title={category ? "Edit Category" : "Add Category"}
            placement="right"
            closable={true}
            onClose={onClose}
            open={open}
            width={400}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Category Name"
                    name="title"
                    rules={[{required: true, message: "Please input the category name!"}]}
                >
                    <Input placeholder="Enter category name"/>
                </Form.Item>

                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default AddTestSeriesCategoryDrawer;
