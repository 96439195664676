import React, { useEffect, useState } from "react";
import { Button, Checkbox, Drawer, Form, Input, message, Select } from "antd";
import styles from "../../components/add-test-series-drawer/addTestSeriesDrawer.module.scss";
import ImageUpload from "../uploadField";
import { useFormHandler } from "../../utils/useFormHandler";
import { createBanner, updateBanner } from "../../api/banner/banner";


interface AddBannerDrawerProps {
    onClose: () => void;
    open: boolean;
    banner?: any;
}
const BannerDrawer: React.FC<AddBannerDrawerProps> = ({ onClose, open, banner }) => {
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false)
    const onFinish = useFormHandler(
        async (values) => {
            banner ?
                await updateBanner(banner.id, values) :
                await createBanner(values)
        },
        form,
        () => {
            message.success(banner ? 'Banner updated successfully!' : 'Banner created successfully!');
            onClose();
        },
        () => {

        },
        setSubmitLoading
    );

    useEffect(() => {
        if (banner) {
            form.setFieldsValue({
                ...banner,
            })
        }
    }, [])

    return (
        <Drawer
            title={banner ? "Edit Banner" : "Add Banner"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout={"vertical"}
                className={styles.form}>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Please input Title" }]}
                >
                    <Input placeholder="Enter Title" />
                </Form.Item>
                <ImageUpload form={form} name="image" title={'Thumbnail'} />
                <Form.Item
                    label="Type"
                    name="link"
                    rules={[{ required: true, message: "Please Enter Type" }]}
                >
                    <Input placeholder="Enter Type" />
                </Form.Item>

                <Form.Item
                    label="Action"
                    name="action"
                >
                    <Input placeholder="Enter Action" />
                </Form.Item>

                <Form.Item
                    name="is_active"
                    initialValue={true}
                    valuePropName="checked"
                >
                    <Checkbox> Active </Checkbox>
                </Form.Item>

                <Form.Item
                    label="Expiry Date"
                    name="expiry_date"
                >
                    <Input type="date" />
                </Form.Item>
                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Close</Button>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        {banner ? "Update" : "Submit"}
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default BannerDrawer;
