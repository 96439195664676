import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

interface LiveViewIFrameProps {
}

const LiveViewIFrame: React.FC<LiveViewIFrameProps> = () => {
    const location = useLocation(); // Access state passed via navigate
    const navigate = useNavigate();
    const url = location.state?.url; // Extract the URL from state

    if (!url) {
        // If no URL is provided, redirect back to the parent component
        navigate("/dashboard/", {replace: true});
        return null;
    }

    return (
        <iframe
            src={`${url}?iframe=true`}
            title="Fullscreen Viewer"
            allow="camera ; microphone ; fullscreen ; display-capture ; autoplay *;"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                border: "none",
                zIndex: 1000,
            }}
        ></iframe>
    );
}

export default LiveViewIFrame;
