// StudentHeader.tsx
import React, {useState} from 'react';
import {Button} from 'antd';
import {IoMdNotificationsOutline} from 'react-icons/io';
import styles from './header.module.scss';
import NotificationViewDrawer from "../../components/notification-view-drawer/NotificationViewDrawer";


const StudentHeader: React.FC = () => {
    const [openNotificationDrawer, setOpenNotificationDrawer] = useState<boolean>(false);
    return (
        <div className={styles.actionBtns}>
            <Button onClick={() => setOpenNotificationDrawer(true)}>
                <IoMdNotificationsOutline/>
            </Button>

            {openNotificationDrawer &&
                <NotificationViewDrawer
                    open={openNotificationDrawer}
                    onClose={() => setOpenNotificationDrawer(false)}
                />}
        </div>
    );
};

export default StudentHeader;
