// Define the interface for a Test Series Category
import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

export interface TestSeriesCategory {
    id: number;
    title: string;
    description?: string; // Optional field
}

// Define the request structure for creating or updating a category
export interface CreateOrUpdateCategoryRequest {
    name: string;
    description?: string; // Optional field
}

export const useTestSeriesCategoryList = () => {
    return useFetchList<TestSeriesCategory>("/api/test-series/categories/");
};

// Function to fetch details of a specific category
export const fetchCategoryDetail = async (categoryId: number): Promise<TestSeriesCategory> => {
    const response = await apiClient.get<TestSeriesCategory>(`/api/test-series/categories/${categoryId}/`);
    return response.data;
};

// Function to create a new category
export const createTestSeriesCategory = async (data: CreateOrUpdateCategoryRequest): Promise<TestSeriesCategory> => {
    const response = await apiClient.post<TestSeriesCategory>('/api/test-series/categories/', data);
    return response.data;
};

// Function to update an existing category
export const updateTestSeriesCategory = async (categoryId: number, data: CreateOrUpdateCategoryRequest): Promise<TestSeriesCategory> => {
    const response = await apiClient.put<TestSeriesCategory>(`/api/test-series/categories/${categoryId}/`, data);
    return response.data;
};

// Function to delete a specific category
export const deleteCategory = async (categoryId: number): Promise<void> => {
    await apiClient.delete(`/api/test-series/categories/${categoryId}/`);
};
