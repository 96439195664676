import React from 'react';
import { Form, TimePicker, Input, FormInstance } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface CustomTimePickerProps {
    form: FormInstance;
    name: string;
    field_name: string;
    label: string;
    rules?: any[];
    timeFormat?: string;
    style?: React.CSSProperties;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
    form,
    name,
    field_name,
    label,
    rules,
    timeFormat = 'HH:mm:ss',
    style = {},
}) => {
    const handleTimeChange = (time: Dayjs | null) => {
        if (time) {
            const formattedTime = time.format(timeFormat);
            form.setFieldsValue({ [name]: formattedTime });
        } else {
            form.setFieldsValue({ [name]: null });
        }
    };

    return (
        <>
            <Form.Item name={field_name} label={label} rules={rules} style={style}>
                <TimePicker
                    style={{ width: '100%' }}
                    format={timeFormat}
                    onChange={handleTimeChange}
                />
            </Form.Item>
            <Form.Item name={name} style={{ display: 'none' }}>
                <Input />
            </Form.Item>
        </>
    );
};

export default CustomTimePicker;
