import React, {useState} from 'react';
import {Button, Form, message, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {uploadFile} from "../../api/imageUpload/upload";
import {useFormHandler} from "../../utils/useFormHandler";
import type {UploadChangeParam} from 'antd/lib/upload';
import type {RcFile, UploadFile} from 'antd/es/upload/interface';
import {BASE_URL} from "../../utils/apiClient";
import styles from "./uploadImage.module.scss"
import cn from "classnames"

interface ImageUploadProps {
    form: any; // Replace `any` with the actual type if you're using a type for your form
    name: string;
    title?: string;
    required?: boolean;
    disabled?: boolean;
    file_extensions?: string[];
    showInstruction?:boolean;
}

const imageExtensions = ['jpeg', 'jpg', 'png']

const UploadField: React.FC<ImageUploadProps> = ({
                                                     form, name,
                                                     title = "",
                                                     required = true,
                                                     disabled = false,
                                                     file_extensions = imageExtensions,
                                                     showInstruction=true,
                                                 }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [imageUrl, setImageUrl] = useState<string>('');
    const maxSize = 10 * 1024 * 1024;

    const handleFileChange = (info: UploadChangeParam) => {
        if (info.fileList.length > 0) {
            const fileExtension = info.file.name.split('.').pop();
            const isValidExtension = file_extensions.length === 0 || file_extensions.includes(fileExtension!);

            const fileSize = info.file.size ?? 0;
            if (fileSize > maxSize) {
                message.error(`The file is too large! Please upload a file smaller than ${maxSize / 1024 / 1024} MB.`);
                return;
            }

            if (!isValidExtension) {
                message.error(`You can only upload ${file_extensions.join(', ')} files!`);
                return isValidExtension
            }
            onFinish(info)

        } else {
            message.success("File removed successfully")
            setImageUrl('')
            form.setFieldsValue({[name]: null});
            setFileList(info.fileList.slice(-1));
        }
    };

    // Function to handle form submission
    const onFinish = useFormHandler(
        (info: UploadChangeParam) => {
            const formData = new FormData();
            formData.append('file', info.file as RcFile); // Append the file for upload
            setFileList(info.fileList.slice(-1));
            return uploadFile(formData);
        },
        form,
        (response: any) => { // Handle `response` type more specifically if you know its structure
            const fileUrl = response.file_url; // Get the uploaded file's URL
            setImageUrl(fileUrl);
            form.setFieldsValue({[name]: fileUrl}); // Set the uploaded image URL in the form field
            message.success('File uploaded successfully.');
        }
    );


    const isImageFile = () => {
        const existingFileUrl = form.getFieldValue(name);
        if (existingFileUrl && typeof existingFileUrl === 'string') {
            // Extract file extension from the URL and check if it's a valid image
            const fileExtension = existingFileUrl.split('.').pop()?.toLowerCase();
            if (imageExtensions.includes(fileExtension!)) {
                return true
            }
        }
    }

    return (
        <Form.Item
            label={`Add ${title ? title : name}`}
            name={name}
            valuePropName="file"
            className={styles.uploadComponent}
            rules={[{required: required, message: `Please upload a ${title ? title : name}!`}]}
        >
            <div className={styles.imgWithInstruction}>
                <Upload
                    listType="picture"
                    beforeUpload={() => false} // Prevent auto-upload
                    fileList={fileList}
                    disabled={disabled}
                    onChange={handleFileChange}

                    maxCount={1} // Restrict to 1 file only
                >
                    <Button icon={<UploadOutlined/>}>{`Select ${title ? title : name}`} </Button>
                </Upload>
                {showInstruction && <p className={styles.imageInstruction}>(Best fit image size is 1920 x 1080)</p>}
            </div>
            {isImageFile() ? (
                    <img
                        src={`${BASE_URL}${form.getFieldValue(name)}`}
                        alt={title}
                        style={{width: '100px', height: '100px', marginTop: '10px'}}
                    />
                ) :
                form.getFieldValue(name) ?
                    <a rel={'noopener,noreferrer'} href={`${BASE_URL}${form.getFieldValue(name)}`} target={'_blank'}>
                        View File</a>
                    :
                    null
            }
        </Form.Item>
    );
}


export default UploadField;
