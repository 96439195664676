import React, {useEffect, useState} from "react";
import {Button, Table, Tabs} from "antd";
import cn from "classnames";
import styles from "../../components/fee-structure/feeStructure.module.scss";
import AddFeeRecordModal from "./AddFeeRecordModal";
import {getFeesRecords} from "../../api/batch/batch";
import {formattedDateTime} from "../../utils/utils";
import { MdCurrencyRupee } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

const FeeRecords = () => {

    const [openAddFeeModal, setOpenAddFeeModal] = useState<boolean>(false);
    const [feesRecord, setFeesRecord] = useState<any>(null)
    const [activeTab, setActiveTab] = useState('1');
    const fetchRecords = async () => {
        try {
            const feesRecord = await getFeesRecords()
            setFeesRecord(feesRecord)
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchRecords()
    }, []);


    const unpaidColumns = [
        {title: 'Student Name', dataIndex: 'student_name', key: 'student_name'},
        {title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name'},
        {title: 'Amount', dataIndex: 'amount', key: 'amount',render:(amount:any)=> <span className={styles.amountSymbolTag}><MdCurrencyRupee /> {amount}</span>},
        {title: 'Due Date', dataIndex: 'due_date', key: 'due_date',  render: (date: any) => formattedDateTime(date)},
    ];

    const upcomingColumns = [
        {title: 'Student Name', dataIndex: 'student_name', key: 'student_name'},
        {title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name'},
        {title: 'Amount', dataIndex: 'amount', key: 'amount',render:(amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {amount}</span>},
        {
            title: 'Upcoming Due Date', dataIndex: 'due_date',
            key: 'due_date',
            render: (date: any) => formattedDateTime(date)
        },
    ];

    const paidColumns = [
        {title: 'Student Name', dataIndex: 'student_name', key: 'student_name'},
        {title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name'},
        {title: 'Installation Number', dataIndex: 'installment_number', key: 'installment_number'},
        {title: 'Amount', dataIndex: 'amount', key: 'amount',render:(amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {amount}</span>},
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (date: any) => formattedDateTime(date)
        },
    ];


    return (
        <div className={cn("container-fluid")}>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            {/*<Input*/}
                            {/*    placeholder="Search by name"*/}
                            {/*    prefix={<FiSearch/>}*/}
                            {/*    onChange={handleDebounceInputChange}*/}
                            {/*/>*/}

                            {/*<Select*/}
                            {/*    className={styles.sortSelect}*/}
                            {/*    onChange={handleSortChange}*/}
                            {/*    placeholder="Sort By"*/}
                            {/*    allowClear*/}
                            {/*>*/}
                            {/*    <Select.Option value="unpaid">Unpaid</Select.Option>*/}
                            {/*    <Select.Option value="paid">Paid</Select.Option>*/}
                            {/*    <Select.Option value="upcoming">Upcoming</Select.Option>*/}
                            {/*</Select>*/}
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                       <div className={styles.actionBtnDiv}>
                           <Button onClick={() => setOpenAddFeeModal(true)}
                                    type="primary"
                            >
                                <FaPlus/> Add Record
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <Tabs.TabPane tab="Unpaid Fees" key="1">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={unpaidColumns}
                                dataSource={feesRecord?.unpaid_fees}
                                // rowKey={(record) => `${record.student_name}-${record.due_date}`}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Upcoming Fees" key="2">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={upcomingColumns}
                                dataSource={feesRecord?.upcoming_fees}
                                // rowKey={(record) => `${record.student_name}-${record.due_date}`}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Paid Fees" key="3">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={paidColumns}
                                dataSource={feesRecord?.paid_fees}
                                // rowKey={(record) => `${record.student_name}-${record.payment_date}`}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
            </Tabs>
            <AddFeeRecordModal
                open={openAddFeeModal}
                onClose={() => setOpenAddFeeModal(false)}
            />
        </div>
    );
};

export default FeeRecords;
