// src/api/otp.ts
import apiClient from "../../utils/apiClient";

// Define the request data type for OTP verification
interface VerifyOtpRequestData {
    otp: string;
    reference_key: string;
}

// Define the response type for OTP verification
interface VerifyOtpResponse {
    refresh: string;
    access: string;
    message: string;
    role: string;
}

// Define the verifyOtp function
export const verifyOtp = async (values: VerifyOtpRequestData): Promise<VerifyOtpResponse> => {
    const response = await apiClient.post<VerifyOtpResponse>('/api/verify-otp/', values);
    return response.data;

};
