import React from 'react';
import {DatePicker, Form, FormInstance, Input} from 'antd';
import moment from 'moment';

const {RangePicker} = DatePicker;

interface CustomDatePickerProps {
    form: FormInstance;
    name: string; // its handing form setting value that will use in api
    label: string;
    field_name: string //its handing form field value
    rules?: any[];
    dateFormat?: string;
    disabledDate?: any;
    style?: React.CSSProperties;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
                                                               form,
                                                               name,
                                                               field_name,
                                                               label,
                                                               rules,
                                                               dateFormat = 'YYYY-MM-DD',
                                                               disabledDate,
                                                               style = {},
                                                           }) => {
    const handleDateChange = (date: moment.Moment | null) => {
        if (date) {
            const formattedDate = date.format(dateFormat);
            form.setFieldsValue({[name]: formattedDate});
        } else {
            form.setFieldsValue({[name]: null});
        }
    };

    return (
        <>
            <Form.Item name={field_name} label={label} rules={rules} style={style}>
                <DatePicker
                    style={{width: '100%'}}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                />
            </Form.Item>
            <Form.Item name={name} style={{display: 'none'}}>
                <Input/>
            </Form.Item>
        </>
    );
};

export default CustomDatePicker;
