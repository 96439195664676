import React from "react";
import styles from "./header.module.scss";
import cn from "classnames";
import {Images} from "../Images";
import {Avatar, Dropdown, Menu} from "antd";
import {LogoutOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {FaAngleDown} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import StudentHeader from "./StudentHeader";
import {UserRole} from "../../utils/Role";

interface Notification {
    id: number;
    title: string;
    description: string;
    avatar: string;
    time: string;
    unread: boolean;
}

const Header: React.FC = () => {
    const navigate = useNavigate();
    const {logout, user} = useAuth();


    const items = [
        {
            key: "account",
            icon: <SettingOutlined/>,
            label: "Account",
            onClick: () => navigate("/settings"),
        },
        {
            key: "profile",
            icon: <UserOutlined/>,
            label: "Profile",
            onClick: () => navigate("/profile"),
        },
        {
            key: "logout",
            icon: <LogoutOutlined/>,
            label: "Logout",
            onClick: () => {
                logout();
                navigate("/login");
            },
        },
    ];

    return (
        <header className={styles.headerContainer}>
            <div className={styles.headerProfile}>
                <div className={styles.userDiv}>
                    <Dropdown arrow overlay={<Menu items={items}/>} trigger={["click"]}>
                        <div className={styles.userDropdown} onClick={(e) => e.preventDefault()}>
                            <Avatar
                                style={{backgroundColor: "#674da1", verticalAlign: "middle"}}
                                size="large"
                                alt="User Image"
                            >
                                {user?.full_name.charAt(0).toUpperCase()}
                            </Avatar>
                            <div>
                                <h6>{user?.full_name}</h6>
                                <p>{user!.userRole}</p>
                            </div>
                            <FaAngleDown className={styles.dropdownIcon}/>
                        </div>
                    </Dropdown>
                </div>
            </div>
            <div className={cn(styles.headerContentDiv)}>
                <div className={cn(styles.logoDiv)}>
                    <img src={Images.logo} alt="Logo"/>
                </div>
                <div className={styles.headerActions}>
                    <div className={styles.actionBtns}></div>
                    {user!.userRole === UserRole.STUDENT && (
                        <StudentHeader/>
                    )}
                </div>
            </div>

        </header>
    );
};

export default Header;
