import React from 'react'
import styles from "./pageLoader.module.scss"
import { LiaSpinnerSolid } from "react-icons/lia";

export default function PageLoader() {
  return (
    <div className={styles.loaderMain}>
        <LiaSpinnerSolid />
    </div>
  )
}
