import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, InputNumber, message, Rate, Select } from "antd";
import styles from "../../components/add-test-series-drawer/addTestSeriesDrawer.module.scss"
import FooterButton from "../../components/FooterButton";
import { createBatchReviews, createCourseReviews } from "../../api/Reviews/StudentReviews";


interface AddReviewDrawerProps {
    onClose: () => void;
    open: boolean;
    type: "batch" | "course"
    course?: any;
    batch?: any;
}

const ReviewDrawer: React.FC<AddReviewDrawerProps> = ({ onClose, open, type, course, batch }) => {

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            if (type === "batch" && batch) {
                const response = await createBatchReviews({ ...values, batch: batch.id });
                message.success(response.message || "Review submitted successfully for batch!");
            } else if (type === "course" && course) {
                const response = await createCourseReviews({ ...values, course: course.id });
                message.success(response.message || "Review submitted successfully for course!");
            } else {
                message.error("Invalid type or missing data.");
                return;
            }

            form.resetFields();
            onClose();
        } catch (error) {
            message.error("Failed to submit the review. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer
            title={"Submit Review"}
            onClose={onClose}
            open={open}
            width={"40%"}
            className={styles.createUserDrawer}
        >
            <Form
                layout={"vertical"}
                form={form}
                onFinish={onFinish}
                className={styles.form}
            >
                <Form.Item
                    label="Title Description"
                    name="title"
                    rules={[{ required: true, message: "Please input Title Description " }]}
                >
                    <Input placeholder="Enter Title Description" />
                </Form.Item>

                <Form.Item
                    label="Reviews"
                    name="description"
                    rules={[{ required: true, message: "Please Enter Reviews" }]}
                >
                    <Input.TextArea placeholder="Enter Reviews" />
                </Form.Item>

                <Form.Item
                    name="rating"
                    label="Rating"
                    rules={[
                        {
                            required: true,
                            message: "Please Provide a Rating",
                        },
                        {
                            validator: (_, value) =>
                                value > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error("Rating at least 1 star")),
                        },
                    ]}
                >
                    <Rate />
                </Form.Item>
                <FooterButton loading={loading} onClose={onClose} submitButtonText={'Submit'} />
            </Form>
        </Drawer>
    );
}

export default ReviewDrawer;
