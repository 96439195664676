import styles from "./coupon.module.scss";
import cn from "classnames";
import PageHeader from "../../common/page-header/PageHeader";
import { Button } from "antd";
import { useState } from "react";
import CreateCoupon from "./coupon/CreateCouponDrawer";
import { CouponRequestData, deleteCoupon, useCouponList } from "../../api/coupon/couponApi";
import moment from "moment";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";

import {Action, ModuleName, Role, UserRole} from "../../utils/Role";


const Coupons = () => {
    const { data, fetchWithParams, pagination, loadMore, loadMoreLoading } = useCouponList();
    const [openCreateCoupon, setOpenCreateCoupon] = useState(false);
    const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<any>(null);
    const [selectedCoupon, setSelectedCoupon] = useState<CouponRequestData | null>(null);
    const { resetParams } = useFilterSortPaginate(fetchWithParams);

    const handleCreateCloseDrawer = () => {
        setSelectedCoupon(null)
        setOpenCreateCoupon(false)
        resetParams()
    }
    const onDeleteCoupon = async () => {
        setDeleteBtnLoading(true)
        try {
            await deleteCoupon(selectedCoupon?.id || null)
            setDeleteBtnLoading(false)
            setOpenDeleteWarning(false)
            setSelectedCoupon(null)
            resetParams()
        } catch (error) {
            setDeleteBtnLoading(false)
            console.log(error, 'error')
        }
    }
    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Coupons" />

            <section className={styles.actionBtnsSection}>
                <div className="col-sm-12 col-12">
                    <div className={styles.actionBtnDiv}>
                        <Button onClick={() => setOpenCreateCoupon(true)} type="primary">
                            <FaPlus /> Create Coupon
                        </Button>
                    </div>
                </div>
            </section>

            <section className={styles.coursesSection}>
                <div className="row">
                    {data && data.length > 0 ? (
                        data.map((coupon) => (
                            <div className="col-sm-3 col-12" key={coupon.id}>

                                <div className={styles.coupon}>
                                    {coupon.total_applied < 1 ? (<>
                                        <div className={styles.couponActionBtns}>
                                        <Role moduleName={ModuleName.COUPONS} action={Action.EDIT}>
                                            <span onClick={() => {
                                                setSelectedCoupon(coupon);
                                                setOpenCreateCoupon(true)
                                            }}>
                                                <CiEdit />

                                            </span>
                                            </Role>
                                            <Role moduleName={ModuleName.COUPONS} action={Action.DELETE}>
                                            <span onClick={() => {
                                                setSelectedCoupon(coupon);
                                                setOpenDeleteWarning(true)
                                            }}>
                                                <MdDeleteOutline />
                                            </span>
                                            </Role>
                                        </div>
                                    </>) : null}
                                    <div
                                        className={cn(
                                            styles["coupon-header"],
                                            coupon.is_visible? styles.activeCoupon: styles.inactiveCoupon
                                        )}
                                    >
                                        <h2>
                                            {coupon.discount_type === "fixed"
                                                ? `₹${coupon.discount_value} OFF`
                                                : `${coupon.discount_value}% OFF`}
                                        </h2>
                                    </div>
                                    <div className={styles["coupon-body"]}>
                                        <p>On orders over ₹{coupon.min_order_value}</p>
                                        <h6>
                                            Use code: <strong>{coupon.code}</strong>
                                        </h6>
                                    </div>
                                    <div className={styles["coupon-footer"]}>
                                        <p>
                                            {coupon.lifetime ? "Unlimited Validity" : `Valid until${" "}  ${moment(coupon.end_datetime).format("Do MMMM YYYY")}`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No coupons available</p>
                    )}
                    {pagination.next && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button type="primary" onClick={loadMore} disabled={loadMoreLoading}>
                                {loadMoreLoading ? 'Loading...' : 'Load More'}
                            </Button>
                        </div>
                    )}
                </div>

                {openCreateCoupon && (
                    <CreateCoupon
                        open={openCreateCoupon}
                        coupon={selectedCoupon}
                        onClose={handleCreateCloseDrawer}
                    />
                )}
                <DeleteWarningModal
                    open={openDeleteWarning}
                    onClose={() => {
                        setOpenDeleteWarning(false);
                        setSelectedCoupon(null)
                    }}
                    onConfirm={onDeleteCoupon}
                    okBtnLoading={deleteBtnLoading}
                    message={'Are you sure you want to delete this coupon?'}
                />
            </section>
        </div>
    );
};

export default Coupons;
