import React, { useState } from 'react';
import styles from "./profile.module.scss";
import { Button, Form, Input, message, Select } from 'antd';
import { AdminProfileRequestData, updateAdminProfile } from '../../api/profile/adminProfile/admin';
import { useFormHandler } from '../../utils/useFormHandler';


interface ProfessionalInformationProps {
    onClose: () => void;
    profile?: any;
}

const ProfessionalInformation: React.FC<ProfessionalInformationProps> = ({ onClose, profile }) => {
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode
    const [form] = Form.useForm();

    const onFinish = useFormHandler(
        async (values: AdminProfileRequestData) => {
            profile = { 'profile': values }
            return await updateAdminProfile(profile);
        },
        form,
        () => {
            message.success('Profile updated successfully!');
            setIsEditable(false)
            onClose();
        }
    );


    return (
        <div>
            <div className={styles.header}>
                <h2>Professional Information</h2>
                <Button type="default" onClick={() => setIsEditable(prev => !prev)}>
                    {isEditable ? "Cancel" : "Edit"}
                </Button>
            </div>

            <Form
                layout="vertical"
                className={styles.form}
                form={form}
                onFinish={onFinish}
                initialValues={profile.profile}
                disabled={!isEditable} // Disable the form if not editable
            >
                <div className="row">
                    {/* Left Column */}
                    <div className="col-sm-6 extended-column">
                        <Form.Item
                            label="Job Title"
                            name="job_title"

                        >
                            {isEditable ? (
                                <Input placeholder='Enter Job Title' />
                            ) : (
                                <span>{profile.profile.job_title || "N/A"}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Institution Name "
                            name="institution_name"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Institution Name" />
                            ) : (
                                <span>{profile.profile.institution_name || "N/A"}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Department"
                            name="department"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Department Name" />
                            ) : (
                                <span>{profile.profile.department || "N/A"}</span>
                            )}
                        </Form.Item>
                    </div>

                    <div className="col-sm-6 extended-column">

                        <Form.Item
                            label="Year of Experience"
                            name="years_of_experience"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Year of Experience" />
                            ) : (
                                <span>{profile.profile.years_of_experience || "N/A"}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Area of Expertise"
                            name="areas_of_expertise"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Area of Expertisee" />
                            ) : (
                                <span>{profile.profile.areas_of_expertise || "N/A"}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Professional Bio"
                            name="professional_bio"

                        >
                            {isEditable ? (
                                <Input placeholder="Enter Professional Bio" />
                            ) : (
                                <span>{profile.profile.professional_bio || "N/A"}</span>
                            )}
                        </Form.Item>
                    </div>
                </div>

                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? "Update" : "Submit"}
                        </Button>
                    </div>
                )}

            </Form>
        </div>
    );
}

export default ProfessionalInformation;
