import styles from "./dashboard.module.scss";
import cn from "classnames";
import ImageCard from "../../common/imageCard/ImageCard";
import PageHeader from "../../common/page-header/PageHeader";
import {useEffect, useState} from "react";
import CreateCourse from "../../components/create-course-drawer/CreateCourseDrawer";
import {useNavigate} from "react-router-dom";
import {useAvailableStudentCourseList, usePurchasedStudentCourseList} from "../../api/course/student/studentCourse";
import { BASE_URL } from "../../utils/apiClient";
import { useAuth } from "../../context/AuthContext";
import PageLoader from "../../common/page-loader/PageLoader";
import StatsCard from "../../common/StatsCard/StatsCard";
import { FcGraduationCap, FcReading } from "react-icons/fc";
import { GiTeacher } from "react-icons/gi";
import { MdCalendarMonth, MdOutlineMenuBook } from "react-icons/md";
import {
    getStudentBatchClasses,
 
    getStudentCourseClasses,
 
    useAvailableStudentBatchList,
   
} from "../../api/batch/student/studentBatch";
import { FaBook } from "react-icons/fa6";
import EnrollBatchModal from "../../components/enroll-batch-modal/EnrollBatchModal";
import moment from "moment";
import {
    getStudentDashboardMetrics,
    StudentDashboardMetrics
} from "../../api/widgets/widgets";
import { Avatar, Button, List, message, Popconfirm, Table, Tag } from "antd";



import { BsClockHistory } from "react-icons/bs";
import { IoMdCalendar } from "react-icons/io";
import { isDateExpired } from "../../utils/dateUtils";
import { formattedDateTime } from "../../utils/utils";
import { VideoCameraOutlined } from "@ant-design/icons";

interface Course {
    id: number;
    title: string;
    image: string;
    price: string;
    originalPrice: string;
    createdBy: string;
    downloads: number;
}


const StudentDashboardHome = () => {
    const navigate = useNavigate();
    const [openCreateCourse, setOpenCreateCourse] = useState(false);
    const {data} = useAvailableStudentCourseList()
    const [openPurchaseModal,setOpenPurchaseModal]=useState({visible:false,selectedData:null})
    const {data: availableBatches = []} = useAvailableStudentBatchList();
    const {user} = useAuth();
    const [batch, setBatch] = useState<any>(null);
    const [course, setCourse] = useState<any>(null);
   
    const {loading:purchasedLoading} = usePurchasedStudentCourseList()
    const [globalMetrics, setGlobalMetrics] = useState<StudentDashboardMetrics | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
 

 

    const batchClassesColumns = [
        {
            title: 'Batch',
            dataIndex: ['classes', 'batch'],
            key: 'batch'
        },
        {
            title: 'Faculty',
            dataIndex: ['classes', 'faculty'],
            key: 'faculty'
        },
        {
            title: 'Class Type',
            dataIndex: ['classes', 'class_type'],
            key: 'class_type',
            render: (class_type: string) => (
                <Tag color={class_type === 'regular' ? 'blue' : 'green'}>
                    {class_type === 'regular' ? 'Regular Class' : 'One-Time Class'}
                </Tag>
            )
        },
        {
            title: 'Time Slot (Day)',
            dataIndex: 'time_slot',
            key: 'time_slot',
            render: (time_slot: string) => (
                <Tag color="blue">{time_slot}</Tag>
            )
        },
        {
            title: 'Schedules',
            key: 'schedules',
            render: (_: any, record: any) => (
                <Table
                    dataSource={record.schedules}
                    pagination={false}
                    rowKey="id"
                    columns={[
                        {
                            title: 'Start Time',
                            dataIndex: 'start_time',
                            key: 'start_time'
                        },
                        {
                            title: 'End Time',
                            dataIndex: 'end_time',
                            key: 'end_time'
                        }
                    ]}
                    bordered
                />
            )
        },
    ]

 

    // Function to handle clicking on the New Course
    const handleNewCourseClick = (course: Course) => {
        // Navigate to the new course detail page with the course ID
        navigate(`/new-course/${course.id}`);
    };
     const fetchStudentGlobalMetrics = async () => {
        try {
            const metrics = await getStudentDashboardMetrics();
            setGlobalMetrics(metrics);
        } catch (err) {
            console.error('Error fetching student metrics:', err);
        } finally {
        }
    };

    useEffect(() => {
        fetchStudentGlobalMetrics()
      
    }, [])


    const fetchBatchDetails = async () => {

        try {
            const batchData = await getStudentBatchClasses();
            console.log(batchData,"batch data");
            setBatch(batchData);
           
        } catch (err) {
            setError('Failed to fetch batch details');
            console.error(err)
        } 
    };

    const fetchCourseDetails = async () => {

        try {
            const courseData = await getStudentCourseClasses();
            console.log(courseData,"course data");
            setCourse(courseData);
           
        } catch (err) {
            setError('Failed to fetch course details');
            console.error(err)
        } 
    };

    useEffect(() => {
        fetchBatchDetails()
        fetchCourseDetails()
    }, []);


    //------------live class
    // const { data:liveClassData } = getStudentBatchClasses(live_classes.id)

    const handleJoinClick = (item: any) => {
        if (item?.student_join_link) {
            navigate("/full-screen", { state: { url: item.student_join_link } });
        } else {
            alert("Host link is not available.");
        }
    };

      const handleRecordingLinkClick = (item: any) => {
        if (item?.recording_url) {
            window.open(item?.recording_url, '_blank', 'noopener,noreferrer');
        } else {
            alert("Host link is not available.");
        }
    };

  
    return (
        <div className={cn("container-fluid")}>
            {purchasedLoading && <PageLoader />}
            <PageHeader heading={`Welcome back, ${user?.full_name} !!`}/>
            <section className={styles.statSection}>
                <div className="row">
                    <div className="col-6 col-sm-3">
                        <StatsCard
                            icon={<FcReading color="#9E49E6" className="icon"/>}
                            iconBg="rgb(231, 244, 251)"
                            title={globalMetrics?.enrolled_courses}
                            description={"Course Purchased"}
                        />
                    </div>
                    <div className="col-6 col-sm-3">
                        <StatsCard
                            icon={<FcGraduationCap color="#49BD65" className="icon"/>}
                            iconBg="rgb(237, 248, 240)"
                            title={globalMetrics?.enrolled_batches}
                            description={"Batch Enrolled"}
                        />
                    </div>
                    <div className="col-6 col-sm-3">
                        <StatsCard
                            icon={<MdOutlineMenuBook color="#0A8FDC" className="icon"/>}
                            iconBg="rgb(231, 244, 251)"
                            title={globalMetrics?.available_courses}
                            description={"Available Courses"}
                        />
                    </div>
                    <div className="col-6 col-sm-3">
                        <StatsCard
                            icon={<GiTeacher color="#9E49E6" className="icon"/>}
                            iconBg="rgb(245, 237, 252)"
                            title={globalMetrics?.available_batches}
                            description={"Available Batches"}
                        />
                    </div>
                </div>
            </section>
            <section className={styles.liveClasses}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Upcoming Batch Live Classes</h4>
                    </div>
                      {/* <LiveClasses batch={batchDataDemo} /> */}
                      <List
                        itemLayout="horizontal"
                        dataSource={batch?.live_classes}
                        className={styles.liveClassDashboard}
                        locale={{ emptyText: <p className={styles.noDataText}>No scheduled class found!</p> }}
                        renderItem={(item:any) => {
                            const isExpired = isDateExpired(item?.date);
                            return (
                                <List.Item className={styles.listItem}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                className={styles.avatarIcon}
                                                icon={<VideoCameraOutlined />}
                                            />
                                        }
                                        title={<h3 className={styles.title}>{item?.title}</h3>}
                                        description={
                                            <div>
                                                <div className={styles.descDate}>
                                                    <IoMdCalendar /> <span>On: {formattedDateTime(item?.date)}</span>
                                                </div>

                                                <div className={styles.duration}>
                                                    <BsClockHistory /> Duration: 60 Minutes
                                                </div>
                                            </div>
                                        }
                                    />
                                    <div className={styles.actions}>
                                        <Popconfirm
                                            title="Are you sure you want to join?"
                                            onConfirm={() => handleJoinClick(item)} // Executes when confirmed
                                            okText="Yes"
                                            cancelText="No"
                                            disabled={isExpired} // Prevents showing Popconfirm if disabled
                                        >
                                            <Button
                                                type="primary"
                                                className={styles.joinButton}
                                                disabled={isExpired}
                                            >
                                                <BsClockHistory /> Join Now
                                            </Button>
                                        </Popconfirm>

                                        <Button
                                            type="default"
                                            className={styles.viewButton}
                                            disabled={!item.recording_url}
                                            onClick={() => handleRecordingLinkClick(item)}

                                        >
                                            <BsClockHistory /> View Class Video
                                        </Button>
                                       
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </div>
            </section>

         {/* course live classes */}
            <section className={styles.liveClasses}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Upcoming Course Live Classes</h4>
                    </div>
                      {/* <LiveClasses batch={batchDataDemo} /> */}
                      <List
                        itemLayout="horizontal"
                        dataSource={course?.live_classes}
                        className={styles.liveClassDashboard}
                        locale={{ emptyText: <p className={styles.noDataText}>No scheduled class found!</p> }}
                        renderItem={(item:any) => {
                            const isExpired = isDateExpired(item?.date);
                            return (
                                <List.Item className={styles.listItem}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                className={styles.avatarIcon}
                                                icon={<VideoCameraOutlined />}
                                            />
                                        }
                                        title={<h3 className={styles.title}>{item?.title}</h3>}
                                        description={
                                            <div>
                                                <div className={styles.descDate}>
                                                    <IoMdCalendar /> <span>On: {formattedDateTime(item?.date)}</span>
                                                </div>

                                                <div className={styles.duration}>
                                                    <BsClockHistory /> Duration: 60 Minutes
                                                </div>
                                            </div>
                                        }
                                    />
                                    <div className={styles.actions}>
                                        <Popconfirm
                                            title="Are you sure you want to join?"
                                            onConfirm={() => handleJoinClick(item)} // Executes when confirmed
                                            okText="Yes"
                                            cancelText="No"
                                            disabled={isExpired} // Prevents showing Popconfirm if disabled
                                        >
                                            <Button
                                                type="primary"
                                                className={styles.joinButton}
                                                disabled={isExpired}
                                            >
                                                <BsClockHistory /> Join Now
                                            </Button>
                                        </Popconfirm>

                                        <Button
                                            type="default"
                                            className={styles.viewButton}
                                            disabled={!item.recording_url}
                                            onClick={() => handleRecordingLinkClick(item)}

                                        >
                                            <BsClockHistory /> View Class Video
                                        </Button>
                                       
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </div>
            </section>

            <section className={styles.offlineClasses}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Offline  Classes</h4>
                    </div>
                    <div className={styles.tableDiv}>
                        <Table rowKey={(record: any) => `${record.classes?.id}-${record.time_slot}`}
                               columns={batchClassesColumns} pagination={false} dataSource={batch?.offline_classes }
                               />
                    </div>
                </div>
            </section>
            {/* Purchase Courses Section */}
            <section className={styles.availableBatchesSection}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Join New Batches!!</h4>
                    </div>
                    {availableBatches.length === 0 ? (
                         <div className={cn(styles.noDataDiv,"col-12")}><p>No New Batches Found</p></div>
                    ) : (availableBatches.map((batch:any) => (
                        <div className="col-sm-4 col-12" key={batch.id}>
                            <div className={styles.batchDiv} onClick={()=>{setOpenPurchaseModal({visible:true,selectedData:batch});}}>
                                <div className={styles.batchCardImg}>
                                    <img src={`${BASE_URL}${batch.thumbnail}`} />
                                </div>
                                <div className={styles.batchContent}>
                                    <div className={styles.heading}>{batch.name}</div>
                                    <div className={styles.smallContent}>
                                        <p title="starts from"><MdCalendarMonth /> {moment(batch?.start_date).format("DD MMM YYYY")}</p>
                                        <p title="subject"><FaBook /> {batch.subject}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        // <div className="col-sm-4 col-12" key={batch.id}>
                        //     <div onClick={()=>{setOpenPurchaseModal({visible:true,selectedData:batch});}} className={styles.batchDiv}>
                        //         {/* <div className={styles.batchCardImg}>
                        //             <img src={Images.availableBatchImg} />
                        //         </div> */}
                        //         <div className={styles.batchContent}>
                        //             <div className={styles.heading}>{batch.name.toUpperCase()}</div>
                        //             <div className={styles.smallContent}>
                        //                 <p title="starts from"><MdCalendarMonth /> {batch?.start_date}</p>
                        //                 <p title="subject"><FaBook /> {batch.subject}</p>
                        //             </div>
                        //         </div>
                        //         {/* <div className={styles.footer}>
                        //             <div className={styles.subjectName}><FiBook/> {batch.created_by}</div>
                        //         </div> */}
                        //     </div>
                        // </div>
                    )))}
                </div>
            </section>

            {/* <section className={styles.purchaseCoursesSection}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Purchase Course</h4>
                    </div>
                    {availableBatches.length>0 ? availableBatches.map((course: any) => (
                        <div key={course.id} className="col-sm-3 col-12" onClick={() => handlePurchaseClick(topTrendingCourses[0])}>
                            <ImageCard
                                className={styles.courseCard}
                                title={course.name}
                                image={`${BASE_URL}${course.thumbnail}`}
                            />
                        </div>
                    )):
                    <p>No Data</p>
                }


                </div>
            </section> */}

            {/* New Courses Section */}
            <section className={styles.coursesSection}>
                <div className="row">
                    <div className="col-12">
                        <h4 className={styles.sectionHeading}>Purchase New Courses!!</h4>
                    </div>
                    {data.length>0?data.map((course: any, index) => ( // Start from the second course
                        <div className="col-sm-3 col-12" key={course.id} onClick={() => handleNewCourseClick(course)}>
                            <ImageCard className={styles.courseCard} title={course.name}
                                       image={`${BASE_URL}${course.thumbnail}`}>
                                <div className={styles.tagsDiv}>
                                    <h6 className={styles.priceCourse}>
                                        Price: <span>{course.effective_price ? `₹${course.effective_price}` : 'Free'}</span>
                                        <div>{course.discount && course.effective_price ? `₹${course.price}` : ''}</div>
                                    </h6>
                                </div>
                                <p className={styles.createdByTag}>
                                    Created By: <span>{course.created_by}</span>
                                </p>
                            </ImageCard>
                        </div>
                    )):
                    <p>No Data</p>
                }
                </div>
            </section>
            {openPurchaseModal.visible && <EnrollBatchModal
                open={openPurchaseModal.visible}
                data={openPurchaseModal.selectedData}
                onClose={()=>setOpenPurchaseModal({visible:false,selectedData:null})}
             />}
            <CreateCourse open={openCreateCourse} course={null} onClose={() => setOpenCreateCourse(false)}/>
        </div>
    );
};

export default StudentDashboardHome;
