export enum SettingsKeys {
    GROUP_STUDY = "GROUP_STUDY",
    PARENT_LOGIN = "PARENT_LOGIN",
    CONFIGURE_GRADING = "CONFIGURE_GRADING",
    ANTI_PIRACY_WATERMARK_VIDEOS = "ANTI_PIRACY_WATERMARK_VIDEOS",
    ANTI_PIRACY_WATERMARK_LIVE = "ANTI_PIRACY_WATERMARK_LIVE",
    ANTI_PIRACY_WATERMARK_IMAGE_UPLOAD = "ANTI_PIRACY_WATERMARK_IMAGE_UPLOAD",
    ATTENDANCE_CRITERIA = "ATTENDANCE_CRITERIA",
    RAZORPAY_API_BASE_URL = "RAZORPAY_API_BASE_URL",
    RAZORPAY_API_KEY = "RAZORPAY_API_KEY",
    RAZORPAY_API_SECRET = "RAZORPAY_API_SECRET",
    SMS_GATEWAY_API_BASE_URL = "SMS_GATEWAY_API_BASE_URL",
    SMS_GATEWAY_API_KEY = "SMS_GATEWAY_API_KEY",
    SMS_GATEWAY_ROUTE = "SMS_GATEWAY_ROUTE",
    SMS_GATEWAY_SENDER_ID = "SMS_GATEWAY_SENDER_ID",
    SMS_GATEWAY_TEMPLATES = "SMS_GATEWAY_TEMPLATES",
    SMTP_MAIL_HOST = "SMTP_MAIL_HOST",
    SMTP_MAIL_PORT = "SMTP_MAIL_PORT",
    SMTP_MAIL_USERNAME = "SMTP_MAIL_USERNAME",
    SMTP_MAIL_PASSWORD = "SMTP_MAIL_PASSWORD",
    SMTP_MAIL_FROM_ADDRESS = "SMTP_MAIL_FROM_ADDRESS",
    SMTP_MAIL_FROM_NAME = "SMTP_MAIL_FROM_NAME",
    SMTP_MAIL_BCC_LIST = "SMTP_MAIL_BCC_LIST",
    MERITHUB_API_BASE_URL = "MERITHUB_API_BASE_URL",
    MERITHUB_CLIENT_ID = "MERITHUB_CLIENT_ID",
    MERITHUB_CLIENT_SECRET = "MERITHUB_CLIENT_SECRET",
    FIREBASE_API_KEY = "FIREBASE_API_KEY",
    FIREBASE_AUTH_DOMAIN = "FIREBASE_AUTH_DOMAIN",
    FIREBASE_PROJECT_ID = "FIREBASE_PROJECT_ID",
    FIREBASE_STORAGE_BUCKET = "FIREBASE_STORAGE_BUCKET",
    FIREBASE_MESSAGING_SENDER_ID = "FIREBASE_MESSAGING_SENDER_ID",
    FIREBASE_APP_ID = "FIREBASE_APP_ID",
    TAX_SETUP = "TAX_SETUP",
    SMS_EMAIL_SCHEDULERS = "SMS_EMAIL_SCHEDULERS",
    LIVE_CHAT_CREATE_GROUP = "LIVE_CHAT_CREATE_GROUP",
    LIVE_CHAT_DOCUMENT_SHARE = "LIVE_CHAT_DOCUMENT_SHARE",
    LIVE_CHAT_IMAGE_SHARE = "LIVE_CHAT_IMAGE_SHARE",
    LIVE_CHAT_AUDIO_SHARE = "LIVE_CHAT_AUDIO_SHARE",
    // New company information keys
    BILLED_BY = "BILLED_BY",
    GSTIN = "GSTIN",
    COMPANY_ADDRESS = "COMPANY_ADDRESS",
    PAN = "PAN",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    INVOICE_PRE_FIX = "INVOICE_PRE_FIX",
    INVOICE_NUMBER_COUNTER = "INVOICE_NUMBER_COUNTER",
    // New bank details keys
    ACCOUNT_NAME = "ACCOUNT_NAME",
    ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
    IFSC = "IFSC",
    SWIFT_CODE = "SWIFT_CODE",
    BANK = "BANK",
    ADDRESS = "ADDRESS",
    PINCODE = "PINCODE",
    CITY = "CITY",
    STATE = "STATE",
    COMPANY_LOGO = "COMPANY_LOGO",
    ANTI_PIRACY_WATERMARK_VIDEO = 'ANTI_PIRACY_WATERMARK_VIDEO',
    ANTI_PIRACY_WATERMARK_LIVE_CLASS = 'ANTI_PIRACY_WATERMARK_LIVE_CLASS',
    ANTI_PIRACY_WATERMARK_IMAGE = 'ANTI_PIRACY_WATERMARK_IMAGE',
    SAFETY_NET_CHECK = 'SAFETY_NET_CHECK',
    DEVICE_RESTRICTION = 'DEVICE_RESTRICTION',
    ALLOW_ON_DESKTOP = 'ALLOW_ON_DESKTOP',
    SHOW_RECORDED_CLASS_ON_WEB = 'SHOW_RECORDED_CLASS_ON_WEB',
    GST_PERCENTAGE="GST_PERCENTAGE",
    INTERNET_CHARGES="INTERNET_CHARGES",
    PLATFORM_FEE="PLATFORM_FEE",
    MAIL_ID="MAIL_ID",
    MAIL_PASSWORD="MAIL_PASSWORD",
    MAIL_PORT="MAIL_PORT",
    MAIL_SERVER="MAIL_SERVER",
    MAIL_NAME="MAIL_NAME",



}
