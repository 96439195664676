import useFetchList from "../../../utils/listClient";
import apiClient from "../../../utils/apiClient";
import {TestSeriesProps} from "../../../types/testSeries"; // Assuming you have this type defined for Test Series

// Fetch the list of purchased test series for the student
export const usePurchasedStudentTestSeriesList = (default_fetch: boolean = true) => {
    return useFetchList<TestSeriesProps>("/api/test-series/student/purchased-test-series/", default_fetch);
};

// Fetch the list of available test series for the student
export const useAvailableStudentTestSeriesList = (default_fetch: boolean = true) => {
    return useFetchList<TestSeriesProps>("/api/test-series/student/available-test-series/", default_fetch);
};

// Fetch the details of a specific purchased test series for the student
export const purchaseDetailStudentTestSeries = async (testSeriesId: string) => {
    const response = await apiClient.get<TestSeriesProps>(`/api/test-series/student/purchased-test-series/${testSeriesId}/`);
    return response.data;
};

// Fetch the details of a specific available test series for the student
export const availableDetailStudentTestSeries = async (testSeriesId: string) => {
    const response = await apiClient.get(`/api/test-series/student/available-test-series/${testSeriesId}/`);
    return response.data;
};
