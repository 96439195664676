import { Modal, Tag } from 'antd';
import React from 'react';
import styles from './leadInfo.module.scss';
import cn from 'classnames';
import {EnquiryRequestData} from "../../api/lead/leadApi";

interface LeadInfoProps {
  open: boolean;
  onClose: () => void;
  leadInfo?:  EnquiryRequestData|null;
}

const LeadInfo: React.FC<LeadInfoProps> = ({ open, onClose, leadInfo }) => {
  return (
    <Modal
      title={"Lead Details"}
      centered
      open={open}
      onOk={onClose}
      onCancel={onClose}
      footer={false}
      className={styles.leadInfo}
    >
      {leadInfo ? (
        <div className="row">
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Name: </label>
            <span>{leadInfo.student_name}</span>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Assigned Lead: </label>
            <span>{leadInfo.assign_lead}</span>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Phone: </label>
            <span>{leadInfo.phone_number}</span>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Source: </label>
            <span>{leadInfo.source}</span>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Follow-up Type: </label>
            <span>{leadInfo.followup_type}</span>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Status: </label>
            <Tag color="blue">
              {leadInfo.enquiry_status}
            </Tag>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Follow-up Date: </label>
            <span>{new Date(leadInfo.followup_date).toLocaleDateString()}</span>
          </div>
          <div className={cn(styles.details, "col-sm-6 col-12")}>
            <label>Enquiry Date: </label>
            <span>{new Date(leadInfo.enquiry_date).toLocaleDateString()}</span>
          </div>
          <div className={cn(styles.details, "col-12")}>
            <label>Notes: </label>
            <span>{leadInfo.notes}</span>
          </div>
        </div>
      ) : (
        <p>No Data Available</p>
      )}
    </Modal>
  );
};

export default LeadInfo;
