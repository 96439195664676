import React, {useState} from 'react';
import {Avatar, Button, Drawer, Form, Input, List, message, Typography} from 'antd';
import styles from './enrolleduserlist.module.scss';
import {useFormHandler} from "../../../utils/useFormHandler";
import {deleteEnrollment} from "../../../api/enrollment/enrollmentApi";
import {CloseOutlined} from "@ant-design/icons";

const {Text} = Typography;

interface RegisteredUserProps {
    onClose: () => void;
    open: boolean;
    batch: any;
}

interface FormValues {
    batch: number;
    students: number[];
}

const EnrolledUserList: React.FC<RegisteredUserProps> = ({open, onClose, batch}) => {
    const [selectedStudents, setSelectedStudents] = useState<number[]>(batch.enrolled_students);
    const [form] = Form.useForm();

    const onRemoveFinish = useFormHandler(
        async (id) => {
            await deleteEnrollment(id);
        },
        form,
        () => {
            message.success('Students removed successfully!');
            onClose(); // Close the drawer on success
        }
    );

    return (
        <div className={styles.registeredUserContainer}>
            <Drawer
                title="Enrolled Students"
                placement="right"
                open={open}
                onClose={onClose}
                width={"30%"} 
                className={styles.enrolledStudentDrawer}

            >
                <Form form={form} initialValues={{batch: batch.id}}>
                    <Form.Item name="batch" hidden>
                        <Input value={batch.id}/>
                    </Form.Item>

                    <List
                        itemLayout="horizontal"
                        dataSource={selectedStudents}
                        renderItem={(student: any) => (
                            <List.Item
                                actions={[
                                    <Button
                                        type="dashed"
                                        icon={<CloseOutlined style={{color: 'red'}}/>}
                                        onClick={() => onRemoveFinish(student.id)}
                                    >Remove</Button>

                                ]}
                                className={styles.regUserList}>
                                {/*<Checkbox checked={true}*/}
                                {/*    // checked={selectedStudents.includes(student.id)}*/}
                                {/*    // onChange={() => handleSelectStudent(student.id)}*/}
                                {/*/>*/}
                                <div className={styles.addedUsersDetails}>
                                    <Avatar style={{backgroundColor: '#f56a00'}}>{student.full_name.charAt(0)}</Avatar>
                                    <div>
                                        <Text strong>{student.full_name}</Text>
                                        <br/>
                                        <Text type="secondary">{student.phone_number}</Text>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </Form>

            </Drawer>
        </div>
    );
};

export default EnrolledUserList;
