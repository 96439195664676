import {Card as AntCard} from "antd"
import React from "react";
import cn from "classnames"
import style from "./imageCard.module.scss"
import { truncateText } from "../../utils/utils";

interface cardProps{
    title:any,
    className?:string,
    onClick?:(...agrs:any)=>void;
    image?:any;
    description?:string;
    iconBg?:string;
    children?:any;
    contentClassName?:string;
}

const ImageCard:React.FC<cardProps>=({image,description,children,title,iconBg,className,contentClassName,onClick,...props})=>{
    return(
        <AntCard 
            onClick={onClick} 
            className={cn(style.statsCard,className)} 
            bordered={false}
            {...props}
            >
            <div className={cn(style.cardBody)}>
                <div className={cn(style.imageDiv)}>
                    <img src={image} />
                </div>
                <div className={cn(style.contentDiv,contentClassName)}>
                    <h6 className={style.titleCard}>{title}</h6>
                    {description && <p className={style.descriptionTxt}>{ truncateText(description,100)}</p>}
                    {children}
                </div>
            </div>
        </AntCard>
    )   
}
export default ImageCard