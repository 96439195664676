import cn from "classnames";
import { Button, Table, Tag } from "antd";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import PageHeader from "../../common/page-header/PageHeader";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import styles from "./testSeriesCategories.module.scss";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import {useTestSeriesCategoryList} from "../../api/testSeries/testSeriesCategories";
import AddTestSeriesCategoryDrawer from "./Category/AddTestSeriesCategoryDrawer";

const TestSeriesCategories = () => {
    const [openCategoryDrawer, setOpenCategoryDrawer] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);

    const {
        data: categories,
        loading,
        fetchWithParams: categoryFetchWithParams,
        pagination,
    } = useTestSeriesCategoryList();

    const {
        params: categoryParams,
        updateParams: categoryUpdateParams,
        resetParams: categoryResetParams,
    } = useFilterSortPaginate(categoryFetchWithParams);

    const handleCategoryOpenDrawer = (record: any) => {
        setSelectedCategory(record);
        setOpenCategoryDrawer(true);
    };

    const handleCategoryCloseDrawer = () => {
        setSelectedCategory(null);
        setOpenCategoryDrawer(false);
        categoryResetParams();
    };

    const columns = [
        {
            title: "Category Name",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: number) => (
                <Tag color={status ? "green" : "red"}>
                    {status ? "Active" : "Inactive"}
                </Tag>
            ),
        },
        {
            title: "Action",
            render: (text: string, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Button onClick={() => handleCategoryOpenDrawer(record)}>
                        <CiEdit />
                    </Button>
                    <Button onClick={() => setOpenDeleteWarning(true)}>
                        <MdDeleteOutline />
                    </Button>
                </div>
            ),
        },
    ];

    const handleTableChange = (pagination: any) => {
        const params = {
            ...categoryParams,
            page: pagination.current,
        };
        categoryUpdateParams(params);
    };

    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Test Series Categories" />
            <section className={styles.actionBtnsSection}>
                <div className={styles.actionBtnDiv}>
                    <Button
                        onClick={() => setOpenCategoryDrawer(true)}
                        type="primary"
                    >
                        <FaPlus /> Add Category
                    </Button>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={categories}
                        pagination={pagination}
                        onChange={handleTableChange}
                    />
                </div>
            </section>

            <DeleteWarningModal
                open={openDeleteWarning}
                onClose={() => setOpenDeleteWarning(false)}
                onConfirm={() => {
                    // Handle the deletion logic here
                }}
                message={'Are you sure you want to delete this category?'}
            />

            {openCategoryDrawer && (
                <AddTestSeriesCategoryDrawer
                    open={openCategoryDrawer}
                    category={selectedCategory}
                    onClose={handleCategoryCloseDrawer}
                />
            )}
        </div>
    );
};

export default TestSeriesCategories;
