import cn from "classnames";
import {Button, message, Table, Tag} from "antd";
import React, {useState} from "react";
import {FaPlus} from "react-icons/fa6";
import {CiEdit} from "react-icons/ci";
import {MdDeleteOutline} from "react-icons/md";
import PageHeader from "../../common/page-header/PageHeader";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import styles from "./courseCategories.module.scss";
import AddCategoryDrawer from "./AddCategoryDrawer";
import AddSubCategoryDrawer from "./AddSubCategoryDrawer";
import {deleteCategory, useCategoryList} from "../../api/category/createUpdateDeleteListCategory";
import {deleteSubCategory, useSubCategoryList} from "../../api/subCategory/createUpdateDeleteListSubCategory";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import {Action, ModuleName, Role} from "../../utils/Role";
import {showErrors} from "../../utils/useFormHandler";

const CourseCategories = () => {
    const [openCategoryDrawer, setOpenCategoryDrawer] = useState<boolean>(false);
    const [openSubCategoryDrawer, setOpenSubCategoryDrawer] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null)

    const [openCategoryDeleteWarning, setOpenCategoryDeleteWarning] = useState<boolean>(false);
    const [openSubcategoryDeleteWarning, setOpenSubcategoryDeleteWarning] = useState<boolean>(false);
    const {
        data: categories,
        loading,
        fetchWithParams: categoryFetchWithParams,
        pagination,
    } = useCategoryList();
    const {
        params: categoryParams,
        updateParams: categoryUpdateParams,
        resetParams: categoryResetParams
    } = useFilterSortPaginate(categoryFetchWithParams);
    const {
        data: subCategories,
        pagination: subPagination,
        loading: subLoading,
        fetchWithParams: subCategoryFetchWithParams
    } = useSubCategoryList();
    const {
        params: subCategoryParams,
        updateParams: subCategoryUpdateParams,
        resetParams: subCategoryResetParams
    } = useFilterSortPaginate(subCategoryFetchWithParams);


    const handleCategoryOpenDrawer = (record: any) => {
        setSelectedCategory(record)
        setOpenCategoryDrawer(true)
    }
    const handleCategoryCloseDrawer = () => {
        setSelectedCategory(null)
        setOpenCategoryDrawer(false)
        categoryResetParams()

    }

    const handleCloseDeleteModal = () => {
        setSelectedCategory(null);
        setOpenCategoryDeleteWarning(false);
        categoryResetParams()
    };
    const handleOpenDeleteModal = (record: any) => {
        setSelectedCategory(record);
        setOpenCategoryDeleteWarning(true);
    };
    const handleOnConfirmDeleteModal = async () => {
        try {
            await deleteCategory(selectedCategory.id)
            message.success("Category deleted successfully")

        } catch (e) {
           showErrors(e)
        }
        handleCloseDeleteModal()
    }
    const columns = [
        {
            title: "Category",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: number) => (
                <Tag color={status ? "green" : "red"}>
                    {status ? "Active" : "Inactive"}
                </Tag>
            )
        },
        {
            title: "Action",
            render: (status: string, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Role moduleName={ModuleName.COURSES} action={Action.EDIT}>
                        <Button onClick={() => handleCategoryOpenDrawer(record)}>
                            <CiEdit/>
                        </Button>
                    </Role>
                    <Role moduleName={ModuleName.COURSES} action={Action.DELETE}>
                        <Button onClick={() => handleOpenDeleteModal(record)}>
                            <MdDeleteOutline/>
                        </Button>
                    </Role>
                </div>
            ),
        },
    ];

    const handleSubCategoryOpenDrawer = (record: any) => {
        setSelectedSubCategory(record)
        setOpenSubCategoryDrawer(true)
    }
    const handleSubCategoryCloseDrawer = () => {
        setSelectedSubCategory(null)
        setOpenSubCategoryDrawer(false)
        subCategoryResetParams()
    }

    const handleCloseSubCategoryDeleteModal = () => {
        setSelectedSubCategory(null);
        setOpenSubcategoryDeleteWarning(false);
        subCategoryResetParams()
    };
    const handleOpenSubCategoryDeleteModal = (record: any) => {
        setSelectedSubCategory(record);
        setOpenSubcategoryDeleteWarning(true);
    };
    const handleOnConfirmSubCategoryDelete = async () => {
        try {
            await deleteSubCategory(selectedSubCategory.id);
            message.success("Subcategory deleted successfully");

        } catch (e) {
            showErrors(e)
        }
        handleCloseSubCategoryDeleteModal()
    }
    const columnsSubCategory = [
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (category: any) => category.title
        }, {
            title: "Sub Category",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: number) => (
                <Tag color={status ? "green" : "red"}>
                    {status ? "Active" : "Inactive"}
                </Tag>
            )
        },
        {
            title: "Action",
            render: (status: string, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Role moduleName={ModuleName.COURSES} action={Action.EDIT}>
                        <Button onClick={() => handleSubCategoryOpenDrawer(record)}>
                            <CiEdit/>
                        </Button>
                    </Role>
                    <Role moduleName={ModuleName.COURSES} action={Action.DELETE}>
                        <Button onClick={() => handleOpenSubCategoryDeleteModal(record)}>
                            <MdDeleteOutline/>
                        </Button>
                    </Role>
                </div>
            ),
        },
    ];

    const handleSubCategoryTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        let params = {}
        // let symbol = '';
        // if (sorter.order === 'descend')
        //     symbol = '-';
        params = {
            ...subCategoryParams,
            page: pagination.current,
        }
        // if (sorter.columnKey) {
        //     params.ordering = `${symbol}${sorter.columnKey}`
        // }
        subCategoryUpdateParams(params);
    };
    const handleCategoryTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        let params = {}
        // let symbol = '';
        // if (sorter.order === 'descend')
        //     symbol = '-';
        params = {
            ...categoryParams,
            page: pagination.current,
        }
        // if (sorter.columnKey) {
        //     params.ordering = `${symbol}${sorter.columnKey}`
        // }
        categoryUpdateParams(params);
    };


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Course Configurations"/>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <section className={styles.actionBtnsSection}>
                        <div className="row">

                            <div className="col-sm-5 col-12">
                                <div className={styles.actionBtnDiv}>
                                    {/* <Button>Featured</Button> */}
                                    <Button
                                        onClick={() => setOpenCategoryDrawer(true)}
                                        type="primary"
                                    >
                                        <FaPlus/> Add Category
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table

                                loading={loading}
                                columns={columns}
                                dataSource={categories}
                                pagination={pagination}
                                onChange={handleCategoryTableChange}
                            />
                        </div>
                    </section>
                </div>
                <div className="col-sm-6 col-12">
                    <section className={styles.actionBtnsSection}>
                        <div className="row">

                            <div className="col-sm-5 col-12">
                                <div className={styles.actionBtnDiv}>
                                    {/* <Button>Featured</Button> */}
                                    <Button
                                        onClick={() => setOpenSubCategoryDrawer(true)}
                                        type="primary"
                                    >
                                        <FaPlus/> Add SubCategory
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                loading={subLoading}
                                columns={columnsSubCategory}
                                dataSource={subCategories}
                                pagination={subPagination}
                                onChange={handleSubCategoryTableChange}
                            />
                        </div>
                    </section>
                </div>
            </div>

            <DeleteWarningModal
                open={openCategoryDeleteWarning}
                onClose={handleCloseDeleteModal}
                onConfirm={handleOnConfirmDeleteModal}
                message={'Are you sure want to delete this category'}
            />
            <DeleteWarningModal
                open={openSubcategoryDeleteWarning}
                onClose={handleCloseSubCategoryDeleteModal}
                onConfirm={handleOnConfirmSubCategoryDelete}
                message={'Are you sure want to delete this sub category record'}
            />
            {openCategoryDrawer &&
                <AddCategoryDrawer
                    open={openCategoryDrawer}
                    category={selectedCategory}
                    onClose={handleCategoryCloseDrawer}
                />
            }
            {openSubCategoryDrawer &&
                <AddSubCategoryDrawer
                    open={openSubCategoryDrawer}
                    subCategory={selectedSubCategory}
                    onClose={handleSubCategoryCloseDrawer}
                />
            }
        </div>
    );
};
export default CourseCategories;
