import React from 'react';
import {Avatar, Drawer, List, Tag} from 'antd';
import styles from "./notificationViewDrawer.module.scss";
import {AnnouncementProps} from "../../types/announcement";
import {useStudentAnnouncementList} from "../../api/announcement/announcementApi";


interface NotificationVProps {
    open: boolean;
    onClose: () => void;
}

const NotificationViewDrawer: React.FC<NotificationVProps> = ({open, onClose}) => {
    const {data} = useStudentAnnouncementList();

    return (
        <Drawer
            title="Notifications"
            placement="right"
            onClose={onClose}
            open={open}
            width={"35%"}
            className={styles.notificationDrawer}
        >
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(notification: AnnouncementProps) => {
                    const {batch, course, created, title, message, created_by} = notification;
                    const date = new Date(created).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                    });

                    return (
                        <List.Item className={notification.is_global ? styles.globalNotification : ""}>
                            <List.Item.Meta
                                avatar={
                                    <Avatar>{notification.created_by?.charAt(0)}</Avatar>
                                }
                                title={
                                    <div>
                                        <strong>{title}</strong>
                                        <span style={{float: 'right', color: '#888'}}>{date}</span>
                                    </div>
                                }
                                description={
                                    <>
                                        <p className={styles.announceMsg}>{message}</p>
                                        {batch && <Tag color="blue">Batch: {batch.name}</Tag>}
                                        {course && <Tag color="green">Course: {course.name}</Tag>}
                                    </>
                                }
                            />
                        </List.Item>
                    );
                }}
            />
        </Drawer>
    );
};

export default NotificationViewDrawer;
