import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";
import {AnnouncementProps} from "../../types/announcement";

export interface AnnouncementRequestData {
    title: string;
    message: string;
    is_global: boolean;
    batch?: number;   // Optional: Batch ID if specific to a batch
    course?: number;  // Optional: Course ID if specific to a course
    created_by?: number; // Optional: User ID of the creator
}

export const createAnnouncement = async (values: AnnouncementRequestData) => {
    const response = await apiClient.post("/api/announcement/announcements/", values);
    return response.data;
};


export const useStudentAnnouncementList = (default_fetch: boolean=true) => {
    return useFetchList<AnnouncementProps>("/api/announcement/student-announcements/", default_fetch);
};
