import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

// Define the request data type for creating a category
export interface SubCategoryRequestData {
    title: string;
    description: string;
}

// Define the response type for category creation
interface SubCategoryResponse {
    message: string;
    category_id: number;  // Example of response data (adjust based on your backend)
}

// API call to create a category
export const createSubCategory = async (formData: SubCategoryRequestData): Promise<SubCategoryResponse> => {
    const response = await apiClient.post<SubCategoryResponse>('/api/course/subcategories/', formData, );
    return response.data;
};

// Define the request data type for updating a category
interface UpdateSubCategoryRequestData {
    title?: string;
    description?: string;
}

// Define the response type for category update
interface UpdateSubCategoryResponse {
    message: string;
    category_id: number;  // Example of response data (adjust based on your backend)
}

// API call to update a category
export const updateSubCategory = async (categoryId: number, values: UpdateSubCategoryRequestData): Promise<UpdateSubCategoryResponse> => {
    const response = await apiClient.put<UpdateSubCategoryResponse>(`/api/course/subcategories/${categoryId}/`, values, {
        headers: {
            'Content-Type': 'application/json',  // Change to JSON for category API
        },
    });
    return response.data;
};

// API call to delete a category
export const deleteSubCategory = async (categoryId: number): Promise<void> => {
    await apiClient.delete(`/api/course/subcategories/${categoryId}/`);
};


export const useSubCategoryList = () => {
    return useFetchList("/api/course/subcategories/");
};
