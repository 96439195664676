import apiClient from "../../utils/apiClient";
import {SettingsDataProps} from "../../types/settings";

export const getSettings = async (): Promise<SettingsDataProps> => {
    const response = await apiClient.get<SettingsDataProps>('/api/settings/retrieve/');
    return response.data;
};

export const updateSettings = async (updatedData: SettingsDataProps): Promise<SettingsDataProps> => {
    const response = await apiClient.post<SettingsDataProps>('/api/settings/update/', updatedData);
    return response.data;
};
