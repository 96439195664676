// Attendance.tsx
import React, {useState} from 'react';
import {Table, Tag} from 'antd';
import {BatchProps} from "../../types/batch";
import styles from "./attendance.module.scss";
import dayjs, {Dayjs} from "dayjs";
import {useStudentBatchAttendance} from "../../api/batch/student/studentBatch";
import {formattedDate, formattedDateTime} from "../../utils/utils";

interface AttendanceProps {
    batch: BatchProps;
}

const Attendance: React.FC<AttendanceProps> = ({batch}) => {
    const {data, loading} = useStudentBatchAttendance(batch.id)
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const columns = [
        {
            title: "Live Class",
            dataIndex: "live_class",
            key: "live_class",
        },
        {
            title: "Created",
            dataIndex: "created",
            key: "created",
            render: (text: string) => formattedDateTime(text),
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
            key: "start_time",
            render: (text: string) => formattedDateTime(text),
        },

        {
            title: "Attended",
            dataIndex: "attended",
            key: "attended",
            render: (attended: boolean) => (
                <Tag color={attended ? "green" : "red"}>
                    {attended ? "Attended" : "Not Attended"}
                </Tag>
            ),
        },


    ];
    const onPanelChange = (value: Dayjs) => {
        setSelectedDate(value);
    };


    const selectedMonthData: string[] = [];

    return (
        <section className={styles.tableSection}>
            <div className={styles.tableCard}>
                <Table loading={loading} columns={columns} dataSource={data}/>
            </div>
        </section>

    );
};

export default Attendance;
