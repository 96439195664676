import React, {useEffect, useState} from "react";
import {Button, Table, Tabs} from "antd";
import cn from "classnames";
import styles from "./studentFeeRecords.module.scss";
import {formattedDateTime} from "../utils/utils";
import {getStudentFeesRecords} from "../api/batch/batch";
import EnrollBatchModal from "../components/enroll-batch-modal/EnrollBatchModal";
import { MdCurrencyRupee } from "react-icons/md";

const StudentFeeRecords = () => {

    const [feesRecord, setFeesRecord] = useState<any>(null)
    const [activeTab, setActiveTab] = useState('1');
    const [openPurchaseModal, setOpenPurchaseModal] = useState(false)
    const [selectedBatch, setSelectedBatch] = useState(null)
    const [installment_number, setInstallment_number] = useState(1)
    const fetchRecords = async () => {
        try {
            const feesRecord = await getStudentFeesRecords()
            setFeesRecord(feesRecord)
        } catch (e) {
            // Handle error if needed
        }
    }

    useEffect(() => {
        fetchRecords()
    }, []);

    const handleOpenPaymentModal = (batch: any, installment_number: number) => {
        setOpenPurchaseModal(true)
        setSelectedBatch(batch)
        setInstallment_number(installment_number)
    }

    const handleClosePaymentModal = () => {
        setOpenPurchaseModal(false)
        setSelectedBatch(null)
        setInstallment_number(1)
    }

    const unpaidColumns = [
        {title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name'},
        {title: 'Amount', dataIndex: 'amount', key: 'amount',render:(amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {amount}</span>},
        {title: 'Due Date', dataIndex: 'due_date', key: 'due_date', render: (date: any) => formattedDateTime(date)},
        {
            title: 'Action',
            key: 'action',
            render: (record: any) => (
                <Button type="primary" onClick={() => handleOpenPaymentModal(record.batch, record.installment_number)}>
                    Pay Now
                </Button>
            )
        }
    ];

    const upcomingColumns = [
        {title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name'},
        {title: 'Amount', dataIndex: 'amount', key: 'amount', render:(amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {amount}</span>},
        {
            title: 'Upcoming Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (date: any) => formattedDateTime(date)
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: any) => (
                <Button type="primary" onClick={() => handleOpenPaymentModal(record.batch, record.installment_number)}>
                    Pay Now
                </Button>
            )
        }
    ];

    const paidColumns = [
        {title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name'},
        {title: 'Amount', dataIndex: 'amount', key: 'amount',render:(amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {amount}</span>},
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (date: any) => formattedDateTime(date)
        },
    ];

    return (
        <div className={cn("container-fluid")}>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>
                            {/* Uncomment the input and select components as needed */}
                        </div>
                    </div>
                </div>
            </section>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <Tabs.TabPane tab="Unpaid Fees" key="1">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={unpaidColumns}
                                dataSource={feesRecord?.unpaid_fees}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Upcoming Fees" key="2">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={upcomingColumns}
                                dataSource={feesRecord?.upcoming_fees}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Paid Fees" key="3">
                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                columns={paidColumns}
                                dataSource={feesRecord?.paid_fees}
                                pagination={false}
                            />
                        </div>
                    </section>
                </Tabs.TabPane>
            </Tabs>
            {openPurchaseModal && selectedBatch &&
                <EnrollBatchModal
                    installment_number={installment_number}
                    open={openPurchaseModal}
                    data={selectedBatch}
                    onClose={handleClosePaymentModal}
                />}
        </div>
    );
};

export default StudentFeeRecords;
