import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

// Define CouponRequestData interface
export interface CouponRequestData {
   id: number;
  created_by: string;
  created: string;
  modified: string;
  name: string;
  code: string;
  coupon_type: string;
  discount_type: string;
  discount_value: string;
  max_discount_amount: string | null;
  start_datetime: string;
  end_datetime: string;
  lifetime: boolean;
  min_order_value: string;
  max_uses: number;
  usage_per_student: number;
  is_visible: boolean;
  status: boolean;
  total_applied: number;
}

// Create a coupon
export const createCoupon = async (values: CouponRequestData) => {
    const response = await apiClient.post<CouponRequestData>('/api/coupon/coupons/', values);
    return response.data;
};

// Update a coupon
export const updateCoupon = async (id: number, values: CouponRequestData) => {
    const response = await apiClient.put<CouponRequestData>(`/api/coupon/coupons/${id}/`, values);
    return response.data;
};

export const useCouponList = () => {
    return useFetchList<CouponRequestData>("/api/coupon/coupons/");
};

export const deleteCoupon = async (id: number|null) => {
    const response = await apiClient.delete(`/api/coupon/coupons/${id}/`);
    return response.data;
};
