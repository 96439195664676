import {Modal} from 'antd';
import React from 'react';
import styles from './fees.module.scss';
import cn from 'classnames';
import {FeeStructureProps} from "../../types/feeStructure";

interface FeesInfoProps {
    open: boolean;
    onClose: () => void;
    feesInfo: FeeStructureProps;
}

const FeesStructure: React.FC<FeesInfoProps> = ({open, onClose, feesInfo}) => {
    if (!feesInfo) {
        return null; // No content to display if feesInfo is null
    }

    return (
        <Modal
            title={"Transaction Details"}
            centered
            open={open}
            onOk={onClose}
            onCancel={onClose}
            footer={false}
            className={styles.feesInfo}
        >
            <div className="row">
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Structure Name: </label>
                    <span>{feesInfo.structure_name}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Fee Amount: </label>
                    <span>{feesInfo.fee_amount}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Frequency: </label>
                    <span>{feesInfo.frequency}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Number of Values: </label>
                    <span>{feesInfo.number_of_values}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Installments: </label>
                    <span>{feesInfo.installments}</span>
                </div>
                <div className={cn(styles.details, "col-sm-6 col-12")}>
                    <label>Total Amount: </label>
                    <span>{feesInfo.total_amount}</span>
                </div>


            </div>
        </Modal>
    );
};

export default FeesStructure;
