import React, {useState} from "react";
import {Button, Table} from "antd";
import {IoEyeOutline} from "react-icons/io5";
import {CiEdit} from "react-icons/ci";
import {MdCurrencyRupee, MdDeleteOutline} from "react-icons/md";
import cn from "classnames";
import {deleteFeeStructure, useFeeStructureList} from "../../api/fees/feesApi";
import styles from "../../components/fee-structure/feeStructure.module.scss";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import AddFeeStructDrawer from "../../components/add-fee-struct-drawer/AddFeeStructDrawer";
import {FaPlus} from "react-icons/fa6";
import {FeeStructureProps} from "../../types/feeStructure";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import FeesStructure from "../fees-structure/FeeStructuremodal";
import {Action, ModuleName, Role, UserRole} from "../../utils/Role";

const FeeStructure = () => {
    const {data, loading, fetchWithParams} = useFeeStructureList(); // Fetch fee structure data from a hook
    const {
        resetParams
    } = useFilterSortPaginate(fetchWithParams);
    const [openFeeStructureDrawer, setOpenFeeStructureDrawer] = useState<boolean>(false);
    const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false);
    const [showFeesInfo, setShowFeesInfo] = useState<boolean>(false);
    const [selectedFees, setSelectedFees] = useState<FeeStructureProps | null>(null); // State for editing fee structure



    const handleOpenCreateDrawer = (fees: any) => {
        setSelectedFees(fees);
        setOpenFeeStructureDrawer(true);
    };
    const handleCloseCreateDrawer = () => {
        setSelectedFees(null);
        setOpenFeeStructureDrawer(false);
        resetParams()
    };

    const handleConfirmDelete = async () => {
        await deleteFeeStructure(selectedFees!.id)
        setOpenDeleteWarning(false)
        setSelectedFees(null)
        resetParams()
    }

    const columns = [
        {
            title: "Fee Structure Name",
            dataIndex: "structure_name", // This should match the key in your data
            key: "structure_name",
        },
        {
            title: "Installment Amount",
            dataIndex: "fee_amount", // This should match the key in your data
            key: "fee_amount",
            render:(fee_amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {fee_amount}</span>
        },
        {
            title: "No. of Installments",
            dataIndex: "installments", // This should match the key in your data
            key: "installments",
        },
        {
            title: "Installment Frequency",
            // dataIndex: "frequency", 
            // key: "frequency",
            render:(record:any)=>record.number_of_values===1?<span>One-Time</span>:
                    <span>{record.number_of_values} - {record.frequency}</span>
        },
        // {
        //     title: "Number of Values",
        //     dataIndex: "number_of_values", // This should match the key in your data
        //     key: "number_of_values",
        // },
        {
            title: "Total Amount",
            dataIndex: "total_amount", // This should match the key in your data
            key: "total_amount",
            render:(total_amount:any)=><span className={styles.amountSymbolTag}><MdCurrencyRupee /> {total_amount}</span>
        },
        {
            title: "Action",
            render: (_: any, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Button onClick={() => {
                        setSelectedFees(record); // Set the user data to view
                        setShowFeesInfo(true);
                    }}>
                        <IoEyeOutline/>
                    </Button>
                    <Role moduleName={ModuleName.FEE_STRUCTURE} action={Action.EDIT}>
                    <Button onClick={() => handleOpenCreateDrawer(record)}>
                        <CiEdit/>
                    </Button>
                    </Role>
                    <Role moduleName={ModuleName.FEE_STRUCTURE} action={Action.DELETE}>
                    <Button onClick={() => {
                        setSelectedFees(record); // Use `id` from API response
                        setOpenDeleteWarning(true);
                    }}>
                        <MdDeleteOutline/>
                    </Button>
                    </Role>
                </div>
            ),
        },
    ];

    return (
        <div className={cn("container-fluid")}>
            <section className={styles.actionBtnsSection}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className={styles.filterDiv}>

                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className={styles.actionBtnDiv}>
                            <Button onClick={() => setOpenFeeStructureDrawer(true)}
                                    type="primary"
                            >
                                <FaPlus/> Create Structure
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.tableSection}>
                <div className={styles.tableCard}>
                    <Table columns={columns} dataSource={data} rowKey="id" loading={loading}/>
                </div>
            </section>

            {/* Drawer and Modal components */}
            {openFeeStructureDrawer &&
                <AddFeeStructDrawer
                    open={openFeeStructureDrawer}
                    onClose={handleCloseCreateDrawer}
                    fees={selectedFees}
                />
            }
            <DeleteWarningModal
                open={openDeleteWarning}
                onClose={() => setOpenDeleteWarning(false)}
                onConfirm={handleConfirmDelete}
                message={'Are you sure you want to delete this record?'}
            />
            {showFeesInfo && selectedFees &&
                <FeesStructure // for view user detail
                    open={showFeesInfo}
                    onClose={() => setShowFeesInfo(false)}
                    feesInfo={selectedFees}
                />
            }
        </div>
    );
};

export default FeeStructure;
