import apiClient from "../../utils/apiClient";
import useFetchList from "../../utils/listClient";

// Define the request data type for creating a category
export interface CategoryRequestData {
    title: string;
    description: string;
}

// Define the response type for category creation
interface CategoryResponse {
    message: string;
    category_id: number;  // Example of response data (adjust based on your backend)
}

// API call to create a category
export const createCategory = async (values: CategoryRequestData): Promise<CategoryResponse> => {
    const response = await apiClient.post<CategoryResponse>('/api/course/categories/', values, {
        headers: {
            'Content-Type': 'application/json',  // Change to JSON for category API
        },
    });
    return response.data;
};

// Define the request data type for updating a category
interface UpdateCategoryRequestData {
    title?: string;
    description?: string;
}

// Define the response type for category update
interface UpdateCategoryResponse {
    message: string;
    category_id: number;  // Example of response data (adjust based on your backend)
}

// API call to update a category
export const updateCategory = async (categoryId: number, values: UpdateCategoryRequestData): Promise<UpdateCategoryResponse> => {
    const response = await apiClient.put<UpdateCategoryResponse>(`/api/course/categories/${categoryId}/`, values, {
        headers: {
            'Content-Type': 'application/json',  // Change to JSON for category API
        },
    });
    return response.data;
};

// API call to delete a category
export const deleteCategory = async (categoryId: number): Promise<void> => {
    await apiClient.delete(`/api/course/categories/${categoryId}/`);
};


export const useCategoryList = () => {
    return useFetchList("/api/course/categories/");
};
