import React, {useEffect, useState} from "react";
import {Button, Card, Col, message, Popconfirm, Row, Table, Tag} from "antd";
import PageHeader from "../../common/page-header/PageHeader";
import StatsCard from "../../common/StatsCard/StatsCard";
import cn from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import {deleteBatch, deleteOfflineClassForBatch, detailBatch, toggleBatchPublish} from "../../api/batch/batch";
import LiveClassDrawer from "./Batch/LiveClassDrawer";
import BatchStudentRequestEnroll from "./Batch/BatchStudentRequestEnroll";
import BatchStudentEnroll from "./Batch/BatchStudentEnroll"; // Assuming you've created this drawer component
import {FiDelete, FiEyeOff, FiUpload, FiUserPlus, FiVideo} from 'react-icons/fi';
import {BatchProps} from "../../types/batch"; // Example icon for Add Student // Example icon for Go Live
import styles from "./batchesList.module.scss"
import PageLoader from "../../common/page-loader/PageLoader";
import RegisteredUser from "./Batch/RegisteredUser";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";
import {showErrors, useFormHandler} from "../../utils/useFormHandler";
import moment from "moment";
import LiveClassList from "./Batch/LiveClassList";
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import CreateBatchClassDrawer from "./Batch/CreateBatchClassDrawer";
import EnrolledUserList from "./Batch/EnrolledUserList";
import {CiEdit} from "react-icons/ci";
import {CgFileDocument} from "react-icons/cg";
import AddBatch from "./Batch/CreateBatchDrawer";
import ContentModal from "../../components/ContentModel/ContentModel";
import {Action, ModuleName, Role} from "../../utils/Role";
import AnnouncementCreateDrawer from "../Announcement/AnnouncementCreateDrawer";
import {TfiAnnouncement} from "react-icons/tfi";
import {FaChalkboardTeacher} from "react-icons/fa";

import BatchAssignFaculty from "../../components/AssignFaculty/BatchAssignFaculty";

const BatchesDetail = () => {

    const {batchId} = useParams<{ batchId: string }>(); // Specify the type of useParams
    const [batch, setBatch] = useState<any>(null); // State to store course data
    const [loading, setLoading] = useState<boolean>(true); // State for loading status
    const [error, setError] = useState<string | null>(null); // State for error handling

    const [liveClassDrawer, setLiveClassDrawer] = useState(false)
    const [enrollStudentDrawer, setEnrollStudentDrawer] = useState(false)
    const [enrollRequestStudentDrawer, setEnrollRequestStudentDrawer] = useState(false)
    const [openExitingUserDrawer, setExitingUserDrawer] = useState<boolean>(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
    const [deleteBtnLoading, setDeleteBtnLoading] = useState<boolean>(false);
    const [openAddClass, setOpenAddClass] = useState<boolean>(false);
    const [openEnrolledUserDrawer, setOpenEnrolledUserDrawer] = useState<boolean>(false);
    const [openContentModal, setOpenContentModal] = useState(false);
    const [openCreateBatch, setOpenCreateBatch] = useState(false);
    const [openAnnouncementDrawer, setOpenAnnouncementDrawer] = useState(false);
    const [openBatchFacultyDrawer, setOpenBatchFacultyDrawer] = useState(false);


    const navigate = useNavigate()
    const onDeleteBatchFinish = useFormHandler(
        async () => {
            setDeleteBtnLoading(true);
            await deleteBatch(batch.id)
        },
        null,
        () => {
            setDeleteBtnLoading(false)
            message.success('Batch deleted successfully!');
            navigate('/batches')
            // resetParams()
        }
    );

    const onBackClick = () => {
        navigate(-1)
    }

    const fetchBatchDetails = async () => {
        if (!batchId) {
            setError('Batch ID is missing'); // Handle missing courseId
            setLoading(false);
            return;
        }

        try {
            const batchData: any = await detailBatch(batchId); // Fetch course details
            setBatch(batchData); // Set the fetched data to state
        } catch (err) {
            setError('Failed to fetch course details'); // Set error message if any
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };
    useEffect(() => {
        fetchBatchDetails(); // Call the fetch function
    }, [batchId]); // Dependency array to re-fetch if courseId changes

    // Render loading, error, or course details
    if (loading) return <PageLoader/>;
    if (error) return <div>{error}</div>;


    const handleCloseStudentEnrollDrawer = () => {
        setEnrollStudentDrawer(false)
    }
    const handleOpenStudentRequestEnrollDrawer = () => {
        setEnrollRequestStudentDrawer(true)
    }
    const handleCloseStudentRequestEnrollDrawer = async () => {
        setEnrollRequestStudentDrawer(false)
        await fetchBatchDetails()
    }
    const 
    handleLiveClasses = () => {
        setLiveClassDrawer(true)
    }
    const handleCloseLiveClassesDrawer = async () => {
        setLiveClassDrawer(false)
        await fetchBatchDetails()
    }

    const handleBatchOpenDrawer = () => {
        setOpenCreateBatch(true)
    }

    const handleBatchCloseDrawer = async () => {
        setOpenCreateBatch(false)
        await fetchBatchDetails()
    }

    const handleContentOpenDrawer = () => {
        setOpenContentModal(true)
    }
    const handleAnnouncementOpenDrawer = () => {
        setOpenAnnouncementDrawer(true)
    }
    const handleAnnouncementCloseDrawer = () => {
        setOpenAnnouncementDrawer(false)
    }

    const handleFacultyOpenDrawer = () => {
        setOpenBatchFacultyDrawer(true)

    }

    const handleFacultyCloseDrawer = () => {
        setOpenBatchFacultyDrawer(false)
    }

    const handleBatchTogglePublish = async (batch: BatchProps) => {
        try {
            // Call the updateBatch function to update the batch
            await toggleBatchPublish(batch.id);

            // Optionally, you can refresh the batch list or update the local state here
            // e.g., setBatches((prevBatches) => prevBatches.map(b => b.id === batch.id ? updatedValues : b));

            // Optionally show a success message
            message.success(`Batch ${!batch.is_published ? 'published' : 'disable'} successfully.`);
            fetchBatchDetails()
        } catch (error) {
            console.error('Failed to update batch:', error);
            // Optionally show an error message
            message.error('Failed to update the batch status.');
        }
    };

    const onOfflineClassDelete = async (classId: number, scheduleId: number) => {
        try {
            await deleteOfflineClassForBatch(classId, scheduleId)
            message.success("Offline class delete successfully")
            fetchBatchDetails()
        } catch (e) {
            showErrors(e)
        }
    }
    const batchClassesColumns = [
        {
            title: 'Batch',
            dataIndex: ['class', 'batch'],
            key: 'batch'
        },
        {
            title: 'Faculty',
            dataIndex: ['class', 'faculty'],
            key: 'faculty'
        },
        {
            title: 'Class Type',
            dataIndex: ['class', 'class_type'],
            key: 'class_type',
            render: (class_type: string) => (
                <Tag color={class_type === 'regular' ? 'blue' : 'green'}>
                    {class_type === 'regular' ? 'Regular Class' : 'One-Time Class'}
                </Tag>
            )
        },
        {
            title: 'Time Slot (Day)',
            dataIndex: 'time_slot',
            key: 'time_slot',
            render: (time_slot: string) => (
                <Tag color="blue">{time_slot}</Tag>
            )
        },
        {
            title: 'Schedules',
            key: 'schedules',
            render: (_: any, record: any) => (
                <Table
                    dataSource={record.schedules}
                    pagination={false}
                    rowKey="id"
                    columns={[
                        {
                            title: 'Start Time',
                            dataIndex: 'start_time',
                            key: 'start_time'
                        },
                        {
                            title: 'End Time',
                            dataIndex: 'end_time',
                            key: 'end_time'
                        },
                        {
                            title: 'Actions',
                            key: 'actions',
                            render: (_: any, schedule: any) => (
                                <Popconfirm
                                    title="Are you sure you want to delete this class?"
                                    onConfirm={() => {
                                        return onOfflineClassDelete(record.id, schedule.id)
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button style={{padding: 0}} type="link" danger>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            ),
                        },
                    ]}
                    bordered
                />
            )
        },

    ];

    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Batches" onBackClick={onBackClick}/>

            <Row gutter={24}>
                {/* Left side: Form */}
                <Col xs={24} md={16}>
                    <Col xs={24} md={24}>
                        <div className={styles.detailViewCard}>
                            <div className={styles.labelValData}>
                                <label>Batch Name</label>
                                <span>{batch.name}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Start Date</label>
                                <span>{moment(batch.start_date).format('DD MMM YYYY')}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Batch Code</label>
                                <span>{batch.batch_code}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Subject</label>
                                <span>{batch?.subject?.name || ""}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Total Enroll</label>
                                {batch.total_enrolled_students > 0 ?
                                    <label className={styles.linkLabel}
                                           onClick={() => setOpenEnrolledUserDrawer(true)}>Views all
                                        ({batch.total_enrolled_students})</label>
                                    :
                                    <label>No Enrollment</label>
                                }
                            </div>
                            <div className={styles.labelValData}>
                                <label>Joining Request</label>
                                {batch.student_join_request.length > 0 ?
                                    <label className={styles.linkLabel} onClick={handleOpenStudentRequestEnrollDrawer}>
                                        Check Requests ({batch.student_join_request.length})
                                    </label> :
                                    <label>No Request</label>
                                }
                            </div>
                            <div className={styles.labelValData}>
                                <label>Status</label>
                                <span>{batch.is_published ? "Published" : "UnPlublished"}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Created By</label>
                                <span>{batch.created_by}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Created At</label>
                                <span>{moment(batch.created).format('DD MMM YYYY')}</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={24}>
                        <h4 className={styles.sectionHeading}>Schedule Live Classes</h4>
                        <LiveClassList batch={batch} fetchBatchDetails={fetchBatchDetails}/>
                    </Col>
                    <Col xs={24} md={24} className={styles.batchClassesTable}>
                        <h4 className={styles.sectionHeading}>Offline Classes</h4>
                        <Table rowKey={(record: any) => `${record.class.id}-${record.time_slot}`}
                               columns={batchClassesColumns} pagination={false} dataSource={batch.offline_classes}/>
                    </Col>
                    <Col xs={24} md={24} className={styles.batchFeeStructure}>
                        <h4 className={styles.sectionHeading}>Fee Structure</h4>
                        <div className={styles.feeDetailViewCard}>
                            <div className={styles.labelValData}>
                                <label>Structure Name</label>
                                <span>{batch.fee_structure.structure_name}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Fee Amount</label>
                                <span>₹ {batch.fee_structure.fee_amount}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>No. of Installments</label>
                                <span>{batch.fee_structure.installments}</span>
                            </div>
                            <div className={styles.labelValData}>
                                <label>Total Amount</label>
                                <span>₹ {batch.fee_structure.total_amount}</span>
                            </div>
                        </div>
                    </Col>
                </Col>
                {/* Right side: Go Live and Add Student */}
                <Col xs={24} md={8}>
                    <div className={styles.detailViewAction}>
                        <Card title="Actions">
                            <Row gutter={[0, 16]}>
                                <Role moduleName={ModuleName.BATCHES} action={Action.EDIT}>
                                    <Col span={24}>
                                        <StatsCard onClick={handleBatchOpenDrawer}
                                                   icon={<CiEdit/>}
                                                   title="Edit Batch"
                                                   description=""/>
                                    </Col>
                                </Role>
                                <Col span={24}>
                                    <StatsCard
                                        onClick={handleContentOpenDrawer}
                                        icon={<CgFileDocument/>}
                                        title="Content"
                                        description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard
                                        icon={<FiVideo/>}
                                        title="Go Live"
                                        description=""
                                        onClick={handleLiveClasses}/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={() => setExitingUserDrawer(true)}
                                               icon={<FiUserPlus/>}
                                               title="Add Student"
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={handleFacultyOpenDrawer}
                                               icon={<FaChalkboardTeacher/>}
                                               title="Assign Instructor"
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={handleAnnouncementOpenDrawer}
                                               icon={<TfiAnnouncement/>}
                                               title="Annoucement"
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={() => setOpenAddClass(true)}
                                               icon={<LiaChalkboardTeacherSolid/>}
                                               title="Add Offline Classes"
                                               description=""/>
                                </Col>
                                <Col span={24}>
                                    <StatsCard onClick={() => handleBatchTogglePublish(batch)}
                                               icon={batch.is_published ? <FiEyeOff/> : <FiUpload/>}
                                               title={batch.is_published ? "Disable Batch" : "Publish Batch"}
                                               description=""/>
                                </Col>
                                <Role moduleName={ModuleName.BATCHES} action={Action.DELETE}>
                                    <Col span={24}>

                                        <StatsCard
                                            onClick={() => setVisibleDeleteModal(true)}
                                            icon={<FiDelete/>}
                                            title="Delete Batch"
                                            description=""/>

                                    </Col>
                                </Role>

                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>

            {openCreateBatch && batch && (
                <AddBatch
                    open={openCreateBatch}
                    onClose={handleBatchCloseDrawer}
                    batch={batch}
                />
            )}
            {openContentModal && batch &&
                <ContentModal
                    open={openContentModal}
                    batch={batch}
                    type={"batch"}
                    onClose={() => setOpenContentModal(false)}
                />
            }

            {/* Drawer Component */}
            {enrollStudentDrawer && batch &&
                <BatchStudentEnroll
                    batchName="Enroll Student"
                    students={batch.enrolled_students}
                    visible={enrollStudentDrawer}
                    onClose={handleCloseStudentEnrollDrawer}
                />
            }
            {enrollRequestStudentDrawer && batch &&
                <BatchStudentRequestEnroll
                    batchName="Student Join Request"
                    students={batch.student_join_request}
                    visible={enrollRequestStudentDrawer}

                    onClose={handleCloseStudentRequestEnrollDrawer}
                />
            }
            {openExitingUserDrawer && (
                <RegisteredUser
                    open={openExitingUserDrawer}
                    batch={batch}
                    onClose={() => setExitingUserDrawer(false)}
                />
            )}
            {openEnrolledUserDrawer && batch &&
                <EnrolledUserList batch={batch} open={openEnrolledUserDrawer}
                                  onClose={() => setOpenEnrolledUserDrawer(false)}/>
            }
            {batch && liveClassDrawer &&
                <LiveClassDrawer batch={batch} open={liveClassDrawer} onClose={handleCloseLiveClassesDrawer}/>
            }
            <DeleteWarningModal
                okBtnLoading={deleteBtnLoading}
                open={visibleDeleteModal}
                onConfirm={() => onDeleteBatchFinish(batch.id)}
                onClose={() => setVisibleDeleteModal(false)}
                message={"Are you sure, want to delete this batch?"}
            />
            {openAddClass && batch &&
                <CreateBatchClassDrawer
                    open={openAddClass}
                    batch={batch}
                    onClose={() => {
                        setOpenAddClass(false)
                        fetchBatchDetails()
                    }}
                />
            }

            {openBatchFacultyDrawer && batch &&
                <BatchAssignFaculty
                    open={openBatchFacultyDrawer}
                    batch={batch}
                    onClose={handleFacultyCloseDrawer}
                />
            }

            {openAnnouncementDrawer && batch &&
                <AnnouncementCreateDrawer
                    open={openAnnouncementDrawer}
                    batch={batch}
                    onClose={handleAnnouncementCloseDrawer}
                />
            }
        </div>
    );
};

export default BatchesDetail;
