import React, {useState} from 'react';
import {Button, Input, message, Spin} from 'antd';

interface ApplyCouponProps {
    onApplyCoupon: (couponCode: string) => void;
    disabled?: boolean;
    onDeleteCoupon: () => void;
}

const ApplyCoupon: React.FC<ApplyCouponProps> = ({onDeleteCoupon, onApplyCoupon, disabled = false}) => {
    const [couponCode, setCouponCode] = useState<string>('');
    const [applying, setApplying] = useState<boolean>(false);

    // Function to handle coupon code input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCouponCode(e.target.value);
    };

    // Function to handle "Apply" button click
    const handleApplyClick = () => {
        if (couponCode.trim() === '') {
            message.error('Please enter a valid coupon code.');
            return;
        }
        setApplying(true);

        onApplyCoupon(couponCode); // Trigger callback function with the entered coupon code
        setApplying(false);

    };

    const handleDeleteClick = () => {
        setCouponCode("")
        onDeleteCoupon()
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <Input
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={handleInputChange}
                style={{width: 200}}
                disabled={disabled}
            />
            <Button type="primary" onClick={handleApplyClick} disabled={disabled || applying}>
                {applying ? <Spin size="small"/> : 'Apply'}
            </Button>
            {couponCode && disabled &&
                <Button type="primary" className={'bg-danger'} onClick={handleDeleteClick}>
                    Delete
                </Button>
            }

        </div>
    );
};

export default ApplyCoupon;
